import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { Box, Skeleton } from '../../1-primative';
import {
  Table,
  TableRow, TableCell, TableBody, Pagination, TextField,
  CardContent, TableHeadCell, Card,
} from '../../2-component';
import { usePermissions, UserContext } from '../../../providers/userContextProvider';
import { User } from '../../../interfaces';
import { formatMoneyValue, generateClientNameString } from '../../../util';
import { usePageState } from '../../../util/usePageState';
import { useLocalization } from '../../../util/useLocalization';

const FETCH_USERS = (permissions: string[]) => gql`
  query fetchUsers($input: FetchUsersInput!) {
    fetchUsers(input: $input) {
      users {
        id
        ${permissions.includes('read:client_low_risk_pii') ? 'firstName' : ''}
        ${permissions.includes('read:client_low_risk_pii') ? 'lastName' : ''}
        ${permissions.includes('read:client_suitability') ? 'lastSuitabilityReviewAt' : ''}
        completedAt
        statistics {
          marketValueCents
        }
        organization {
          name
        }
      }
      totalCount
    }
  }
`;

export const AnnualSuitabilityReview = () => {
  const { permissions } = usePermissions();
  const { activeOrganization } = useContext(UserContext);
  const { t } = useTranslation(['annualSuitabilityReview']);
  const { localizedDateTime } = useLocalization();
  const navigate = useNavigate();
  const [page, setPage] = usePageState(1, 'page');
  const [searchText, setSearchText] = usePageState('', 'q');
  const [pageSize, setPageSize] = usePageState(15, 'ps');
  const [sortField, setSortField] = usePageState('lastSuitabilityReviewAt', 's');
  const [sortDesc, setSortDesc] = usePageState(true, 'desc');

  const {
    loading, data, previousData,
  } = useQuery(FETCH_USERS(permissions), {
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        filter: {
          suitabilityReviewDue: true,
          searchText,
          organizationId: activeOrganization.id,
        },
        pagination: {
          sortField: sortField ?? 'lastSuitabilityReviewAt',
          sortDesc,
          perPage: pageSize,
          offSet: (page - 1) * pageSize,
        },
      },
    },
  });

  const daysPastDue = (date: string | undefined): string => {
    const suitabilityReviewDate: Date = dayjs().utc().subtract(12, 'month').toDate();
    if (!date) return '';
    return dayjs(suitabilityReviewDate).diff(date, 'day').toString();
  };

  const onSort = (field: string) => {
    if (sortField === field) {
      setSortDesc(!sortDesc);
    }
    setSortField(field);
  };

  const sortDirection = (field: string) => (sortField === field ? sortDesc ? 'desc' : 'asc' : undefined);

  return (
    <Card loading={loading}>
      <Box>
        <CardContent>
          <Box display='flex' justifyContent='space-between' flexWrap='wrap'>
            <TextField
              value={searchText}
              onChange={(event: any) => {
                setSearchText(event.target.value);
                setPage(1);
              }}
              leadingIcon='search'
            />
          </Box>
        </CardContent>
        <Box sx={{ overflowX: 'auto' }}>
          <Table sx={{ minWidth: 650 }} aria-label="table">
            <TableBody>
              <TableRow>
                <TableHeadCell
                  isSortable
                  onClick={() => onSort('firstName')}
                  sortDirection={sortDirection('firstName')}
                >
                  {t('client')}
                </TableHeadCell>
                <TableHeadCell
                  isSortable
                  onClick={() => onSort('completedAt')}
                  sortDirection={sortDirection('completedAt')}
                >
                  {t('completedAt')}
                </TableHeadCell>
                <TableHeadCell
                  isSortable
                  onClick={() => onSort('lastSuitabilityReviewAt')}
                  sortDirection={sortDirection('lastSuitabilityReviewAt')}
                >
                  {t('previousInformationUpdate')}
                </TableHeadCell>
                <TableHeadCell
                  isSortable
                  onClick={() => onSort('lastSuitabilityReviewAt')}
                  sortDirection={sortDirection('lastSuitabilityReviewAt')}
                  number
                >
                  {t('daysPastDue')}
                </TableHeadCell>
                <TableHeadCell>{t('marketValue')}</TableHeadCell>
                <TableHeadCell>{t('organization')}</TableHeadCell>
              </TableRow>
              { loading && !previousData && [...Array(15)].map((x: any, i: number) => (
                <TableRow key={i}>
                  <TableCell><Skeleton width='100%' /></TableCell>
                  <TableCell><Skeleton width='100%' /></TableCell>
                  <TableCell><Skeleton width='100%' /></TableCell>
                  <TableCell><Skeleton width='100%' /></TableCell>
                  <TableCell><Skeleton width='100%' /></TableCell>
                  <TableCell><Skeleton width='100%' /></TableCell>
                  <TableCell><Skeleton width='100%' /></TableCell>
                </TableRow>
              ))}
              {(data || previousData)?.fetchUsers?.users?.map((user: User) => (
                <TableRow
                  hover
                  key={user.id}
                  sx={{ textDecoration: 'none', cursor: 'pointer' }}
                  onClick={() => navigate(`/clients/${user.id}`)}
                >
                  {permissions.includes('read:client_low_risk_pii') ? (
                    <>
                      <TableCell>
                        {generateClientNameString(user)}
                      </TableCell>
                    </>
                  ) : (
                    <TableCell>
                      {user.id}
                    </TableCell>
                  )}
                  <TableCell>{localizedDateTime(user.completedAt)}</TableCell>
                  <TableCell>{localizedDateTime(user.lastSuitabilityReviewAt)}</TableCell>
                  <TableCell>{daysPastDue(user.lastSuitabilityReviewAt)}</TableCell>
                  <TableCell number>{formatMoneyValue(user.statistics?.marketValueCents)}</TableCell>
                  <TableCell>{user.organization?.name}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
        <Pagination
          count={Math.ceil(((data || previousData)?.fetchUsers?.totalCount ?? 0) / pageSize)}
          page={page}
          perPage={pageSize}
          onChangePerPage={(newPageSize) => setPageSize(newPageSize)}
          onChange={(_e, newPage) => setPage(newPage)}
          sx={{
            p: 1,
            textAlign: 'right',
            '.MuiPagination-ul': {
              justifyContent: 'end',
            },
          }}
        />
      </Box >
    </Card>
  );
};

export default AnnualSuitabilityReview;
