import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { translateBackend } from 'assets/i18n/config';
import { gql, useMutation, useQuery } from '@apollo/client';
import {
  Box, Grid, Skeleton, Typography,
} from '../../../1-primative';
import {
  Button, Card, CardContent, Form,
  MenuItem,
  SelectField,
} from '../../../2-component';
import { PageObjectType } from '../../../5-page';
import { FeeGridSelect, BillingScheduleSelect } from '../../../3-pattern';
import {
  ACCOUNT_FETCH, HOUSEHOLD_FETCH, SUB_ACCOUNT_FETCH, USER_FETCH,
  UPDATE_ACCOUNT, UPDATE_HOUSEHOLD, UPDATE_SUB_ACCOUNT, UPDATE_USER,
  FETCH_ACCOUNTS,
} from './feeDetails.queries';
import { usePermissions } from '../../../../providers/userContextProvider';

export const FeeDetails = ({ objectId, objectType, options }: { objectId: string, objectType: PageObjectType, options: any }) => {
  const { t } = useTranslation(['components', 'shared']);
  const { permissions } = usePermissions();
  const [feeTier, setFeeTier] = useState('');
  const [billingSchedule, setBillingSchedule] = useState('');
  const [feeAccount, setFeeAccount] = useState('');
  const [availableAccounts, setAvailableAccounts] = useState<any[]>([]);
  const [edited, setEdited] = useState(false);

  const query = () => {
    switch (objectType) {
      case PageObjectType.INDIVIDUAL:
      case PageObjectType.NON_INDIVIDUAL:
        return USER_FETCH;
      case PageObjectType.ACCOUNT:
        return ACCOUNT_FETCH;
      case PageObjectType.HOUSEHOLD:
        return HOUSEHOLD_FETCH;
      case PageObjectType.SUB_ACCOUNT:
        return SUB_ACCOUNT_FETCH;
      default:
        return gql``;
    }
  };

  const mutation = () => {
    switch (objectType) {
      case PageObjectType.INDIVIDUAL:
      case PageObjectType.NON_INDIVIDUAL:
        return UPDATE_USER;
      case PageObjectType.ACCOUNT:
        return UPDATE_ACCOUNT;
      case PageObjectType.HOUSEHOLD:
        return UPDATE_HOUSEHOLD;
      case PageObjectType.SUB_ACCOUNT:
        return UPDATE_SUB_ACCOUNT;
      default:
        return gql``;
    }
  };

  const mutationVariable = () => {
    switch (objectType) {
      case PageObjectType.INDIVIDUAL:
      case PageObjectType.NON_INDIVIDUAL:
        return { userId: objectId };
      case PageObjectType.ACCOUNT:
        return { accountId: objectId };
      case PageObjectType.HOUSEHOLD:
        return { clientGroupId: objectId };
      case PageObjectType.SUB_ACCOUNT:
        return { subAccountId: objectId };
      default:
        return gql``;
    }
  };

  const path = (d: any) => {
    switch (objectType) {
      case PageObjectType.INDIVIDUAL:
      case PageObjectType.NON_INDIVIDUAL:
        return d.fetchUser.user;
      case PageObjectType.ACCOUNT:
        return d.fetchAccount.account;
      case PageObjectType.HOUSEHOLD:
        return d.fetchClientGroup.clientGroup;
      case PageObjectType.SUB_ACCOUNT:
        return d.fetchSubAccount.subAccount;
      default:
        return gql``;
    }
  };

  const { data, loading, refetch } = useQuery(query(), {
    variables: {
      id: objectId,
    },
    skip: [PageObjectType.INDIVIDUAL, PageObjectType.NON_INDIVIDUAL].includes(objectType) && !permissions.includes('read:client_billing'),
  });

  const { data: accountsData } = useQuery(FETCH_ACCOUNTS(permissions), {
    variables: {
      filter: {
        userId: loading || objectType !== PageObjectType.ACCOUNT ? '' : path(data)?.user?.id,
        states: ['ACTIVE', 'REQUESTED', 'FROZEN', 'FAILED', 'INITIATED', 'READY'],
      },
    },
    skip: objectType !== PageObjectType.ACCOUNT || loading,
  });

  const [update] = useMutation(mutation(), {
    variables: {
      input: {
        ...mutationVariable(),
        feeTierId: feeTier === 'inherited' ? null : feeTier,
        billingScheduleId: billingSchedule === 'inherited' ? null : billingSchedule,
        feePaymentAccountId: options.feePaymentAccount.enabled ? feeAccount === objectId ? null : feeAccount : undefined,
      },
    },
    onCompleted: () => {
      setEdited(false);
      refetch();
    },
  });

  useEffect(() => {
    if (data) {
      setFeeTier(path(data)?.feeTier?.id || 'inherited');
      setBillingSchedule(path(data)?.billingSchedule?.id || 'inherited');
      if (objectType === PageObjectType.ACCOUNT) {
        setFeeAccount(path(data)?.feePaymentAccount?.id || objectId);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, objectId, objectType]);

  useEffect(() => {
    if (accountsData) {
      setAvailableAccounts([
        ...(accountsData?.fetchAccounts?.accounts?.filter((x: any) => (x.id === objectId)) || []),
        ...(accountsData?.fetchAccounts?.accounts?.filter((x: any) => (x.id !== objectId && ['CASH_JOINT', 'PERSONAL'].includes(x.type as string))) || []),
      ]);
    }
  }, [accountsData, objectId]);

  if ([PageObjectType.INDIVIDUAL, PageObjectType.NON_INDIVIDUAL].includes(objectType) && !permissions.includes('read:client_billing')) return <></>;

  return (
    <>
      { options.customTitle && (<Typography variant='headingSmall' mb={2}>{translateBackend(options.customTitle)}</Typography>) }
      { loading ? (
        <Skeleton variant='rectangular' height={200} width='100%' />
      ) : (
        <Card>
          <CardContent sx={{ paddingBottom: '16px !important' }}>
            <Form onSubmit={update}>
              <Grid container spacing={2}>
                {
                  options.feeGrid.enabled && (
                    <Grid item xs={12} md={4}>
                      <FeeGridSelect
                        value={feeTier}
                        setValue={(e: string) => {
                          setFeeTier(e);
                          setEdited(true);
                        }}
                        label={translateBackend(options.feeGrid.label)}
                        showInherited
                      />
                    </Grid>
                  )
                }
                {
                  options.billingCycle.enabled && (
                    <Grid item xs={12} md={4}>
                      <BillingScheduleSelect
                        value={billingSchedule}
                        setValue={(e: string) => {
                          setBillingSchedule(e);
                          setEdited(true);
                        }}
                        label={translateBackend(options.billingCycle.label)}
                        showInherited
                      />
                    </Grid>
                  )
                }
                {
                  options.feePaymentAccount.enabled && (
                    <Grid item xs={12} md={4}>
                      <SelectField
                        value={feeAccount}
                        onChange={(e: any) => {
                          setFeeAccount(e.target.value);
                          setEdited(true);
                        }}
                        fullWidth
                        label={translateBackend(options.feePaymentAccount.label)}
                      >
                        {availableAccounts.map((x: any) => (
                          <MenuItem key={x.id} value={x.id}>
                            {t(`${x.type}`)} - #{x.custodianAccountNumber}
                          </MenuItem>
                        ))}
                      </SelectField>
                    </Grid>
                  )
                }
              </Grid>
              <Box display='flex' justifyContent='flex-end' mt={2}>
                <Button type='submit' label={t('shared:update')} disabled={!edited}/>
              </Box>
            </Form>
          </CardContent>
        </Card>
      )}
    </>
  );
};
