import { CurrencyCodes } from '@onevesthq/ov-enums';
import { round } from 'lodash';
import { isNull } from 'lodash/fp';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useState } from 'react';
import { translateBackend } from '../../../../../assets/i18n/config';
import { Holding } from '../../../../../interfaces';
import { useThemeTokens } from '../../../../../providers/themeTokenProvider';
import {
  formatDecimalNumber, formatMoneyValue, formatPercentValue, getBookCostCents,
} from '../../../../../util';
import { currencySymbol } from '../../../../../util/currency';
import { Link } from '../../../../1-primative';
import { TableCell } from '../../../../2-component';
import { CurrencyFlag } from '../../../../3-pattern';
import {
  find1Day, find1Month, find1Week, find3Day,
} from './tableHoldings';
import { BookValueType } from '../../../../../providers/statsHooks';

export const SecurityHoldingCell = ({
  holding, type, history, totalMarketValue, bookValueType, useCustodianData, displayCurrency, isFirst, maxQuantityDecimalPlaces, options, dataTestId = 'security-holding-cell',
}: {
  holding: Holding, type: string, history: any[], totalMarketValue: number, bookValueType?: BookValueType,
  useCustodianData?: boolean, missesFxRate?: boolean, displayCurrency?: boolean, isFirst?: boolean, maxQuantityDecimalPlaces?: number, options?: any, dataTestId?: string,
}) => {
  const { t } = useTranslation('shared');
  const { sys } = useThemeTokens();
  const [nativeCurrency, setNativeCurrency] = useState(CurrencyCodes.CAD);

  useEffect(() => {
    setNativeCurrency(holding?.currency ?? holding?.originalCurrency?.currency ?? CurrencyCodes.CAD);
  }, [holding]);

  const isNotCashHolding = isNull(holding.financialProduct?.isCash) || !holding.financialProduct?.isCash;
  const hasBookCost = !!holding.adjustedCostBaseCents;
  const fractionDigits = options.defaultPriceDecimalPlaceValue || 2;

  const leadingComponent = useMemo(() => (
    displayCurrency && nativeCurrency ? <CurrencyFlag baseCurrency={nativeCurrency} /> : undefined
  ), [displayCurrency, nativeCurrency]);

  const getColorByValue = (value: number) => {
    if (value === 0) return sys.color.onSurface;
    return value > 0 ? sys.color.positive : sys.color.negative;
  };

  const oneDayDifferenceCents = () => {
    const currentTotal = holding.totalCents ?? 0;
    const historicalTotal = find1Day(holding, history)?.totalCents;
    const custodianTotal = (holding.eodPriceCents ?? 0) * (holding.quantity ?? 0);

    const difference = round(currentTotal - (useCustodianData ? custodianTotal : (historicalTotal ?? currentTotal)));

    if (!useCustodianData && historicalTotal === undefined) {
      return currentTotal;
    }

    return difference;
  };

  const threeDayDifferenceCents = () => (
    (holding.totalCents ?? 0) - ((find3Day(holding, history)?.totalCents) ?? 0)
  );

  const oneWeekDifferenceCents = () => (
    (holding.totalCents ?? 0) - ((find1Week(holding, history)?.totalCents) ?? 0)
  );

  const oneMonthDifferenceCents = () => (
    (holding.totalCents ?? 0) - ((find1Month(holding, history)?.totalCents) ?? 0)
  );

  const bookCostCents = getBookCostCents(holding, bookValueType);
  const marketValueCents = (holding.totalCents ?? 0);
  const unrealizedGainsAndLossCents = Math.round(marketValueCents - bookCostCents);
  const unrealizedGainsAndLossPercent = bookCostCents !== 0 ? (unrealizedGainsAndLossCents / Math.abs(bookCostCents)) : 0;

  const getUnrealizedGainsAndLoss = () => (
    <>
      {`${formatMoneyValue(unrealizedGainsAndLossCents, currencySymbol[holding.currency ?? CurrencyCodes.CAD] ?? '$')}
      (${formatPercentValue(unrealizedGainsAndLossPercent)})`}
    </>
  );

  switch (type) {
    case 'symbol':
      return (
        <TableCell testId={dataTestId} maxWidth='250px' sx={{ minWidth: '60px' }} leadingComponent={leadingComponent} isFirst={isFirst}>
          {holding.financialProduct?.ticker ?? holding?.currency ?? CurrencyCodes.CAD}
        </TableCell>
      );
    case 'securityName':
      return (
        <TableCell testId={dataTestId} maxWidth='250px' leadingComponent={leadingComponent} isFirst={isFirst}>
          {holding.financialProduct?.url ? (
            <Link href={holding.financialProduct.url} target='_blank'>
              {holding.financialProduct?.isCash && holding?.currency !== 'CAD' ? `${t('cash')} ${holding?.currency}` : translateBackend(holding.financialProduct.translatedName)}
            </Link>
          ) : (
            <>
              {holding.financialProduct?.isCash && holding?.currency !== 'CAD' ? `${t('cash')} ${holding?.currency}` : translateBackend(holding.financialProduct?.translatedName)}
            </>
          )}
        </TableCell>
      );
    case 'quantity':
      return (
        <TableCell testId={dataTestId} right number leadingComponent={leadingComponent} isFirst={isFirst}>
          {isNotCashHolding
            ? formatDecimalNumber(holding.quantity, maxQuantityDecimalPlaces)
            : '-'}
        </TableCell>
      );
    case 'bookCostNative':
      return (
        <TableCell testId={dataTestId} right number isFirst={isFirst}>
          {isNotCashHolding && hasBookCost
            ? formatMoneyValue(holding?.originalCurrency?.adjustedCostBaseCents ?? holding.adjustedCostBaseCents, currencySymbol[nativeCurrency] ?? '$')
            : '-'}
        </TableCell>
      );
    case 'bookCost':
      return (
        <TableCell testId={dataTestId} right number isFirst={isFirst}>
          {isNotCashHolding && hasBookCost ? (
            formatMoneyValue(holding.adjustedCostBaseCents, currencySymbol[holding.currency ?? CurrencyCodes.CAD] ?? '$')
          ) : '-'}
        </TableCell>
      );
    case 'bookValue':
      return (
        <TableCell testId={dataTestId} right number leadingComponent={leadingComponent} isFirst={isFirst}>
          {isNotCashHolding && hasBookCost
            ? formatMoneyValue(bookCostCents, currencySymbol[holding.currency ?? CurrencyCodes.CAD] ?? '$')
            : '-'}
        </TableCell>
      );
    case 'currentPriceNative':
      return (
        <TableCell testId={dataTestId} right number leadingComponent={leadingComponent} isFirst={isFirst}>
          {isNotCashHolding
            ? formatMoneyValue((holding?.originalCurrency?.currentPriceCents ?? holding.currentPriceCents), currencySymbol[nativeCurrency] ?? '$')
            : '-'}
        </TableCell>
      );
    case 'currentPrice':
      return (
        <TableCell testId={dataTestId} right number leadingComponent={leadingComponent} isFirst={isFirst}>
          {isNotCashHolding ? (
            formatMoneyValue(holding.currentPriceCents, currencySymbol[holding.currency ?? CurrencyCodes.CAD] ?? '$', fractionDigits)
          ) : '-'}
        </TableCell>
      );
    case 'unrealizedGainLoss':
      return (
        <TableCell testId={dataTestId} right number isFirst={isFirst} leadingComponent={leadingComponent} sx={{
          textAlign: 'right', whiteSpace: 'nowrap', color: getColorByValue(unrealizedGainsAndLossCents),
        }}>
          {isNotCashHolding && hasBookCost ? getUnrealizedGainsAndLoss() : '-'}
        </TableCell>
      );
    case 'oneDayChange':
      return (
        <TableCell testId={dataTestId} right number isFirst={isFirst} leadingComponent={leadingComponent} sx={{
          textAlign: 'right', whiteSpace: 'nowrap', color: getColorByValue(oneDayDifferenceCents()),
        }}>
          {isNotCashHolding ? (
            <>
              {`${formatMoneyValue(oneDayDifferenceCents(), currencySymbol[holding.currency ?? CurrencyCodes.CAD] ?? '$')} `}
              {(() => {
                const oneDayDiff = oneDayDifferenceCents();
                const eodPrice = holding.eodPriceCents;
                const { quantity } = holding;
                const historyTotal = find1Day(holding, history)?.totalCents;

                if ((!useCustodianData && historyTotal === undefined)
                    || (useCustodianData && !eodPrice)) {
                  return '(-)';
                }

                const denominator = useCustodianData
                  ? (eodPrice ?? 0) * (quantity ?? 0)
                  : (historyTotal ?? 0);

                return denominator !== 0
                  ? `(${formatPercentValue(oneDayDiff / denominator)})`
                  : '(-)';
              })()}
            </>
          ) : '-'}
        </TableCell>
      );
    case 'threeDayChange':
      return (
        <TableCell testId={dataTestId} right number isFirst={isFirst} leadingComponent={leadingComponent} sx={{
          textAlign: 'right', whiteSpace: 'nowrap', color: getColorByValue(threeDayDifferenceCents()),
        }}>
          {isNotCashHolding ? (
            <>
              {`${formatMoneyValue(threeDayDifferenceCents(), currencySymbol[holding.currency ?? CurrencyCodes.CAD] ?? '$')} `}
              {find3Day(holding, history)?.totalCents
                ? `(${formatPercentValue(threeDayDifferenceCents() / (find3Day(holding, history)?.totalCents ?? 0))})`
                : '-'}
            </>
          ) : '-'}
        </TableCell>
      );
    case 'oneWeekChange':
      return (
        <TableCell testId={dataTestId} right number isFirst={isFirst} leadingComponent={leadingComponent} sx={{
          textAlign: 'right', whiteSpace: 'nowrap', color: getColorByValue(oneWeekDifferenceCents()),
        }}>
          {isNotCashHolding ? (
            <>
              {`${formatMoneyValue(oneWeekDifferenceCents(), currencySymbol[holding.currency ?? CurrencyCodes.CAD] ?? '$')} `}
              {find1Week(holding, history)?.totalCents
                ? `(${formatPercentValue(oneWeekDifferenceCents() / (find1Week(holding, history)?.totalCents ?? 0))})`
                : '-'}
            </>
          ) : '-'}
        </TableCell>
      );
    case 'oneMonthChange':
      return (
        <TableCell testId={dataTestId} right number isFirst={isFirst} leadingComponent={leadingComponent} sx={{
          textAlign: 'right', whiteSpace: 'nowrap', color: getColorByValue(oneMonthDifferenceCents()),
        }}>
          {isNotCashHolding ? (
            <>
              {`${formatMoneyValue(oneMonthDifferenceCents(), currencySymbol[holding.currency ?? CurrencyCodes.CAD] ?? '$')} `}
              {find1Month(holding, history)?.totalCents
                ? `(${formatPercentValue(oneMonthDifferenceCents() / (find1Month(holding, history)?.totalCents ?? 0))})`
                : '-'}
            </>
          ) : '-'}
        </TableCell>
      );
    case 'percentOfTotal':
      return (
        <TableCell testId={dataTestId} right number leadingComponent={leadingComponent} isFirst={isFirst}>
          {formatPercentValue((holding.totalCents ?? 0) / totalMarketValue) }
        </TableCell>
      );
    case 'currentValue':
    case 'marketValueNative':
      return (
        <TableCell testId={dataTestId} right number leadingComponent={leadingComponent} isFirst={isFirst}>
          {formatMoneyValue((holding?.originalCurrency?.totalCents ?? holding.totalCents ?? 0), currencySymbol[nativeCurrency] ?? '$')}
        </TableCell>
      );
    case 'marketValue':
      return (
        <TableCell testId={dataTestId} right number leadingComponent={leadingComponent} isFirst={isFirst}>
          {holding?.originalCurrency ? formatMoneyValue(holding.totalCents, currencySymbol[holding.currency as CurrencyCodes ?? CurrencyCodes.CAD]) : '-'}
        </TableCell>
      );
    default:
      return (
        <TableCell testId={dataTestId} leadingComponent={leadingComponent} isFirst={isFirst}>
          {(holding as any)[type]}
        </TableCell>
      );
  }
};
