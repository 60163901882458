import React, { useContext, useEffect, useState } from 'react';
import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { CircularProgress } from '@mui/material';
import WarningIcon from '@mui/icons-material/WarningRounded';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import {
  AccountTypes, EmploymentStatus, EntityTypes, GoalRiskLevels, GoalTimeHorizons, GoalTypes, IntegrationProvider, IntegrationType, IntegrationTypes, TranslatedString, User, getAccountTypes,
} from 'interfaces';
import { colors } from 'ovComponents/0-tokens';
import { Box, Grid, Typography } from 'ovComponents/1-primative';
import {
  Alert, Autocomplete, Button, Dialog, IconButton, Switch, TranslatableTextField,
} from 'ovComponents/2-component';
import {
  AdditionalInfoEdit,
  BasicFieldEdit,
  BasicOrSelectFieldEdit,
  BooleanEdit,
  DataFieldsEdit,
  DefaultValue,
  InvestmentKnowledgeOptionsEdit,
  RiskQuestionOptionsEdit,
  SelectFieldEdit,
  TranslatedStringEdit,
  TimeHorizonOptionsEdit,
  Attachment,
  WorkflowSelectFieldEdit,
  CountrySpecificTaxIdEdit,
  DisclaimerInfoEdit,
  ExtendedBooleanEdit,
  DividerWithTitle,
} from 'ovComponents/4-module/configurableOptionFields';

import { FieldTypes } from 'ovComponents/4-module/configurableOptionFields/optionEditComponent';
import { UserContext, usePermissions } from 'providers/userContextProvider';
import { CUSTOM_FIELDS_AVIALABLE_SUB_STEP, CustomField } from 'interfaces/customField';
import { FinancialProjectionVisual } from 'ovComponents/4-module/workflowCompletion/subSteps/financialProjection/financialProjection.visual';
import { isNil } from 'lodash';
import { CountryCodes, CurrencyCodes } from '@onevesthq/ov-enums';
import { FETCH_CUSTOM_FIELDS } from '../../customFields';
import { NotificationDefinitionForm } from '../../../4-module/notificationDefinitionForm/notificationDefinitionForm';
import { ApprovalVisual } from '../../../4-module/workflowCompletion/subSteps/approval/approval.visual';
import { DocumentsVisual } from '../../../4-module/workflowCompletion/subSteps/documents/documents.visual';
import { EmploymentInformationVisual } from '../../../4-module/workflowCompletion/subSteps/employmentInformation/employmentInformation.visual';
import { GoalsVisual } from '../../../4-module/workflowCompletion/subSteps/goals/goals.visual';
import { HouseholdVisual } from '../../../4-module/workflowCompletion/subSteps/household/household.visual';
import { IdVerificationVisual } from '../../../4-module/workflowCompletion/subSteps/idVerification/idVerification.visual';
import { IncomeAssetsAndDebtsVisual } from '../../../4-module/workflowCompletion/subSteps/incomeAssetsAndDebts/incomeAssetsAndDebts.visual';
import { InvestmentKnowledgeVisual } from '../../../4-module/workflowCompletion/subSteps/investmentKnowledge/investmentKnowledge.visual';
import { PersonalInformationVisual } from '../../../4-module/workflowCompletion/subSteps/personalInformation/personalInformation.visual';
import { NonIndividualInformationVisual } from '../../../4-module/workflowCompletion/subSteps/nonIndividualInformation/nonIndividualInformation.visual';
import { NonIndividualSetupVisual } from '../../../4-module/workflowCompletion/subSteps/nonIndividualSetup/nonIndividualSetup.visual';
import { PersonsOfInterestVisual } from '../../../4-module/workflowCompletion/subSteps/personsOfInterest/personsOfInterest.visual';
import { RelationshipInformationVisual } from '../../../4-module/workflowCompletion/subSteps/relationshipInformation/relationshipInformation.visual';
import { ResidencyInformationVisual } from '../../../4-module/workflowCompletion/subSteps/residencyInformation/residencyInformation.visual';
import { RiskQuestionVisual } from '../../../4-module/workflowCompletion/subSteps/riskQuestion/riskQuestion.visual';
import { RelatedEntitiesVisual, relatedEntitesVisualPropsExample } from '../../../4-module/workflowCompletion/subSteps/relatedEntities';
import { SubAccountsVisual, subAccountsVisualPropsExample } from '../../../4-module/workflowCompletion/subSteps/subAcounts';
import { CreateGoalVisual } from '../../../4-module/workflowCompletion/subSteps/createGoal/createGoal.visual';
import { EditGoalVisual } from '../../../4-module/workflowCompletion/subSteps/editGoal/editGoal.visual';
import { GoalRiskQuestionVisual } from '../../../4-module/workflowCompletion/subSteps/goalRiskQuestion/goalRiskQuestion.visual';
import { CreateAccountForGoalVisual } from '../../../4-module/workflowCompletion/subSteps/createAccountForGoal/createAccountForGoal.visual';
import { EditAccountVisual } from '../../../4-module/workflowCompletion/subSteps/editAccount/editAccount.visual';
import { AssignPortfolioToSubAccountVisual } from '../../../4-module/workflowCompletion/subSteps/assignPortfolioToSubAccount/assignPortfolioToSubAccount.visual';
import { ReviewVisual } from '../../../4-module/workflowCompletion/subSteps/review/review.visual';
import { RiskProfileVisual } from '../../../4-module/workflowCompletion/subSteps/riskProfile/riskProfile.visual';
import { ListTileOptionsEdit } from '../../../4-module/configurableOptionFields/listTileOptions';
import { CustomLabelsEdit } from './customLabelsEdit';
import { translateBackend } from '../../../../assets/i18n/config';
import { SelectCustomField } from '../../../4-module/configurableOptionFields/selectCustomField';
import { ScheduleFrequencyOptionsEdit } from '../../../4-module/configurableOptionFields/scheduleFrequencyOptions';
import { DepositType } from '../../../4-module/workflowCompletion/subSteps/depositType/depositType';
import { DepositForm } from '../../../4-module/workflowCompletion/subSteps/depositForm/depositForm';
import { DepositReview } from '../../../4-module/workflowCompletion/subSteps/depositReview/depositReview';
import { DepositTypeOptionsEdit } from '../../../4-module/configurableOptionFields/depositTypeOptionsEdit';
import { TransferTypeSelectField } from '../../../4-module/configurableOptionFields/transferTypeSelectField';
import { NumberFieldEdit } from '../../../4-module/configurableOptionFields/numberFieldEdit';

import { CustomLabelsEditTemp } from './customLabelsEditTemp';
import { initialTransfer } from '../../../4-module/depositWorkflow/depositWorkflow';
import { initialWithdraw } from '../../../4-module/withdrawWorkflow/withdrawWorkflow';
import { WithdrawForm } from '../../../4-module/workflowCompletion/subSteps/withdrawForm/withdrawForm';
import { WithdrawReview } from '../../../4-module/workflowCompletion/subSteps/withdrawReview/withdrawReview';
import { IntegrationSelectFieldEdit } from '../../../4-module/configurableOptionFields/integrationSelectField';
import { CollectSignersVisual } from '../../../4-module/workflowCompletion/subSteps/collectSigners/collectSigners.visual';

const FETCH_SUBSTEP_OPTIONS = gql`
  query fetchSubStepOptions($subStepType: SubStepTypes!) {
    fetchSubStepOptions(subStepType: $subStepType) {
      subStepOptions {
        key
        label {
          en
          fr
        }
        optionType
        options
        showIf
        default
      }
    }
  }
`;

export const FETCH_ROLE_PROFILES = gql`
  query fetchRoleProfiles($input: FetchRoleProfilesInput!) {
    fetchRoleProfiles(input: $input) {
      roleProfiles {
        id
        organization {
          id
        }
        translatedName {
          en
        }
        translatedDescription {
          en
        }
        template {
          permissions
          accessiblePages
          dashboards {
            id
            name
          }
        }
      }
    }
  }
`;

export const EditSubStepDialog = ({
  activeSubStep,
  open,
  setOpen,
  updateStep,
  removeStep,
  organizationId,
  localClickCount,
}: {
  activeSubStep: any;
  open: boolean;
  setOpen: (x: boolean) => void;
  updateStep: (x: any) => void;
  removeStep: () => void;
  organizationId: string;
  localClickCount?: number;
}) => {
  const { t } = useTranslation('workflowCompletions');
  const [options, setOptions] = useState<any>([]);
  const { permissions } = usePermissions();
  const { activeOrganization, integrations, custodianConnection } = useContext(UserContext);
  const [derivedCustomFields, setDerivedCustomFields] = useState<CustomField[]>();
  const [derivedCustomFieldsKeys, setDerivedCustomFieldsKeys] = useState<string[]>();
  const [subStepOptions, setSubStepOptions] = useState<any>({});
  const [formattedOptions, setFormattedOptions] = useState<any>({});
  const [optionList, setOptionList] = useState<any>(undefined);
  const [excludedFields, setExcludedFields] = useState<string[]>([]);
  const [dummyDeposit, setDummyDeposit] = useState({
    ...initialTransfer,
    type: 'EFT',
    schedule: 'ONE_TIME',
    bankAccount: {
      bankAccountNumber: 'Account***1234',
      name: 'Example Bank',
    },
    subAccount: {
      name: 'Example Portfolio',
    },
  });
  const [dummyWithdraw, setDummyWithdraw] = useState({
    ...initialWithdraw,
    bankAccount: {
      bankAccountNumber: 'Account***1234',
      name: 'Example Bank',
    },
    frequency: 'ONE_TIME',
    withdrawalReason: 'COMPLETED_MY_GOAL',
    subAccount: {
      name: 'Example Portfolio',
    },
  });

  const [fetchCustomFields] = useLazyQuery(FETCH_CUSTOM_FIELDS, {
    fetchPolicy: 'no-cache',
    onCompleted: (d: any) => {
      if (activeSubStep && d) {
        const customOptions: any[] = [];
        setDerivedCustomFields(d?.fetchCustomFields?.customFields ?? []);
        d?.fetchCustomFields?.customFields.forEach((customField: CustomField) => {
          const optionObj = {
            __typename: 'SubStepOption',
            label: activeSubStep?.options[customField.key]?.label ?? customField.translatedName,
            key: customField.key,
            optionType: FieldTypes.BASIC_FIELD,
            additionalInfo: activeSubStep?.options[customField.key]?.additionalInfo,
            options: {
              allowAdditionalInfo: true,
              isMarkdown: true,
            },
            default: {
              enabled: activeSubStep?.options[customField.key]?.enabled,
              label: activeSubStep?.options[customField.key]?.label ?? customField.translatedName,
              required: activeSubStep?.options[customField.key]?.required,
            },
            value: {
              enabled: activeSubStep?.options[customField.key]?.enabled,
              label: activeSubStep?.options[customField.key]?.label ?? customField.translatedName,
              required: activeSubStep?.options[customField.key]?.required,
              additionalInfo: activeSubStep?.options[customField.key]?.additionalInfo,
              customField,
            },
          };
          customOptions.push(optionObj);
        });
        setOptions([...options, ...customOptions]);
      }
    },
  });

  useEffect(() => {
    if (derivedCustomFields) {
      const fields = derivedCustomFields.map((a) => a.key);
      setDerivedCustomFieldsKeys(fields);
    }
  }, [derivedCustomFields]);

  const [querySubStepOptions, { loading }] = useLazyQuery(FETCH_SUBSTEP_OPTIONS, {
    fetchPolicy: 'no-cache',
    onCompleted: (subStepData: any) => {
      const newOptions = [...(subStepData?.fetchSubStepOptions?.subStepOptions || [])];
      newOptions.forEach((x: any, i: number) => {
        newOptions[i] = { ...x, value: activeSubStep.options[x.key] ?? x.default };
      });
      setOptions(newOptions);
      setSubStepOptions({
        id: activeSubStep.id,
        type: activeSubStep.type,
        skippable: activeSubStep.skippable !== undefined ? activeSubStep.skippable : false,
        canGoBack: activeSubStep.canGoBack !== undefined ? activeSubStep.canGoBack : true,
        completeableBy: activeSubStep.completableBy || ['ORGANIZATION_USER'],
        rolesCompleteableBy: activeSubStep.rolesCompleteableBy || [],
      });
      setOptionList(activeSubStep.options?.options || newOptions.find((x: any) => x.key === 'options')?.default);
    },
  }); // fetch policy is to not look for cache and take the data from network only

  const roleProfiles = useQuery(FETCH_ROLE_PROFILES, {
    variables: {
      input: {
        filter: { organizationId },
      },
    },
    skip: !organizationId,
  });
  useEffect(() => {
    if (activeSubStep && activeSubStep?.type) {
      querySubStepOptions({
        variables: { subStepType: activeSubStep?.type },
      });
    }
    if (CUSTOM_FIELDS_AVIALABLE_SUB_STEP.includes(activeSubStep?.type) && permissions.includes('read:custom_fields')) {
      fetchCustomFields({
        variables: {
          input: {
            filter: {
              organizationId: activeOrganization?.id ?? undefined,
              workflowStep: activeSubStep?.type,
            },
            pagination: {
              perPage: 100,
            },
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localClickCount, activeSubStep, permissions, activeOrganization]);

  useEffect(() => {
    if (!custodianConnection?.enableFetchCustodianSuitability) {
      setExcludedFields((prevState) => [...prevState, 'useExternalSuitabilityScore']);
    }
  }, [custodianConnection]);

  useEffect(() => {
    if (options) {
      const newFormattedOptions: any = {};
      options.forEach((x: any) => {
        newFormattedOptions[x.key] = x.value;
      });
      setFormattedOptions({ ...newFormattedOptions, options: optionList });
    }
  }, [options, optionList]);

  const getRoleProfileNames = (roleProfileIds: any): string[] => roleProfileIds
    .map((profile: any) => {
      const fetchedProfile = roleProfiles?.data?.fetchRoleProfiles?.roleProfiles?.find((fetched: any) => fetched?.id === (profile.id || profile));
      return { id: fetchedProfile?.id, name: fetchedProfile?.translatedName?.en };
    })
    .filter((obj: any) => Object.keys(obj).length !== 0);

  const [exampleUserData, setExampleUserData] = useState<Partial<User>>({
    type: EntityTypes.INDIVIDUAL,
  });

  const exampleSuggestedPortfolio = [
    {
      percentage: 50,
      financialProduct: {
        primaryAssetClass: {
          translatedName: {
            en: 'Alternative Investments',
            fr: 'Stratégie de Gestion Alternative',
          },
          key: 'ALTERNATIVE_INVESTMENT_STRATEGIES',
        },
      },
    },
    {
      percentage: 15,
      financialProduct: {
        primaryAssetClass: {
          translatedName: {
            en: 'Fixed Income',
            fr: 'Titres à Revenu Fixe',
          },
          key: 'FIXED_INCOME',
        },
      },
    },
    {
      percentage: 15,
      financialProduct: {
        primaryAssetClass: {
          translatedName: {
            en: 'Equities',
            fr: 'Actions de Sociétés Ouvertes',
          },
          key: 'PUBLIC_EQUITIES',
        },
      },
    },
    {
      percentage: 20,
      financialProduct: {
        primaryAssetClass: {
          translatedName: {
            en: 'Real Assets',
            fr: 'Actifs Réels',
          },
          key: 'REAL_ASSETS',
        },
      },
    },
  ];

  const exampleScreen = () => {
    switch (activeSubStep?.type) {
      case 'APPROVAL':
        return (<ApprovalVisual
            options={formattedOptions}
            approvalData={{}} loading={false}
            updateApproval={() => { }}
            continueFunc={() => { }} activeCustomFields={derivedCustomFieldsKeys}
            ignoreCustomFieldTriggerRules
          />);
      case 'DOCUMENTS':
        return (
          <DocumentsVisual
            options={formattedOptions}
            signableDocuments={[{ id: 'test', translatedDisplayName: { en: 'Test Document' }, signed: false }]}
            uploadableDocuments={[]}
            loading={false}
            previewLoading={false}
            signAgreement={() => {}}
            downloadTemplatePDF={() => {}}
            doTheUpload={() => {}}
            continueFunc={() => {}}
            setSignableDocumentIndex={() => {}}
            setUploadableDocumentIndex={() => {}}
            html='This is a test document'
            viewNext={() => { }}
            signAll={async () => { }}
            languageAction={''}
            updateLanguageAction={() => { }}
          />
        );
      case 'EMPLOYMENT_INFORMATION':
        return (
          <EmploymentInformationVisual
            options={formattedOptions}
            userData={{ employmentStatus: EmploymentStatus.EMPLOYED }}
            loading={false}
            updateUser={() => {}}
            continueFunc={() => {}}
            activeCustomFields={derivedCustomFieldsKeys}
            ignoreCustomFieldTriggerRules
          />
        );
      case 'GOALS':
        return (
          <GoalsVisual
            options={formattedOptions}
            loading={false}
            goals={[]}
            draftGoals={[]}
            updateDraftGoals={() => {}}
            continueFunc={() => {}}
            generateDraftGoal={() => ({
              userId: 'test',
              type: GoalTypes.BUILD_WEALTH,
              riskQuestion1: GoalRiskLevels.LEVEL_3,
              timeHorizon: GoalTimeHorizons.MID_TERM,
            })}
          />
        );
      case 'SUB_ACCOUNTS':
        return <SubAccountsVisual {...subAccountsVisualPropsExample} options={formattedOptions} />;
      case 'HOUSEHOLD':
        return (
          <HouseholdVisual
            options={formattedOptions}
            loading={false}
            continueFunc={() => {}}
            householdAction={formattedOptions?.defaultAction?.value || 'createHousehold'}
            feeTierOptions={[]}
            billingScheduleOptions={[]}
            householdOptions={[]}
            updateHouseholdAction={() => {}}
            updateNewHouseholdData={() => {}}
            updateJoinHouseholdData={() => {}}
          />
        );
      case 'ID_VERIFICATION':
        return <IdVerificationVisual options={formattedOptions} idData={{}} loading={false} updateId={() => {}} continueFunc={() => {}} openPersona={() => {}} />;
      case 'INCOME_ASSETS_AND_DEBTS':
        return <IncomeAssetsAndDebtsVisual options={formattedOptions} userData={{}} loading={false} updateUser={() => {}} continueFunc={() => {}} />;
      case 'INVESTMENT_KNOWLEDGE':
        return <InvestmentKnowledgeVisual options={formattedOptions} userData={{}} loading={false} updateUser={() => {}} continueFunc={() => {}} />;
      case 'PERSONAL_INFORMATION':
        return (
          <PersonalInformationVisual
            options={formattedOptions}
            userData={exampleUserData}
            loading={false}
            updateUser={setExampleUserData}
            continueFunc={() => {}}
            activeCustomFields={derivedCustomFieldsKeys}
            applicableJurisdictions={activeOrganization.jurisdictions}
            applicableCountries={activeOrganization.applicableLocalization.countries}
            ignoreCustomFieldTriggerRules
          />
        );
      case 'PERSONS_OF_INTEREST':
        return <PersonsOfInterestVisual
          options={formattedOptions}
          userData={{}} loading={false} updateUser={() => {}}
          continueFunc={() => {}}
          activeCustomFields={derivedCustomFieldsKeys}
          ignoreCustomFieldTriggerRules
        />;
      case 'PROJECTIONS':
        return <FinancialProjectionVisual readonly options={formattedOptions} onNext={() => {}} stepLoading={false} loading={false} />;
      case 'FINANCIAL_PROJECTIONS':
        return <FinancialProjectionVisual readonly options={formattedOptions} onNext={() => {}} stepLoading={false} loading={false} />;
      case 'RELATED_ENTITIES':
        return <RelatedEntitiesVisual {...relatedEntitesVisualPropsExample} options={formattedOptions} />;
      case 'RELATIONSHIP_INFORMATION':
        return <RelationshipInformationVisual options={formattedOptions} userData={{}} loading={false} updateUser={() => { }} updating={false} continueFunc={() => { }} />;
      case 'RESIDENCY_INFORMATION':
        return (
          <ResidencyInformationVisual
            options={formattedOptions}
            userData={exampleUserData}
            applicableCountries={activeOrganization.applicableLocalization?.countries ?? [CountryCodes.CA, CountryCodes.US]}
            updateUser={setExampleUserData}
            loading={false}
            updating={false}
            continueFunc={() => {}}
          />
        );
      case 'RISK_QUESTION_1':
        return <RiskQuestionVisual question={1} options={formattedOptions} userData={{}} loading={false} updateUser={() => {}} continueFunc={() => {}} />;
      case 'RISK_QUESTION_2':
        return <RiskQuestionVisual question={2} options={formattedOptions} userData={{}} loading={false} updateUser={() => {}} continueFunc={() => {}} />;
      case 'NON_INDIVIDUAL_INFORMATION':
        return <NonIndividualInformationVisual
          options={formattedOptions} userData={{}}
          loading={false} updateUser={() => {}}
          continueFunc={() => {}} activeCustomFields={derivedCustomFieldsKeys}
          ignoreCustomFieldTriggerRules
        />;
      case 'NON_INDIVIDUAL_SETUP':
        return <NonIndividualSetupVisual options={formattedOptions} loading={loading} onNext={() => {}} />;
      case 'CREATE_GOAL':
        return <CreateGoalVisual options={formattedOptions} goalData={{ type: '', name: '' }} loading={false} updateGoal={() => {}} continueFunc={() => {}} />;
      case 'EDIT_GOAL':
        return <EditGoalVisual
          options={formattedOptions}
          goalData={{}} loading={false} updateGoal={() => {}}
          continueFunc={() => {}} activeCustomFields={derivedCustomFieldsKeys}
          ignoreCustomFieldTriggerRules
        />;
      case 'GOAL_RISK_QUESTION_1':
        return <GoalRiskQuestionVisual options={formattedOptions} goalData={{}} loading={false} updateGoal={() => {}} continueFunc={() => {}} />;
      case 'CREATE_ACCOUNT_FOR_GOAL':
        return (
          <CreateAccountForGoalVisual
            suggestedTypes={[AccountTypes.PERSONAL]}
            otherTypes={getAccountTypes(EntityTypes.INDIVIDUAL, activeOrganization?.availableFeatureFlags, activeOrganization.applicableLocalization?.countries)}
            options={formattedOptions}
            accountType={''}
            loading={false}
            continueFunc={async () => {}}
          />
        );
      case 'EDIT_ACCOUNT':
        return (
          <EditAccountVisual
            options={formattedOptions}
            accountData={{
              type: AccountTypes.TFSA,
              baseCurrency: CurrencyCodes.CAD,
              availableCurrencies: [CurrencyCodes.CAD, CurrencyCodes.USD],
              custodianConnection,
              user: { physicalAddress: { province: 'AB' } },
            }}
            loading={false}
            loadingData={false}
            updateAccount={() => {}}
            continueFunc={() => {}}
            custodianConnections={[
              {
                name: 'Test Custodian',
                default: false,
                accountTypeSettings: [
                  {
                    type: AccountTypes.TFSA,
                    baseCurrency: CurrencyCodes.CAD,
                    availableCurrencies: [CurrencyCodes.CAD, CurrencyCodes.USD],
                    isMultiCurrencyEnabled: true,
                  },
                ],
              },
            ]}
            skip={() => {}}
            activeCustomFields={derivedCustomFieldsKeys}
            ignoreCustomFieldTriggerRules
          />
        );
      case 'ASSIGN_PORTFOLIO_TO_SUB__ACCOUNT':
        return <AssignPortfolioToSubAccountVisual options={formattedOptions} objectData={{}} loading={false} updateObject={() => {}} continueFunc={() => {}} />;
      case 'REVIEW':
        return <ReviewVisual options={formattedOptions} loading={false} continueFunc={() => {}} />;
      case 'RISK_PROFILE':
        return (
          <RiskProfileVisual
            options={formattedOptions}
            riskProfileData={{
              riskProfileName: 'Medium',
              riskProfileDescription: `For clients willing to accept a moderate level of risk.
            You are looking to achieve high potential returns over the medium to long term under normal market conditions.`,
              children: exampleSuggestedPortfolio,
              investmentKnowledge: 'EXPERT',
              timeHorizon: 'VERY_SHORT_TERM',
            }}
            loading={false}
            continueFunc={() => {}}
          />
        );
      case 'DEPOSIT_TYPE':
        return <DepositType options={formattedOptions} onNext={() => {}} direction='FORWARD' dummyDeposit={dummyDeposit} setDummyDeposit={setDummyDeposit} />;
      case 'DEPOSIT_FORM':
        return <DepositForm options={formattedOptions} onNext={() => {}} dummyDeposit={dummyDeposit} setDummyDeposit={setDummyDeposit} />;
      case 'DEPOSIT_REVIEW':
        return <DepositReview options={formattedOptions} onNext={() => {}} dummyDeposit={dummyDeposit} setDummyDeposit={setDummyDeposit} />;
      case 'WITHDRAW_FORM':
        return <WithdrawForm options={formattedOptions} onNext={() => {}} dummyWithdraw={dummyWithdraw} setDummyWithdraw={setDummyWithdraw} />;
      case 'WITHDRAW_REVIEW':
        return <WithdrawReview options={formattedOptions} onNext={() => {}} dummyWithdraw={dummyWithdraw} setDummyWithdraw={setDummyWithdraw} />;
      case 'COLLECT_SIGNERS':
        return (
          <CollectSignersVisual
            options={formattedOptions}
            onNext={() => {}}
            integrationFormAgreementData={{
              id: 'sampleId',
              type: 'INTEGRATION',
              state: 'PENDING',
              integrations: [
                {
                  externalData: {
                    signers: [
                      {
                        roleId: 'roleId1',
                        roleName: 'Owner',
                        roleType: 'SENDER',
                      },
                      {
                        roleId: 'roleId2',
                        roleName: 'Account Holder 1',
                        roleType: 'SIGNER',
                      },
                      {
                        roleId: 'roleId3',
                        roleName: 'Advisor',
                        roleType: 'SIGNER',
                      },
                    ],
                  },
                },
              ],
            }}
            setIntegrationFormAgreementData={() => {}}
            signingRoles={[
              {
                roleId: 'roleId2',
                roleName: 'Account Holder 1',
                roleType: 'SIGNER',
              },
              {
                roleId: 'roleId3',
                roleName: 'Advisor',
                roleType: 'SIGNER',
              },
            ]}
            loading={false}
          />
        );
      default:
        return null;
    }
  };

  const exampleScreenExists = !!exampleScreen();

  const opt = (x: any, k: any): any => {
    const opts = { ...x?.options?.[k], value: x?.value?.[k] ?? x?.options?.[k]?.default };
    if (k === 'defaultAddressProvider') {
      const addressProviders = integrations?.filter((a) => a?.type === IntegrationType.ADDRESS).map((b) => b?.provider);
      const defaultProvider = addressProviders.length === 0 ? [IntegrationProvider.GOOGLE] : addressProviders;
      return { ...opts, options: opts.options.filter((op: any) => defaultProvider.includes(op.value)) };
    }
    return opts;
  };

  const setSubOption = (key: string, opts: any[], i: number): void => {
    const newOptions = [...options];
    newOptions[i] = { ...options[i], value: { ...options[i].value, [key]: opts[0].value } };
    setOptions(newOptions);
  };

  const independentlyScrollingSurfacesSx = {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: 'calc(100vh - 64px)', // to fit the MUI Dialog
    overflowY: 'scroll',
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth={exampleScreenExists ? 'xl' : 'sm'} fullWidth>
      {loading ? (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress sx={{ m: 18 }} />
        </Box>
      ) : (
        <Grid container>
          {exampleScreenExists && (
            <Grid item xs={6} p={12} sx={independentlyScrollingSurfacesSx}>
              {exampleScreen()}
            </Grid>
          )}
          <Grid
            item
            xs={exampleScreenExists ? 6 : 12}
            p={4}
            sx={{
              background: colors.neutral200,
              ...independentlyScrollingSurfacesSx,
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'end' }}>
              <IconButton onClick={() => setOpen(false)}>
                <CloseIcon />
              </IconButton>
            </Box>
            {activeSubStep?.type?.includes('ACTION') && (
              <Alert severity='warning' icon={<WarningIcon />} sx={{ border: `1px solid ${colors.warning400}`, borderRadius: '8px', marginBottom: '16px' }}>
                <Typography variant='bodyLarge' weight='bold'>
                  {t('subStepOptions.visibility.label')}
                </Typography>
              </Alert>
            )}
            {activeSubStep?.type === 'ACTION_NOTIFY' ? (
              <NotificationDefinitionForm
                baseNotificationDefinition={{ id: activeSubStep?.options?.notificationDefinitionId }}
                fetch
                action={activeSubStep?.options?.notificationDefinitionId ? 'edit' : 'create'}
                onRemove={() => {
                  removeStep();
                  setOpen(false);
                }}
                afterSubmit={(id?: string) => {
                  updateStep({ ...subStepOptions, options: { ...formattedOptions, options: optionList, notificationDefinitionId: id } });
                  setOpen(false);
                }}
                triggerEvent={false}
              />
            ) : (
              <>
                {options.map((x: any, i: number) => (
                  <Box key={i} display='flex' flexDirection='column'>
                    {(x.optionType === FieldTypes.TRANSLATED_STRING || x.optionType === FieldTypes.OPTIONAL_TRANSLATED_STRING)
                    && (isNil(x.showIf) || (
                      options.find((elem: any) => elem.key === Object.keys(x.showIf)[0])?.value === Object.values(x.showIf)[0]
                      || options.find((elem: any) => elem.key === Object.keys(x.showIf)[0])?.value?.enabled === Object.values(x.showIf)[0]
                    )) ? (
                      <TranslatedStringEdit option={x} options={options} setOptions={setOptions} i={i} />
                      ) : x.optionType === FieldTypes.BASIC_FIELD ? (
                      <BasicFieldEdit option={x} options={options} setOptions={setOptions} i={i} />
                      ) : x.optionType === FieldTypes.BASIC_OR_SELECT_FIELD ? (
                      <BasicOrSelectFieldEdit option={x} options={options} setOptions={setOptions} i={i} />
                      ) : x.optionType === FieldTypes.INVESTMENT_KNOWLEDGE_OPTIONS ? (
                      <InvestmentKnowledgeOptionsEdit optionList={optionList} setOptionList={setOptionList} />
                      ) : x.optionType === FieldTypes.DEPOSIT_TYPE_SELECT_FIELD ? (
                      <DepositTypeOptionsEdit optionList={optionList} setOptionList={setOptionList} />
                      ) : x.optionType === FieldTypes.AUTOMATED_BANKING_SELECTED
                      && (isNil(x.showIf) || options.find((elem: any) => elem.key === Object.keys(x.showIf)[0])?.value === Object.values(x.showIf)[0]) ? (
                        <IntegrationSelectFieldEdit option={x} options={options} setOptions={setOptions} i={i} type={IntegrationTypes.BANKING} />
                        ) : x.optionType === FieldTypes.TRANSFER_TYPE_OPTIONS ? (
                      <TransferTypeSelectField option={x} options={options} setOptions={setOptions} i={i} dummyDeposit={dummyDeposit} setDummyDeposit={setDummyDeposit}/>
                        ) : x.optionType === FieldTypes.RISK_QUESTION_OPTIONS ? (
                      <RiskQuestionOptionsEdit optionList={optionList} setOptionList={setOptionList} />
                        ) : x.optionType === FieldTypes.SCHEDULE_FREQUENCY_OPTIONS
                      && (isNil(x.showIf) || options.find((elem: any) => elem.key === Object.keys(x.showIf)[0])?.value === Object.values(x.showIf)[0]) ? (
                      <ScheduleFrequencyOptionsEdit optionList={optionList} setOptionList={setOptionList} />
                          ) : x.optionType === FieldTypes.LIST_TILE ? (
                      <ListTileOptionsEdit optionList={optionList} setOptionList={setOptionList} />
                          ) : x.optionType === FieldTypes.BOOLEAN && !excludedFields.includes(x.key)
                         && (isNil(x.showIf) || options.find((elem: any) => elem.key === Object.keys(x.showIf)[0])?.value === Object.values(x.showIf)[0])
                            ? (
                      <BooleanEdit option={x} options={options} setOptions={setOptions} i={i} />
                            ) : x.optionType === FieldTypes.DATA_FIELDS ? (
                      <DataFieldsEdit option={x} options={options} setOptions={setOptions} i={i} />
                            ) : x.optionType === FieldTypes.SELECT_FIELD
                        && (isNil(x.showIf) || options.find((elem: any) => elem.key === Object.keys(x.showIf)[0])?.value === Object.values(x.showIf)[0]) ? (
                      <SelectFieldEdit option={x} options={options} setOptions={setOptions} i={i} />
                              ) : x.optionType === FieldTypes.ATTACHMENT ? (
                      <Attachment option={x} options={options} setOptions={setOptions} i={i} />
                              ) : x.optionType === FieldTypes.EXTENDED_BOOLEAN ? (
                      <ExtendedBooleanEdit option={x} options={options} setOptions={setOptions} i={i} />
                              ) : x.optionType === FieldTypes.SELECT_CUSTOM_FIELDS ? (
                      <SelectCustomField />
                              ) : x.optionType === FieldTypes.WORKFLOW_SELECT_FIELD
                      && (isNil(x.showIf) || options.find((elem: any) => elem.key === Object.keys(x.showIf)[0])?.value === Object.values(x.showIf)[0]) ? (
                      <WorkflowSelectFieldEdit option={x} options={options} setOptions={setOptions} i={i} />
                                ) : x.optionType === FieldTypes.COUNTRY_SPECIFIC_TAX_ID ? (
                      <CountrySpecificTaxIdEdit option={x} options={options} setOptions={setOptions} i={i} />
                                ) : x.optionType === FieldTypes.NUMBER_FIELD ? (
                       <NumberFieldEdit option={x} options={options} setOptions={setOptions} i={i} />
                                ) : x.optionType === FieldTypes.DIVIDER_WITH_TITLE ? (
                              <DividerWithTitle options={x}/>
                                ) : null}

                    {x?.options
                      && Object.keys(x?.options)?.map((k: any, index: number) => (
                        <Box key={index}>
                          {k === 'allowAdditionalInfo' ? (
                            <Box sx={{ mb: 2 }}>
                              <AdditionalInfoEdit option={x} options={options} setOptions={setOptions} i={i} />
                            </Box>
                          ) : k === 'allowDefaultValue' ? (
                            <Box sx={{ mb: 2 }}>
                              <DefaultValue option={x} options={options} setOptions={setOptions} i={i} />
                            </Box>
                          ) : x?.options?.[k]?.optionType === FieldTypes.BOOLEAN ? (
                            <BooleanEdit option={opt(x, k)} options={[opt(x, k)]} setOptions={(v) => setSubOption(k, v, i)} i={0} />
                          ) : x?.options?.[k]?.optionType === FieldTypes.SELECT_FIELD ? (
                            <SelectFieldEdit option={opt(x, k)} options={[opt(x, k)]} setOptions={(v) => setSubOption(k, v, i)} i={0} />
                          ) : x?.options?.[k]?.optionType === 'TRANSLATED_STRING' ? (
                            <TranslatableTextField
                              value={options[i].value?.[k] ?? { en: '' }}
                              label={translateBackend(x?.options?.[k]?.label)}
                              dialogLabel={translateBackend(x?.options?.[k]?.label)}
                              fullWidth
                              sx={{ mb: 2 }}
                              multiline
                              rows={3}
                              onChange={(e: TranslatedString) => {
                                const newOptions = [...options];
                                newOptions[i] = { ...options[i], value: { ...options[i].value, [k]: e } };
                                setOptions(newOptions);
                              }}
                            />
                          ) : null}
                          {k === 'allowTimeHorizonOptions' ? <TimeHorizonOptionsEdit optionList={optionList} setOptionList={setOptionList} /> : null}
                          {k === 'allowDisclaimer' ? <DisclaimerInfoEdit option={x} options={options} setOptions={setOptions} i={i} /> : null}
                          {k === 'customLabels' ? <CustomLabelsEdit option={x} options={options} setOptions={setOptions} i={i} /> : null}
                          {k === 'customLabelsTemp' ? <CustomLabelsEditTemp option={x} options={options} setOptions={setOptions} i={i} /> : null}
                        </Box>
                      ))}
                  </Box>
                ))}
                <Box sx={{ mb: 2 }}>
                  <Switch
                    checked={subStepOptions.skippable}
                    label={t('skippable')}
                    size='medium'
                    onChange={(e: any) => {
                      setSubStepOptions({ ...subStepOptions, skippable: e });
                    }}
                  ></Switch>
                </Box>
                <Box sx={{ mb: 2 }}>
                  <Switch
                    checked={subStepOptions.canGoBack}
                    label={t('canGoBack')}
                    size='medium'
                    onChange={(e: any) => {
                      setSubStepOptions({ ...subStepOptions, canGoBack: e });
                    }}
                  ></Switch>
                </Box>
                <Box sx={{ mb: 2 }}>
                  <Autocomplete
                    label={t('addRoleProfileToWorkflow')}
                    options={
                      roleProfiles?.data?.fetchRoleProfiles?.roleProfiles?.map((x: any) => ({
                        key: x.id,
                        label: x.translatedName.en,
                      })) || []
                    }
                    value={
                      getRoleProfileNames(subStepOptions?.rolesCompleteableBy || [])?.map((x: any) => ({
                        key: x.id,
                        label: x.name,
                      })) || []
                    }
                    isOptionEqualToValue={(option: any, value: any) => option.key === value.key}
                    multiple
                    fullWidth
                    onChange={(e: React.SyntheticEvent, value: any) => setSubStepOptions({ ...subStepOptions, rolesCompleteableBy: value.map((item: any) => item.key) })}
                  />
                </Box>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Button
                      variant='outlined'
                      color='destructive'
                      label={t('remove')}
                      onClick={() => {
                        removeStep();
                        setOpen(false);
                      }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      label={t('update')}
                      onClick={() => {
                        updateStep({ ...subStepOptions, options: { ...formattedOptions, options: optionList } });
                        setOpen(false);
                      }}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      )}
    </Dialog>
  );
};
