import {
  Button, IconButton,
} from '@mui/material';
import AddIcon from '@mui/icons-material/AddRounded';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormModal from './formModal';

export interface CreateNewModalProp {
  onSubmit?: (event: any) => void,
  loading?: boolean,
  children?: any[] | any,
  title?: string,
  fullWidth?: boolean,
  sx?: any,
  buttonType?: 'BUTTON' | 'ICON' | 'NONE',
  maxWidth?: string,
  disabled?: boolean,
  modalOpenDisabled?: boolean,
  iconType?: 'ADD' | 'EDIT' | 'DOT',
  buttonText?: string,
  hideSubmit?: boolean,
  modalButton?: string,
  icon?: any,
  buttonVariant?: 'outlined' | 'text' | 'contained' | undefined,
  forceOpen?: boolean,
  onClose?: () => void,
}

const CreateNewModal = ({
  onSubmit,
  loading,
  children,
  title,
  fullWidth = false,
  sx,
  buttonType = 'BUTTON',
  maxWidth = 'sm',
  buttonText,
  disabled = false,
  disableButton = false,
  icon = <AddIcon data-testid="add-icon" />,
  hideSubmit = false,
  modalButton,
  buttonVariant = 'outlined',
  forceOpen = false,
  onClose,
  state,
  initialState,
}: {
  onSubmit: (event: any) => void,
  loading: boolean,
  children: any[] | any,
  title: string,
  fullWidth?: boolean,
  sx?: any,
  buttonType?: 'BUTTON' | 'ICON' | 'NONE',
  maxWidth?: string,
  disabled?: boolean,
  disableButton?: boolean,
  iconType?: 'ADD' | 'EDIT' | 'DOT'
  buttonText?: string,
  hideSubmit?: boolean,
  modalButton?: string,
  icon?: any,
  buttonVariant?: 'outlined' | 'text' | 'contained' | undefined,
  forceOpen?: boolean,
  onClose?: () => void,
  state?: any,
  initialState?: any,
}) => {
  const { t } = useTranslation(['shared']);
  const [open, setOpen] = useState(forceOpen);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    if (onClose) onClose();
    setOpen(false);
  };

  const submit = (event: any) => {
    try {
      event.preventDefault();
      event.stopPropagation();
      onSubmit(event);
      setOpen(false);
    } catch (error) {
      /* eslint-disable no-console */
      console.error('validation errors', error);
    }
  };

  return (
    <>
      {buttonType === 'NONE' ? (
        <Button variant='text' disabled={disableButton} onClick={handleClickOpen} sx={sx} fullWidth={fullWidth} data-testid='add-button'>
          {modalButton || t('shared:add')}
        </Button>
      ) : buttonType === 'ICON' ? (
        <IconButton disabled={disableButton} style={{ marginTop: '-2px' }} data-testid='add-button' onClick={handleClickOpen}>
          {icon}
        </IconButton>
      ) : (
        <Button variant={buttonVariant} disabled={disableButton} onClick={handleClickOpen} sx={sx} fullWidth={fullWidth} startIcon={icon} data-testid='add-button'>
          {modalButton || t('shared:add')}
        </Button>
      )}
      <FormModal
        hideSubmitButton={hideSubmit}
        onSubmit={submit}
        loading={loading}
        disabled={disabled}
        children={children}
        title={title}
        open={open}
        handleClose={handleClose}
        formButton={buttonText || t('shared:create')}
        maxWidth={maxWidth}
        state={state}
        initialState={initialState}
      />
    </>
  );
};

export default CreateNewModal;
