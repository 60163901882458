/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  AccountBalance, AssignmentInd, Brush, DesktopMac,
  Extension, FactCheck, InsertChart, Insights, MonetizationOn,
  People, Person, PieChart, Settings, SsidChart,
} from '@mui/icons-material';
import { ovAnalyticsEvents } from '../../../util/analytics/analytics';
import { Section } from '../../2-component/navSection/navSection';

export const sectionsConfig: Section[] = [
  {
    name: 'menu.workflows',
    icon: Insights,
    subSections: [
      {
        name: 'menu.workflows',
        path: '/workflows',
        accessKey: 'WORKFLOWS',
      },
    ],
  },
  {
    name: 'menu.clientManagement',
    icon: People,
    subSections: [
      {
        name: 'menu.households',
        path: '/households',
        accessKey: 'HOUSEHOLDS',
      },
      {
        /* superseded by INDIVIDUAL_CLIENTS, TODO: remove this once OV-8830 finished */
        name: 'menu.individuals',
        path: '/clients',
        accessKey: 'CLIENTS',
      },
      {
        name: 'menu.individuals',
        path: '/clients',
        accessKey: 'INDIVIDUAL_CLIENTS',
      },
      {
        /* superseded by NON_INDIVIDUAL_CLIENTS, TODO: remove this once OV-8830 finished */
        name: 'menu.corporations',
        path: '/corporations',
        accessKey: 'CORPORATIONS',
      },
      {
        name: 'menu.nonIndividualClients',
        path: '/nonIndividualClients',
        accessKey: 'NON_INDIVIDUAL_CLIENTS',
      },
    ],
  },
  {
    name: 'menu.portfolioManagement',
    icon: PieChart,
    subSections: [
      {
        name: 'menu.modelPortfolios',
        path: '/buildModels',
        accessKey: 'BUILD_MODELS',
      },
      {
        name: 'menu.modelPortfoliosV2',
        path: '/modelPortfolios',
        accessKey: 'MODEL_PORTFOLIOS',
      },
      {
        name: 'menu.productShelfPage',
        path: '/productShelf',
        accessKey: 'PRODUCT_SHELF',
      },
      {
        name: 'menu.assetClassesPage',
        path: '/assetClasses',
        accessKey: 'ASSET_CLASSES',
      },
      {
        name: 'menu.tradingSchedules',
        path: '/schedules',
        accessKey: 'SCHEDULES',
      },
    ],
  },
  {
    name: 'menu.trading',
    icon: SsidChart,
    subSections: [
      {
        name: 'menu.rebalancing',
        path: '/rebalances',
        accessKey: 'REBALANCING',
      },
      {
        name: 'menu.rebalanceReport',
        path: '/rebalanceReport',
        accessKey: 'REBALANCE_REPORT',
      },
      {
        name: 'menu.portfolioOptimizer',
        path: '/portfolioOptimizers',
        accessKey: 'PORTFOLIO_OPTIMIZER',
      },
      {
        name: 'menu.bulkTrader',
        path: '/bulkTrader',
        accessKey: 'BULK_TRADER',
      },
      {
        name: 'menu.taxLossHarvesting',
        path: '/taxLossHarvesting',
        accessKey: 'TAX_LOSS_HARVESTING',
      },
    ],
  },
  {
    name: 'menu.compliance',
    icon: FactCheck,
    subSections: [
      {
        name: 'menu.accountsReview',
        path: '/accountsReview',
        accessKey: 'ACCOUNTS_REVIEW',
        stats: 'subAccountsToReviewCount',
      },
      {
        name: 'menu.complianceReview',
        path: '/complianceReview',
        accessKey: 'COMPLIANCE_REVIEW',
        stats: 'complianceReviewCount',
      },
      {
        name: 'menu.transactionMonitoringTool',
        path: '/transactionMonitoringTool',
        accessKey: 'SUSPICIOUS_TRANSACTIONS',
        stats: 'alertsCount',
      },
      {
        name: 'menu.transactions',
        path: '/transactions',
        accessKey: 'TRANSACTIONS',
      },
      {
        name: 'menu.elderlyClients',
        path: '/elderlyClients',
        accessKey: 'ELDERLY_CLIENTS',
      },
      {
        name: 'menu.annualSuitabilityReview',
        path: '/annualSuitabilityReview',
        accessKey: 'ANNUAL_SUITABILITY_REVIEW',
        stats: 'annualSuitabilityReviewCount',
      },
      {
        name: 'menu.bankAccountsToReview',
        path: '/BankAccountsToReview',
        accessKey: 'BANK_ACCOUNTS_TO_REVIEW',
        stats: 'bankAccountAwaitingReviewCount',
      },
      {
        name: 'menu.institutionsToReview',
        path: '/institutionsToReview',
        accessKey: 'INSTITUTIONS_TO_REVIEW',
        stats: 'institutionAwaitingReviewCount',
      },
    ],
  },
  {
    name: 'menu.reports',
    icon: InsertChart,
    subSections: [
      {
        name: 'menu.reports',
        path: '/reports',
        accessKey: 'REPORTS',
      },
    ],
  },
  {
    name: 'menu.feeAndBilling',
    icon: MonetizationOn,
    subSections: [
      {
        name: 'menu.billingManagement',
        path: '/billingManagement',
        accessKey: 'BILLING_MANAGEMENT',
      },
      {
        name: 'menu.feeGrids',
        path: '/feeGrids',
        accessKey: 'FEE_GRIDS',
      },
      {
        name: 'menu.billingSchedules',
        path: '/billingSchedules',
        accessKey: 'BILLING_SCHEDULES',
      },
      {
        name: 'menu.revenueShareReport',
        path: '/revenueShareReport',
        accessKey: 'ORGANIZATION_REVENUE_SHARE',
      },
    ],
  },
  {
    name: 'menu.systemManagement',
    icon: Settings,
    subSections: [
      {
        name: 'menu.reconciliation',
        path: '/reconciliation',
        accessKey: 'RECONCILIATION',
        stats: 'flagsCount',
      },
      {
        name: 'menu.statementsReview',
        path: '/statementsReview',
        accessKey: 'STATEMENTS_REVIEW',
        stats: 'statementsToReviewCount',
      },
      {
        name: 'menu.bulkImport',
        path: '/bulkImport',
        accessKey: 'BULK_IMPORT',
      },
      {
        name: 'menu.newsAndInsights',
        path: '/newsAndInsights',
        accessKey: 'NEWS_AND_INSIGHT',
      },
      {
        name: 'menu.devSettings',
        path: '/devSettings',
        accessKey: 'DEVELOPER_SETTINGS',
      },
      {
        name: 'menu.orgSettings',
        path: '/orgSettings',
        accessKey: 'ORGANIZATION_SETTINGS',
      },
    ],
  },
];

// Commenting out all those that aren't completed yet

export const settingsSectionsConfig: Section[] = [
  {
    name: 'menu.general',
    icon: Settings,
    subSections: [
      {
        name: 'menu.general',
        path: '/settings/general',
        permissions: ['write:organizations'],
      },
    ],
  },
  {
    name: 'menu.theme',
    icon: Brush,
    subSections: [
      {
        name: 'menu.designer',
        path: '/settings/designer',
        permissions: ['write:organizations'],
      },
      {
        name: 'menu.authenticationTheme',
        path: '/settings/authenticationTheme',
        permissions: ['write:organizations'],
      },
      {
        name: 'menu.errorTheme',
        path: '/settings/errorTheme',
        permissions: ['write:organizations'],
      },
    ],
  },
  // {
  //   name: 'menu.roles',
  //   icon: AssignmentInd,
  //   subSections: [
  //     {
  //       name: 'menu.roles',
  //       path: '/settings/roles',
  //       permissions: ['write:roles'],
  //     },
  //     {
  //       name: 'menu.roleSettings',
  //       path: '/settings/roleSettings',
  //       permissions: ['write:organizations'],
  //     },
  //   ],
  // },
  {
    name: 'menu.accessUsers',
    icon: Person,
    subSections: [
      {
        name: 'menu.accessUsers',
        path: '/settings/accessUsers',
        permissions: ['write:organization_users'],
      },
      {
        name: 'menu.authentication',
        path: '/settings/authenticationSettings',
        permissions: ['write:advanced_organization_settings'],
      },
      {
        name: 'menu.accessSettings',
        path: '/settings/accessSettings',
        permissions: ['write:organizations'],
      },
    ],
  },
  // {
  //   name: 'menu.custodianAndAccounts',
  //   icon: AccountBalance,
  //   subSections: [
  //     {
  //       name: 'menu.accounts',
  //       path: '/settings/accounts',
  //       permissions: ['write:organizations', 'write:feature_flags'],
  //     },
  //     {
  //       name: 'menu.custodianConnections',
  //       path: '/settings/custodianConnections',
  //       permissions: ['write:custodian_connection'],
  //     },
  //   ],
  // },
  {
    name: 'menu.productExperience',
    icon: DesktopMac,
    subSections: [
      {
        name: 'menu.dashboards',
        path: '/settings/dashboards',
        permissions: ['write:dashboards'],
      },
      // {
      //   name: 'menu.localization',
      //   path: '/settings/localization',
      //   permissions: ['write:organizations'],
      // },
      {
        name: 'menu.newsAndInsights',
        path: '/settings/newsAndInsights',
        permissions: ['write:news_and_insights'],
      },
      // {
      //   name: 'menu.notifications',
      //   path: '/settings/notifications',
      //   permissions: ['write:notification_definition', 'write:notification_group'],
      // },
      // {
      //   name: 'menu.forms',
      //   path: '/settings/forms',
      //   permissions: ['write:form_template'],
      // },
      // {
      //   name: 'menu.clientReports',
      //   path: '/settings/clientReports',
      //   permissions: ['write:advanced_organization_settings'],
      // },
      // {
      //   name: 'menu.statementsAndTaxDocuments',
      //   path: '/settings/statementsAndTaxDocuments',
      //   permissions: ['write:organizations', 'write:feature_flags'],
      // },
      // {
      //   name: 'menu.customFields',
      //   path: '/settings/customFields',
      //   permissions: ['write:custom_fields'],
      // },
      // {
      //   name: 'menu.pageConfigurations',
      //   path: '/settings/pageConfigurations',
      //   permissions: ['write:page_configurations'],
      // },
      // {
      //   name: 'menu.workflows',
      //   path: '/settings/workflows',
      //   permissions: ['write:workflows'],
      // },
      // {
      //   name: 'menu.downtimeSchedule',
      //   path: '/settings/downtimeSchedule',
      //   permissions: ['write:organizations'],
      // },
    ],
  },
  // {
  //   name: 'menu.portfolioManagements',
  //   icon: SsidChart,
  //   subSections: [
  //     {
  //       name: 'menu.transfers',
  //       path: '/settings/transfers',
  //       permissions: ['write:organizations', 'write:feature_flags'],
  //     },
  //     {
  //       name: 'menu.trades',
  //       path: '/settings/trades',
  //       permissions: ['write:organizations', 'write:feature_flags'],
  //     },
  //     {
  //       name: 'menu.tradingSchedules',
  //       path: '/settings/tradingSchedules',
  //       permissions: ['write:schedules'],
  //     },
  //     {
  //       name: 'menu.assetClasses',
  //       path: '/settings/assetClasses',
  //       permissions: ['write:asset_classes'],
  //     },
  //     {
  //       name: 'menu.compliance',
  //       path: '/settings/compliance',
  //       permissions: ['write:organizations', 'write:feature_flags'],
  //     },
  //     {
  //       name: 'menu.portfolioManagementSettings',
  //       path: '/settings/portfolioManagementSettings',
  //       permissions: ['write:organizations', 'write:advanced_organization_settings'],
  //     },
  //   ],
  // },
  // {
  //   name: 'menu.feesAndBilling',
  //   icon: MonetizationOn,
  //   subSections: [
  //     {
  //       name: 'menu.feeGrids',
  //       path: '/settings/feeGrids',
  //       permissions: ['write:fee_tier'],
  //     },
  //     {
  //       name: 'menu.billingSchedules',
  //       path: '/settings/billingSchedules',
  //       permissions: ['write:billing_schedule'],
  //     },
  //     {
  //       name: 'menu.feeSettings',
  //       path: '/settings/feeSettings',
  //       permissions: ['write:organizations'],
  //     },
  //   ],
  // },
  // {
  //   name: 'menu.integrations',
  //   icon: Extension,
  //   subSections: [
  //     {
  //       name: 'menu.integrations',
  //       path: '/settings/integrations',
  //       permissions: ['write:integrations'],
  //     },
  //     {
  //       name: 'menu.bulkImport',
  //       path: '/settings/bulkImport',
  //       permissions: ['write:bulk_import'],
  //     },
  //     {
  //       name: 'menu.embedded',
  //       path: '/settings/embedded',
  //       permissions: ['write:organizations'],
  //     },
  //     {
  //       name: 'menu.apiTokens',
  //       path: '/settings/apiTokens',
  //       permissions: ['write:api_tokens'],
  //     },
  //     {
  //       name: 'menu.webhooks',
  //       path: '/settings/webhooks',
  //       permissions: ['write:webhook'],
  //     },
  //   ],
  // },
];

export const handleAnalytics = (name: string, sendAnalytic: (e: any) => void) => {
  switch (name) {
    case 'Organization': {
      sendAnalytic(ovAnalyticsEvents.dashboardOrganizationSelect);
      break;
    }
    case 'Basic': {
      sendAnalytic(ovAnalyticsEvents.dashboardBasicSelect);
      break;
    }
    case 'Portfolio Manager':
    case 'Portfolio Manager v2':
    case 'Portfolio Manager v3': {
      sendAnalytic(ovAnalyticsEvents.dashboardPortfolioManagerSelect);
      break;
    }
    case 'Compliance': {
      sendAnalytic(ovAnalyticsEvents.dashboardComplianceSelect);
      break;
    }
    case 'Operations': {
      sendAnalytic(ovAnalyticsEvents.dashboardOperationsSelect);
      break;
    }
    case 'menu.individuals': {
      sendAnalytic(ovAnalyticsEvents.clientManagementClientsSelect);
      break;
    }
    case 'menu.households': {
      sendAnalytic(ovAnalyticsEvents.clientManagementHouseholdSelect);
      break;
    }
    case 'menu.corporations': {
      // superseded by clientManagementNonIndividualClientsSelect (below), TODO: remove this once OV-8830 finished
      sendAnalytic(ovAnalyticsEvents.clientManagementCorporationsSelect);
      break;
    }
    case 'menu.nonIndividualClients': {
      sendAnalytic(ovAnalyticsEvents.clientManagementNonIndividualClientsSelect);
      break;
    }
    case 'menu.modelPortfolios': {
      sendAnalytic(ovAnalyticsEvents.portfolioManagementModelPortfoliosSelect);
      break;
    }
    case 'menu.productShelfPage': {
      sendAnalytic(ovAnalyticsEvents.portfolioManagementProductShelfSelect);
      break;
    }
    case 'menu.assetClassesPage': {
      sendAnalytic(ovAnalyticsEvents.portfolioManagementAssetClassesSelect);
      break;
    }
    case 'menu.tradingSchedules': {
      sendAnalytic(ovAnalyticsEvents.portfolioManagementTradingSchedulesSelect);
      break;
    }
    case 'menu.rebalancing': {
      sendAnalytic(ovAnalyticsEvents.tradingRebalancingSelect);
      break;
    }
    case 'menu.rebalanceReportV1': {
      sendAnalytic(ovAnalyticsEvents.tradingRebalanceReportV1Select);
      break;
    }
    case 'menu.rebalanceReport': {
      sendAnalytic(ovAnalyticsEvents.tradingRebalanceReportV1Select);
      break;
    }
    case 'menu.portfolioOptimizer': {
      sendAnalytic(ovAnalyticsEvents.tradingPortfolioOptimizerSelect);
      break;
    }
    case 'menu.bulkTrader': {
      sendAnalytic(ovAnalyticsEvents.tradingBulkTraderSelect);
      break;
    }
    case 'menu.bulkImport': {
      sendAnalytic(ovAnalyticsEvents.systemManagementBulkImportSelect);
      break;
    }
    case 'menu.taxLossHarvesting': {
      sendAnalytic(ovAnalyticsEvents.tradingTLHSelect);
      break;
    }
    case 'menu.accountsReview': {
      sendAnalytic(ovAnalyticsEvents.complianceAccountsReviewSelect);
      break;
    }
    case 'menu.complianceReview': {
      sendAnalytic(ovAnalyticsEvents.complianceComplianceReviewSelect);
      break;
    }
    case 'menu.transactionMonitoringTool': {
      sendAnalytic(ovAnalyticsEvents.complianceSuspiciousTransfersSelect);
      break;
    }
    case 'menu.transactions': {
      sendAnalytic(ovAnalyticsEvents.complianceAllTransactionsSelect);
      break;
    }
    case 'menu.elderlyClients': {
      sendAnalytic(ovAnalyticsEvents.complianceElderlyClientsSelect);
      break;
    }
    case 'menu.annualSuitabilityReview': {
      sendAnalytic(ovAnalyticsEvents.complianceAnnualProfileUpdateSelect);
      break;
    }
    case 'menu.bankAccountsToReview': {
      sendAnalytic(ovAnalyticsEvents.complianceNewBankAccountApprovalSelect);
      break;
    }
    case 'menu.institutionsToReview': {
      sendAnalytic(ovAnalyticsEvents.complianceInstitutionsApprovalSelect);
      break;
    }
    case 'menu.reports': {
      sendAnalytic(ovAnalyticsEvents.reportsSelect);
      break;
    }
    case 'menu.feeGrids': {
      sendAnalytic(ovAnalyticsEvents.feesAndBillingFeeGridSelect);
      break;
    }
    case 'menu.billingSchedules': {
      sendAnalytic(ovAnalyticsEvents.feesAndBillingBillingSchedulesSelect);
      break;
    }
    case 'menu.revenueShareReport': {
      sendAnalytic(ovAnalyticsEvents.feesAndBillingRevenueShareSelect);
      break;
    }
    case 'menu.billingManagement': {
      sendAnalytic(ovAnalyticsEvents.feesAndBillingIncurredFeeSelect);
      break;
    }
    case 'menu.reconciliation': {
      sendAnalytic(ovAnalyticsEvents.systemManagementReconciliationSelect);
      break;
    }
    case 'menu.statementsReview': {
      sendAnalytic(ovAnalyticsEvents.systemManagementStatementsReviewSelect);
      break;
    }
    case 'menu.devSettings': {
      sendAnalytic(ovAnalyticsEvents.systemManagementIntegrationSettingsSelect);
      break;
    }
    case 'menu.orgSettings': {
      sendAnalytic(ovAnalyticsEvents.systemManagementOrganizationSettingsSelect);
      break;
    }
    default: {
      break;
    }
  }
};
