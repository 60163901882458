import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  gql, useApolloClient, useMutation, useQuery,
} from '@apollo/client';
import { Box, Grid, Typography } from '../../../1-primative';
import {
  Button, Card, CardContent, ColorPickerField, DroppableFileInput, Form, MenuItem, SelectField, TextField,
} from '../../../2-component';
import { useButtonTokens } from '../../../2-component/button/button.tokens';
import { useCardTokens } from '../../../2-component/card/card.tokens';
import { SettingsCard } from '../../../3-pattern';
import { useLayoutTokens } from '../../../4-module/layout/layout.tokens';
import { UserContext } from '../../../../providers/userContextProvider';
import { useGlobalToast } from '../../../../providers/globalToastProvider';
import { useThemeTokens } from '../../../../providers/themeTokenProvider';
import { fileUploader, FileUploaderProps } from '../../../../util/fileUploader';
import { FileDocumentObjectTypes, FileDocumentTypes } from '../../../../interfaces/fileDocument';
import { FETCH_LOGO } from '../../../4-module/layout/organizationLogo';

const FETCH_ORGANIZATION = gql`
  query fetchOrganization($organizationId: String!) {
    fetchOrganization(organizationId: $organizationId) {
      organization {
        id
        name
        faviconLink
        browserTabTitle
        themeTokens
      }
    }
  }
`;

const UPDATE_ORGANIZATION = gql`
  mutation updateOrganization($input: UpdateOrganizationInput!) {
    updateOrganization(input: $input) {
      organization {
        id
      }
    }
  }
`;

export const DesignerSettings = () => {
  const { activeOrganization } = useContext(UserContext);
  const graphqlClient = useApolloClient();
  const { showToast } = useGlobalToast();
  const { ref, sys, comp: layoutComp } = useThemeTokens(useLayoutTokens());
  const { comp: buttonComp } = useThemeTokens(useButtonTokens());
  const { comp: cardComp } = useThemeTokens(useCardTokens());
  const [file, setFile] = useState<File>();
  const [fileLoading, setFileLoading] = useState(false);
  const { t } = useTranslation(['settings', 'shared']);
  const [organization, setOrganization] = useState<any>({
    themeTokens: {},
  });

  const { data, loading } = useQuery(FETCH_ORGANIZATION, {
    variables: { organizationId: activeOrganization?.id },
  });

  const [updateOrganization] = useMutation(UPDATE_ORGANIZATION, {
    variables: {
      input: {
        organizationId: activeOrganization?.id,
        faviconLink: organization?.faviconLink || null,
        browserTabTitle: organization?.browserTabTitle || null,
        themeTokens: organization?.themeTokens || null,
        theme: {
          logo: organization?.theme?.logo,
        },
      },
    },
    onCompleted: async () => {
      if (file && organization) {
        setFileLoading(true);
        const fileUploadProps: FileUploaderProps = {
          file,
          createFileInput: {
            objectType: FileDocumentObjectTypes.ORGANIZATION,
            objectId: organization.id,
            userId: organization.id,
            fileName: file.name,
            type: FileDocumentTypes.LOGO,
            name: file.name,
            mediaType: file.type,
            permissionType: 'PUBLIC',
            sourceType: FileDocumentTypes.LOGO,
          },
          onSuccess: () => {
            showToast({ severity: 'success', message: `Logo uploaded successfully: ${file.name}` });
            setFile(undefined);
          },
          refetchQueries: [FETCH_LOGO],
          apolloClient: graphqlClient,
        };
        await fileUploader(fileUploadProps);
        setFileLoading(false);
        setFile(undefined);
      }
      showToast({
        message: t('shared:successfullySaved'),
        severity: 'success',
      });
    },
  });

  useEffect(() => {
    if (data?.fetchOrganization?.organization) {
      setOrganization(data?.fetchOrganization?.organization);
    }
  }, [data]);

  return (
    <SettingsCard
      title={t('designer.title')}
      description={t('designer.description')}
    >
      <Form onSubmit={updateOrganization}>
        <Typography variant='labelLarge'>{t('designer.example')}</Typography>
        <Box display='flex' flexDirection='column' gap={2}>
          <Box sx={{
            border: `1px solid ${sys.color.neutralPressed}`,
            backgroundColor: organization.themeTokens?.sys?.color?.background ?? sys?.color?.background,
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            mb: 2,
          }}>
            <Box sx={{
              backgroundColor: organization.themeTokens?.comp?.layout?.backgroundColor ?? layoutComp?.layout?.backgroundColor,
              width: '100%',
              height: '64px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}>
              <Typography variant='bodyLarge' sx={{
                color: organization.themeTokens?.comp?.layout?.textColor ?? layoutComp?.layout?.textColor,
                pl: 2,
                pr: 2,
              }}>
                {organization.name}
              </Typography>
            </Box>
            <Box width='100%'>
              <Card sx={{
                m: 2,
                ml: '16px !important',
                mr: '16px !important',
                borderRadius: `${organization.themeTokens?.comp?.card?.borderRadius ?? cardComp?.card?.borderRadius} !important`,
              }}>
                <CardContent>
                  <Typography variant='headingMedium'>{t('designer.exampleTitle')}</Typography>
                  <Typography>{t('designer.exampleDescription')}</Typography>
                  <Box display='flex' justifyContent='end' gap={1}>
                    <Button
                      sx={{
                        borderRadius: `${organization.themeTokens?.comp?.button?.borderRadius ?? buttonComp?.button?.borderRadius} !important`,
                        backgroundColor: `${organization.themeTokens?.ref?.palette?.primary50 ?? ref?.palette?.primary50} !important`,
                      }}
                      label={t('shared:save')}
                    />
                  </Box>
                </CardContent>
              </Card>
            </Box>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <ColorPickerField
              testId='settings-designer-top-bar-color'
                fullWidth
                label={t('designer.topBarColor')}
                value={organization?.themeTokens?.comp?.layout?.backgroundColor ?? layoutComp?.layout?.backgroundColor}
                onChange={(e) => setOrganization(
                  {
                    ...organization,
                    themeTokens: {
                      ...organization?.themeTokens,
                      comp: {
                        ...organization?.themeTokens?.comp,
                        layout: {
                          ...organization?.themeTokens?.comp?.layout,
                          backgroundColor: e,
                        },
                      },
                    },
                  },
                )}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <ColorPickerField
                testId='settings-designer-top-bar-text-color'
                fullWidth
                label={t('designer.topBarTextColor')}
                value={organization?.themeTokens?.comp?.layout?.textColor ?? layoutComp?.layout?.textColor}
                onChange={(e) => setOrganization(
                  {
                    ...organization,
                    themeTokens: {
                      ...organization.themeTokens,
                      comp: {
                        ...organization.themeTokens?.comp,
                        layout: {
                          ...organization.themeTokens?.comp?.layout,
                          textColor: e,
                        },
                      },
                    },
                  },
                )}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <ColorPickerField
              testId='settings-designer-top-primary-color'
                fullWidth
                label={t('designer.primaryColor')}
                value={organization?.themeTokens?.ref?.palette?.primary50 ?? ref?.palette?.primary50}
                onChange={(e) => setOrganization(
                  {
                    ...organization,
                    themeTokens: {
                      ...organization.themeTokens,
                      ref: {
                        ...organization.themeTokens?.ref,
                        palette: {
                          ...organization.themeTokens?.ref?.palette,
                          primary50: e,
                        },
                      },
                    },
                  },
                )}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <ColorPickerField
              testId='settings-designer-background-color'
                fullWidth
                label={t('designer.pageBackgroundColor')}
                value={organization?.themeTokens?.sys?.color?.background ?? sys?.color?.background}
                onChange={(e) => setOrganization(
                  {
                    ...organization,
                    themeTokens: {
                      ...organization.themeTokens,
                      sys: {
                        ...organization.themeTokens?.sys,
                        color: {
                          ...organization.themeTokens?.sys?.color,
                          background: e,
                        },
                      },
                    },
                  },
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <SelectField
              testId='settings-designer-card-border-radius'
                fullWidth
                label={t('designer.cardBorderRadius')}
                value={organization?.themeTokens?.comp?.card?.borderRadius ?? cardComp?.card?.borderRadius}
                onChange={(e: any) => setOrganization(
                  {
                    ...organization,
                    themeTokens: {
                      ...organization.themeTokens,
                      comp: {
                        ...organization.themeTokens?.comp,
                        card: {
                          ...organization.themeTokens?.comp?.card,
                          borderRadius: e.target.value,
                        },
                      },
                    },
                  },
                )}
              >
                <MenuItem data-testid={'settings-designer-card-border-radius-large'} value='16px'>{t('designer.borderRadiusLarge')}</MenuItem>
                <MenuItem data-testid={'settings-designer-card-border-radius-medium'} value='12px'>{t('designer.borderRadiusMedium')}</MenuItem>
                <MenuItem data-testid={'settings-designer-card-border-radius-small'} value='4px'>{t('designer.borderRadiusSmall')}</MenuItem>
                <MenuItem data-testid={'settings-designer-card-border-radius-none'} value='0px'>{t('designer.borderRadiusNone')}</MenuItem>
              </SelectField>
            </Grid>
            <Grid item xs={12} md={6}>
              <SelectField
                testId='settings-designer-button-border-radius'
                fullWidth
                label={t('designer.buttonBorderRadius')}
                value={organization?.themeTokens?.comp?.button?.borderRadius ?? buttonComp?.button?.borderRadius}
                onChange={(e: any) => setOrganization(
                  {
                    ...organization,
                    themeTokens: {
                      ...organization.themeTokens,
                      comp: {
                        ...organization.themeTokens?.comp,
                        button: {
                          ...organization.themeTokens?.comp?.button,
                          borderRadius: e.target.value,
                        },
                      },
                    },
                  },
                )}
              >
                <MenuItem data-testid={'settings-designer-button-border-radius-round'} value='100px'>{t('designer.borderRadiusRound')}</MenuItem>
                <MenuItem data-testid={'settings-designer-button-border-radius-large'} value='16px'>{t('designer.borderRadiusLarge')}</MenuItem>
                <MenuItem data-testid={'settings-designer-button-border-radius-medium'} value='12px'>{t('designer.borderRadiusMedium')}</MenuItem>
                <MenuItem data-testid={'settings-designer-button-border-radius-small'} value='4px'>{t('designer.borderRadiusSmall')}</MenuItem>
                <MenuItem data-testid={'settings-designer-button-border-radius-none'} value='0px'>{t('designer.borderRadiusNone')}</MenuItem>
              </SelectField>
            </Grid>
          </Grid>
          <Typography>{t('designer.moreChanges')}</Typography>
          <Typography variant='labelSmall' colorVariant='variant' sx={{ mb: '-8px' }}>{t('designer.logo')}</Typography>
          <DroppableFileInput onFileChosen={(f: File) => setFile(f)} fileMaxSize={1} testId='settings-designer-logo'/>
          <TextField
            testId='settings-designer-favicon-link'
            label={t('designer.faviconLink')}
            value={organization?.faviconLink}
            onChange={(e: any) => setOrganization({ ...organization, faviconLink: e.target.value })}
            helpText={t('designer.faviconLinkHelpText')}
            fullWidth
          />
          <TextField
            testId='settings-designer-browser-tab-title'
            label={t('designer.browserTabTitle')}
            value={organization?.browserTabTitle}
            onChange={(e: any) => setOrganization({ ...organization, browserTabTitle: e.target.value })}
            fullWidth
          />
          <Box display='flex' justifyContent='flex-end'>
            <Button type='submit' disabled={loading || fileLoading} label={t('shared:save')} dataTestId='settings-designer-submit-button'/>
          </Box>
        </Box>
      </Form>
    </SettingsCard>
  );
};
