import {
  gql, useMutation, useQuery,
} from '@apollo/client';
import { useTranslation } from 'react-i18next';
import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import FilterListRoundedIcon from '@mui/icons-material/FilterListRounded';
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import RemoveRedEyeRoundedIcon from '@mui/icons-material/RemoveRedEyeRounded';
import VisibilityOffRoundedIcon from '@mui/icons-material/VisibilityOffRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import AddIcon from '@mui/icons-material/Add';
import {
  Box,
  Button,
  Card,
  IconButton, Pagination,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
  Menu, MenuItem, Badge,
  TableHeadCell,
  CardContent,
  Skeleton,
} from '../..';
import { translateBackend } from '../../../assets/i18n/config';
import { usePermissions, UserContext } from '../../../providers/userContextProvider';
import { TranslatedString } from '../../../interfaces';
import { DeleteArticle } from './components/deleteArticle';
import { FilterArticles } from './components/filterArticles';
import { Article, CreateArticle } from './components/createArticle';
import { EditArticle } from './components/editArticle';

export const FETCH_ARTICLES = gql`
  query fetchArticles($input: FetchArticlesInput!) {
    fetchArticles(input: $input) {
      articles {
        id
        articleUrl
        createdAt
        createdBy {
          id
          firstName
          lastName
        }
        organization {
          id
        }
        state
        translatedDescription {
          en
          fr
        }
        translatedName {
          en
          fr
        }
        imageUrl
        updatedAt
      }
      totalCount
    }
  }
`;

export const TRANSITION_ARTICLE = gql`
  mutation transitionArticle($input: TransitionArticleInput!) {
    transitionArticle(input: $input) {
      article {
        id
      }
    }
  }
`;

export const ManageNewsAndInsight = () => {
  const { t } = useTranslation(['shared', 'newsAndInsight']);
  const { permissions } = usePermissions();
  const hasWritePermission = permissions.includes('write:news_and_insights');
  const { activeOrganization } = useContext(UserContext);
  const [page, setPage] = useState(1);
  const [searchText, setSearchText] = useState('');
  const [stateToBeFiltered, setStateToBeFiltered] = useState<string | undefined>();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedArticleState, setSelectedArticleState] = useState<Article | null>(null);
  const selectedArticleRef = useRef<{
    id: string,
    state: 'DRAFT' | 'ACTIVE' | 'INACTIVE',
    articleUrl: string,
    translatedName: TranslatedString,
    translatedDescription: TranslatedString,
  }>();
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>, article: any) => {
    event.stopPropagation();
    selectedArticleRef.current = article;
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleEditClickedOption = () => {
    handleClose();
    if (selectedArticleRef.current) {
      setSelectedArticleState(selectedArticleRef.current);
    }
    setOpenEditArticleDialog(true);
  };

  const pageSize = 15;
  const clearFilter = () => {
    setStateToBeFiltered(undefined);
    setOpenFilterDialog(false);
  };

  const {
    loading, error, data, previousData, refetch,
  } = useQuery(FETCH_ARTICLES, {
    variables: {
      input: {
        filter: {
          searchText,
          state: stateToBeFiltered,
          organizationId: activeOrganization.id,
        },
        pagination: {
          sortField: 'createdAt', sortDesc: true, perPage: pageSize, offSet: (page - 1) * pageSize,
        },
      },
    },
  });

  const [openCreateArticleDialog, setOpenCreateArticleDialog] = useState(false);
  const [openEditArticleDialog, setOpenEditArticleDialog] = useState(false);
  const [openFilterDialog, setOpenFilterDialog] = useState(false);
  const [openDeleteArticleDialog, setOpenDeleteArticleDialog] = useState(false);

  const statesToReadableWordMap: { [key: string]: string } = {
    ACTIVE: t('shared:published'),
    INACTIVE: t('shared:inactive'),
    DRAFT: t('shared:saved'),
  };

  const statesToVariantMap: { [key: string]: 'positive' | 'warning' | 'neutral' | 'negative' } = {
    ACTIVE: 'positive',
    INACTIVE: 'warning',
    DRAFT: 'neutral',
  };

  const [transitionArticle, { loading: transitionArticleLoading }] = useMutation(TRANSITION_ARTICLE, {
    variables: {
      input: {
        articleId: '',
        transition: 'activate',
      },
    },
  });
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  };
  if (error) (<Typography>Error</Typography>);

  const openAddArticleDialog = () => {
    setOpenCreateArticleDialog(true);
  };

  useEffect(() => {
    refetch().then();
  }, [refetch, searchText]);

  const publishOrUnPublishArticle = async () => {
    await transitionArticle({
      variables: {
        input: {
          articleId: selectedArticleRef.current?.id ?? '',
          transition: selectedArticleRef.current?.state === 'DRAFT' ? 'activate' : 'review',
        },
      },
    });
  };

  return (
    <Box>
      <Card variant='elevated' sx={{ overflowX: 'auto' }}>
        <CardContent>
          <Box display='flex' alignItems='center'justifyContent='space-between'>
            <TextField
              label=""
              sx={{ width: '300px' }}
              value={searchText}
              onChange={((e: {
                target: {
                  name: string,
                  value: string,
                },
              }) => {
                setSearchText(e.target.value);
                setPage(1);
              })}
              leadingIcon="search"
            />
            <Box display='flex' alignItems='center'>
              <IconButton onClick={() => setOpenFilterDialog(true)} sx={{ mr: 2 }} testId='news-and-insights-filter-button'>
                <FilterListRoundedIcon />
              </IconButton>
              {
                hasWritePermission && (<Button label={t('shared:add')} leadingIcon={AddIcon} onClick={() => {
                  setSelectedArticleState(null);
                  openAddArticleDialog();
                }}
                dataTestId='news-and-insights-add-button'
                />)
              }
            </Box>
          </Box>
        </CardContent>
        <Table aria-label="table">
          <TableBody>
            <TableRow>
              <TableHeadCell>
                {t('newsAndInsight:articleName')}
              </TableHeadCell>
              <TableHeadCell>
                {t('newsAndInsight:uploadedBy')}
              </TableHeadCell>
              <TableHeadCell>
                {t('newsAndInsight:lastUpdated')}
              </TableHeadCell>
              <TableHeadCell>
                {t('newsAndInsight:articleLink')}
              </TableHeadCell>
              <TableHeadCell>
                {t('newsAndInsight:status')}
              </TableHeadCell>
              {
                hasWritePermission && (
                  <TableHeadCell></TableHeadCell>
                )
              }
            </TableRow>
            {
              loading && !previousData ? (
                <>
                  { [...Array(15)].map((x: any, i: number) => (
                    <TableRow key={i}>
                      <TableCell><Skeleton width='100%' /></TableCell>
                      <TableCell><Skeleton width='100%' /></TableCell>
                      <TableCell><Skeleton width='100%' /></TableCell>
                      <TableCell><Skeleton width='100%' /></TableCell>
                      <TableCell><Skeleton width='100%' /></TableCell>
                      <TableCell><Skeleton width='100%' /></TableCell>
                    </TableRow>
                  ))}
                </>
              ) : (data || previousData)?.fetchArticles?.articles?.map((article: any) => (
                <TableRow
                  hover
                  sx={{ cursor: 'pointer' }}
                  key={article.id}
                  onClick={() => {
                    if (hasWritePermission) {
                      selectedArticleRef.current = article;
                      handleEditClickedOption();
                    }
                  }}
                >
                  <TableCell sx={{ maxWidth: '150px' }}>
                    <Typography
                      variant='bodyMedium'
                      sx={{
                        width: '100%',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {translateBackend(article.translatedName)}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='bodyMedium'>{`${article.createdBy.firstName} ${article.createdBy.lastName}`}</Typography>
                  </TableCell>
                  <TableCell><Typography variant='bodyMedium'>{formatDate(article.updatedAt)}</Typography></TableCell>
                  <TableCell sx={{ maxWidth: '350px' }}>
                    <Typography
                      variant='bodyMedium'
                      weight='regular'
                      sx={{
                        width: '100%',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        fontWeight: 400,
                      }}
                    >
                      {article.articleUrl}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Badge label={statesToReadableWordMap[article.state]} color={statesToVariantMap[article.state]}/>
                  </TableCell>
                  {
                    hasWritePermission && (
                      <TableCell>
                        <IconButton onClick={(e) => handleClick(e, article)}>
                          <MoreVertRoundedIcon />
                        </IconButton>
                      </TableCell>
                    )
                  }
                </TableRow>
              ))
            }
          </TableBody>
        </Table>
        <Pagination
          count={Math.ceil(((data || previousData)?.fetchArticles?.totalCount ?? 0) / pageSize)}
          page={page}
          onChange={(_e, newPage) => setPage(newPage)}
          sx={{
            p: 1,
            textAlign: 'right',
            '.MuiPagination-ul': {
              justifyContent: 'end',
            },
          }}
        />
      </Card>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={handleEditClickedOption}>
          <EditRoundedIcon sx={{ marginRight: '8px' }} />
          {t('shared:edit')}
        </MenuItem>
        <MenuItem
          onClick={async () => {
            await publishOrUnPublishArticle();
            refetch().then();
            handleClose();
          }}
        >
          {selectedArticleRef.current?.state === 'ACTIVE' ? (<VisibilityOffRoundedIcon sx={{ marginRight: '8px' }} />) : (<RemoveRedEyeRoundedIcon sx={{ marginRight: '8px' }} />)}
          {selectedArticleRef.current?.state === 'ACTIVE' ? t('shared:unpublish') : t('shared:publish')}
        </MenuItem>
        <MenuItem
          onClick={() => {
            setOpenDeleteArticleDialog(true);
            handleClose();
          }}
        >
          <DeleteRoundedIcon sx={{ marginRight: '8px' }} />
          {t('shared:delete')}
        </MenuItem>
      </Menu>
      {
        openCreateArticleDialog && (
          <CreateArticle
            open={openCreateArticleDialog}
            setOpen={setOpenCreateArticleDialog}
            refetch={refetch}
            loading={transitionArticleLoading}
            transitionArticle={transitionArticle}
          />
        )
      }
      {
        openEditArticleDialog && (
          <EditArticle
            open={openEditArticleDialog}
            setOpen={setOpenEditArticleDialog}
            refetch={refetch}
            article={selectedArticleState}
            loading={transitionArticleLoading}
            transitionArticle={transitionArticle}
          />
        )
      }
      <FilterArticles
        open={openFilterDialog}
        setOpen={setOpenFilterDialog}
        state={stateToBeFiltered}
        setState={setStateToBeFiltered}
        loading={transitionArticleLoading}
        clearFilter={clearFilter}
      />
      <DeleteArticle open={openDeleteArticleDialog} setOpen={setOpenDeleteArticleDialog} article={selectedArticleRef} refetch={refetch} loading={transitionArticleLoading} />
    </Box>
  );
};

export default ManageNewsAndInsight;
