import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { gql, useMutation, useQuery } from '@apollo/client';
import { InfoOutlined } from '@mui/icons-material';
import { Box, Skeleton, Typography } from '../../1-primative';
import { SegmentedControl } from '../../2-component';
import { usePermissions, UserContext } from '../../../providers/userContextProvider';
import { FeatureFlag as FeatureFlagInterface, FeatureFlagOption } from '../../../interfaces/organizationFeatureFlag';
import { TranslatedString } from '../../../interfaces';
import { translateBackend } from '../../../assets/i18n/config';

export const FETCH_ORGANIZATION_FEATURE_FLAGS = (organizationId: string) => gql`
  query fetchOrganizationFeatureFlags {
    fetchOrganization(organizationId:"${organizationId}") {
      organization {
        id
        availableFeatureFlags
      }
    }
    fetchOrganizationFeatureFlag(organizationId:"${organizationId}") {
      organizationFeatureFlag {
        id
        featureFlags { type enabled }
      }
    }
    fetchFeatureFlags {
      featureFlags {
        value
        group
        translatedDescription { en }
      }
    }
  }
`;

export const UPDATE_ORGANIZATION_FEATURE_FLAG = gql`
  mutation updateOrganizationFeatureFlag($input: UpdateOrganizationFeatureFlagInput!) {
    updateOrganizationFeatureFlag(input: $input) {
      organizationFeatureFlag {
        id
      }
    }
  }
`;

type FeatureFlagState = 'ON' | 'OFF' | 'UNDEFINED';
interface FeatureFlagRow {
  key: string,
  title: TranslatedString,
  group: string,
  originalState: FeatureFlagState,
  state: FeatureFlagState,
  isEnabled?: boolean,
}

const findFeatureFlagRowState = (orgFeatureFlags: FeatureFlagOption[], type = ''): FeatureFlagState => {
  const found = orgFeatureFlags.find((f) => f.type === type);
  if (!found) return 'UNDEFINED';
  return found.enabled ? 'ON' : 'OFF';
};

const findFeatureFlagRowIsEnabled = (availableFeatureFlags: string[], type = ''): boolean => availableFeatureFlags.some((x) => x === type);

export const FeatureFlag = ({
  featureFlag, onChange, testId,
}: {
  featureFlag?: FeatureFlagRow, onChange: (key: string, newState: FeatureFlagState) => void, testId?: string,
}) => {
  const { permissions } = usePermissions();
  const { t } = useTranslation(['shared', 'components']);

  if (!featureFlag || !permissions.includes('write:feature_flags')) return <></>;

  return (
    <Box maxWidth='400px'>
      { featureFlag ? (
        <Typography variant='labelSmall' colorVariant='variant' mb={0.5}>{translateBackend(featureFlag?.title)}</Typography>
      ) : (
        <Skeleton height='16px' width='100px' sx={{ mb: '4px' }} />
      ) }
      <SegmentedControl testId={testId} value={featureFlag?.state} onChange={(value) => onChange(featureFlag.key, value as FeatureFlagState)} segments={[
        { label: t('yes'), value: 'ON' },
        { label: t('no'), value: 'OFF' },
        { label: t('inherited'), value: 'UNDEFINED' }]}
      />
      {featureFlag?.state === 'UNDEFINED' && featureFlag?.state === featureFlag?.originalState && (
        <Box alignItems='center' display='flex' mt={0.5}>
          <Typography variant='bodySmall' colorVariant='variant'>
            <InfoOutlined sx={{ fontSize: '12px', verticalAlign: 'bottom', mr: 0.5 }} />
            {t('components:featureFlags.inheritedSetTo')} {t(`components:featureFlags.${featureFlag.isEnabled}`)}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export const useFeatureFlags = (): {
  featureFlags: FeatureFlagRow[],
  isFeatureFlagEnabled: (type: string) => boolean,
  loading: boolean,
  updateFeatureFlag: (key: string, newState: FeatureFlagState) => void,
  saveFeatureFlags: () => Promise<void>,
} => {
  const { activeOrganization } = useContext(UserContext);
  const [organizationFeatureFlagId, setOrganizationFeatureFlagId] = useState('');
  const [featureFlags, setFeatureFlags] = useState<FeatureFlagRow[]>([]);

  const { loading, data, refetch } = useQuery(FETCH_ORGANIZATION_FEATURE_FLAGS(activeOrganization.id ?? ''));

  const [updateOrganizationFeatureFlag, { loading: updateLoading }] = useMutation(UPDATE_ORGANIZATION_FEATURE_FLAG, {
    variables: {
      input: {
        organizationFeatureFlagId,
        featureFlags: [] as { type: string, enabled: boolean }[],
      },
    },
  });

  const updateFeatureFlag = (key: string, newState: FeatureFlagState) => {
    const newFeatureFlags = featureFlags.map((ff) => {
      if (ff.key === key) {
        return {
          ...ff,
          state: newState,
        };
      }
      return ff;
    });
    setFeatureFlags(newFeatureFlags);
  };

  const isFeatureFlagEnabled = (type: string) => {
    const found = featureFlags.find((ff) => ff.key === type);
    if (found?.state === 'OFF') return false;
    return found?.isEnabled ?? false;
  };

  const saveFeatureFlags = async () => {
    await updateOrganizationFeatureFlag({
      variables: {
        input: {
          organizationFeatureFlagId,
          featureFlags: featureFlags.filter((f) => f.state !== 'UNDEFINED').map((f) => ({ type: f.key, enabled: f.state === 'ON' })),
        },
      },
      onCompleted: () => {
        refetch();
      },
    });
  };

  useEffect(() => {
    if (data) {
      const orgFeatureFlags = data.fetchOrganizationFeatureFlag.organizationFeatureFlag.featureFlags;
      const flags = data.fetchFeatureFlags.featureFlags;
      setFeatureFlags((prev) => flags.map((f: FeatureFlagInterface) => {
        const state = findFeatureFlagRowState(orgFeatureFlags, f.value);
        const editedState = prev.find((ff) => ff.key === f.value)?.state;
        return {
          key: f.value,
          title: f.translatedDescription,
          group: f.group,
          originalState: state,
          state: editedState ?? state,
          isEnabled: state === 'ON' ? true : state === 'OFF' ? false : findFeatureFlagRowIsEnabled(data.fetchOrganization.organization.availableFeatureFlags, f.value),
        };
      }));
      setOrganizationFeatureFlagId(data.fetchOrganizationFeatureFlag.organizationFeatureFlag.id);
    }
  }, [data]);

  return {
    featureFlags, loading: updateLoading || loading, updateFeatureFlag, isFeatureFlagEnabled, saveFeatureFlags,
  };
};
