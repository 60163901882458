import { useLocation } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import DashboardIcon from '@mui/icons-material/Dashboard';
import StartIcon from '@mui/icons-material/Start';
import { Drawer } from '@mui/material';
import { usePermissions, UserContext } from '../../../providers/userContextProvider';

import { useGlobalStats } from '../../../providers/globalStatsHooks';
import {
  IconButton,
  NavSection,
} from '../../2-component';
import {
  Box,
} from '../../1-primative';
import { Section, SubSection } from '../../2-component/navSection/navSection';
import { handleAnalytics, sectionsConfig, settingsSectionsConfig } from './config';
import { AnalyticsContext } from '../../../providers/analyticsProvider';
import { useThemeTokens } from '../../../providers/themeTokenProvider';
import { useLayoutTokens } from './layout.tokens';

export const useMenuSections = () => {
  const { userContext } = useContext(UserContext);
  const location = useLocation();

  const dashboardsSection = {
    name: 'menu.dashboard',
    icon: DashboardIcon,
    subSections: userContext.role?.dashboards?.map((x: any) => ({
      name: x.name,
      path: `/dashboard/${x.id}`,
      accessKey: 'HOME',
    })) ?? [],
  };

  return location.pathname.includes('/settings') ? settingsSectionsConfig : [dashboardsSection, ...sectionsConfig];
};

const Sidebar = (props: any) => {
  const { userContext, closed, setClosed } = useContext(UserContext);
  const { sys, comp } = useThemeTokens(useLayoutTokens());
  const { accessiblePages } = usePermissions();
  const globalStats = useGlobalStats();
  const location = useLocation();
  const { window } = props;
  const { mobileOpen } = props;
  const { setMobileOpen } = props;
  const [sectionsOpen, setSectionsOpen] = useState<any[]>([]);
  const [openedMenu, setOpenedMenu] = useState<string>('');
  const { sendAnalytic } = useContext(AnalyticsContext);

  const handleDrawerClose = () => {
    setMobileOpen(false);
  };

  const handleDrawerClick = (name: string) => {
    handleAnalytics(name, sendAnalytic);
    setMobileOpen(false);
  };

  const finalSections: Section[] = useMenuSections();

  useEffect(() => {
    const initiallyOpenSections: any[] = Array(finalSections.length).fill(false);
    finalSections.forEach((sec, idx) => {
      if (sec.subSections && sec.subSections.length > 1) {
        const matchingPaths = sec.subSections.filter((subsec: SubSection) => location.pathname.includes(subsec.path));
        if (matchingPaths.length > 0) {
          initiallyOpenSections[idx] = true;
        }
      }
    });
    setSectionsOpen(initiallyOpenSections);
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [userContext.role?.dashboards]);

  const calculateActiveSection = () => {
    const activeSection = finalSections.find((section, index) => {
      if (section.subSections && section.subSections.length > 0) {
        return section.subSections.some((subsec: SubSection) => location.pathname.includes(subsec.path));
      }
      return false;
    });

    if (activeSection) {
      const activeSectionIndex = finalSections.indexOf(activeSection);
      const newSectionsOpen = Array(finalSections.length).fill(false);
      newSectionsOpen[activeSectionIndex] = true;
      setSectionsOpen(newSectionsOpen);
    }
  };

  useEffect(() => {
    calculateActiveSection();
  }, [location.pathname, closed]);

  const displayStats = (s: SubSection) => !!(s.stats && globalStats[s.stats] && globalStats[s.stats] > 0);

  const drawer = (
    <Box display='flex' flexDirection='column' justifyContent='space-between' sx={{
      height: '100%', flexWrap: 'inherit', mt: { xs: 2, lg: 0 }, overflowX: 'visible', overflowY: closed ? 'inherit' : 'auto',
    }}>
      <Box overflow='visible'>
        {finalSections.map((section, index) => (
          <Box key={section.name} onMouseEnter={() => setOpenedMenu(section.name)} overflow='visible'>
            <NavSection
              section={section}
              accessiblePages={accessiblePages}
              handleDrawerClick={handleDrawerClick}
              globalStats={globalStats}
              displayStats={displayStats}
              showSection={false}
              setSectionsOpen={setSectionsOpen}
              sectionsOpen={sectionsOpen}
              index={index}
              location={location}
              closed={closed && !mobileOpen}
              openedMenu={openedMenu === section.name}
            />
          </Box>
        ))}
      </Box>
      <Box display='flex' justifyContent='end'>
        <IconButton onClick={() => setClosed(!closed)} size='small' sx={{ fontSize: '12px', m: 1 }}>
            <StartIcon sx={{ transform: !closed ? 'rotate(180deg)' : undefined, transition: 'all 0.4s', color: sys.color.onSurface }} />
        </IconButton>
      </Box>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box onMouseLeave={() => setOpenedMenu('')}>
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Drawer
        container={container}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerClose}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: {
            xs: 'block', sm: 'block', md: 'block', lg: 'none',
          },
          overflow: 'visible',
          '& .MuiDrawer-paper': {
            width: closed && !mobileOpen ? '48px' : '240px',
            backgroundColor: comp.layout.sidebarColor,
            transition: 'all 0.4s',
            overflow: 'visible',
          },
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: {
            xs: 'none', sm: 'none', md: 'none', lg: 'block',
          },
          overflow: 'visible',
          '& .MuiDrawer-paper': {
            width: closed && !mobileOpen ? '52px' : '240px',
            backgroundColor: comp.layout.sidebarColor,
            transition: 'all 0.4s',
            overflow: 'visible',
            mt: 8,
            pt: 1.5,
            height: 'calc(100% - 76px)',
          },
        }}
        open
      >
        {drawer}
      </Drawer>
    </Box>
  );
};

export default Sidebar;
