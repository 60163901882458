import { useQuery } from '@apollo/client';
import { useContext, useEffect, useState } from 'react';
import { translateBackend } from 'assets/i18n/config';
import { MenuItem, SelectField } from 'ovComponents/2-component';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../../providers/userContextProvider';
import { FETCH_INTEGRATIONS } from '../../../pages/devSettings/components/integrations';
import { IntegrationState, IntegrationTypes } from '../../../interfaces';

export const IntegrationSelectFieldEdit = ({
  option,
  options,
  setOptions,
  i,
  type,
}: {
  option: any;
  options: any;
  setOptions: (x: any) => void;
  i: number;
  type: IntegrationTypes,
}) => {
  const { activeOrganization } = useContext(UserContext);
  const { t } = useTranslation('devSettings');
  const [opts, setOpts] = useState([]);
  const { data } = useQuery(FETCH_INTEGRATIONS, {
    variables: {
      input: {
        filter: {
          organizationId: activeOrganization.id,
          state: IntegrationState.ACTIVE,
          type,
        },
        pagination: {
          perPage: 100,
        },
      },
    },
  });

  useEffect(() => {
    if (data) {
      const integrations = data.fetchIntegrations?.integrations?.map((integration: any) => ({ label: integration.provider, value: integration.id }));
      setOpts(integrations);
    }
  }, [data, option]);

  return (
    <SelectField
      label={translateBackend(option?.label)}
      value={option?.value}
      sx={{ mb: 2 }}
      fullWidth
      onChange={(e: any) => {
        const newOptions = [...options];
        newOptions[i] = { ...option, value: e.target.value };
        setOptions(newOptions);
      }}
    >
      {[...option.options, ...opts].map((item: any, idx: number) => (
        <MenuItem key={idx} value={item.value}>
          {item.value === 'default' ? t('integrationsTableOptions.default') : t(`integrationsTableOptions.provider.${item.label}`)}
        </MenuItem>
      ))}
    </SelectField>
  );
};
