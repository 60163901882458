import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import {
  DialogTitle,
  DialogContent,
  Form,
  IconButton,
  Button,
} from 'ovComponents/2-component';
import { Typography } from 'ovComponents/1-primative';
import { useThemeTokens } from 'providers/themeTokenProvider';
import { useTranslation } from 'react-i18next';
import { FeatureFlagTypes } from 'interfaces/organizationFeatureFlag';
import { useContext } from 'react';
import LinkBankAccountIcon from '../../../../assets/images/icons-outline/link-bank-icon.svg';
import { isFeatureEnabled } from '../../../../util';
import { UserContext } from '../../../../providers/userContextProvider';

export interface InputProp {
  hasError: boolean,
  message: string,
  value: string,
}

const AddBankAccountInformation = ({ onNext, onCancel, options }: { onNext: (index: number) => void, onCancel: () => void, options?: any }) => {
  const { activeOrganization } = useContext(UserContext);
  const { sys } = useThemeTokens();
  const { t } = useTranslation(['components']);
  const enableManualBankAccount: boolean = isFeatureEnabled(FeatureFlagTypes.MANUALLY_ADD_BANK_ACCOUNT) || options?.manualbankAccountLinking;
  const enableAutomaticBankAccount: boolean = isFeatureEnabled(FeatureFlagTypes.AUTOMATED_BANK_ACCOUNT_LINKING) || options?.automatedBankAccountLinking
    || activeOrganization?.externalProvider?.bankingConnectorProvider.clientIframeUrl;
  return (
    <Form>
      <DialogTitle display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="headingLarge">{t('components:bankAccountSelect.information.header')}</Typography>
        <IconButton onClick={() => onCancel()}>
          <CloseRoundedIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <img src={LinkBankAccountIcon} alt='link-bank-account' style={{ marginBottom: sys.spacing.xxl }} />
        <Typography textAlign="center" style={{ marginBottom: sys.spacing.xxl }} variant='titleLarge'>{t('components:bankAccountSelect.information.title')}</Typography>
        <Typography
          style={{ marginBottom: sys.spacing.xxl }}
          variant="bodyMedium"
          textAlign="center"
        >
          {t('components:bankAccountSelect.information.body')}
        </Typography>
        {enableAutomaticBankAccount && <Button onClick={() => onNext(2)} sx={{ marginBottom: sys.spacing.md }} label={t('components:bankAccountSelect.information.addAccountBtn')} />}
        {
          enableManualBankAccount ? (
            <Button onClick={() => onNext(3)} variant='text' label={t('components:bankAccountSelect.information.addManuallyBtn')} sx={{ fontSize: '14px', fontWeight: '500' }} />
          ) : undefined
        }
      </DialogContent>
    </Form>
  );
};

export default AddBankAccountInformation;
