import { useContext } from 'react';
import { gql, useQuery } from '@apollo/client';
import { MenuItem } from '@mui/material';
import { kebabCase } from 'lodash';
import { SelectField } from '../../2-component';
import { Organization } from '../../../interfaces';
import { UserContext } from '../../../providers/userContextProvider';

const FETCH_SUB_ACCOUNTS = gql`
  query fetchOrganizations($input: FetchOrganizationsInput!) {
    fetchOrganizations(input: $input) {
      organizations {
        id
        name
      }
      totalCount
    }
  }
`;
interface OrganizationSelectProp {
  label: string;
  onChange: (id: string) => void;
  value: string | undefined;
  sx?: any;
  size?: 'small' | 'medium';
  showValue?: boolean;
  filter?: any;
  onBlur?: any;
  error?: any;
  testId?: string;
}
export const OrganizationSelect = ({
  label, onChange, value, sx = {}, size, filter, onBlur, error, testId,
}: OrganizationSelectProp) => {
  const { activeOrganization } = useContext(UserContext);
  const { data } = useQuery(FETCH_SUB_ACCOUNTS, {
    variables: {
      input: {
        filter: {
          ...filter,
          childrenFor: activeOrganization.id,
        },
        pagination: {
          perPage: 1000,
        },
      },
      fetchPolicy: 'cache-and-network',
    },
  });

  return (
    <SelectField onChange={(e: any) => onChange(e.target.value)} label={label} fullWidth sx={sx} size={size} value={value ?? ''} onBlur={onBlur} error={error} testId={testId ?? 'organization-select'}>
      {data?.fetchOrganizations?.organizations?.map((organization: Organization) => (
        <MenuItem data-testId={testId ? `${testId}-${kebabCase(organization.name)}` : `organization-select-${kebabCase(organization.name)}`} key={organization.id} value={organization.id}>
          {organization.name}
        </MenuItem>
      )) ?? []}
    </SelectField>
  );
};
