import {
  Typography, TextField, MenuItem,
} from '@mui/material';
import { gql, useQuery } from '@apollo/client';

export const FETCH_ROLES = gql`
  query fetchRoles($input: FetchRolesInput!) {
    fetchRoles(input: $input) {
      roles {
        id
        translatedName {
          en
        }
      }
    }
  }
`;

const RoleSelect = ({
  onChange,
  value,
  label,
  organizationId,
  disabled = false,
  includeEmpty = false,
  size,
  error = false,
  onBlur,
}: {
  onChange: (event: any) => void;
  value: string;
  label: string;
  organizationId: string;
  disabled?: boolean;
  includeEmpty?: boolean;
  size?: 'small' | 'medium' | undefined;
  error?: boolean;
  onBlur?: () => void;
}) => {
  const { loading, error: rolesError, data } = useQuery(FETCH_ROLES, {
    variables: {
      input: {
        filter: { organizationId: organizationId || undefined },
        pagination: { perPage: 50 },
      },
    },
  });

  if (rolesError) <Typography>Error</Typography>;

  return (
    <>
      <TextField select value={data ? value : ''} label={label} sx={{ width: '100%', height: '100%' }} onChange={onChange} size={size} disabled={disabled} error={error} onBlur={onBlur}>
        {includeEmpty && (
          <MenuItem key='empty' value=''>
            -
          </MenuItem>
        )}
        {loading ? (
          <MenuItem>...</MenuItem>
        ) : (
          data.fetchRoles.roles.map((x: any) => (
            <MenuItem key={x.id} value={x.id}>
              {x.translatedName.en}
            </MenuItem>
          ))
        )}
      </TextField>
    </>
  );
};

export default RoleSelect;
