import { Grid, Typography } from '../../1-primative';
import {
  Card, CardContent,
} from '../../2-component';

export const SettingsCard = ({
  title, description, children,
}: {
  title: string, description: string, children: React.ReactNode,
}) => (
  <Card sx={{ maxWidth: '1440px', overflow: 'visible' }}>
    <CardContent sx={{ p: 3 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Typography variant='headingLarge' sx={{ mb: 1 }}>{title}</Typography>
          <Typography variant='bodyLarge' colorVariant='variant'>{description}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          {children}
        </Grid>
      </Grid>
    </CardContent>
  </Card>
);
