import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Close } from '@mui/icons-material';
import dayjs from 'dayjs';
import { gql, useMutation } from '@apollo/client';
import { useAuthContext } from 'providers/ovApolloProvider';
import { auth0RefreshTokenSilently } from 'providers/auth0Client';
import { kebabCase, sum } from 'lodash/fp';
import {
  Account, Affiliation, EmploymentStatus, OrganizationUserAccessTypes,
} from '../../../../../../interfaces';
import { AffiliateType } from './affiliate';
import { Box, Grid, Typography } from '../../../../../1-primative';
import {
  AddressField, Button, Checkbox, DateField, Dialog, DialogContent, DialogFooter, DialogTitle, Form, IconButton, MenuItem, SelectField, TextField,
} from '../../../../../2-component';
import { SelectionTile } from '../../../../../3-pattern';
import { ALL_RELATIONS, type FieldType, RelationType } from '../accountConfig';
import { delay } from '../../../../../../util';
import { UserContext } from '../../../../../../providers/userContextProvider';
import { TaxIdInputs } from './taxIdExists';

const MAXIMUM_ALLOCATION_PERCENTAGE = 100;

const EMPLOYED_EMPLOYMENT_STATUSES = [EmploymentStatus.RETIRED, EmploymentStatus.EMPLOYED, EmploymentStatus.SELF_EMPLOYED];

const CREATE_AFFILIATE = gql`
  mutation createAffiliate($input: CreateAffiliateInput!) {
    createAffiliate(input: $input) {
      user { id }
    }
  }
`;

const CREATE_USER = gql`
  mutation createUser($input: CreateUserInput!) {
    createUser(input: $input) {
      user { id }
    }
  }
`;

const UPDATE_USER = gql`
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user { id }
    }
  }
`;

export const UPDATE_AFFILIATIONS = gql`
  mutation updateAffiliations($input: UpdateAffiliationsInput!) {
    updateAffiliations(input: $input) {
      account { id affiliations { id allocation relation type } }
      incompleteAffiliations {
        user { id firstName lastName }
        type
        relation
        incompleteFields
      }
    }
  }
`;

export const AffiliateModal = ({
  open, setOpen, affiliate, action = 'create', fields = [], optionalFields = [], type, title, accountId, affiliates, refetch, allAffiliates, account, useAccountHoldersAddress,
}: {
  open: boolean, setOpen: (open: boolean) => void, affiliate?: Affiliation, action?: string, fields?: FieldType[], optionalFields?: FieldType[], type: AffiliateType, title?: string,
  accountId: string, affiliates: Affiliation[], refetch?: () => void, allAffiliates: Affiliation[], account: Partial<Account>, useAccountHoldersAddress?: boolean,
}) => {
  const { t } = useTranslation(['affiliationTypes', 'client']);
  const { setAuthToken } = useAuthContext();
  const { userContext } = useContext(UserContext);
  const [affiliateData, setAffiliateData] = useState<any>(affiliate);
  const [focused, setFocused] = useState<string[]>([]);

  const [createAffiliate, { loading: createAffiliateLoading }] = useMutation(CREATE_AFFILIATE);
  const [createUser, { loading: createUserLoading }] = useMutation(CREATE_USER);
  const [updateUser, { loading: updateLoading }] = useMutation(UPDATE_USER);
  const [updateAffiliations, { loading }] = useMutation(UPDATE_AFFILIATIONS);

  const requiredFields = fields.map((field) => field.type);
  const allFieldTypes = requiredFields.concat(...optionalFields.map((field) => field.type));
  const relations = fields.find((field) => field?.type === 'relation')?.options;
  const isDisabled = createAffiliateLoading || createUserLoading || updateLoading || loading;

  useEffect(() => {
    if (affiliate?.user && Object.keys(affiliate?.user).length !== 0) setAffiliateData(affiliate);
  }, [affiliate]);

  useEffect(() => {
    if ((affiliate?.user && Object.keys(affiliate?.user).length === 0) && allFieldTypes.includes('allocated')) {
      const allocation = requiredFields.includes('allocated') ? calculateAllocation() : 0;
      setAffiliateData({ ...affiliateData, allocation });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [affiliate]);

  const calculateAllocation = () => Math.floor(MAXIMUM_ALLOCATION_PERCENTAGE / ((affiliates.filter((x: any) => x.type === type).length || 0) + 1));
  const calculateAllocatedValue = (diff: number, aff: Affiliation[]) => Math.floor((MAXIMUM_ALLOCATION_PERCENTAGE - diff) / ((aff.filter((x: any) => x.type === type).length || 0)));

  const refreshAuthToken = async (): Promise<void> => {
    await delay(2000);
    const newToken = await auth0RefreshTokenSilently({ orgSubdomain: userContext.organization?.subdomain });
    if (newToken) setAuthToken(newToken);
  };

  const updateAccountAffiliations = (userId: string) => {
    let allocation = 0;
    if (requiredFields.includes('allocation')) allocation = calculateAllocation();
    if (allFieldTypes.includes('allocated')) allocation = affiliateData.allocation || 0;

    const affiliations = [...(allAffiliates || []).map((item: any) => ({
      allocation: item.type === type
        ? (allFieldTypes.includes('allocated') ? calculateAllocatedValue(affiliateData.allocation || 0, affiliates) : calculateAllocation())
        : item.allocation,
      relation: item.relation,
      type: item.type,
      userId: item.user.id,
    })), {
      userId, relation: affiliateData.relation || RelationType.OTHER, type, allocation,
    }];

    updateAffiliations({
      variables: { input: { accountId, affiliations } },
      onCompleted: async () => {
        refetch && refetch();
        setOpen(false);
        if (userContext.accessType === OrganizationUserAccessTypes.ENTITY) {
          await refreshAuthToken();
        }
      },
    });
  };

  const updateExistingAccountAffiliations = () => {
    const newAff = allAffiliates.filter((item) => item.id !== affiliateData.id);

    let allocationGreaterThanMaxPercentage = false;
    if (allFieldTypes.includes('allocated')) {
      const relatedAff = newAff.filter((item: any) => item.type === type);
      const totalAllocation = sum(relatedAff.map((item) => item.allocation)) + (affiliateData.allocation || 0);
      allocationGreaterThanMaxPercentage = totalAllocation - MAXIMUM_ALLOCATION_PERCENTAGE >= 0;
    }

    const affiliations = [...newAff.map((item: Affiliation) => ({
      allocation: allFieldTypes.includes('allocated') && (item.type as unknown as AffiliateType) === type && allocationGreaterThanMaxPercentage
        ? calculateAllocatedValue(affiliateData.allocation || 0, newAff)
        : item.allocation,
      relation: item.relation,
      type: item.type,
      userId: item.user.id,
    })), {
      type, userId: affiliateData.user.id, relation: affiliateData.relation || RelationType.OTHER, allocation: affiliateData.allocation,
    }];

    updateAffiliations({
      variables: { input: { accountId, affiliations } },
      onCompleted: () => {
        refetch && refetch();
        setOpen(false);
      },
    });
  };

  const submit = () => {
    if (!validate()) return;

    if (action === 'create') {
      const userData = { ...affiliateData.user };
      if (!userData?.dateOfBirth) delete userData?.dateOfBirth;
      if (!userData?.dateOfDeath) delete userData?.dateOfDeath;

      if (type === AffiliateType.JOINT) {
        createUser({ variables: { input: userData }, onCompleted: (res) => updateAccountAffiliations(res?.createUser?.user.id) });
        return;
      }

      createAffiliate({ variables: { input: userData }, onCompleted: (res) => updateAccountAffiliations(res?.createAffiliate?.user.id) });
    } else {
      const userData = { ...affiliateData.user };
      if (!userData?.dateOfBirth) delete userData?.dateOfBirth;
      if (!userData?.dateOfDeath) delete userData?.dateOfDeath;

      delete userData?.id;
      delete userData?.taxIdExists;
      delete userData?.__typename;
      delete userData?.physicalAddress?.__typename;
      // For USA addresses, province is not available and is set to "null" from the backend. which causes an error while updating the affiliate.
      if (userData?.physicalAddress?.province === null) delete userData?.physicalAddress?.province;

      const input = { userId: affiliateData.user.id, ...userData };
      updateUser({ variables: { input }, onCompleted: updateExistingAccountAffiliations });
    }
  };

  const validate = () => {
    const invalidFields: string[] = [];

    if (requiredFields.includes('fullName') && !affiliateData.user.firstName) invalidFields.push('firstName');
    if (requiredFields.includes('fullName') && !affiliateData.user.lastName) invalidFields.push('lastName');
    if (requiredFields.includes('primaryEmail') && !affiliateData.user.primaryEmail) invalidFields.push('primaryEmail');
    if (requiredFields.includes('physicalAddress') && !affiliateData.user.physicalAddress?.streetName) invalidFields.push('physicalAddress');
    if (requiredFields.includes('dateOfBirth') && !affiliateData.user.dateOfBirth) invalidFields.push('dateOfBirth');
    if (requiredFields.includes('dateOfDeath') && !affiliateData.user.dateOfDeath) invalidFields.push('dateOfDeath');
    if (requiredFields.includes('gender') && !affiliateData.user.gender) invalidFields.push('gender');

    if (requiredFields.includes('relation') && !affiliateData.relation) invalidFields.push('relation');
    if (affiliateData.allocation < 0 || (requiredFields.includes('allocated') && !affiliateData.allocation)) invalidFields.push('allocated');

    const isTaxIdIncorrect = affiliateData.user.taxId && affiliateData.user.taxId.length !== 9;
    if (isTaxIdIncorrect || (requiredFields.includes('taxId') && !affiliateData.user.taxIdExists && !affiliateData.user.taxId)) invalidFields.push('taxId');

    if (allFieldTypes.includes('employmentStatus')) {
      if (requiredFields.includes('employmentStatus') && !affiliateData.user.employmentStatus) invalidFields.push('employmentStatus');

      if (EMPLOYED_EMPLOYMENT_STATUSES.includes(affiliateData.user.employmentStatus) && !affiliateData.user.companyType) invalidFields.push('companyType');
      if (EMPLOYED_EMPLOYMENT_STATUSES.includes(affiliateData.user.employmentStatus) && !affiliateData.user.jobTitle) invalidFields.push('jobTitle');
      if (affiliateData.user.employmentStatus === EmploymentStatus.STUDENT && !affiliateData.user.studentAreaOfStudy) invalidFields.push('studentAreaOfStudy');
    }

    setFocused(invalidFields);
    return invalidFields.length === 0;
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth='sm' fullWidth>
      <DialogTitle>
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          <Typography variant='headingLarge'>{title ?? t(`button.${type}`)}</Typography>
          <IconButton onClick={() => setOpen(false)}><Close /></IconButton>
        </Box>
      </DialogTitle>

      <Form onSubmit={submit}>
        <DialogContent>
          {allFieldTypes.includes('fullName') && (
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TextField
                  testId="affiliate-first-name"
                  label={t('client:details.firstName')}
                  value={affiliateData.user.firstName || ''}
                  onChange={(e: any) => setAffiliateData({ ...affiliateData, user: { ...affiliateData.user, firstName: e.target.value } })}
                  onBlur={() => setFocused([...focused, 'firstName'])}
                  error={!affiliateData.user.firstName && focused.includes('firstName') && requiredFields.includes('fullName')}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  testId="affiliate-middle-name"
                  label={t('client:details.middleName')}
                  value={affiliateData.user.middleName || ''}
                  onChange={(e: any) => setAffiliateData({ ...affiliateData, user: { ...affiliateData.user, middleName: e.target.value } })}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  testId="affiliate-last-name"
                  label={t('client:details.lastName')}
                  value={affiliateData.user.lastName || ''}
                  onChange={(e: any) => setAffiliateData({ ...affiliateData, user: { ...affiliateData.user, lastName: e.target.value } })}
                  onBlur={() => setFocused([...focused, 'lastName'])}
                  error={!affiliateData.user.lastName && focused.includes('lastName') && requiredFields.includes('fullName')}
                />
              </Grid>
            </Grid>
          )}
          {allFieldTypes.includes('physicalAddress') && (
            <AddressField manualAddressEntry sx={{ mt: 2 }}
              testId="affiliate-physical-address"
              address={affiliateData.user.physicalAddress || {}}
              onChange={(e: any) => setAffiliateData({ ...affiliateData, user: { ...affiliateData.user, physicalAddress: e } })}
              label={t('client:details.physicalAddress')}
              onBlur={() => setFocused([...focused, 'physicalAddress'])}
              error={!affiliateData.user.physicalAddress?.streetName && focused.includes('physicalAddress') && requiredFields.includes('physicalAddress')}
            />
          )}
          {useAccountHoldersAddress && (
            <Box display='flex' flexDirection='row' sx={{ flexFlow: 'wrap' }} mt={1}>
              <Checkbox chip
                testId="assign-account-holder-address"
                label={t('affiliationTypes:assignAccountHoldersAddress')}
                onChange={(checked: boolean) => {
                  if (checked) {
                    const accountHolderAddress = account.user?.physicalAddress;
                    delete accountHolderAddress?.__typename;
                    setAffiliateData({ ...affiliateData, user: { ...affiliateData.user, physicalAddress: accountHolderAddress } });
                  } else {
                    setAffiliateData({ ...affiliateData, user: { ...affiliateData.user, physicalAddress: '' } });
                  }
                }}
              />
            </Box>
          )}
          {allFieldTypes.includes('dateOfBirth') && (
            <DateField fullWidth sx={{ mt: 2 }}
              dataTestId="affiliate-date-of-birth"
              onChange={(date: any) => setAffiliateData({ ...affiliateData, user: { ...affiliateData.user, dateOfBirth: dayjs(date?.toString()).format('YYYY-MM-DD') } })}
              label={t('client:details.dateOfBirth')}
              value={affiliateData.user.dateOfBirth || ''}
              onBlur={() => setFocused([...focused, 'dateOfBirth'])}
              error={!affiliateData.user.dateOfBirth && focused.includes('dateOfBirth') && requiredFields.includes('dateOfBirth')}
            />
          )}
          {allFieldTypes.includes('dateOfDeath') && (
            <DateField fullWidth sx={{ mt: 2 }}
              dataTestId="affiliate-date-of-death"
              onChange={(date: any) => setAffiliateData({ ...affiliateData, user: { ...affiliateData.user, dateOfDeath: dayjs(date?.toString()).format('YYYY-MM-DD') } })}
              label={t('client:details.dateOfDeath')}
              value={affiliateData.user.dateOfDeath || ''}
              onBlur={() => setFocused([...focused, 'dateOfDeath'])}
              error={!affiliateData.user.dateOfDeath && focused.includes('dateOfDeath') && requiredFields.includes('dateOfDeath')}
            />
          )}
          {allFieldTypes.includes('taxId') && (
            <Box sx={{ mt: 2 }}>
              <TaxIdInputs
                user={account?.user}
                affiliateUser={affiliateData.user}
                setAffiliateUser={(user) => setAffiliateData({ ...affiliateData, user })}
                onBlur={() => setFocused([...focused, 'taxId'])}
                error={((affiliateData.user.taxId && affiliateData.user.taxId.length !== 9) || (!affiliateData.user.taxId && requiredFields.includes('taxId'))) && focused.includes('taxId')}
              />
            </Box>
          )}
          {allFieldTypes.includes('primaryEmail') && (
            <TextField fullWidth sx={{ mt: 2 }}
              testId="affiliate-primary-email"
              label={t('client:details.primaryEmail')}
              value={affiliateData.user.primaryEmail || ''}
              onChange={(e: any) => setAffiliateData({ ...affiliateData, user: { ...affiliateData.user, primaryEmail: e.target.value } })}
              onBlur={() => setFocused([...focused, 'primaryEmail'])}
              error={!affiliateData.user.primaryEmail && focused.includes('primaryEmail') && requiredFields.includes('primaryEmail')}
            />
          )}
          {allFieldTypes.includes('gender') && (
            <Box mt={2}>
              <Typography variant='labelSmall' colorVariant='variant' sx={{ mb: 1 }}>{t('client:details.gender')}</Typography>
              <SelectionTile
                testId="affiliate-gender"
                direction='row'
                value={affiliateData.user.gender || ''}
                onChange={(e: any) => setAffiliateData({ ...affiliateData, user: { ...affiliateData.user, gender: e.target.value } })}
                options={[{ value: 'Male', label: t('client:details.Male') }, { value: 'Female', label: t('client:details.Female') }]}
                error={!affiliateData.user.gender && focused.includes('gender') && requiredFields.includes('gender')}
              />
            </Box>
          )}
          {allFieldTypes.includes('employmentStatus') && (
            <SelectField fullWidth sx={{ mt: 2 }}
              testId="affiliate-employment-status"
              label={t('client:details.employmentStatus')}
              value={affiliateData.user.employmentStatus || ''}
              onChange={(e: any) => setAffiliateData({ ...affiliateData, user: { ...affiliateData.user, employmentStatus: e.target.value } })}
              onBlur={() => setFocused([...focused, 'employmentStatus'])}
              error={!affiliateData.user.employmentStatus && focused.includes('employmentStatus') && requiredFields.includes('employmentStatus')}
            >
              {['EMPLOYED', 'SELF_EMPLOYED', 'UNEMPLOYED', 'STUDENT', 'RETIRED'].map((value) => (
                <MenuItem key={value} data-testid={`affiliate-employment-status-${kebabCase(value)}`} value={value}>{t(`client:edit.employmentStatusOptions.${value}`)}</MenuItem>
              ))}
            </SelectField>
          )}
          {EMPLOYED_EMPLOYMENT_STATUSES.includes(affiliateData.user.employmentStatus) && (
            <>
              <TextField fullWidth sx={{ mt: 2 }}
                testId="affiliate-company-type"
                onChange={(e: any) => setAffiliateData({ ...affiliateData, user: { ...affiliateData.user, companyType: e.target.value } })}
                label={t('client:details.companyType')}
                value={affiliateData.user.companyType}
                onBlur={() => setFocused([...focused, 'companyType'])}
                error={!affiliateData.user.companyType && focused.includes('companyType')}
              />
              <TextField fullWidth sx={{ mt: 2 }}
                testId="affiliate-job-title"
                onChange={(e: any) => setAffiliateData({ ...affiliateData, user: { ...affiliateData.user, jobTitle: e.target.value } })}
                label={t('client:details.jobTitle')}
                value={affiliateData.user.jobTitle}
                onBlur={() => setFocused([...focused, 'jobTitle'])}
                error={!affiliateData.user.jobTitle && focused.includes('jobTitle')}
              />
            </>
          )}
          {affiliateData.user.employmentStatus === EmploymentStatus.STUDENT && (
            <TextField fullWidth sx={{ mt: 2 }}
              testId="affiliate-area-of-study"
              onChange={(e: any) => setAffiliateData({ ...affiliateData, user: { ...affiliateData.user, studentAreaOfStudy: e.target.value } })}
              label={t('client:details.studentAreaOfStudy')}
              value={affiliateData.user.studentAreaOfStudy}
              onBlur={() => setFocused([...focused, 'studentAreaOfStudy'])}
              error={!affiliateData.user.studentAreaOfStudy && focused.includes('studentAreaOfStudy')}
            />
          )}
          {allFieldTypes.includes('allocated') && (
            <TextField fullWidth sx={{ mt: 2 }}
              testId='affiliate-allocated'
              label={t('client:details.allocation')}
              type='number'
              trailingIcon='percent'
              value={affiliateData.allocation || 0}
              onChange={(e: any) => setAffiliateData({ ...affiliateData, allocation: parseInt(e.target.value, 10) })}
              onBlur={() => setFocused([...focused, 'allocated'])}
              error={(affiliateData.allocation < 0 || (!affiliateData.allocation && requiredFields.includes('allocated'))) && focused.includes('allocated')}
            />
          )}
          {allFieldTypes.includes('relation') && (
            <SelectField fullWidth sx={{ mt: 2 }}
              testId="affiliate-relation"
              label={t('client:details.relation')}
              value={affiliateData.relation || ''}
              onChange={(e: any) => setAffiliateData({ ...affiliateData, relation: e.target.value })}
              onBlur={() => setFocused([...focused, 'relation'])}
              error={!affiliateData.relation && focused.includes('relation') && requiredFields.includes('relation')}
            >
              {relations
                ? relations.map((item: any, idx: number) => <MenuItem key={idx} value={item.value}>{item.label}</MenuItem>)
                : ALL_RELATIONS.map((item: any, idx: number) => (
                  <MenuItem data-testid={`affiliate-relation-${item}`} key={idx} value={item}>{t(`client:details.relationOptions.${item}`)}</MenuItem>
                ))}
            </SelectField>
          )}
        </DialogContent>
        <DialogFooter>
          <Box display='flex' justifyContent='end' p={1}>
            <Button dataTestId="create-btn" label={t(`client:form.${action === 'create' ? 'add' : 'update'}`)} type='submit' variant='tonal' disabled={isDisabled} />
          </Box>
        </DialogFooter>
      </Form>
    </Dialog>
  );
};
