import { useEffect, useState } from 'react';
import { ToggleButton, ToggleButtonGroup, SxProps } from '@mui/material';
import { colors, SPACING } from 'ovComponents/0-tokens';
import { Typography } from 'ovComponents/1-primative';
import { kebabCase } from 'lodash';

export type SegmentValue = string | number;
export type SegmentControlValue = SegmentValue | SegmentValue[] | undefined;
export type Segment = {
  value: SegmentValue;
  label: string | number;
  disabled?: boolean;
};

interface SegmentedControlProps {
  value?: SegmentControlValue;
  segments: Segment[];
  onChange?: (value: SegmentValue) => void;
  disabled?: boolean;
  exclusive?: boolean;
  fullWidth?: boolean;
  enforceActive?: boolean;
  sx?: SxProps;
  testId?: string;
}

export const SegmentedControl = ({
  segments, value, onChange, disabled = false, exclusive = true, fullWidth = false, enforceActive = false, sx, testId,
}: SegmentedControlProps) => {
  const [active, setActive] = useState<SegmentControlValue>(value);
  useEffect(() => {
    setActive(value);
  }, [value]);

  const setActiveAndPropagate = (event: React.SyntheticEvent, newValue: SegmentValue) => {
    if (newValue !== null || !enforceActive) {
      setActive(newValue);
      if (onChange) onChange(newValue);
    }
  };

  const separatarOffest = '14%';
  const defaultSegmentedControlSx: SxProps = {
    '&.MuiToggleButtonGroup-root': {
      backgroundColor: colors.neutral300,
      padding: '2px',
      width: '100%',
    },
    '.MuiButtonBase-root.MuiToggleButton-root': {
      textTransform: 'none',
      padding: `${SPACING}px`,
      width: `${100 / segments.length}%`,
      color: colors.neutral800,
      border: 'none',
      willChange: 'transform',
      transition: 'all 0.2s ease',

      /*
      The use of the 'before' and 'after' pseudo-selectors
      is used to render the segment dividers.
      The `willChange` and `transition` styles are used to
      trigger selection events and to respond to them by easing
      the dividers into view.
       */
      '&::before, &::after': {
        content: '""',
        width: '1px',
        backgroundColor: colors.neutral500,
        position: 'absolute',
        top: `${separatarOffest}`,
        bottom: `${separatarOffest}`,
        borderRadius: '10px',
        willChange: 'background',
        transition: 'background 0.2s ease',
        zIndex: 1,
      },
      '&::before': {
        left: 0,
      },
      '&::after': {
        right: 0,
      },
      '&:first-of-type::before, &:last-of-type::after': {
        opacity: 0,
      },

      '&.Mui-disabled': {
        color: colors.neutral500,
      },
      '&.Mui-selected': {
        borderRadius: '4px',
        backgroundColor: 'white',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.0225)',
        willChange: 'transform',
        transition: 'transform 0.2s ease',
        zIndex: 2,
        '&::before, &::after': {
          opacity: 0,
        },
      },
    },
  };

  return (
    <ToggleButtonGroup
      value={active}
      onChange={setActiveAndPropagate}
      disabled={disabled}
      exclusive={exclusive}
      fullWidth={fullWidth}
      sx={[{ ...defaultSegmentedControlSx }, ...(Array.isArray(sx) ? sx : [sx])]}
    >
      {segments.map((segment, index) => (
        <ToggleButton
          value={segment.value}
          key={index}
          disabled={segment?.disabled}
          sx={{ minWidth: '50px !important' }}
          data-testid={testId ? kebabCase(`${testId}-${segment.value}`) : kebabCase(`segmented-control-${segment.value}`)}
        >
          <Typography variant='trimmedSmall'>{segment.label}</Typography>
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};
