import { useContext, useState } from 'react';
import { Dialog } from 'ovComponents/2-component/dialog/dialog';
import { UserContext } from 'providers/userContextProvider';
import AddBankAccountInformation from './information';
import AddBankAccountAutomaticallyModal from './addBankAutomatically';
import AddBankAccountManuallyModal from './addBankAccountManually/addBankAccountManually';

const AddBankAccountVisual = ({
  open,
  handleClose,
  userId,
  afterCreate,
  bankAccounts,
  transactionType,
  options,
}: {
  userId: string;
  afterCreate: (newBankAccountId: string) => void;
  open: boolean;
  handleClose: () => void;
  bankAccounts: { id: string; name: string; bankAccountNumber: string }[];
  transactionType?: 'DEPOSIT' | 'WITHDRAWAL';
  options?: any;
}) => {
  const { activeOrganization } = useContext(UserContext);
  const enableAutoBanking: boolean = (activeOrganization.availableFeatureFlags?.includes('AUTOMATED_BANK_ACCOUNT_LINKING') || options?.automatedBankAccountLinking) ?? false;
  const [step, setStep] = useState(enableAutoBanking ? 1 : 3);

  const onClose = () => {
    setStep(enableAutoBanking ? 1 : 3);
    handleClose();
  };
  return (
    <Dialog open={open} onClose={onClose}>
      {step === 1 ? <AddBankAccountInformation options={options} onNext={(index) => setStep(index)} onCancel={onClose} /> : undefined}
      {step === 2 ? (
        <AddBankAccountAutomaticallyModal
          afterCreate={(bank: any) => {
            setStep(1);
            afterCreate(bank);
          }}
          userId={userId}
          bankAccounts={bankAccounts}
          onNext={(index: number) => setStep(index)}
          onCancel={onClose}
          transactionType={transactionType}
          options={options}
        />
      ) : undefined}
      {step === 3 ? (
        <AddBankAccountManuallyModal
          onNext={(index) => setStep(index)}
          afterCreate={(bank: any) => {
            setStep(1);
            afterCreate(bank);
          }}
          userId={userId}
          open={false}
          handleClose={onClose}
          options={options}
        />
      ) : undefined}
    </Dialog>
  );
};

export default AddBankAccountVisual;
