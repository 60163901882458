/* eslint-disable object-curly-newline */
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { gql as gqlQuery, useLazyQuery, useMutation } from '@apollo/client';
import dayjs from 'dayjs';
import ClearIcon from '@mui/icons-material/Clear';
import { Dialog, DialogContent, DialogTitle, IconButton, TextField } from '../../2-component';
import { useGlobalToast } from '../../../providers/globalToastProvider';
import { Box, Typography } from '../../1-primative';
import { formatPercentValue } from '../../../util';
import { useThemeTokens } from '../../../providers/themeTokenProvider';
import ConfigurationModal, { Configuration } from './configurationModal';
import { LocalizedDatePicker } from '../../../components/fields/localizedDatePicker';

export enum ExportApiReportFormatters {
  DIVIDE_BY_100 = 'DIVIDE_BY_100',
  MULTIPLY_BY_100 = 'MULTIPLY_BY_100',
}

enum DataExportStates {
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  GENERATING = 'GENERATING',
}

export interface Column {
  gqlAlias: string,
  header: string,
  formatter?: ExportApiReportFormatters,
}

export interface DateRangeConfig {
  fromLabel?: string;
  toLabel?: string;
  fromDate: string | null;
  toDate: string | null;
  onFromDateChange: (date: string | null) => void;
  onToDateChange: (date: string | null) => void;
}

export interface DownloadButtonProps {
  columns: Column[],
  additionalColumns?: Column[],
  datasetPath: string,
  fileName?: string,
  filter: Record<string, any>,
  additionalFilter?: Record<string, any>,
  gql: string,
  queryPath: string,
  sortDesc?: boolean,
  sortField?: string,
  perPage?: number,
  progressTitle?: string,
  configurable?: boolean,
  testId?: string,
  dateRangeConfig?: DateRangeConfig,
  maxDateWindow?: number,
  onDateValidationError?: (message: string) => void,
  disabled?: boolean;
  onColumnsChange?: (columns: string[]) => void;
  onExportComplete?: () => void;
}

const EXPORT_API_REPORT = gqlQuery`
  mutation exportApiReport($input: ExportApiReportInput!) {
    exportApiReport(input: $input) {
      apiExport {
        id
      }
    }
  }
`;

const FETCH_API_EXPORT = gqlQuery`
  query fetchApiExport($input: FetchApiExportInput!) {
    fetchApiExport(input: $input) {
      apiExport {
        id
        progress
        state
        fileUrl
      }
    }
  }
`;

/**
 * @param columns - use it for columns that are usually present in a table and visible to a user
 * @param additionalColumns - use it for additional columns to fetch the data beyond the table columns
 * @param filter - use it for required filters ex. user, organization
 * @param additionalFilter - use it for optional filters ex. state, type
 * @param configurable - use it for showing a configuration dialog before exporting, a user will be able to choose desired columns to export
 */
export const DownloadButton = ({
  columns,
  additionalColumns,
  datasetPath,
  fileName,
  filter,
  additionalFilter,
  gql,
  queryPath,
  sortDesc,
  sortField,
  perPage,
  progressTitle,
  configurable,
  testId,
  dateRangeConfig,
  maxDateWindow,
  onDateValidationError,
  disabled,
  onColumnsChange,
  onExportComplete,
}: DownloadButtonProps) => {
  const [open, setOpen] = useState(false);
  const [openConfiguration, setOpenConfiguration] = useState(false);
  const { t } = useTranslation('components');
  const { sys } = useThemeTokens();
  const { showToast } = useGlobalToast();

  const handleExportComplete = (fileUrl: string) => {
    window.open(fileUrl, '_blank');
    onExportComplete?.();
    setOpen(false);
    stopPolling();
  };

  const handleExportFailed = () => {
    showToast({ severity: 'error', message: t('downloadButton.failedDownloadMessage') });
    setOpen(false);
    stopPolling();
  };

  const [fetchApiExport, { data, startPolling, stopPolling }] = useLazyQuery(FETCH_API_EXPORT, {
    onCompleted: (response) => {
      const { state, fileUrl, progress } = response.fetchApiExport.apiExport;

      if (state === DataExportStates.GENERATING) {
        startPolling(1000);
      } else if (state === DataExportStates.COMPLETED) {
        setTimeout(() => handleExportComplete(fileUrl), 1000);
      } else if (state === DataExportStates.FAILED || progress >= 1) {
        handleExportFailed();
      }
    },
  });

  const queryParams = {
    gql,
    filter,
    queryPath,
    datasetPath,
    columns,
    fileName,
    sortField,
    sortDesc,
    perPage,
  };

  const [exportApiReport] = useMutation(EXPORT_API_REPORT, {
    variables: { input: queryParams },
    onCompleted: (dataApiExport: any) => {
      setOpen(true);
      fetchApiExport({
        variables: {
          input: {
            apiExportId: dataApiExport.exportApiReport.apiExport.id,
          },
        },
      });
    },
  });

  const onExport = (configuration?: Configuration) => {
    exportApiReport({
      variables: {
        input: {
          ...queryParams,
          filter: configuration?.useAdditionalFilter ? { ...filter, ...additionalFilter } : filter,
          columns: configuration?.selectedColumns ?? columns,
        },
      },
    });
  };

  const handleDateChange = (date: Date, onDateChange: (date: string | null) => void) => {
    const formattedDate = dayjs(date).format('YYYY-MM-DD');
    onDateChange(formattedDate);
  };

  const renderDateRangePickers = () => {
    if (!dateRangeConfig) return null;

    return (
      <Box>
        <Box display="flex" flexDirection="column" gap={2} mt={1}>
          <LocalizedDatePicker
            value={dateRangeConfig.fromDate || null}
            onChange={(date: Date) => handleDateChange(date, dateRangeConfig.onFromDateChange)}
            onAccept={(date: Date) => handleDateChange(date, dateRangeConfig.onFromDateChange)}
            renderInput={(params: any) => (
              <TextField
                fullWidth
                {...params}
                type='default'
                label={dateRangeConfig.fromLabel || t('exportConfiguration.fromDate')}
                trailingIcon='calendar'
              />
            )}
            InputProps={{
              endAdornment: dateRangeConfig.fromDate && (
                <IconButton onClick={() => dateRangeConfig.onFromDateChange(null)}>
                  <ClearIcon />
                </IconButton>
              ),
            }}
          />
          <LocalizedDatePicker
            value={dateRangeConfig.toDate || null}
            onChange={(date: Date) => handleDateChange(date, dateRangeConfig.onToDateChange)}
            onAccept={(date: Date) => handleDateChange(date, dateRangeConfig.onToDateChange)}
            renderInput={(params: any) => (
              <TextField
                fullWidth
                {...params}
                type='default'
                label={dateRangeConfig.toLabel || t('exportConfiguration.toDate')}
                trailingIcon='calendar'
              />
            )}
            InputProps={{
              endAdornment: dateRangeConfig.toDate && (
                <IconButton onClick={() => dateRangeConfig.onToDateChange(null)}>
                  <ClearIcon />
                </IconButton>
              ),
            }}
          />
          {maxDateWindow && (
            <Typography variant="bodyMedium" sx={{ color: 'gray', fontSize: '12px' }}>
              {t('exportConfiguration.maxDateRange', { days: maxDateWindow })}
            </Typography>
          )}
        </Box>
      </Box>
    );
  };

  return (
    <>
      <IconButton
        onClick={() => (configurable ? setOpenConfiguration(true) : onExport())}
        label={t('downloadButton.download')}
        testId={testId ?? 'download-button'}
        disabled={disabled}
      >
        <FileDownloadOutlinedIcon />
      </IconButton>

      <ConfigurationModal
        open={openConfiguration}
        onClose={() => setOpenConfiguration(false)}
        onExport={(configuration) => {
          onExport(configuration);
          setOpenConfiguration(false);
        }}
        columns={columns}
        additionalColumns={additionalColumns}
        maxDateWindow={maxDateWindow}
        onDateValidationError={onDateValidationError}
        dateRangeConfig={dateRangeConfig}
        onColumnsChange={onColumnsChange}
      >
        {renderDateRangePickers()}
      </ConfigurationModal>

      <Dialog onClose={() => setOpen(false)} open={open} fullWidth maxWidth='xs'>
        <DialogTitle>
          <Box display='flex' justifyContent='space-between' alignItems='center'>
            {progressTitle ?? t('downloadButton.progressModalTitle')}
            <IconButton onClick={() => setOpen(false)} sx={{ color: `${sys.color.onSurface}` }}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Box
              sx={{
                position: 'relative',
                height: '8px',
                borderRadius: '4px',
                backgroundColor: `${sys.color.surfaceContainerVariant}`,
                width: '90%',
              }}
            >
              <Box
                sx={{
                  position: 'relative',
                  height: '8px',
                  borderRadius: '4px',
                  backgroundColor: `${sys.color.primary}`,
                  transition: 'width 0.5s',
                  width: `${(data?.fetchApiExport?.apiExport?.progress ?? 0) * 100}%`,
                }}
              />
            </Box>
            <Typography sx={{ fontWeight: 500 }}>
              {formatPercentValue(data?.fetchApiExport?.apiExport?.progress ?? 0, 0)}
            </Typography>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};
