import { useEffect, useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { groupBy } from 'lodash/fp';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { Badge } from '@mui/material';
import { Notifications as NB } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import {
  IconButton, Menu, Button, Pagination,
} from '../../2-component';
import { Box, Skeleton, Typography } from '../../1-primative';
import { useThemeTokens } from '../../../providers/themeTokenProvider';
import { translateBackend } from '../../../assets/i18n/config';
import { useLocalization } from '../../../util/useLocalization';
import { SelectionChip } from '../../3-pattern';
import { Divider } from '../../2-component/divider/divider';
import { NotificationChannelTypes } from '../../../interfaces';

dayjs.extend(relativeTime);

const NOTIFICATIONS = `#graphql
  notifications {
    id
    read
    title { en fr }
    body { en fr }
    linkText { en fr }
    link
    createdAt
  }
`;

const FETCH_UNREAD_NOTIFICATIONS = gql`
  query fetchNotifications($input: FetchNotificationsInput!) {
    fetchNotifications(input: $input) {
      ${NOTIFICATIONS}
      totalCount
    }
  }
`;

const FETCH_NOTIFICATIONS = gql`
  query fetchNotifications($input: FetchNotificationsInput!) {
    fetchNotifications(input: $input) {
      ${NOTIFICATIONS}
      totalCount
    }
  }
`;

const MARK_READ = gql`
  mutation markNotificationAsRead($input: MarkNotificationAsReadInput!) {
    markNotificationAsRead(input: $input)
  }
`;

const PAGE_SIZE = 5;

enum NotificationTabType {
  ALL = 'ALL',
  UNREAD = 'UNREAD',
}

export const Notifications = ({ color }: { color?: string }) => {
  const { sys } = useThemeTokens();
  const [groupedNotifications, setGroupedNotifications] = useState<any>({});
  const [notificationTab, setNotificationTab] = useState<NotificationTabType>(NotificationTabType.ALL);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(1);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { t } = useTranslation('shared');
  const { localizedDate } = useLocalization();

  const pagination = {
    perPage: PAGE_SIZE,
    offSet: (page - 1) * PAGE_SIZE,
    sortField: 'createdAt',
    sortDesc: true,
  };

  const baseFilter = {
    notificationChannelTypes: [NotificationChannelTypes.IN_APP],
  };

  const { data, refetch, loading } = useQuery(FETCH_UNREAD_NOTIFICATIONS, {
    variables: {
      input: {
        filter: { ...baseFilter, read: false },
        pagination,
      },
    },
  });

  const { data: notificationsData, refetch: notificationsRefetch, loading: notificationLoading } = useQuery(FETCH_NOTIFICATIONS, {
    variables: {
      input: {
        filter: { ...baseFilter },
        pagination,
      },
    },
  });

  useEffect(() => {
    let notifications: any;
    if (notificationTab === NotificationTabType.ALL) {
      notifications = notificationsData?.fetchNotifications?.notifications ?? [];
      setTotalCount(notificationsData?.fetchNotifications?.totalCount ?? 0);
    } else {
      notifications = data?.fetchNotifications?.notifications ?? [];
      setTotalCount(data?.fetchNotifications?.totalCount ?? 0);
    }
    const notificationsToGroup = groupBy((notification) => {
      const createdAt = localizedDate(notification.createdAt);
      const now = localizedDate(dayjs());
      return createdAt === now ? 'today' : createdAt;
    }, notifications);
    setGroupedNotifications(notificationsToGroup);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationTab, data, notificationsData]);

  const [markRead] = useMutation(MARK_READ);

  const handleClick = (event: any) => {
    notificationsRefetch();
    refetch();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    notificationsRefetch();
    refetch();
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton sx={{ mr: 2, color }} onClick={handleClick}>
        <Badge invisible={data?.fetchNotifications?.totalCount === 0} variant='dot' color="error">
          <NB />
        </Badge>
      </IconButton>
      <Menu
        id='notification-menu'
        anchorEl={anchorEl}
        open={open}
        disableScrollLock={true}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: { width: '350px', paddingY: 1 },
        }}
      >
        <Box sx={{ pl: 2 }}>
          <Typography variant='headingSmall'>{t('notificationsMenu.title')}</Typography>
        </Box>
        <Box sx={{ pl: 4, pt: 3, pb: 2 }}>
          <SelectionChip
            onChange={(e: NotificationTabType) => setNotificationTab(e as NotificationTabType)}
            value={notificationTab}
            options={Object.values(NotificationTabType).map((value) => ({
              value,
              label: `${t(`notificationsMenu.${value}`)} ${value === NotificationTabType.UNREAD && (data?.fetchNotifications?.totalCount !== 0)
                ? `(${data?.fetchNotifications?.totalCount})`
                : ''
              }`,
              dataTestId: `notification-tab-${value.toLowerCase()}`,
            }))}
          />
        </Box>
        <Divider />
        {(notificationTab === NotificationTabType.ALL ? notificationLoading : loading) ? (
          <Skeleton variant='rectangular' height='100px' width='calc(100% - 32px)' sx={{ m: 2 }} />
        ) : (
          <Box display='flex' flexDirection='column' gap={2} sx={{ paddingX: 2, pt: 2 }}>
            {Object.keys(groupedNotifications).map((key) => (
              <Box key={key}>
                <Typography variant='labelLarge' colorVariant='variant' sx={{ mb: 1 }}>{key === 'today' ? t('notificationsMenu.today') : key}</Typography>
                <Box display='flex' flexDirection='column' gap={2}>
                  {groupedNotifications[key].map((notification: any) => (
                    <Box key={notification.id}>
                      <Box display='flex' flexDirection='row' width='100%'>
                        <Badge invisible={notification.read} variant='dot' color="error" sx={{ mr: 2, mt: 1.5 }}>
                        </Badge>
                        <Box display='flex' flexDirection='column' whiteSpace='initial' gap={0.5}>
                          <Typography variant='bodyLarge' weight='bold'>{translateBackend(notification.title)}</Typography>
                          <Typography variant='bodyMedium' sx={{ fontWeight: 400 }}>{translateBackend(notification.body)}</Typography>
                          <Typography variant='bodySmall' sx={{ color: sys.color.onSurfaceVariant }}>
                            {key === 'today' ? dayjs(notification.createdAt).fromNow() : dayjs(notification.createdAt).format('h:mm a')}
                          </Typography>
                          <Box mt={0.5}>
                            <Button size='sm' variant='outlined' label={translateBackend(notification.linkText)} onClick={() => {
                              markRead({
                                variables: { input: { notificationId: notification.id } },
                                onCompleted: () => { window.location.href = notification.link; },
                              });
                              handleClose();
                            }} />
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  ))}
                </Box>
              </Box>
            ))}
            { totalCount > PAGE_SIZE && (
              <Box display='flex' justifyContent='end' mr={1} mt={1}>
                <Pagination
                  size='small'
                  count={Math.ceil(totalCount / PAGE_SIZE)}
                  page={page}
                  onChange={(e, p) => setPage(p)}
                />
              </Box>
            )}
            {totalCount === 0 && (
              <Box display='flex' justifyContent='center' alignItems='center' height='100px'>
                <Typography variant='bodyMedium' sx={{ color: sys.color.onSurfaceVariant }}>{t('noNotifications')}</Typography>
              </Box>
            )}
          </Box>
        )}
      </Menu>
    </>
  );
};
