import AddIcon from '@mui/icons-material/AddRounded';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormModal } from '../formModal/formModal';
import { Button, IconButton } from '../../2-component';

export interface CreateNewModalProp {
  onSubmit?: (event: any) => void;
  loading?: boolean;
  children?: any[] | any;
  title?: string;
  fullWidth?: boolean;
  sx?: any;
  buttonType?: 'BUTTON' | 'ICON' | 'NONE';
  maxWidth?: string;
  disabled?: boolean;
  modalOpenDisabled?: boolean;
  iconType?: 'ADD' | 'EDIT' | 'DOT';
  buttonText?: string;
  hideSubmit?: boolean;
  modalButton?: string;
  icon?: any;
  buttonVariant?: 'outlined' | 'text' | 'contained' | undefined;
  forceOpen?: boolean;
  onClose?: () => void;
}

export const CreateNewModal = ({
  onSubmit,
  loading,
  children,
  title,
  fullWidth = false,
  sx,
  buttonType = 'BUTTON',
  maxWidth = 'sm',
  buttonText,
  disabled = false,
  disableButton = false,
  icon = AddIcon,
  modalButton,
  buttonVariant = 'filled',
  forceOpen = false,
  onClose,
  state,
  initialState,
  testId,
}: {
  onSubmit: (event: any) => void;
  loading: boolean;
  children: any[] | any;
  title: string;
  fullWidth?: boolean;
  sx?: any;
  buttonType?: 'BUTTON' | 'ICON' | 'NONE';
  maxWidth?: string;
  disabled?: boolean;
  disableButton?: boolean;
  iconType?: 'ADD' | 'EDIT' | 'DOT';
  buttonText?: string;
  modalButton?: string;
  icon?: any;
  buttonVariant?: 'outlined' | 'text' | 'filled' | 'tonal' | undefined;
  forceOpen?: boolean;
  onClose?: () => void;
  state?: any;
  initialState?: any;
  testId?: string;
}) => {
  const { t } = useTranslation(['shared']);
  const [open, setOpen] = useState(forceOpen);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    if (onClose) onClose();
    setOpen(false);
  };

  const submit = (event: any) => {
    onSubmit(event);
    setOpen(false);
  };

  return (
    <>
      {buttonType === 'NONE' ? (
        <Button
          label={modalButton || t('shared:add')}
          variant='text'
          disabled={disableButton}
          onClick={handleClickOpen}
          sx={sx}
          fullWidth={fullWidth}
          dataTestId={testId ? `${testId}-add-button` : 'new-modal-add-button'}
        />
      ) : buttonType === 'ICON' ? (
        <IconButton disabled={disableButton} style={{ marginTop: '-2px' }} data-testid='add-button' onClick={handleClickOpen} testId={testId ? `${testId}-add-button` : 'new-modal-add-button'}>
          {icon}
        </IconButton>
      ) : (
        <Button
          variant={buttonVariant}
          disabled={disableButton}
          onClick={handleClickOpen}
          sx={sx}
          fullWidth={fullWidth}
          leadingIcon={icon}
          label={modalButton || t('shared:add')}
          dataTestId={testId ? `${testId}-add-button` : 'new-modal-add-button'}
        />
      )}
      <FormModal
        onSubmit={submit}
        loading={loading}
        disabled={disabled}
        children={children}
        title={title}
        open={open}
        handleClose={handleClose}
        formButton={buttonText || t('shared:create')}
        maxWidth={maxWidth}
        state={state}
        initialState={initialState}
        testId={testId}
      />
    </>
  );
};

export default CreateNewModal;
