/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { ForeignTaxInformation } from 'interfaces/foreignTaxInformation';
import { CountryCodes } from '@onevesthq/ov-enums';
import { difference } from 'lodash/fp';
import { ResidencyInformationVisual } from './residencyInformation.visual';
import { User } from '../../../../../interfaces';

const FETCH_USER = gql`
  query fetchUserResidencyInformation($userId: ObjectID!) {
    fetchUser(userId: $userId) {
      user {
        id
        type
        citizenships
        foreignTaxInformation {
          foreignTaxCountry
          foreignTaxNumber
        }
        countryOfTaxResidence
        yearEnd
        taxNumber
        taxIdType
        taxIdExists
        fatcaStatus
        fatcaStatusOtherDescription
        organization {
          applicableLocalization {
            countries
          }
        }
      }
    }
  }
`;

const UPDATE_USER = gql`
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        id
      }
    }
  }
`;

export const ResidencyInformation = ({
  options, userId, onNext, stepLoading, workflowCompletion, grid = false, updateMode = false,
}: {
  options: any, userId: string, onNext: () => void, stepLoading: boolean, workflowCompletion?: any, grid?: boolean, updateMode?: boolean,
}) => {
  const [userData, setUserData] = useState<Partial<User>>({
    citizenships: [],
    sin: '',
    yearEnd: '',
    taxNumber: '',
    countryOfTaxResidence: undefined,
    foreignTaxInformation: [],
  });
  const [taxIdExists, setTaxIdExists] = useState<undefined | boolean>(undefined);
  const [applicableCountries, setApplicableCountries] = useState<CountryCodes[] | undefined>();

  const { data, loading } = useQuery(FETCH_USER, {
    variables: { userId },
    fetchPolicy: 'no-cache',
  });

  const [updateUser, { loading: updating }] = useMutation(UPDATE_USER, {
    onCompleted: () => {
      onNext();
    },
    variables: {
      input: {
        ...userData,
        userId,
      },
    },
  });

  useEffect(() => {
    if (data) {
      setUserData({
        sin: undefined,
        type: data.fetchUser.user.type,
        taxIdType: data.fetchUser.user.taxIdType || undefined,
        yearEnd: data.fetchUser.user.yearEnd || undefined,
        taxNumber: data.fetchUser.user.taxNumber || undefined,
        countryOfTaxResidence: data.fetchUser.user.countryOfTaxResidence || undefined,
        citizenships: data.fetchUser.user.citizenships || [],
        foreignTaxInformation: data.fetchUser.user.foreignTaxInformation.map((ft: any) => ({
          foreignTaxCountry: ft.foreignTaxCountry,
          foreignTaxNumber: ft.foreignTaxNumber,
        })) || [],
        fatcaStatus: data.fetchUser.user.fatcaStatus || undefined,
        fatcaStatusOtherDescription: data.fetchUser.user.fatcaStatusOtherDescription || undefined,
      });
      setTaxIdExists(data.fetchUser.user.taxIdExists);
      setApplicableCountries(data?.fetchUser?.user?.organization?.applicableLocalization?.countries ?? [CountryCodes.CA]);
    }
  }, [data]);

  const foreignTaxInformationIncludes = (prevUserData: any, country: string) => prevUserData.foreignTaxInformation
    .map((fti: ForeignTaxInformation) => fti.foreignTaxCountry)
    .includes(country);

  const addIntoForeignTaxInformation = (country: string) => {
    setUserData((prev: any) => {
      if (foreignTaxInformationIncludes(prev, country)) return prev;
      return {
        ...prev,
        foreignTaxInformation: [...prev.foreignTaxInformation, { foreignTaxCountry: country, foreignTaxNumber: '' }],
      };
    });
  };

  /* expand "Pays Foreign Taxes" for citizens foreign to org's localization */
  useEffect(() => {
    if (options.foreignTaxInformation?.enabled !== true) return;
    if (!applicableCountries) return;
    if (!userData.citizenships) return;
    if (userData.citizenships.length === 0) return;

    const extraCountries = difference(userData.citizenships as [], applicableCountries);

    if (extraCountries.length > 0) {
      extraCountries.forEach(addIntoForeignTaxInformation);
    }
  }, [options, userData.citizenships]);

  /* expand "Pays Foreign Taxes" for tax residents foreign to org's localization */
  useEffect(() => {
    if (options.foreignTaxInformation?.enabled !== true) return;
    if (!applicableCountries) return;
    if (!userData.countryOfTaxResidence) return;

    if (!applicableCountries.includes(userData.countryOfTaxResidence)) {
      addIntoForeignTaxInformation(userData.countryOfTaxResidence);
    }
  }, [options, userData.countryOfTaxResidence]);

  /* expand "Pays Foreign Taxes" for U.S. citizens */
  useEffect(() => {
    if (userData.countryOfTaxResidence !== CountryCodes.US && userData.citizenships?.includes(CountryCodes.US)) {
      if (userData.foreignTaxInformation?.length === 0) {
        addIntoForeignTaxInformation(CountryCodes.US);
      }
    }
  }, [userData.citizenships, userData.countryOfTaxResidence]);

  return (
    <ResidencyInformationVisual
      options={options}
      userData={userData}
      updateUser={setUserData}
      continueFunc={updateUser}
      loading={loading || stepLoading}
      updating={updating}
      taxIdExists={taxIdExists}
      applicableCountries={applicableCountries}
      grid={grid}
      updateMode={updateMode}
      workflowCompletion={workflowCompletion}
    />
  );
};

export default ResidencyInformation;
