import React, { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { MoreVert } from '@mui/icons-material';
import { IconButton, Menu } from '../../../2-component';
import { PageObjectType } from '../../../5-page';
import { EditGoal } from './pageTypes/editGoal';
import { EditSubAccount } from './pageTypes/editSubAccount';
import { EditAccount } from './pageTypes/editAccount';
import { useFeatureFlags, usePermissions } from '../../../../providers/userContextProvider';
import { EditClient } from './pageTypes/editClient';
import filterHouseholdsWithAccess from '../../../../util/filterHouseholdsWithAccess';

const FETCH_ACCOUNT = gql`
  query fetchAccount($accountId: ObjectID!) {
    fetchAccount(accountId: $accountId) {
      account {
        id
        householdClientGroup { id name }
        user {
          id
          households { id relationships { type accessType user { id } } }
        }
      }
    }
  }
`;

const FETCH_GOAL = gql`
  query fetchGoal($goalId: ObjectID!) {
    fetchGoal(goalId: $goalId) {
      goal {
        id
        name
        targetAmountCents
        user {
          id
          households {
            id
            name
            relationships {
              user {
                id
              }
              accessType
            }
          }
        }
        householdClientGroup {
          id
          name
        }
      }
    }
  }
`;

export const EditButton = ({
  objectId, objectType, options = {}, setActiveWorkflowCompletionId,
}: {
  objectId: string, objectType: PageObjectType, options?: any, setActiveWorkflowCompletionId?: (id: string) => void,
}) => {
  const { permissions } = usePermissions();
  const { flagEnabled } = useFeatureFlags();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [associateAccount, setAssociateAccount] = useState(false);
  const [dissociateAccount, setDissociateAccount] = useState(false);
  const [associateGoal, setAssociateGoal] = useState(false);
  const [dissociateGoal, setDissociateGoal] = useState(false);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  useQuery(FETCH_ACCOUNT, {
    variables: { accountId: objectId },
    skip: objectType !== PageObjectType.ACCOUNT,
    onCompleted: (data) => {
      const households = filterHouseholdsWithAccess((data.fetchAccount.account?.user?.households ?? []), data.fetchAccount.account?.user?.id);
      setAssociateAccount(!data.fetchAccount.account?.householdClientGroup && households.length > 0);
      setDissociateAccount(!!data.fetchAccount.account?.householdClientGroup);
    },
  });

  useQuery(FETCH_GOAL, {
    variables: { goalId: objectId },
    skip: objectType !== PageObjectType.GOAL,
    onCompleted: (data) => {
      const households = filterHouseholdsWithAccess((data.fetchGoal.goal?.user?.households ?? []), data.fetchGoal.goal?.user?.id);
      setAssociateGoal(!data.fetchGoal.goal?.householdClientGroup && households.length > 0);
      setDissociateGoal(!!data.fetchGoal.goal?.householdClientGroup);
    },
  });

  const editMenu = () => {
    switch (objectType) {
      case PageObjectType.GOAL:
        return <EditGoal objectId={objectId} onClose={handleClose} canClose={options?.canCloseGoal !== false} canUpdate={flagEnabled('UPDATE_GOAL')} options={options} />;
      case PageObjectType.ACCOUNT:
        return <EditAccount objectId={objectId} onClose={handleClose} options={options} />;
      case PageObjectType.SUB_ACCOUNT:
        return <EditSubAccount objectId={objectId} onClose={handleClose} />;
      case PageObjectType.INDIVIDUAL:
      case PageObjectType.NON_INDIVIDUAL:
        return <EditClient objectId={objectId} options={options} onClose={handleClose} setActiveWorkflowCompletionId={setActiveWorkflowCompletionId} />;
      default:
        return null;
    }
  };

  const canEditAccount = (permissions.includes('write:account_number') && (!!options?.nickName?.enabled || (!!options?.custodianAccountNumber?.enabled && permissions.includes('read:account_number'))))
    || (!!options?.state?.enabled && permissions.includes('transition:account') && permissions.includes('read:account_basic'))
    || (('canCloseAccount' in options) && options.canCloseAccount && (permissions.includes('write:account_basic') || permissions.includes('transition:account')));

  if (objectType === PageObjectType.ACCOUNT
    && (!options.enableAccountEditing || !canEditAccount || !options.canForceOpenAccount)
    && (!options.enableAddingAccountToHousehold || !(associateAccount || dissociateAccount))) {
    return <></>;
  }
  if (objectType === PageObjectType.SUB_ACCOUNT && (('canCloseSubAccount' in options) && options.canCloseSubAccount === false)) return <></>;
  if ([PageObjectType.INDIVIDUAL, PageObjectType.NON_INDIVIDUAL].includes(objectType)
    && !options.canTriggerWorkflows
    && !options.canChangeOrganization
    && !options.canChangeHousehold
    && !options.canFreezeClient
    && !options.canDeactivateClient) {
    return <></>;
  }
  if (objectType === PageObjectType.GOAL && options?.canCloseGoal === false
    && !flagEnabled('UPDATE_GOAL')
    && (!options.enableAddingGoalToHousehold || !(associateGoal || dissociateGoal))) {
    return <></>;
  }
  if (objectType === PageObjectType.HOUSEHOLD) return <></>;

  return (
    <>
      <IconButton data-testid='edit-menu' onClick={handleClick} sx={{ ml: 1 }}><MoreVert /></IconButton>
      <Menu disablePortal keepMounted id="edit-menu" anchorEl={anchorEl} open={open} onClose={handleClose}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }} anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      >
        {editMenu()}
      </Menu>
    </>
  );
};
