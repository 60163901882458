import { useTranslation } from 'react-i18next';
import DownloadIcon from '@mui/icons-material/Download';
import React from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import { Box, Typography } from '../../1-primative';
import { useThemeTokens } from '../../../providers/themeTokenProvider';
import { Button } from '../../2-component';

const FETCH_TRANSFER_SIGNED_FORM_URL = gql`
query fetchTransferSignedFormURL($manualProcessId: ID!) {
  fetchManualProcess(id: $manualProcessId) {
    manualProcess {
      signedUrl
    }
  }
}
`;

export const ExternalTransferInManualProcessInfo = ({ transfer }: { transfer: any }) => {
  const { t } = useTranslation(['components', 'shared']);
  const { sys } = useThemeTokens();
  const [fetchFileDocumentUrl] = useLazyQuery(FETCH_TRANSFER_SIGNED_FORM_URL, {
    variables: { manualProcessId: transfer.manualProcess?.id },
    fetchPolicy: 'no-cache',
  });
  const downloadFile = async () => {
    const result = await fetchFileDocumentUrl();
    const url = result?.data?.fetchManualProcess?.manualProcess?.signedUrl;
    if (window && url) {
      window.open(url, '_blank');
    }
  };

  return (
    <Box
      style={{
        padding: '8px 16px',
        borderStyle: 'solid',
        borderWidth: 1,
        borderRadius: sys.borderRadius.lg,
        borderColor: sys.color.outlineVariant,
        marginBottom: '20px',
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <Box>
        <Typography variant='bodyLarge' weight="bold">
          {transfer.manualProcess?.transferMethod && t(`transferModal.sources.${transfer.manualProcess?.transferMethod}`)} &nbsp;
          {t('transferSources.EXTERNAL_TRANSFER_IN')}
        </Typography>
        <Box mt={1.5}>
          <Typography variant='labelSmall' weight="bold">{t('transferModal.externalTransfer.relinquishingInstitution')}</Typography>
          <Typography variant='bodyLarge'>{transfer.manualProcess?.institution?.name}</Typography>
        </Box>
        <Box mt={1}>
          <Typography variant='labelSmall' weight="bold">{t('transferModal.externalTransfer.account')}</Typography>
          <Typography variant='bodyLarge'>{transfer.manualProcess?.transferAccountNumber}</Typography>
        </Box>
      </Box>
      {transfer.manualProcess?.signedUrl && (
        <Box>
          <Button
            variant="filled"
            sx={{
              backgroundColor: `${sys.color.primaryVariant} !important`,
              color: `${sys.color.onPrimaryVariant} !important`,
              width: '195px !important',
              height: '28px !important',
              borderRadius: `${sys.borderRadius.round} !important`,
            }}
            label={t('transferModal.externalTransfer.signedTransferFormBtnText')}
            leadingIcon={DownloadIcon}
            onClick={downloadFile}
          />
        </Box>
      )}
    </Box>
  );
};
