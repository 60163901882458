import {
  useContext, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { MenuItem } from '@mui/material';
import dayjs from 'dayjs';
import {
  eligibleTaxIdTypes, encryptedTaxIdPlaceholder, MaritalStatus, taxIdTypeFormatType, TaxIdTypes, type PhysicalAddress, type User,
} from 'interfaces';
import { translateBackend } from 'assets/i18n/config';
import { additionalInfo } from 'ovComponents/4-module/configurableOptionFields';
import InfoDialog from 'ovComponents/2-component/infoDialog/infoDialog';
import { useLazyQuery } from '@apollo/client';
import { kebabCase } from 'lodash';
import { invalidFields } from '../utils';
import {
  Button, TextField, Typography, AddressField,
  Form, SelectField, DateField, Box, Grid,
  RadioGroup,
  Radio,
} from '../../../..';
import { ovAnalyticsEvents } from '../../../../../util/analytics/analytics';
import { AnalyticsContext } from '../../../../../providers/analyticsProvider';
import { VALIDATE_TAX_ID } from '../residencyInformation/residencyInformation.visual';

export const RelationshipInformationVisual = ({
  options, userData, loading, updateUser, updating, continueFunc, workflowCompletion, grid, updateMode,
}: {
  options: any, userData: any, loading: boolean, updateUser: any, updating: boolean, continueFunc: any, grid?: boolean, updateMode?: boolean,
  workflowCompletion?: any
}) => {
  const { sendAnalytic } = useContext(AnalyticsContext);
  const { t } = useTranslation('client');
  const [focused, setFocused] = useState<string[]>([]);
  const [showEncryptedTaxId, setShowEncryptedTaxId] = useState<string | undefined>();
  const [updated, setUpdated] = useState<boolean>(false);
  const [invalidFieldSpouseTaxId, setInvalidFieldSpouseTaxId] = useState<boolean>(false);
  const [invalidFieldsList, setInvalidFieldsList] = useState<string[]>([]);

  const requiresSpousalInfo = (data: Partial<User>): boolean => (
    [MaritalStatus.MARRIED, MaritalStatus.COMMON_LAW].includes(data?.maritalStatus as MaritalStatus)
  );

  const validate = () => {
    const fields = invalidFields({
      ...options,
      spouseFirstName: { ...options.spouseFirstName, requiredIf: requiresSpousalInfo },
      spouseMiddleName: { ...options.spouseMiddleName, requiredIf: requiresSpousalInfo },
      spouseLastName: { ...options.spouseLastName, requiredIf: requiresSpousalInfo },
      spouseEmail: { ...options.spouseEmail, requiredIf: requiresSpousalInfo },
      spouseTaxId: { ...options?.spouseTaxId?.enabled, requiredIf: () => requiresSpousalInfo(userData) && !showEncryptedTaxId },
      spouseSin: { enabled: false },
      spouseEmployerName: { ...options.spouseEmployerName, requiredIf: requiresSpousalInfo },
      spouseCompanyType: { ...options.spouseCompanyType, requiredIf: requiresSpousalInfo },
      spouseJobTitle: { ...options.spouseJobTitle, requiredIf: requiresSpousalInfo },
      spouseDateOfBirth: { ...options.spouseDateOfBirth, requiredIf: requiresSpousalInfo },
    }, userData);

    setInvalidFieldsList(fields);
    setFocused(fields);

    return fields.length === 0;
  };

  const submit = () => {
    if (validate()) {
      if (workflowCompletion) {
        sendAnalytic(ovAnalyticsEvents.workflowsRelationshipInformationContinueButtonSelect, {
          workflowStepTitle: options?.title,
          workflowId: workflowCompletion.workflow?.id,
          workflowName: workflowCompletion.workflow?.name,
          activeWorkflowCompletionId: workflowCompletion.id,
          objectId: workflowCompletion.objectId,
          objectType: workflowCompletion.objectType,
        });
      }
      if (userData.spouseTaxIdExists || userData.spouseTaxId) {
        updateUser({ ...userData, spouseTaxId: '' });
      }
      continueFunc();
    }
  };

  const [validateTaxId] = useLazyQuery(VALIDATE_TAX_ID);
  const update = (newValue: any) => {
    setUpdated(true);
    updateUser(newValue);
  };

  useEffect(() => { if (updating === false) setUpdated(false); }, [updating]);

  /* (spouse) taxId logic */
  const isIndividual = userData.type === 'INDIVIDUAL';
  const countryOfTaxResidence = userData?.countryOfTaxResidence ?? userData?.physicalAddress?.country;

  const taxIdTypesShown: TaxIdTypes[] = useMemo(() => {
    if (!isIndividual) return [];
    if (!countryOfTaxResidence) return [];

    return eligibleTaxIdTypes(countryOfTaxResidence, true);
  }, [isIndividual, countryOfTaxResidence]);

  const defaultTaxIdType = taxIdTypesShown[0];

  const optionSpouseTaxIdEnabled = (options?.spouseTaxId?.enabled || options?.spouseSin?.enabled) && taxIdTypesShown?.length >= 1;

  const spouseTaxIdLabel = t('shared:somethingBelongingToSomeone', {
    whose: t('details.relationOptions.SPOUSE'),
    what: `${t(`taxId:${userData.spouseTaxIdType}`)} (${t(`taxId:abbreviated.${userData.spouseTaxIdType}`)})`,
  });

  useEffect(() => {
    if (!userData) return;
    if (!optionSpouseTaxIdEnabled) return;

    // no taxIdType selected yet - pick default
    if (!userData.spouseTaxIdType && defaultTaxIdType) {
      updateUser({ ...userData, spouseTaxIdType: defaultTaxIdType });
    }
    // stale taxIdType exists that's no longer allowed - reset to default
    if (userData.spouseTaxIdType && !taxIdTypesShown.includes(userData.spouseTaxIdType)) {
      updateUser({ ...userData, spouseTaxIdType: defaultTaxIdType, spouseTaxId: undefined });
      setShowEncryptedTaxId(undefined);
    }
  }, [userData, defaultTaxIdType, updateUser, taxIdTypesShown, optionSpouseTaxIdEnabled]);

  useEffect(() => {
    if (userData.spouseTaxIdExists !== undefined && !userData.spouseTaxId) {
      setShowEncryptedTaxId(encryptedTaxIdPlaceholder(userData.spouseTaxIdType));
    }
  }, [userData.spouseTaxId, userData.spouseTaxIdExists, userData.spouseTaxIdType]);

  useEffect(() => {
    if (!optionSpouseTaxIdEnabled) return;

    if (focused.includes('spouseTaxId') && userData.spouseTaxIdType) {
      validateTaxId({
        variables: { taxIdType: userData.spouseTaxIdType, taxId: userData.spouseTaxId ?? '' },
        onCompleted: (data) => { setInvalidFieldSpouseTaxId(!data.validateTaxId); },
      });
    }
  }, [userData, optionSpouseTaxIdEnabled, focused, validateTaxId, showEncryptedTaxId]);

  /* (spouse) taxId logic - end */

  const lockMessage = (): string => (options.customLockedMessage ? translateBackend(options.customLockedMessage) : t('pageConfiguration:notEditableMessage'));

  return (
    <Form onSubmit={submit}>
      {options?.title && (
        <Typography variant='displayLarge' sx={{ mt: 1 }}>
          {translateBackend(options?.title)}
          {additionalInfo(options?.title?.additionalInfo) && (<InfoDialog information={additionalInfo(options?.title?.additionalInfo) ?? ''} />)}
        </Typography>
      )}

      {options?.subtitle && (
        <Typography display='inline-flex' alignItems='end' variant='bodyLarge' component='div' sx={{ mb: 3, table: { width: '100%' } }}>
          <Box display='inline-block'>
            <ReactMarkdown linkTarget="_blank" remarkPlugins={[remarkGfm]}>{translateBackend(options?.subtitle)}</ReactMarkdown>
          </Box>
          {additionalInfo(options?.subtitle?.additionalInfo) && (<InfoDialog information={additionalInfo(options?.subtitle?.additionalInfo) ?? ''} />)}
        </Typography>
      )}

      <Grid container spacing={2}>
        {options?.maritalStatus?.enabled && (
          <Grid item xs={12}>
            <SelectField
              testId="marital-status"
              onChange={(e: any) => update({ ...userData, maritalStatus: e.target.value })}
              disabled={loading}
              label={translateBackend(options?.maritalStatus?.label)}
              infoTooltip={additionalInfo(options?.maritalStatus?.additionalInfo)}
              fullWidth
              locked={options?.maritalStatus?.required === 'NOT_EDITABLE'}
              lockMessage={lockMessage()}
              value={userData.maritalStatus ?? ''}
              onBlur={() => setFocused([...focused, 'maritalStatus'])}
              error={((!userData.maritalStatus && focused.includes('maritalStatus'))
                || invalidFieldsList.includes('maritalStatus')) && focused.includes('maritalStatus') && options?.maritalStatus?.required !== 'NOT_REQUIRED'}
            >
              <MenuItem data-testid="marital-status-single" value='SINGLE'>{t('edit.maritalStatusOptions.SINGLE')}</MenuItem>
              <MenuItem data-testid="marital-status-married" value='MARRIED'>{t('edit.maritalStatusOptions.MARRIED')}</MenuItem>
              <MenuItem data-testid="marital-status-common-law" value='COMMON_LAW'>{t('edit.maritalStatusOptions.COMMON_LAW')}</MenuItem>
              <MenuItem data-testid="marital-status-divorced" value='DIVORCED'>{t('edit.maritalStatusOptions.DIVORCED')}</MenuItem>
              <MenuItem data-testid="marital-status-separated" value='SEPARATED'>{t('edit.maritalStatusOptions.SEPARATED')}</MenuItem>
              <MenuItem data-testid="marital-status-widowed" value='WIDOWED'>{t('edit.maritalStatusOptions.WIDOWED')}</MenuItem>
              <MenuItem data-testid="marital-status-civil-union" value='CIVIL_UNION'>{t('edit.maritalStatusOptions.CIVIL_UNION')}</MenuItem>
            </SelectField>
          </Grid>
        )}
        {options?.spouseFirstName?.enabled && requiresSpousalInfo(userData) && (
          <Grid item xs={12} md={grid ? 4 : 12}>
            <TextField
              testId="spouse-first-name"
              onChange={(e: any) => update({ ...userData, spouseFirstName: e.target.value })}
              disabled={loading}
              label={translateBackend(options?.spouseFirstName?.label)}
              fullWidth
              locked={options?.spouseFirstName?.required === 'NOT_EDITABLE'}
              lockMessage={lockMessage()}
              infoTooltip={additionalInfo(options?.spouseFirstName?.additionalInfo)}
              value={userData.spouseFirstName ?? ''}
              onBlur={() => setFocused([...focused, 'spouseFirstName'])}
              error={((!userData.spouseFirstName && requiresSpousalInfo(userData) && focused.includes('spouseFirstName'))
                || invalidFieldsList.includes('spouseFirstName')) && focused.includes('spouseFirstName') && options?.spouseFirstName?.required !== 'NOT_REQUIRED'}
            />
          </Grid>
        )}
        {options?.spouseMiddleName?.enabled && requiresSpousalInfo(userData) && (
          <Grid item xs={12} md={grid ? 4 : 12}>
            <TextField
              testId="spouse-middle-name"
              onChange={(e: any) => update({ ...userData, spouseMiddleName: e.target.value })}
              disabled={loading}
              label={translateBackend(options?.spouseMiddleName?.label)}
              locked={options?.spouseMiddleName?.required === 'NOT_EDITABLE'}
              lockMessage={lockMessage()}
              fullWidth
              infoTooltip={additionalInfo(options?.spouseMiddleName?.additionalInfo)}
              value={userData.spouseMiddleName ?? ''}
              onBlur={() => setFocused([...focused, 'spouseMiddleName'])}
              error={((!userData.spouseMiddleName && focused.includes('spouseMiddleName'))
                || invalidFieldsList.includes('spouseMiddleName')) && options?.spouseMiddleName?.required !== 'NOT_REQUIRED'}
            />
          </Grid>
        )}
        {options?.spouseLastName?.enabled && requiresSpousalInfo(userData) && (
          <Grid item xs={12} md={grid ? 4 : 12}>
            <TextField
              testId="spouse-last-name"
              onChange={(e: any) => update({ ...userData, spouseLastName: e.target.value })}
              disabled={loading}
              label={translateBackend(options?.spouseLastName?.label)}
              infoTooltip={additionalInfo(options?.spouseLastName?.additionalInfo)}
              locked={options?.spouseLastName?.required === 'NOT_EDITABLE'}
              lockMessage={lockMessage()}
              fullWidth
              value={userData.spouseLastName ?? ''}
              onBlur={() => setFocused([...focused, 'spouseLastName'])}
              error={((!userData.spouseLastName && requiresSpousalInfo(userData) && focused.includes('spouseLastName'))
                || invalidFieldsList.includes('spouseLastName')) && options?.spouseLastName?.required !== 'NOT_REQUIRED'}
            />
          </Grid>
        )}
        {options?.spouseEmail?.enabled && requiresSpousalInfo(userData) && (
          <Grid item xs={12} md={grid ? 4 : 12}>
            <TextField
              testId="spouse-email"
              onChange={(e: any) => update({ ...userData, spouseEmail: e.target.value })}
              disabled={loading}
              label={translateBackend(options?.spouseEmail?.label)}
              infoTooltip={additionalInfo(options?.spouseEmail?.additionalInfo)}
              locked={options?.spouseEmail?.required === 'NOT_EDITABLE'}
              lockMessage={lockMessage()}
              fullWidth
              value={userData.spouseEmail ?? ''}
              onBlur={() => setFocused([...focused, 'spouseEmail'])}
              error={((!userData.spouseEmail && requiresSpousalInfo(userData) && focused.includes('spouseEmail'))
                || invalidFieldsList.includes('spouseEmail')) && options?.spouseEmail?.required !== 'NOT_REQUIRED'}
            />
          </Grid>
        )}
        {optionSpouseTaxIdEnabled && requiresSpousalInfo(userData) && (
          <Grid item xs={12} md={grid ? 4 : 12}>
            <TextField
              label={spouseTaxIdLabel}
              value={showEncryptedTaxId ? '' : userData.spouseTaxId}
              placeholder={showEncryptedTaxId}
              onChange={(e: any) => {
                update({ ...userData, spouseTaxId: e.target.value });
                if (showEncryptedTaxId) setShowEncryptedTaxId(undefined);
              }}
              disabled={loading || updating}
              infoTooltip={additionalInfo(options?.spouseTaxId?.additionalInfo)}
              locked={options?.spouseTaxId?.required === 'NOT_EDITABLE'}
              lockMessage={lockMessage()}
              fullWidth
              type={taxIdTypeFormatType(userData.spouseTaxIdType)}
              onFocus={() => setFocused([...focused, 'spouseTaxId'])}
              error={invalidFieldSpouseTaxId}
              testId="spouse-tax-id"
            />
            {taxIdTypesShown.length > 1 && (
              <Box>
                <RadioGroup
                  value={userData.spouseTaxIdType ?? ''}
                  onChange={(e: any) => {
                    update({ ...userData, spouseTaxIdType: e.target.value });
                    setFocused([...focused, 'spouseTaxId']);
                  }}
                  testId="spouse-tax-id-type"
                >
                  {taxIdTypesShown.map((type) => <>
                    <Radio key={type}
                      testId={`spouse-tax-id-option-${type}`}
                      label={type}
                      value={type}
                      size='small'
                      disabled={loading || updating}
                    />
                  </>)}
                </RadioGroup>
              </Box>
            )}
          </Grid>
        )}
        {options?.spouseDateOfBirth?.enabled && requiresSpousalInfo(userData) && (
          <Grid item xs={12} md={grid ? 4 : 12}>
            <DateField
              dataTestId="spouse-date-of-birth"
              onChange={(date: any) => update({ ...userData, spouseDateOfBirth: dayjs(date?.toString()).format('YYYY-MM-DD') })}
              disabled={loading}
              label={translateBackend(options?.spouseDateOfBirth?.label)}
              infoTooltip={additionalInfo(options?.spouseDateOfBirth?.additionalInfo)}
              locked={options?.spouseDateOfBirth?.required === 'NOT_EDITABLE'}
              lockMessage={lockMessage()}
              fullWidth
              value={userData.spouseDateOfBirth}
              onBlur={() => setFocused([...focused, 'spouseDateOfBirth'])}
              error={((!userData.spouseDateOfBirth && requiresSpousalInfo(userData) && focused.includes('spouseDateOfBirth'))
                || invalidFieldsList.includes('spouseDateOfBirth')) && options?.spouseDateOfBirth?.required !== 'NOT_REQUIRED'}
            />
          </Grid>
        )}
        {options?.spouseEmployerName?.enabled && requiresSpousalInfo(userData) && (
          <Grid item xs={12} md={grid ? 4 : 12}>
            <TextField
              testId="spouse-employer-name"
              onChange={(e: any) => update({ ...userData, spouseEmployerName: e.target.value })}
              disabled={loading}
              label={translateBackend(options?.spouseEmployerName?.label)}
              infoTooltip={additionalInfo(options?.spouseEmployerName?.additionalInfo)}
              locked={options?.spouseEmployerName?.required === 'NOT_EDITABLE'}
              lockMessage={lockMessage()}
              fullWidth
              value={userData.spouseEmployerName ?? ''}
              onBlur={() => setFocused([...focused, 'spouseEmployerName'])}
              error={((!userData.spouseEmployerName && requiresSpousalInfo(userData) && focused.includes('spouseEmployerName'))
                || invalidFieldsList.includes('spouseEmployerName')) && options?.spouseEmployerName?.required !== 'NOT_REQUIRED'}
            />
          </Grid>
        )}
        {options?.spouseCompanyType?.enabled && requiresSpousalInfo(userData) && (
          <Grid item xs={12} md={grid ? 4 : 12}>
            <TextField
              testId="spouse-company-type"
              onChange={(e: any) => update({ ...userData, spouseCompanyType: e.target.value })}
              disabled={loading}
              label={translateBackend(options?.spouseCompanyType?.label)}
              infoTooltip={additionalInfo(options?.spouseCompanyType?.additionalInfo)}
              locked={options?.spouseCompanyType?.required === 'NOT_EDITABLE'}
              lockMessage={lockMessage()}
              fullWidth
              value={userData.spouseCompanyType ?? ''}
              onBlur={() => setFocused([...focused, 'spouseCompanyType'])}
              error={((!userData.spouseCompanyType && requiresSpousalInfo(userData) && focused.includes('spouseCompanyType'))
                || invalidFieldsList.includes('spouseCompanyType')) && options?.spouseCompanyType?.required !== 'NOT_REQUIRED'}
            />
          </Grid>
        )}
        {options?.spouseJobTitle?.enabled && requiresSpousalInfo(userData) && (
          <Grid item xs={12} md={grid ? 4 : 12}>
            <TextField
              testId="spouse-job-title"
              onChange={(e: any) => update({ ...userData, spouseJobTitle: e.target.value })}
              disabled={loading}
              label={translateBackend(options?.spouseJobTitle?.label)}
              infoTooltip={additionalInfo(options?.spouseJobTitle?.additionalInfo)}
              locked={options?.spouseJobTitle?.required === 'NOT_EDITABLE'}
              lockMessage={lockMessage()}
              fullWidth
              value={userData.spouseJobTitle ?? ''}
              onBlur={() => setFocused([...focused, 'spouseJobTitle'])}
              error={((!userData.spouseJobTitle && requiresSpousalInfo(userData) && focused.includes('spouseJobTitle'))
                || invalidFieldsList.includes('spouseJobTitle')) && options?.spouseJobTitle?.required !== 'NOT_REQUIRED'}
            />
          </Grid>
        )}
        {options?.trustedContactName?.enabled && (
          <Grid item xs={12} md={grid ? 4 : 12}>
            <TextField
              testId="trusted-contact-name"
              onChange={(e: any) => update({ ...userData, trustedContactPerson: { ...userData.trustedContactPerson, name: e.target.value } })}
              disabled={loading}
              label={translateBackend(options?.trustedContactName?.label)}
              infoTooltip={additionalInfo(options?.trustedContactName?.additionalInfo)}
              locked={options?.trustedContactName?.required === 'NOT_EDITABLE'}
              lockMessage={lockMessage()}
              fullWidth
              value={userData.trustedContactPerson?.name ?? ''}
              onBlur={() => setFocused([...focused, 'trustedContactName'])}
              error={((!userData.trustedContactPerson?.name && focused.includes('trustedContactName'))
                || invalidFieldsList.includes('trustedContactName')) && options?.trustedContactName?.required !== 'NOT_REQUIRED'}
            />
          </Grid>
        )}
        {options?.trustedContactEmail?.enabled && (
          <Grid item xs={12} md={grid ? 4 : 12}>
            <TextField
              testId="trusted-contact-email"
              onChange={(e: any) => update({ ...userData, trustedContactPerson: { ...userData.trustedContactPerson, email: e.target.value } })}
              disabled={loading}
              label={translateBackend(options?.trustedContactEmail?.label)}
              infoTooltip={additionalInfo(options?.trustedContactEmail?.additionalInfo)}
              locked={options?.trustedContactEmail?.required === 'NOT_EDITABLE'}
              lockMessage={lockMessage()}
              fullWidth
              value={userData.trustedContactPerson?.email ?? ''}
              onBlur={() => setFocused([...focused, 'trustedContactEmail'])}
              error={((!userData.trustedContactPerson?.email && focused.includes('trustedContactEmail'))
                || invalidFieldsList.includes('trustedContactEmail')) && options?.trustedContactEmail?.required !== 'NOT_REQUIRED'}
            />
          </Grid>
        )}
        {options?.trustedContactPhone?.enabled && (
          <Grid item xs={12} md={grid ? 4 : 12}>
            <TextField
              testId="trusted-contact-phone"
              type='phone'
              onChange={(e: any) => update({ ...userData, trustedContactPerson: { ...userData.trustedContactPerson, phone: e.target.value } })}
              disabled={loading}
              label={translateBackend(options?.trustedContactPhone?.label)}
              locked={options?.trustedContactPhone?.required === 'NOT_EDITABLE'}
              lockMessage={lockMessage()}
              fullWidth
              value={userData.trustedContactPerson?.phone ?? ''}
              infoTooltip={additionalInfo(options?.trustedContactPhone?.additionalInfo)}
              onBlur={() => setFocused([...focused, 'trustedContactPhone'])}
              error={((!userData.trustedContactPerson?.phone && focused.includes('trustedContactPhone'))
                || invalidFieldsList.includes('trustedContactPhone')) && focused.includes('trustedContactPhone') && options?.trustedContactPhone?.required !== 'NOT_REQUIRED'}
            />
          </Grid>
        )}
        {options?.trustedContactAddress?.enabled && (
          <Grid item xs={12} md={grid ? 4 : 12}>
            <AddressField
              testId="trusted-contact-address"
              onChange={(e) => update({ ...userData, trustedContactPerson: { ...userData.trustedContactPerson, physicalAddress: e } })}
              disabled={loading}
              label={translateBackend(options?.trustedContactAddress?.label)}
              infoTooltip={additionalInfo(options?.trustedContactAddress?.additionalInfo)}
              lockMessage={lockMessage()}
              fullWidth
              address={userData.trustedContactPerson?.physicalAddress as PhysicalAddress}
              onFocus={() => setFocused([...focused, 'trustedContactAddress'])}
              error={((!userData.trustedContactPerson?.physicalAddress?.streetName && focused.includes('trustedContactAddress'))
                || invalidFieldsList.includes('trustedContactAddress')) && focused.includes('trustedContactAddress') && options?.trustedContactAddress?.required !== 'NOT_REQUIRED'}
            />
          </Grid>
        )}
        {options?.trustedContactRelation?.enabled && (
          <Grid item xs={12} md={grid ? 4 : 12}>
            <SelectField
              testId="trusted-contact-relation"
              onChange={(e: any) => update({ ...userData, trustedContactPerson: { ...userData.trustedContactPerson, relation: e.target.value } })}
              disabled={loading}
              label={translateBackend(options?.trustedContactRelation?.label)}
              infoTooltip={additionalInfo(options?.trustedContactRelation?.additionalInfo)}
              locked={options?.trustedContactRelation?.required === 'NOT_EDITABLE'}
              lockMessage={lockMessage()}
              fullWidth
              value={userData.trustedContactPerson?.relation ?? ''}
              onBlur={() => setFocused([...focused, 'trustedContactRelation'])}
              error={((!userData.trustedContactPerson?.relation && focused.includes('trustedContactRelation'))
                || invalidFieldsList.includes('trustedContactRelation')) && options?.trustedContactRelation?.required !== 'NOT_REQUIRED'}
            >
              <MenuItem data-testid="trusted-contact-relation-spouse" value='SPOUSE'>{t('edit.relationOptions.SPOUSE')}</MenuItem>
              <MenuItem data-testid="trusted-contact-relation-parent" value='PARENT'>{t('edit.relationOptions.PARENT')}</MenuItem>
              <MenuItem data-testid="trusted-contact-relation-sibling" value='SIBLING'>{t('edit.relationOptions.SIBLING')}</MenuItem>
              <MenuItem data-testid="trusted-contact-relation-child" value='CHILD'>{t('edit.relationOptions.CHILD')}</MenuItem>
              <MenuItem data-testid="trusted-contact-relation-grandparent" value='GRANDPARENT'>{t('edit.relationOptions.GRANDPARENT')}</MenuItem>
              <MenuItem data-testid="trusted-contact-relation-friend" value='FRIEND'>{t('edit.relationOptions.FRIEND')}</MenuItem>
              <MenuItem data-testid="trusted-contact-relation-other" value='OTHER'>{t('edit.relationOptions.OTHER')}</MenuItem>
            </SelectField>
          </Grid>
        )}
        {options?.numberOfDependents?.enabled && (
          <Grid item xs={12} md={grid ? 4 : 12}>
            <TextField
              testId="number-of-dependents"
              onChange={(e: any) => update({ ...userData, numberOfDependents: parseInt(e.target.value, 10) })}
              disabled={loading}
              type='number'
              label={translateBackend(options?.numberOfDependents?.label)}
              infoTooltip={additionalInfo(options?.numberOfDependents?.additionalInfo)}
              lockMessage={lockMessage()}
              locked={options?.numberOfDependents?.required === 'NOT_EDITABLE'}
              fullWidth
              value={userData.numberOfDependents ?? 0}
              onBlur={() => setFocused([...focused, 'numberOfDependents'])}
              error={((!userData.numberOfDependents && focused.includes('numberOfDependents'))
                || invalidFieldsList.includes('numberOfDependents')) && options?.numberOfDependents?.required !== 'NOT_REQUIRED'}
            />
          </Grid>
        )}
      </Grid>
      <Box display='flex' justifyContent='end'>
        {(!updateMode || options.displayUpdateButton) && (
          <Button
            type='submit'
            label={t(updateMode ? 'update' : 'continue')}
            disabled={loading || (!updated && updateMode) || invalidFieldSpouseTaxId}
            sx={{ mt: 3, textAlign: 'center' }}
            dataTestId={`relationship-information-${kebabCase(translateBackend(options.title))}-continue-button`}
          />
        )}
      </Box>
    </Form>
  );
};

export default RelationshipInformationVisual;
