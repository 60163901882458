/* eslint-disable object-curly-newline */
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Column, DownloadButton, DownloadButtonProps, ExportApiReportFormatters } from '../../../3-pattern';

export const FETCH_SUB_ACCOUNT_DAILY_FEES_QUERY = `query fetchSubAccountDailyFees($input: FetchSubAccountDailyFeesInput!) {
  fetchSubAccountDailyFees(input: $input) {
    totalCount
    subAccountDailyFees {
      id
      date
      user {
        id
        firstName
        lastName
        entityName
        organization {
          id
          name
        }
      }
      subAccount {
        id
        name
        goal {
          id
          name
        }
        account {
          id
          type
          feePaymentAccount {
             id
          }
          custodianAccountNumber
        }
      }
      billingCycle {
         state
         startDate
         endDate
         salesTaxCents
         projectedFeeAndTaxCents
      }
      feeTier {
        id
        name
      }
      marketValueCents
      moneyAvailableCents
      gridScopeTotalMarketValueCents
      annualFeeBps
      chargeableMarketValueCents
      totalDailyFeeCents
      dailyFeeBps
      dailyFixedFeeCents
    }
  }
}`;

const DownloadBillingCycle = ({ filter, queryFilter }: { filter: any; queryFilter: any }) => {
  const { t } = useTranslation(['feeAndBilling']);

  const columns: Column[] = [
    { gqlAlias: 'id', header: t('feeAndBilling:feeReport.exportHeaders.billingCycleId') },
    { gqlAlias: 'date', header: t('feeAndBilling:feeReport.exportHeaders.date') },
    { gqlAlias: 'user.id', header: t('feeAndBilling:feeReport.exportHeaders.clientId') },
    { gqlAlias: 'user.entityName', header: t('feeAndBilling:feeReport.exportHeaders.entityName') },
    { gqlAlias: 'user.firstName', header: t('feeAndBilling:feeReport.exportHeaders.clientFirstName') },
    { gqlAlias: 'user.lastName', header: t('feeAndBilling:feeReport.exportHeaders.clientLastName') },
    { gqlAlias: 'subAccount.account.id', header: t('feeAndBilling:feeReport.exportHeaders.accountId') },
    { gqlAlias: 'subAccount.account.type', header: t('feeAndBilling:feeReport.exportHeaders.accountType') },
    { gqlAlias: 'subAccount.id', header: t('feeAndBilling:feeReport.exportHeaders.subAccountId') },
    { gqlAlias: 'subAccount.goal.id', header: t('feeAndBilling:feeReport.exportHeaders.goalId') },
    { gqlAlias: 'subAccount.goal.name', header: t('feeAndBilling:feeReport.exportHeaders.goalName') },
    { gqlAlias: 'marketValueCents', header: t('feeAndBilling:feeReport.exportHeaders.aum'), formatter: ExportApiReportFormatters.DIVIDE_BY_100 },
    { gqlAlias: 'chargeableMarketValueCents', header: t('feeAndBilling:feeReport.exportHeaders.billableAum'), formatter: ExportApiReportFormatters.DIVIDE_BY_100 },
    { gqlAlias: 'moneyAvailableCents', header: t('feeAndBilling:feeReport.exportHeaders.cash'), formatter: ExportApiReportFormatters.DIVIDE_BY_100 },
    { gqlAlias: 'gridScopeTotalMarketValueCents', header: t('feeAndBilling:feeReport.exportHeaders.householdAum'), formatter: ExportApiReportFormatters.DIVIDE_BY_100 },
    { gqlAlias: 'annualFeeBps', header: t('feeAndBilling:feeReport.exportHeaders.annualFeeBPS'), formatter: ExportApiReportFormatters.DIVIDE_BY_100 },
    { gqlAlias: 'dailyFeeBps', header: t('feeAndBilling:feeReport.exportHeaders.dailyFeeBPS'), formatter: ExportApiReportFormatters.DIVIDE_BY_100 },
    { gqlAlias: 'totalDailyFeeCents', header: t('feeAndBilling:feeReport.exportHeaders.dailyFee'), formatter: ExportApiReportFormatters.DIVIDE_BY_100 },
  ];

  const additionalColumns: Column[] = [
    { gqlAlias: 'billingCycle.state', header: t('feeAndBilling:feeReport.exportHeaders.billingCycleState') },
    { gqlAlias: 'billingCycle.startDate', header: t('feeAndBilling:feeReport.exportHeaders.billingCycleStartDate') },
    { gqlAlias: 'billingCycle.endDate', header: t('feeAndBilling:feeReport.exportHeaders.billingCycleEndDate') },
    { gqlAlias: 'billingCycle.salesTaxCents', header: t('feeAndBilling:feeReport.exportHeaders.billingCycleSalesTax'), formatter: ExportApiReportFormatters.DIVIDE_BY_100 },
    { gqlAlias: 'billingCycle.projectedFeeAndTaxCents', header: t('feeAndBilling:feeReport.exportHeaders.projectedFeeAndTax'), formatter: ExportApiReportFormatters.DIVIDE_BY_100 },
    { gqlAlias: 'subAccount.account.custodianAccountNumber', header: t('feeAndBilling:feeReport.exportHeaders.accountNumber') },
    { gqlAlias: 'subAccount.name', header: t('feeAndBilling:feeReport.exportHeaders.subAccountName') },
    { gqlAlias: 'user.organization.id', header: t('feeAndBilling:feeReport.exportHeaders.organizationId') },
    { gqlAlias: 'user.organization.name', header: t('feeAndBilling:feeReport.exportHeaders.organizationName') },
    { gqlAlias: 'feeTier.id', header: t('feeAndBilling:feeReport.exportHeaders.feeGridId') },
    { gqlAlias: 'feeTier.name', header: t('feeAndBilling:feeReport.exportHeaders.feeGridName') },
    { gqlAlias: 'subAccount.account.feePaymentAccount.id', header: t('feeAndBilling:feeReport.exportHeaders.feePaymentAccountId') },
  ];

  const config: DownloadButtonProps = {
    gql: FETCH_SUB_ACCOUNT_DAILY_FEES_QUERY,
    fileName: `${t('feeAndBilling:feeReport.exportTitle')}_${dayjs().format('YYYY-MM-DD')}`,
    filter: { ...filter },
    additionalFilter: { ...queryFilter },
    queryPath: 'fetchSubAccountDailyFees',
    datasetPath: 'subAccountDailyFees',
    columns,
    additionalColumns,
    sortField: '_id',
    sortDesc: true,
    progressTitle: t('feeAndBilling:feeReport.exportProgressTitle'),
  };

  return (
    <DownloadButton
      configurable
      gql={config.gql}
      fileName={config.fileName}
      filter={config.filter}
      additionalFilter={config.additionalFilter}
      additionalColumns={config.additionalColumns}
      queryPath={config.queryPath}
      datasetPath={config.datasetPath}
      columns={config.columns}
      sortField={config.sortField}
      sortDesc={config.sortDesc}
      progressTitle={config.progressTitle}
    />
  );
};

export default DownloadBillingCycle;
