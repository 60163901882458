import { gql, useMutation, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { floor } from 'lodash/fp';
import {
  Box, Link as MuiLink, Skeleton,
} from '../../1-primative';
import {
  Table, TableRow, TableCell, TableBody,
  Pagination, Badge, Button, MenuItem,
  Card, CardContent, SelectField, TableHeadCell,
} from '../../2-component';
import { AccountTypeSelect, FilterModal } from '../../3-pattern';
import { formatMoneyValue } from '../../../util';
import { useGlobalStats } from '../../../providers/globalStatsHooks';
import { EntityTypes, SubAccount, User } from '../../../interfaces';
import { UserContext } from '../../../providers/userContextProvider';
import { usePageState } from '../../../util/usePageState';
import { STATE_CHANGES_FIELD } from '../../../util/reusableGraphQLFields';
import { useLocalization } from '../../../util/useLocalization';
import { useThemeTokens } from '../../../providers/themeTokenProvider';

const FETCH_SUB_ACCOUNTS = gql`
  query fetchSubAccounts($input: FetchSubAccountsInput!) {
    fetchSubAccounts(input: $input) {
      subAccounts {
        state
        ${STATE_CHANGES_FIELD}
        account {
          type
          user {
            id
            firstName
            lastName
            dateOfBirth
            maritalStatus
            suitabilityScore
            annualIncomeCents
            annualDebtPaymentsCents
            totalDebtCents
            financialLiquidAssetsCents
            financialFixedAssetsCents
            riskQuestion1
            riskQuestion2
            investmentKnowledge
            employmentStatus
            jobTitle
            companyType
            politicallyExposedDomesticPerson
            politicallyExposedForeignPerson
            isMemberOfIiroc
            isOwnerOfPublicCompany
            isOfficerOfPublicCompany
            closeAssociateOfPEP
            headOfInternationalOrganization
            complianceState
            complianceIssueSource
            isVulnerablePerson
            isOwnerOfEntity
            createdAt
            organization {
              name
            }
            iDVerified
            type
            entityName
            isNonProfit
            isRegisteredWithCRA
            receivesDonationsFromPublic
            isOwnerOfPublicCompany
            isEntityRegulated
            isReportingIssuer
            isForThirdParty
            isAFinancialEntity
            isAffiliateOfFinancialEntity
            instructionsFromFinancialEntity
            isPublicBody
            isLargeCorporation
          }
        }
        id
        createdAt
        completedAt
        availableFinancialProducts{
          id
        }
        goal {
          retirementAge
          expectedAnnualIncomeCents
          riskQuestion1
          type
          timeHorizon
          targetAmountCents
          targetDate
          suitabilityScore
          financialProduct {
            id
            translatedName{
              en
            }
          }
        }
        suitabilityScore
        suggestedFinancialProduct {
          id
          translatedName {
            en
          }
        }
        acceptableFinancialProduct
        financialProduct {
          id
          translatedName {
            en
          }
        }
      }
      totalCount
    }
  }
`;

const TRANSITION_SUB_ACCOUNT = gql`
  mutation transitionSubAccount($input: TransitionSubAccountInput!) {
    transitionSubAccount(input: $input) {
      subAccount {
        id
      }
    }
  }
`;

const age = (dob: string | undefined) => {
  if (!dob) { return ''; }
  const today: any = new Date();
  const date: any = new Date(dob);
  return floor((today - date) / 60 / 60 / 24 / 365 / 1000);
};

const isEntityOfInterest = (user: User) => (user.politicallyExposedDomesticPerson
  || user.politicallyExposedForeignPerson
  || user.isMemberOfIiroc
  || user.isOwnerOfPublicCompany
  || user.isOfficerOfPublicCompany
  || user.closeAssociateOfPEP
  || user.headOfInternationalOrganization
  || user.isNonProfit
  || user.isRegisteredWithCRA
  || user.receivesDonationsFromPublic
  || user.isOwnerOfPublicCompany
  || user.isEntityRegulated
  || user.isReportingIssuer
  || user.isForThirdParty
  || user.isAFinancialEntity
  || user.isAffiliateOfFinancialEntity
  || user.instructionsFromFinancialEntity
  || user.isPublicBody
  || user.isLargeCorporation
  || user.isVulnerablePerson
  || user.isOwnerOfEntity
);

export const AccountsReview = () => {
  const { subAccountsRefetch } = useGlobalStats();
  const { t } = useTranslation(['accountsReview']);
  const { activeOrganization } = useContext(UserContext);
  const { localizedDateTime } = useLocalization();
  const [page, setPage] = usePageState(1, 'page');
  const [pageSize, setPageSize] = usePageState(15, 'ps');
  const INDIVIDUAL_PATH = 'clients';
  const NON_INDIVIDUAL_PATH = 'nonIndividualClients';
  const [transitionSubAccount] = useMutation(TRANSITION_SUB_ACCOUNT);
  const [complianceState, setComplianceState] = usePageState<string | undefined>('ANY', 'complianceState');
  const [accountType, setAccountType] = usePageState<string | undefined>('ANY', 'accountType');
  const [idVerified, setIdVerified] = usePageState<string | undefined>('YES', 'idVerified');
  const [state, setState] = usePageState<string | undefined>('AWAITING_APPROVAL', 'state');
  const { sys } = useThemeTokens();
  const [isLoading, setIsLoading] = useState(false);

  const {
    loading, data, previousData, refetch,
  } = useQuery(FETCH_SUB_ACCOUNTS, {
    variables: {
      input: {
        filter: {
          complianceState: complianceState === 'ANY' ? undefined : complianceState,
          accountType: accountType === 'ANY' ? undefined : accountType,
          state: state === 'ANY' ? undefined : state,
          isComplete: true,
          iDVerified: idVerified === 'YES' ? true : idVerified === 'NO' ? false : undefined,
          organizationId: activeOrganization.id,
        },
        pagination: {
          sortField: 'createdAt', sortDesc: false, perPage: pageSize, offSet: (page - 1) * pageSize,
        },
      },
    },
    onCompleted: () => {
      setIsLoading(false);
    },
  });

  const deriveClientName = (user: User): string => {
    if (user && user.firstName) {
      return `${user.firstName} ${user.lastName}`;
    }
    if (user && user.entityName) {
      return user.entityName;
    }
    return '';
  };

  const isIndividualUser = (user: User): boolean => !user.type || user.type === EntityTypes.INDIVIDUAL;

  const generatePortfolioLabel = (subAccount: SubAccount): string => {
    let portfolioName = subAccount.financialProduct?.translatedName?.en || '';
    if (subAccount?.goal?.financialProduct?.id) {
      portfolioName = subAccount?.goal?.financialProduct?.translatedName?.en || '';
      return `${t('inherited')}${portfolioName}`;
    }
    return portfolioName;
  };

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  return (
    <Card loading={isLoading}>
      <Box>
        <CardContent>
          <Box display='flex' justifyContent='space-between' flexWrap='wrap'>
            <SelectField
              value={state}
              label=''
              onChange={(e: any) => {
                setState(e.target.value);
              }}
              fullWidth
              placeholder={t('any')}
            >
              <MenuItem value={'ANY'} key={'ANY'}>{t('table.stateOptions.ANY')}</MenuItem>
              <MenuItem value={'ACTIVE'} key={'ACTIVE'}>{t('table.stateOptions.ACTIVE')}</MenuItem>
              <MenuItem value={'AWAITING_APPROVAL'} key={'AWAITING_APPROVAL'}>{t('table.stateOptions.AWAITING_APPROVAL')}</MenuItem>
              <MenuItem value={'INACTIVE'} key={'INACTIVE'}>{t('table.stateOptions.INACTIVE')}</MenuItem>
            </SelectField>
            <Box display='flex'>
              <FilterModal>
                <Box gap={2} display='flex' flexDirection='column'>
                  <SelectField
                    value={complianceState}
                    label={t('table.complianceState')}
                    onChange={(e: any) => {
                      setComplianceState(e.target.value);
                    }}
                    fullWidth
                    placeholder={t('any')}
                  >
                    <MenuItem value='ANY'>{t('table.complianceStateOptions.ANY')}</MenuItem>
                    <MenuItem value='APPROVED'>{t('table.complianceStateOptions.APPROVED')}</MenuItem>
                    <MenuItem value='NEEDS_REVIEW'>{t('table.complianceStateOptions.NEEDS_REVIEW')}</MenuItem>
                    <MenuItem value='REJECTED'>{t('table.complianceStateOptions.REJECTED')}</MenuItem>
                  </SelectField>
                  <AccountTypeSelect
                    label={t('table.accountType')}
                    size='medium'
                    value={accountType}
                    onChange={(value) => setAccountType(value)}
                  />
                  <SelectField
                    value={idVerified}
                    label={t('table.idVerified')}
                    onChange={(event: any) => {
                      setIdVerified(event.target.value);
                    }}
                    fullWidth
                    placeholder={t('any')}
                  >
                    <MenuItem value='ANY' key={'ANY'}>{t('table.idVerifiedOptions.ANY')}</MenuItem>
                    <MenuItem value='YES' key={'YES'}>{t('table.idVerifiedOptions.YES')}</MenuItem>
                    <MenuItem value='NO' key={'NO'}>{t('table.idVerifiedOptions.NO')}</MenuItem>
                  </SelectField>
                </Box>
              </FilterModal>
            </Box>
          </Box>
        </CardContent>
        <Box sx={{ overflowX: 'auto' }}>
          <Table sx={{ minWidth: 650 }} aria-label="table">
            <TableBody>
              <TableRow>
                <TableHeadCell sx={{
                  position: 'sticky',
                  left: 0,
                }}>{t('table.client')}</TableHeadCell>
                <TableHeadCell>{t('table.completedAt')}</TableHeadCell>
                <TableHeadCell>{t('table.organization')}</TableHeadCell>
                <TableHeadCell>{t('table.account')}</TableHeadCell>
                <TableHeadCell number>{t('table.age')}</TableHeadCell>
                <TableHeadCell number>{t('table.annualIncome')}</TableHeadCell>
                <TableHeadCell number>{t('table.annualDebtPayments')}</TableHeadCell>
                <TableHeadCell number>{t('table.totalDebt')}</TableHeadCell>
                <TableHeadCell number>{t('table.liquidAssets')}</TableHeadCell>
                <TableHeadCell number>{t('table.totalAssets')}</TableHeadCell>
                <TableHeadCell number>{t('table.netWorth')}</TableHeadCell>
                <TableHeadCell number>{t('table.debtAssetRatio')}</TableHeadCell>
                <TableHeadCell number>{t('table.debtServiceRatio')}</TableHeadCell>
                <TableHeadCell>{t('table.employment')}</TableHeadCell>
                <TableHeadCell>{t('table.jobTitle')}</TableHeadCell>
                <TableHeadCell>{t('table.companyType')}</TableHeadCell>
                <TableHeadCell>{t('table.iDVerified')}</TableHeadCell>
                <TableHeadCell>{t('table.entityOfInterest')}</TableHeadCell>
                <TableHeadCell>{t('table.complianceState')}</TableHeadCell>
                <TableHeadCell>{t('table.userSuitablitiy')}</TableHeadCell>
                <TableHeadCell>{t('table.goalSuitability')}</TableHeadCell>
                <TableHeadCell>{t('table.suggestedPortfolio')}</TableHeadCell>
                <TableHeadCell>{t('table.actualPortfolio')}</TableHeadCell>
                <TableHeadCell>{t('table.subAccountStatus')}</TableHeadCell>
                <TableHeadCell>{t('table.actions')}</TableHeadCell>
              </TableRow>
              { loading && !previousData && [...Array(15)].map((x: any, i: number) => (
                <TableRow key={i}>
                  <TableCell><Skeleton width='100%' /></TableCell>
                  <TableCell><Skeleton width='100%' /></TableCell>
                  <TableCell><Skeleton width='100%' /></TableCell>
                  <TableCell><Skeleton width='100%' /></TableCell>
                  <TableCell><Skeleton width='100%' /></TableCell>
                  <TableCell><Skeleton width='100%' /></TableCell>
                  <TableCell><Skeleton width='100%' /></TableCell>
                  <TableCell><Skeleton width='100%' /></TableCell>
                  <TableCell><Skeleton width='100%' /></TableCell>
                  <TableCell><Skeleton width='100%' /></TableCell>
                  <TableCell><Skeleton width='100%' /></TableCell>
                  <TableCell><Skeleton width='100%' /></TableCell>
                  <TableCell><Skeleton width='100%' /></TableCell>
                  <TableCell><Skeleton width='100%' /></TableCell>
                  <TableCell><Skeleton width='100%' /></TableCell>
                  <TableCell><Skeleton width='100%' /></TableCell>
                  <TableCell><Skeleton width='100%' /></TableCell>
                  <TableCell><Skeleton width='100%' /></TableCell>
                  <TableCell><Skeleton width='100%' /></TableCell>
                  <TableCell><Skeleton width='100%' /></TableCell>
                  <TableCell><Skeleton width='100%' /></TableCell>
                  <TableCell><Skeleton width='100%' /></TableCell>
                  <TableCell><Skeleton width='100%' /></TableCell>
                  <TableCell><Skeleton width='100%' /></TableCell>
                  <TableCell><Skeleton width='100%' /></TableCell>
                </TableRow>
              ))}
              {(data || previousData)?.fetchSubAccounts?.subAccounts?.map((subAccount: SubAccount) => (
                <TableRow
                  hover
                  key={subAccount.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 }, textDecoration: 'none' }}
                >
                  <TableCell sx={{
                    background: sys.color.background,
                    position: 'sticky',
                    left: 0,
                  }}>
                    <MuiLink
                      component={Link}
                      to={`/${isIndividualUser(subAccount.account.user) ? INDIVIDUAL_PATH : NON_INDIVIDUAL_PATH}/${subAccount.account.user.id}`}>
                        {deriveClientName(subAccount.account.user)}
                    </MuiLink>
                  </TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>{localizedDateTime(subAccount.completedAt)}</TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>{subAccount.account.user.organization.name}</TableCell>
                  <TableCell>{subAccount.account.type}</TableCell>
                  <TableCell number>{age(subAccount.account.user.dateOfBirth)}</TableCell>
                  <TableCell number>{formatMoneyValue(subAccount.account.user.annualIncomeCents)}</TableCell>
                  <TableCell number>{formatMoneyValue(subAccount.account.user.annualDebtPaymentsCents)}</TableCell>
                  <TableCell number>{formatMoneyValue(subAccount.account.user.totalDebtCents)}</TableCell>
                  <TableCell number>{formatMoneyValue(subAccount.account.user.financialLiquidAssetsCents)}</TableCell>
                  <TableCell number>{formatMoneyValue((subAccount.account.user.financialLiquidAssetsCents || 0) + (subAccount.account.user.financialFixedAssetsCents || 0))}</TableCell>
                  <TableCell number>
                    {formatMoneyValue(
                      (subAccount.account.user.financialLiquidAssetsCents || 0) + (subAccount.account.user.financialFixedAssetsCents || 0) - (subAccount.account.user.totalDebtCents || 0),
                    )
                    }
                  </TableCell>
                  <TableCell number>
                    {(
                      ((subAccount.account.user.totalDebtCents || 0) / ((subAccount.account.user.financialLiquidAssetsCents || 0) + (subAccount.account.user.financialFixedAssetsCents || 0)))
                      * 100
                    ).toFixed(2)}
                    %
                  </TableCell>
                  <TableCell number>
                    {subAccount.account.user.annualDebtPaymentsCents
                      && subAccount.account.user.annualIncomeCents ? `${((subAccount.account.user.annualIncomeCents / subAccount.account.user.annualDebtPaymentsCents) * 100).toFixed(2)}%` : 'N/A'}
                  </TableCell>
                  <TableCell>{subAccount.account.user.employmentStatus}</TableCell>
                  <TableCell>{subAccount.account.user.jobTitle}</TableCell>
                  <TableCell>{subAccount.account.user.companyType}</TableCell>
                  <TableCell>{subAccount.account.user.iDVerified ? t('table.yes') : <Badge label={t('table.no')} color='negative' />}</TableCell>
                  <TableCell>{isEntityOfInterest(subAccount.account.user) ? <Badge label={t('table.yes')} color='negative' /> : t('table.no')}</TableCell>
                  <TableCell>
                    <Badge
                      label={subAccount.account.user.complianceState ?? ''}
                      color={subAccount.account.user.complianceState === 'REJECTED' ? 'negative' : subAccount.account.user.complianceState === 'NEEDS_REVIEW' ? 'warning' : 'positive'}
                    />
                  </TableCell>
                  <TableCell><Badge label={subAccount.account.user.suitabilityScore?.toString() ?? ''} /></TableCell>
                  <TableCell><Badge label={subAccount.goal.suitabilityScore?.toString() ?? ''} /></TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>{subAccount.suggestedFinancialProduct?.translatedName?.en}</TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }}><Badge label={generatePortfolioLabel(subAccount)} color={subAccount?.acceptableFinancialProduct ? 'positive' : 'negative'} /></TableCell>
                  <TableCell>{(subAccount.stateMachineChanges ?? []).map((x) => x.to).includes('ACTIVE') ? t('table.updated') : t('table.new')}</TableCell>
                  <TableCell>
                    <Button
                      size='sm'
                      variant='tonal'
                      label={t('approveAndSign')}
                      disabled={subAccount.state !== 'AWAITING_APPROVAL' || subAccount.account.user.complianceState === 'NEEDS_REVIEW' || subAccount.account.user.iDVerified === false}
                      onClick={async (e) => {
                        e.stopPropagation();
                        setIsLoading(true);
                        await transitionSubAccount({
                          variables: {
                            input: { subAccountId: subAccount.id, transition: 'activate' },
                          },
                        });
                        refetch();
                        subAccountsRefetch();
                      }} />
                    </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
        <Pagination
          count={Math.ceil(((data || previousData)?.fetchSubAccounts?.totalCount ?? 0) / pageSize)}
          page={page}
          perPage={pageSize}
          onChangePerPage={(newPageSize) => setPageSize(newPageSize)}
          onChange={(_e, newPage) => setPage(newPage)}
          sx={{
            p: 1,
            textAlign: 'right',
            '.MuiPagination-ul': {
              justifyContent: 'end',
            },
          }}
        />
      </Box>
    </Card>
  );
};

export default AccountsReview;
