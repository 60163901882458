import ClearIcon from '@mui/icons-material/Clear';
import { gql, useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect } from 'react';
import {
  Box, Skeleton, Typography,
} from '../../1-primative';
import {
  Table, TableHeadCell,
  TableRow, TableCell, TableBody, Pagination,
  IconButton, DateField, Card, CardContent,
} from '../../2-component';
import { UserContext } from '../../../providers/userContextProvider';
import { usePageState } from '../../../util/usePageState';
import { OrganizationBilling } from '../../../interfaces/organizationBilling';
import { formatMoneyValue } from '../../../util';

export const FETCH_ORGANIZATION_BILLINGS = gql`
  query fetchOrganizationBillings($input: FetchOrganizationBillingsInput!) {
    fetchOrganizationBillings(input: $input) {
      totalCount
      organizationBillings {
        id
        organization { id name }
        feeCents
        userManagementFeeCents
        revenueShareCents
        revenueShareTaxCents
      }
    }
  }
`;

export const RevenueShare = () => {
  const { activeOrganization } = useContext(UserContext);
  const { t } = useTranslation(['feeAndBilling', 'shared', 'components']);
  const [organizationId, setOrganizationId] = usePageState(activeOrganization.id ?? '', 'org');
  const [dateAfter, setDateAfter] = usePageState('', 'dateAfter');
  const [dateBefore, setDateBefore] = usePageState('', 'dateBefore');
  const [page, setPage] = usePageState(1, 'page');

  useEffect(() => {
    if (activeOrganization.id) {
      setOrganizationId(activeOrganization.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeOrganization.id]);

  const pageSize = 15;
  const {
    loading, error, data, previousData,
  } = useQuery(FETCH_ORGANIZATION_BILLINGS, {
    variables: {
      input: {
        filter: {
          organizationId: organizationId || activeOrganization.id,
          billingDateAfter: dateAfter || undefined,
          billingDateBefore: dateBefore || undefined,
        },
        pagination: { perPage: pageSize, offSet: (page - 1) * pageSize },
      },
    },
  });

  if (error) (<Typography>Error</Typography>);

  return (
    <Card loading={loading}>
      <CardContent>
        <Box display="flex" gap={2}>
          <DateField
            label={t('feeAndBilling:feeReport.filters.billingDateFrom')}
            value={dateAfter || null}
            onChange={(date: any) => setDateAfter(dayjs(date).format('YYYY-MM-DD'))}
            InputProps={{ endAdornment: dateAfter && (<IconButton onClick={() => setDateAfter('')}><ClearIcon /></IconButton>) }}
          />
          <DateField
            label={t('feeAndBilling:feeReport.filters.billingDateTo')}
            value={dateBefore || null}
            onChange={(date: any) => setDateBefore(dayjs(date).format('YYYY-MM-DD'))}
            InputProps={{ endAdornment: dateBefore && (<IconButton onClick={() => setDateBefore('')}><ClearIcon /></IconButton>) }}
          />
        </Box>
      </CardContent>
      <Table aria-label="table">
        <TableBody>
          <TableRow>
            <TableHeadCell sx={{ whiteSpace: 'nowrap' }}>{t('feeAndBilling:revenueShareReport.table.organization')}</TableHeadCell>
            <TableHeadCell sx={{ whiteSpace: 'nowrap' }} right>{t('feeAndBilling:revenueShareReport.table.feeCents')}</TableHeadCell>
            <TableHeadCell sx={{ whiteSpace: 'nowrap' }} right>{t('feeAndBilling:revenueShareReport.table.userManagementFeeCents')}</TableHeadCell>
            <TableHeadCell sx={{ whiteSpace: 'nowrap' }} right>{t('feeAndBilling:revenueShareReport.table.revenueShareCents')}</TableHeadCell>
            <TableHeadCell sx={{ whiteSpace: 'nowrap' }} right>{t('feeAndBilling:revenueShareReport.table.revenueShareTaxCents')}</TableHeadCell>
          </TableRow>
          { loading && !previousData && [...Array(15)].map((x: any, i: number) => (
            <TableRow key={i}>
              <TableCell><Skeleton width='100%' /></TableCell>
              <TableCell><Skeleton width='100%' /></TableCell>
              <TableCell><Skeleton width='100%' /></TableCell>
              <TableCell><Skeleton width='100%' /></TableCell>
              <TableCell><Skeleton width='100%' /></TableCell>
            </TableRow>
          ))}
          {data?.fetchOrganizationBillings?.organizationBillings?.map((item: OrganizationBilling) => (
            <TableRow
              hover
              key={item.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 }, textDecoration: 'none' }}
            >
              <TableCell sx={{ whiteSpace: 'nowrap' }}>{item.organization?.name}</TableCell>
              <TableCell sx={{ whiteSpace: 'nowrap' }} number>{formatMoneyValue(item.feeCents)}</TableCell>
              <TableCell sx={{ whiteSpace: 'nowrap' }} number>{formatMoneyValue(item.userManagementFeeCents)}</TableCell>
              <TableCell sx={{ whiteSpace: 'nowrap' }} number>{formatMoneyValue(item.revenueShareCents)}</TableCell>
              <TableCell sx={{ whiteSpace: 'nowrap' }} number>{formatMoneyValue(item.revenueShareTaxCents)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Pagination
        count={Math.ceil(((data || previousData)?.fetchGroupedBillings?.totalCount ?? 0) / pageSize)}
        page={page}
        onChange={(_e, newPage) => setPage(newPage)}
        sx={{
          p: 1,
          textAlign: 'right',
          '.MuiPagination-ul': {
            justifyContent: 'end',
          },
        }}
      />
    </Card>
  );
};

export default RevenueShare;
