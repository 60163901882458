import { gql, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { SubAccountBilling } from '../../../interfaces/subAccountBilling';
import { getSubAccountName } from '../../../interfaces/subAccount';

const FETCH_SUB_ACCOUNT_BILLING_BREADCRUMB = gql`
  query fetchSubAccountBillingBreadcrumb($input: FetchSubAccountBillingsInput!) {
    fetchSubAccountBillings(input: $input) {
      subAccountBillings {
        id
        subAccount {
          id
          goal { id name type }
          account { id type user { id firstName lastName } }
        }
      }
    }
  }
`;

export const DailyFeeDetailsBreadcrumb = () => {
  const { data } = useQuery(FETCH_SUB_ACCOUNT_BILLING_BREADCRUMB, {
    variables: {
      input: {
        filter: {
          subAccountIds: useParams().subAccountId,
          billingCycleIds: useParams().billingCycleId,
        },
      },
    },
  });
  const subAccountBilling: SubAccountBilling = data?.fetchSubAccountBillings?.subAccountBillings?.[0];
  if (!subAccountBilling?.subAccount) return '';

  return `${getSubAccountName(subAccountBilling?.subAccount, true)}`;
};
