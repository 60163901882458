/* eslint-disable react-hooks/exhaustive-deps */
import { gql, useMutation, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Box, Link as MuiLink,
} from '../../1-primative';
import {
  Table, TableHeadCell, TableRow, TableCell, TableBody, Pagination,
  Badge, MenuItem, Card, CardContent, SelectField,
} from '../../2-component';
import { useGlobalToast } from '../../../providers/globalToastProvider';
import { formatMoneyValue, generateClientNameString } from '../../../util';
import { usePermissions, UserContext } from '../../../providers/userContextProvider';
import { FilterModal } from '../../3-pattern';
import { TransferModal } from '../../4-module/widgets/pendingTransfers/components/transferModal';
import { usePageState } from '../../../util/usePageState';
import { TransferSourcesTypes } from '../../../interfaces/transaction';
import { useLocalization } from '../../../util/useLocalization';

export const FETCH_TRANSFERS_QUERY = (permissions: string[]) => `query fetchTransfers($input: FetchTransfersInput!) {
  fetchTransfers(input: $input) {
    transfers {
      id
      amountCents
      initiatedByRepresentative
      state
      type
      source
      sourceId
      stateChanges
      instantInvest
      dividendFor {
        ticker
      }
      fromSubAccount {
        id
        account {
          type
        }
      }
      failReason
      cancelReason
      cancelReconciledReason
      bankAccount {
        id
        name
        institutionNumber
        bankAccountNumber
        transitNumber
        ${permissions.includes('read:bank_account_disclosed') ? 'transitNumberDisclosed' : ''}
        ${permissions.includes('read:bank_account_disclosed') ? 'bankAccountNumberDisclosed' : ''}
      }
      isWithdrawAll
      ${permissions.includes('read:alert_report_basic') ? 'isSuspicious' : ''}
      ${permissions.includes('write:alert_report_basic') ? `
        alertReport {
          id
          rules {
            type
            translatedDescription {
              en
            }
          }
        }
      ` : ''}
      subAccount {
        id
        account {
          id
          type
          user {
            id
            type
            entityName
            ${permissions.includes('read:client_low_risk_pii') ? 'firstName middleName lastName' : ''}
          }
          organization {
            id
          }
        }
        goal {
          name
        }
        financialProduct {
          translatedName {
            en
          }
        }
        statistics {
          marketValueCents
          moneyAvailableCents
          availableFundsToWithdrawCents
        }
      }
      createdAt
      reconciledAt
      isRecurring
      manualProcess {
        id
        type
        signedUrl
        institution { name }
        transferAccount
        transferAccountNumber
        transferMethod
      }
    }
    totalCount
  }
}`;

export const FETCH_TRANSFERS = (permissions: string[]) => gql`${FETCH_TRANSFERS_QUERY(permissions)}`;

const TRANSITION_TRANSFER = gql`
  mutation transitionTransfer($input: TransitionTransferInput!) {
    transitionTransfer(input: $input) {
      transfer {
        id
      }
    }
  }
`;

export const AllTransactions = () => {
  const { t } = useTranslation(['components']);
  const { permissions } = usePermissions();
  const { activeOrganization } = useContext(UserContext);
  const { showToast } = useGlobalToast();
  const [page, setPage] = usePageState(1, 'tfr/page');
  const [pageSize, setPageSize] = usePageState(15, 'tfr/pageSize');
  const { localizedDateTime } = useLocalization();
  const [source, setSource] = usePageState('any', 'tfr/source');
  const [type, setType] = usePageState('any', 'tfr/type');
  const [state, setState] = usePageState('any', 'tfr/state');
  const [activeTransfer, setActiveTransfer] = useState<any>({});
  const [modalOpen, setModalOpen] = useState(false);

  const [transitionTransfer] = useMutation(TRANSITION_TRANSFER, {
    onError: (error: any) => {
      showToast({ message: error?.graphQLErrors[0]?.extensions?.response?.body?.errors[0]?.message || '', severity: 'error' });
    },
  });
  const generateSourceFilter = (filteredSource: string): any => {
    if (filteredSource === 'any') return { source: undefined };
    if (filteredSource === 'USER') {
      return {
        source: 'USER',
        initiatedByRepresentative: false,
      };
    }
    if (filteredSource === 'REPRESENTATIVE') {
      return {
        source: 'USER',
        initiatedByRepresentative: true,
      };
    }
    return { source: filteredSource };
  };

  const organizationId = activeOrganization.id;

  const queryFilter = {
    ...generateSourceFilter(source),
    type: type === 'any' ? undefined : type,
    state: state === 'any' ? undefined : state,
    organizationId: organizationId ?? activeOrganization.id,
  };

  const {
    loading, data, previousData, refetch,
  } = useQuery(FETCH_TRANSFERS(permissions), {
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'all',
    variables: {
      input: {
        filter: {
          ...queryFilter,
        },
        pagination: {
          sortField: 'createdAt',
          sortDesc: false,
          perPage: pageSize,
          offSet: (page - 1) * pageSize,
        },
      },
    },
  });

  useEffect(() => function cleanupOnUnmount() {
    setSource('any');
    setType('any');
    setState('any');
    setPage(1);
  }, []);

  const stateColor = (forState: any) => (forState === 'RECONCILED' ? 'positive' : ['FAILED', 'CANCELED'].includes(forState) ? 'negative' : 'warning');

  const sourceDetails = (transfer: any): string => {
    if (transfer.dividendFor) {
      return ` - ${transfer.dividendFor.ticker}`;
    } if (transfer.bankAccount) {
      return ` - ${transfer.bankAccount.institutionNumber}`;
    }
    return '';
  };

  const transferSources = TransferSourcesTypes;

  const transferStates = [
    'INITIATED',
    'REVIEWING',
    'READY',
    'REQUESTED',
    'PROCESSING',
    'RECONCILED',
    'FAILED',
    'CANCELED',
  ];

  const generateSourceText = (transfer: any): string => {
    if (['TO_ACCOUNT', 'TO_SUB_ACCOUNT'].includes(transfer.source as string)) {
      const accType = t(`accountTypes:${transfer?.fromSubAccount?.account?.type}`);
      return t('transferTo', { subAccount: accType });
    }
    if (['FROM_ACCOUNT', 'FROM_SUB_ACCOUNT'].includes(transfer.source as string)) {
      const accType = t(`accountTypes:${transfer?.fromSubAccount?.account?.type}`);
      return t('transferFrom', { subAccount: accType });
    }
    const sourceOption = transfer.initiatedByRepresentative ? 'REPRESENTATIVE' : transfer.source;
    return t(`transferSources.${sourceOption}`);
  };

  const navigationLink = (user: any): string => {
    if (user.type === 'INDIVIDUAL') return `/clients/${user.id}`;
    return `/nonIndividualClients/${user.id}`;
  };

  const transition = (transferId: string, _isScheduled: boolean, variables: any) => {
    transitionTransfer({
      variables: { input: { transferId, ...variables } },
      onCompleted: refetch,
    });
  };

  return (
    <Card loading={loading}>
      <CardContent sx={{ display: 'flex', justifyContent: 'end' }}>
        <FilterModal>
          <Box display='flex' flexDirection='column' gap={2}>
            <SelectField
              value={source}
              label={t('activityTable.source')}
              onChange={(e: any) => setSource(e.target.value) }
              placeholder={t('any')}
              fullWidth
            >
              <MenuItem value={'any'}>{t('any')}</MenuItem>
              {Object.values(transferSources).map((x: any) => (
                <MenuItem value={x} key={x}>{t(`transferSources.${x}`)}</MenuItem>
              ))}
            </SelectField>
            <SelectField
              value={type}
              label={t('activityTable.type')}
              onChange={(e: any) => setType(e.target.value)}
              fullWidth
              placeholder={t('any')}
            >
              <MenuItem value={'any'}>{t('any')}</MenuItem>
              <MenuItem value='DEPOSIT'>{t('DEPOSIT')}</MenuItem>
              <MenuItem value='WITHDRAW'>{t('WITHDRAW')}</MenuItem>
            </SelectField>
            <SelectField
              value={state}
              label={t('activityTable.state')}
              onChange={(e: any) => setState(e.target.value) }
              fullWidth
              placeholder={t('any')}
            >
              <MenuItem value={'any'}>{t('any')}</MenuItem>
              {transferStates.map((x: any) => (
                <MenuItem value={x} key={x}>{t(x)}</MenuItem>
              ))}
            </SelectField>
          </Box>
        </FilterModal>
      </CardContent>
      <Table aria-label="table">
        <TableBody>
          <TableRow>
            <TableHeadCell>{t('activityTable.client')}</TableHeadCell>
            <TableHeadCell>{t('activityTable.account')}</TableHeadCell>
            <TableHeadCell>{t('activityTable.type')}</TableHeadCell>
            <TableHeadCell>{t('activityTable.source')}</TableHeadCell>
            <TableHeadCell>{t('activityTable.amount')}</TableHeadCell>
            <TableHeadCell>{t('activityTable.state')}</TableHeadCell>
            <TableHeadCell>{t('activityTable.autoTransfer')}</TableHeadCell>
            <TableHeadCell>{t('activityTable.createdAt')}</TableHeadCell>
            <TableHeadCell>{t('activityTable.reconciledAt')}</TableHeadCell>
          </TableRow>
          {(data || previousData)?.fetchTransfers?.transfers?.map((transfer: any) => (
            <TableRow
              hover
              pointer
              onClick={() => {
                setActiveTransfer(transfer);
                setModalOpen(true);
              }}
              selected={transfer.id === activeTransfer.id}
              key={transfer.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 }, textDecoration: 'none' }}
            >
              <TableCell component="th" scope="row">
                <MuiLink
                  component={Link}
                  to={navigationLink(transfer.subAccount.account.user)}>
                  {/* to={`/${clients}/${transfer.subAccount.account.user.id}`}> */}
                  {generateClientNameString(transfer.subAccount.account.user)}
                </MuiLink>
              </TableCell>
              <TableCell component="th" scope="row">
                {transfer.subAccount.goal.name} - {t(`accountTypes:${transfer.subAccount.account.type}`)}
              </TableCell>
              <TableCell><Badge label={transfer.isWithdrawAll ? 'WITHDRAW ALL' : transfer.type} color={transfer.isSuspicious ? 'negative' : 'neutral'} /></TableCell>
              <TableCell>{generateSourceText(transfer)}<b>{sourceDetails(transfer)}</b></TableCell>
              <TableCell>{formatMoneyValue(transfer.amountCents)}</TableCell>
              <TableCell>
                <Badge
                  label={transfer.state}
                  color={stateColor(transfer.state)}
                />
              </TableCell>
              <TableCell>{transfer.isRecurring ? t('transfersTable.yes') : t('transfersTable.no')}</TableCell>
              <TableCell>{localizedDateTime(transfer.createdAt)}</TableCell>
              <TableCell>{transfer.reconciledAt ? localizedDateTime(transfer.reconciledAt) : '-'}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Pagination
        onChangePerPage={setPageSize}
        count={Math.ceil(((data || previousData)?.fetchTransfers?.totalCount ?? 0) / pageSize)}
        page={page}
        onChange={(_e, newPage) => setPage(newPage)}
        sx={{
          p: 1,
          textAlign: 'right',
          '.MuiPagination-ul': {
            justifyContent: 'end',
          },
        }}
      />
      {activeTransfer && (
        <TransferModal
          transfer={activeTransfer}
          setOpen={setModalOpen}
          open={modalOpen}
          transition={transition}
          options={{ canTransition: (permissions.includes('transition:transfer') || permissions.includes('write:adjustment_transfer')) }}
        />
      )}
    </Card>
  );
};
