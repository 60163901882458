import { CSSProperties } from 'react';
import theme from '../theme';

export const stickyColumn = {
  position: 'sticky',
  left: 0,
  background: theme.palette.background.default,
  borderRight: '2px solid black !important',
  minWidth: '200px',
};

export const textColumnStyles = {
  fontSize: 'small',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  maxWidth: '150px',
  whiteSpace: 'nowrap',
};

export const stickyPosition = {
  position: 'sticky',
  left: 0,
};

interface ControlStyles {
  columnStyles: CSSProperties;
  wrapperStyles: CSSProperties;
  customStyle: CSSProperties;
}

export const useControlColumnStyles = (): ControlStyles => {
  const columnStyles: CSSProperties = {
    width: '48px',
    padding: '0',
  };

  const wrapperStyles: CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '48px',
  };

  const customStyle: CSSProperties = {
    margin: 0,
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  return {
    columnStyles,
    wrapperStyles,
    customStyle,
  };
};
