import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { Typography } from '../../../1-primative';
import { SubAccountBilling } from '../../../../interfaces/subAccountBilling';
import { useLocalization } from '../../../../util/useLocalization';

export const DailyFeeGridBox = ({ row }: { row: SubAccountBilling }) => {
  const { t } = useTranslation();
  const { localizedDate } = useLocalization();

  return (
    <>
      <Typography>{t('feeAndBilling:feeReport.dailyFee.feeGrid', { feeGrid: row.feeTier?.name })}</Typography>
      <Typography>{t('feeAndBilling:feeReport.dailyFee.billingDate', { billingDate: localizedDate(row.billingDate) })}</Typography>
      <Typography>
        {t('feeAndBilling:feeReport.dailyFee.period', { from: localizedDate(row.startDate), to: localizedDate(dayjs(row.endDate).subtract(1, 'day')) })}
      </Typography>
      {row.notes && (
        <Typography>
          {`${t('feeAndBilling:feeReport.overrideFees.note')}: ${row.notes}`}
        </Typography>
      )}
    </>
  );
};
