import { gql, useMutation } from '@apollo/client';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '../../../../1-primative';
import {
  TextField, MenuItem, RadioGroup,
  Radio, Switch,
  SelectField,
} from '../../../../2-component';
import { CreateNewModal, OrganizationSelect, RoleSelect } from '../../../../3-pattern';
import { Languages, OrganizationUserAccessTypes } from '../../../../../interfaces';

const CREATE_ORGANIZATION = gql`
  mutation createOrganizationUser($input: CreateOrganizationUserInput!) {
    createOrganizationUser(input: $input) {
      organizationUser {
        id
      }
    }
  }
`;

export const NewAccessUser = ({ afterCreate, defaultOrg }: { afterCreate: () => void, defaultOrg?: string }) => {
  const { t } = useTranslation(['orgSettings']);
  const languages = Object.values(Languages);

  const [organizationUser, setOrganizationUser] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    language: Languages.ENGLISH,
    roleId: '',
    organizationId: defaultOrg ?? '',
    accessType: OrganizationUserAccessTypes.ORGANIZATION,
    autoInviteUser: true,
  });

  const [createOrganizationUser, { loading }] = useMutation(CREATE_ORGANIZATION, {
    variables: {
      input: organizationUser,
    },
  });

  const createOrg = async (event: any) => {
    await createOrganizationUser();
    setOrganizationUser({
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      roleId: '',
      language: Languages.ENGLISH,
      organizationId: defaultOrg ?? '',
      accessType: OrganizationUserAccessTypes.ORGANIZATION,
      autoInviteUser: true,
    });
    afterCreate();
  };

  return (
    <CreateNewModal loading={loading} title={t('userModal.title')} onSubmit={createOrg} sx={{ float: 'right' }} testId='access-users-new'>
      <Box display='flex' flexDirection='column' gap={2}>
        <TextField
          fullWidth
          label={t('userModal.firstName')}
          value={organizationUser.firstName}
          onChange={(e: any) => setOrganizationUser({ ...organizationUser, firstName: e.target.value })}
          testId='access-users-new-first-name'
        />
        <TextField
          fullWidth
          label={t('userModal.lastName')}
          value={organizationUser.lastName}
          onChange={(e: any) => setOrganizationUser({ ...organizationUser, lastName: e.target.value })}
          testId='access-users-new-last-name'
        />
        <TextField
          fullWidth
          label={t('userModal.email')}
          value={organizationUser.email}
          onChange={(e: any) => setOrganizationUser({ ...organizationUser, email: e.target.value })}
          testId='access-users-new-email'
        />
        <TextField
          fullWidth
          label={t('userModal.phone')}
          value={organizationUser.phone}
          onChange={(e: any) => setOrganizationUser({ ...organizationUser, phone: e.target.value })}
          testId='access-users-new-phone'
        />
          <RadioGroup
            label={t('userModal.language')}
            value={organizationUser.language}
            onChange={(e: any) => setOrganizationUser({ ...organizationUser, language: e.target.value as Languages })}
            testId='access-users-new-language'
          >
            {languages.map((lang) => <Radio testId={`access-users-new-language-${lang.toLowerCase()}`} label={t(`userModal.languageList.${lang}`)} value={lang} />)}
          </RadioGroup>
        <OrganizationSelect
          value={organizationUser.organizationId}
          label={t('userModal.organization')}
          onChange={(event: any) => setOrganizationUser({ ...organizationUser, organizationId: event.target.value as string })}
          testId='access-users-new-organization'
        />
        <RoleSelect
          value={organizationUser.roleId}
          label={t('userModal.role')}
          onChange={(event: any) => setOrganizationUser({ ...organizationUser, roleId: event.target.value as string })}
          testId='access-users-new-role'
        />
        <SelectField
          value={organizationUser.accessType}
          label={t('userModal.accessType')}
          fullWidth
          onChange={(event: any) => setOrganizationUser({ ...organizationUser, accessType: event.target.value })}
          testId='access-users-new-access-type'
        >
          <MenuItem data-testId='access-users-new-access-type-organization' key='1' value={OrganizationUserAccessTypes.ORGANIZATION}>{t(OrganizationUserAccessTypes.ORGANIZATION)}</MenuItem>
          <MenuItem data-testId='access-users-new-access-type-entity' key='2' value={OrganizationUserAccessTypes.ENTITY}>{t(OrganizationUserAccessTypes.ENTITY)}</MenuItem>
        </SelectField>
        <Switch
          label={t('userModal.autoInviteUser')}
          checked={organizationUser?.autoInviteUser ?? true}
          onChange={async (event) => setOrganizationUser({ ...organizationUser, autoInviteUser: event })}
          testId='access-users-new-auto-invite-user'
        />
      </Box>
    </CreateNewModal>
  );
};

export default NewAccessUser;
