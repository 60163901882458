/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { SvgIconComponent } from '@mui/icons-material';

import {
  List, ListItem, ListItemButton, Collapse, ListItemIcon,
} from '@mui/material';
import {
  Badge,
} from '..';
import { Box, Icon, Typography } from '../../1-primative';
import { useNavSectionTokens } from './navSection.tokens';
import { useThemeTokens } from '../../../providers/themeTokenProvider';
import { usePermissions } from '../../../providers/userContextProvider';

export interface SubSection {
  name: string,
  path: string,
  accessKey?: string,
  permissions?: string[],
  stats?: 'flagsCount'
  | 'subAccountsToReviewCount'
  | 'complianceReviewCount'
  | 'alertsCount' | 'statementsToReviewCount' | 'annualSuitabilityReviewCount' | 'bankAccountAwaitingReviewCount' | 'institutionAwaitingReviewCount',
}

export interface Section {
  name: string,
  icon: SvgIconComponent,
  subSections: SubSection[],
}

export const NavSection = ({
  section, accessiblePages, handleDrawerClick, globalStats, displayStats, showSection,
  setSectionsOpen, sectionsOpen, index, location, closed, openedMenu,
}: {
  section: Section, accessiblePages: any[], handleDrawerClick: (x: string) => void, globalStats: any,
  displayStats: (x: SubSection) => boolean, showSection: boolean, setSectionsOpen: (x: boolean[]) => void, openedMenu: boolean,
  sectionsOpen: boolean[], index: number, location: any, closed: boolean
}) => {
  const { t } = useTranslation(['navMenu']);
  const { permissions } = usePermissions();
  const pageIsAccessible = (
    key?: string,
    permissionsRequired?: string[],
  ):boolean => (!key && !permissionsRequired) || accessiblePages.includes(key) || (permissionsRequired?.some((x) => permissions.includes(x)) ?? false);
  const { comp, sys } = useThemeTokens(useNavSectionTokens());
  const [localClosed, setLocalClosed] = useState<boolean>(closed);

  useEffect(() => {
    if (closed) {
      setLocalClosed(closed);
    } else {
      setTimeout(() => {
        setLocalClosed(closed);
      }, 150);
    }
  }, [closed]);

  const buttonStyle = {
    padding: '12px 12px',
    color: comp.navSection.color,
    borderRadius: comp.navSection.radius,
    '&:hover,&.Mui-selected': { backgroundColor: comp.navSection.selectedColor },
    '&:hover&.Mui-selected': { backgroundColor: comp.navSection.selectedColor },
    '&:hover': { backgroundColor: comp.navSection.hoverColor },
  };

  const sectionActive = () => section.subSections.filter((x: SubSection) => !x.accessKey || pageIsAccessible(x.accessKey, x.permissions)).some((x: SubSection) => location.pathname.startsWith(x.path));

  const menuPopout = (s: Section) => (
    <Box
      sx={{
        position: 'absolute',
        top: '0px',
        left: '100%',
        display: {
          xs: 'none',
          lg: openedMenu && closed ? 'block' : 'none',
        },
        background: comp.navSection.backgroundColor,
        p: '4px',
        borderRadius: '8px',
        boxShadow: sys.elevation.high,
        minWidth: '200px',
      }}
    >
      { section.subSections.filter((y: SubSection) => pageIsAccessible(y.accessKey, y.permissions)).map((sub: SubSection) => (
        <ListItem disablePadding key={sub.name} component={Link} to={sub.path} onClick={() => handleDrawerClick(sub.name)} secondaryAction={ displayStats(sub) && (
          <Badge label={ globalStats[sub.stats!] > 999 ? '999+' : globalStats[sub.stats!].toString()} color='negative' variant='number' />
        )}>
          <ListItemButton
            sx={{ ...buttonStyle }}
            selected={sub.path === '/' ? sub.path === location.pathname : location.pathname.startsWith(sub.path)}
          >
            <Typography variant='bodySmall' weight={(sub.path === '/' ? sub.path === location.pathname : location.pathname.startsWith(sub.path)) ? 'bold' : 'regular'}>{t(sub.name)}</Typography>
          </ListItemButton>
        </ListItem>
      ))}
    </Box>
  );

  const subSections = section.subSections.filter((x: SubSection) => pageIsAccessible(x.accessKey, x.permissions));
  if (subSections.length === 0) {
    return <></>;
  }
  if (subSections.length === 1) {
    const s0 = subSections[0];
    return (
      <Box key={section.name} pl={0.5} pr={0.5} position='relative' mb={0.25}>
        <ListItem key={t(s0.name) as string} component={Link} to={s0.path} onClick={() => handleDrawerClick(s0.name)} disablePadding secondaryAction={ displayStats(s0) && (
          <Badge label={ globalStats[s0.stats!] > 999 ? '999+' : globalStats[s0.stats!].toString()} color='negative' variant='number' />
        )}>
          <ListItemButton
            selected={s0.path === '/' ? s0.path === location.pathname : location.pathname.startsWith(s0.path)}
            sx={buttonStyle}
          >
            <Box display='flex' justifyContent='start' alignItems='center'>
              <ListItemIcon sx={{ minWidth: 28 }}><Icon icon={ section.icon } sx={{ color: comp.navSection.color, p: '2px' }} size='small' /></ListItemIcon>
              { !localClosed && (
                <Typography variant='bodyMedium'>{showSection ? t(section.name) : t(s0.name)}</Typography>
              )}
            </Box>
          </ListItemButton>
        </ListItem>
        {menuPopout(section)}
      </Box>
    );
  }
  return (
    <Box key={section.name} pl={0.5} pr={0.5} position='relative' overflow='visible' mb={0.25}>
      <ListItemButton
        onClick={() => setSectionsOpen(sectionsOpen.map((val, idx) => ((idx === index) ? !val : val))) }
        sx={buttonStyle}
        selected={sectionActive() && localClosed}
      >
        <Box display='flex' justifyContent='space-between' alignItems='center' width='100%'>
          <Box display='flex' justifyContent='start' alignItems='center'>
            <ListItemIcon sx={{ minWidth: 28 }}><Icon icon={ section.icon } sx={{ color: comp.navSection.color, p: '2px' }} size='small' /></ListItemIcon>
            { !localClosed && (
              <Typography variant='bodyMedium'>{t(section.name)}</Typography>
            )}
          </Box>
          { !localClosed && (
            sectionsOpen[index] ? <ExpandLess sx={{ fontSize: '16px' }}/> : <ExpandMore sx={{ fontSize: '16px' }}/>
          )}
          </Box>
      </ListItemButton>
      <Collapse in={sectionsOpen[index] && !localClosed} timeout="auto" unmountOnExit>
        <List component="div" sx={{ padding: 0 }}>
          { section.subSections.filter((y: SubSection) => pageIsAccessible(y.accessKey, y.permissions)).map((sub: SubSection) => (
            <ListItem sx={{ mb: 0.25 }} disablePadding key={sub.name} component={Link} to={sub.path} onClick={() => handleDrawerClick(sub.name)} secondaryAction={ displayStats(sub) && (
              <Badge label={ globalStats[sub.stats!] > 999 ? '999+' : globalStats[sub.stats!].toString()} color='negative' variant='number' />
            )}>
              <ListItemButton
                sx={{ ...buttonStyle }}
                selected={sub.path === '/' ? sub.path === location.pathname : location.pathname.startsWith(sub.path)}
              >
                <Typography variant='bodySmall' weight={(sub.path === '/' ? sub.path === location.pathname : location.pathname.startsWith(sub.path)) ? 'bold' : 'regular'}>{t(sub.name)}</Typography>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Collapse>
      {menuPopout(section)}
    </Box>
  );
};
