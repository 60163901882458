import {
  ApolloClient, ApolloProvider, createHttpLink, from, InMemoryCache, ServerError,
} from '@apollo/client';
import { datadogLogs } from '@datadog/browser-logs';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import React, {
  createContext, useContext, useEffect, useMemo, useRef, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { auth0Logout } from './auth0Client';
import { useGlobalToast } from './globalToastProvider';
import { clientHostedLogout } from './CIAMProvider';
import { getBackendLanguage } from '../assets/i18n/config';

type AuthContextType = {
  authToken: string;
  setAuthToken: (token: string) => void;
  appLogout: () => Promise<void>;
  settings: AuthContextSettings;
  setSettings: (settings: AuthContextSettings) => void;
  permissions: string[];
  setPermissions: (permissions: string[]) => void;
};

interface AuthContextSettings {
  clientHostedLoginRedirectUri?: string;
  clientHostedLoginURL?: string;
  auth0RedirectUri?: string;
  enableInactivityTimeOut?: boolean;
  inactivityTimeoutInMinutes?: number;
}

const AuthContext = createContext<AuthContextType>({
  authToken: '',
  setAuthToken: () => {},
  appLogout: async () => {},
  settings: {},
  setSettings: () => {},
  permissions: [],
  setPermissions: () => {},
});

const OvApolloProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation(['shared']);
  const { showToast } = useGlobalToast();
  const [resetKey, setResetKey] = useState(0);
  const [authToken, setAuthToken] = useState('');
  const [settings, setSettings] = useState<AuthContextSettings>({});
  const [permissions, setPermissions] = useState<string[]>([]);
  const tokenRef = useRef(authToken);
  const language = getBackendLanguage();

  useEffect(() => {
    if (authToken) tokenRef.current = authToken;
  }, [authToken]);

  const client = useMemo(
    () => new ApolloClient({
      cache: new InMemoryCache(),
      link: from([
        onError(({ graphQLErrors, networkError, operation }) => {
          if (networkError) {
            if ('statusCode' in networkError && (networkError as ServerError).statusCode === 401) {
              showToast({ message: t('shared:validationError.sessionTimeout'), severity: 'error' });
            } else {
              datadogLogs.logger.error(`Network Error: cause: ${networkError.cause}, message: ${networkError.message}`);
            }
          }
          if (graphQLErrors) {
            graphQLErrors.forEach(({
              message, locations, path, extensions,
            }) => {
              // eslint-disable-next-line no-console
              console.log(
                `[GraphQL error]: Code: ${extensions?.code}, Message: ${message}, Operation: ${JSON.stringify(operation.query)}, Variables: ${JSON.stringify(
                  operation.variables,
                )}, Location: ${JSON.stringify(locations)}, Path: ${path}`,
              );
            });

            if (!operation.variables.skipErrorHandler && graphQLErrors.length > 0 && (graphQLErrors[0].message || operation.variables.errorMessage)) {
              let uniqueFields: any[] = [];
              const readableData: any = graphQLErrors[0].extensions?.readableData;
              const userMessage: any = readableData?.userMessage?.[language] ?? readableData?.userMessage?.en;
              const validationErrorWithFields = `${t('shared:validationError.unique')} ${uniqueFields
                .map((a) => a.path)
                .join(', ')
                .replace(/, ([^,]*)$/, ' or $1')}.`;

              if (readableData?.fields) {
                uniqueFields = readableData.fields.filter((f: any) => Object.prototype.hasOwnProperty.call(f, 'kind') && f.kind === 'unique');
              }

              if (graphQLErrors[0].extensions?.code === 'VALIDATION' && readableData?.code === 11000) {
                showToast({
                  message: readableData.userMessage ? userMessage : `${t('shared:validationError.unique')} ${Object.keys(readableData?.keyValue)[0]}.`,
                  severity: 'error',
                });
              } else if (graphQLErrors[0].extensions?.code === 'VALIDATION' && readableData?.fields && uniqueFields.length > 0) {
                showToast({
                  message: readableData.userMessage ? userMessage : validationErrorWithFields,
                  severity: 'error',
                });
              } else {
                showToast({
                  message: readableData.userMessage ? userMessage : operation.variables.errorMessage ?? graphQLErrors[0].message,
                  severity: 'error',
                });
              }
            }
          }
        }),
        setContext((_, { headers }) => ({
          headers: { ...headers, ...(tokenRef.current && { authorization: `Bearer ${tokenRef.current}` }) },
        })).concat(createHttpLink({ uri: process.env.REACT_APP_GRAPHQL_CLIENT })),
      ]),
    }),
    [showToast, t, language],
  );

  const appLogout = async () => {
    await auth0Logout(settings?.auth0RedirectUri);
    if (settings?.clientHostedLoginRedirectUri && settings?.clientHostedLoginURL) {
      await clientHostedLogout(settings?.clientHostedLoginURL, settings?.clientHostedLoginRedirectUri);
    }
    if (client) {
      await client.clearStore();
    }
    if (window && window.localStorage) {
      window.localStorage.clear();
    }
    tokenRef.current = '';
    setResetKey((prevKey) => prevKey + 1); // Increment the key to force re-mount
  };

  return (
    <AuthContext.Provider
      value={{
        authToken,
        setAuthToken,
        appLogout,
        settings,
        setSettings,
        permissions,
        setPermissions,
      }}
    >
      <ApolloProvider client={client}>
        <div key={resetKey}>{children}</div>
      </ApolloProvider>
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext);

export default OvApolloProvider;
