import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { kebabCase } from 'lodash/fp';
import { Close } from '@mui/icons-material';
import { gql, useMutation, useQuery } from '@apollo/client';
import { BankAccountSelectField } from 'ovComponents/3-pattern/bankAccountSelectField/bankAccountSelect';
import {
  Box, Grid, Link, Typography,
} from 'ovComponents/1-primative';
import {
  Button, DateField, Dialog, DialogContent, DialogFooter, DialogTitle, Form, IconButton, MenuItem, SelectField,
} from 'ovComponents/2-component';
import dayjs from 'dayjs';
import { useThemeTokens } from 'providers/themeTokenProvider';
import { AmountField } from 'ovComponents/3-pattern';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { BankAccount } from '../../../../../../interfaces';

const getAge = (dob?: Date): string => {
  if (!dob) return '0';
  const today = new Date();
  const birthDate = new Date(dob);
  let myAge = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    myAge -= 1;
  }
  return myAge.toString();
};

export const CREATE_SCHEDULED_INCOME_FUND_TRANSFER = gql`
  mutation createScheduledIncomeFundTransfer($input: CreateScheduledIncomeFundTransferInput!) {
    createScheduledIncomeFundTransfer(input: $input) {
      scheduledIncomeFundTransfer { id }
    }
  }
`;

export const UPDATE_SCHEDULED_INCOME_FUND_TRANSFER = gql`
  mutation updateScheduledIncomeFundTransfer($input: UpdateScheduledIncomeFundTransferInput!) {
    updateScheduledIncomeFundTransfer(input: $input) {
      scheduledIncomeFundTransfer { id }
    }
  }
`;

const FETCH_USER = gql`
  query fetchUser($userId: ObjectID!) {
    fetchUser(userId: $userId) {
      user {
        dateOfBirth
        language
        bankAccounts { id name bankAccountNumber }
      }
    }
  }
`;

export const PaymentInstructionModal = ({
  open, setOpen, action = 'create', userId, refetch, account, fundTitle,
}: {
  open: boolean; setOpen: (open: boolean) => void; action?: 'create' | 'edit'; userId: string; refetch?: any; account: any; fundTitle?: string;
}) => {
  const { t } = useTranslation(['paymentInstruction']);
  const { sys } = useThemeTokens();
  const { data, loading } = useQuery(FETCH_USER, { variables: { userId }, fetchPolicy: 'no-cache' });

  const [focused, setFocused] = useState<string[]>([]);
  const [totalAnnualWithdrawal, setTotalAnnualWithdrawal] = useState<string>(account?.scheduledIncomeFundTransfer?.amountPayableType ? 'Custom' : 'Minimum');
  const [frequency, setFrequency] = useState<string>(account?.scheduledIncomeFundTransfer?.frequency ?? '');
  const [amount, setAmount] = useState<number | undefined>(account?.scheduledIncomeFundTransfer?.annualAmountCents ?? undefined);
  const [amountPayableType, setAmountPayableType] = useState<string>(account?.scheduledIncomeFundTransfer?.amountPayableType ?? undefined);
  const [scheduledDate, setScheduledDate] = useState<string>(account?.scheduledIncomeFundTransfer?.scheduledDate ?? '');
  const [bankAccount, setBankAccount] = useState<BankAccount | null>({
    id: account?.scheduledIncomeFundTransfer?.bankAccount?.id ?? '',
    name: account?.scheduledIncomeFundTransfer?.bankAccount?.name ?? '',
    bankAccountNumber: account?.scheduledIncomeFundTransfer?.bankAccount?.bankAccountNumber ?? '',
    bankAccountNumberDisclosed: '',
    institutionNumber: '',
    transitNumber: '',
  });

  const decideWhoseAgeWasSelected = (): string => {
    if (!account?.scheduledIncomeFundTransfer?.dateOfBirth) return '';
    if (account?.affiliations?.[0] && account.affiliations[0]?.user?.dateOfBirth === account?.scheduledIncomeFundTransfer?.dateOfBirth) {
      return 'MY_SPOUSE';
    }
    return 'MY_AGE';
  };
  const [age, setAge] = useState<string>(decideWhoseAgeWasSelected());

  const getDOB = () => {
    if (age === 'MY_AGE') return loading ? '' : data.fetchUser.user.dateOfBirth;
    if (account.affiliations.length > 0) return account.affiliations[0].user.dateOfBirth;
    return '';
  };

  const [updateScheduledIncomeFundTransfer] = useMutation(UPDATE_SCHEDULED_INCOME_FUND_TRANSFER, {
    variables: {
      input: {
        scheduledIncomeFundTransferId: account?.scheduledIncomeFundTransfer?.id,
        dateOfBirth: getDOB(),
        subAccountId: account.subAccounts && account.subAccounts.length > 0 ? account.subAccounts[0].id : '',
        bankAccountId: bankAccount?.id,
        frequency,
        scheduledDate,
        annualAmountCents: totalAnnualWithdrawal.toLowerCase() === 'minimum' ? 0 : Number(amount),
        amountPayableType,
        taxOption: 'NO_TAX_ON_MINIMUM',
        specialTaxRate: 0,
        transferredAmountCents: 0,
      },
    },
    onCompleted: () => {
      refetch && refetch();
      setOpen(false);
    },
  });

  const [createScheduledIncomeFundTransfer] = useMutation(CREATE_SCHEDULED_INCOME_FUND_TRANSFER, {
    variables: {
      input: {
        dateOfBirth: getDOB(),
        subAccountId: account.subAccounts && account.subAccounts.length > 0 ? account.subAccounts[0].id : '',
        bankAccountId: bankAccount?.id,
        frequency,
        scheduledDate,
        annualAmountCents: totalAnnualWithdrawal.toLowerCase() === 'minimum' ? undefined : Number(amount),
        amountPayableType: totalAnnualWithdrawal.toLowerCase() === 'minimum' ? undefined : amountPayableType,
        taxOption: 'NO_TAX_ON_MINIMUM',
        transferredAmountCents: 0,
        specialTaxRate: 0,
      },
    },
    onCompleted: () => {
      refetch && refetch();
      setOpen(false);
    },
  });

  const submit = () => {
    if (!validate()) return;

    if (action === 'create') {
      createScheduledIncomeFundTransfer();
      return;
    }
    updateScheduledIncomeFundTransfer();
  };

  const validate = () => {
    const invalidFields: string[] = [];

    if (!age) invalidFields.push('age');
    if (!frequency) invalidFields.push('paymentFrequency');
    if (!scheduledDate) invalidFields.push('scheduledDate');
    if (!bankAccount?.id) invalidFields.push('bankAccount');
    if (!totalAnnualWithdrawal) invalidFields.push('totalAnnualWithdrawal');

    if (totalAnnualWithdrawal.toLowerCase() === 'custom') {
      if (!amount) invalidFields.push('amount');
      if (!amountPayableType) invalidFields.push('amountPayableType');
    }

    setFocused(invalidFields);
    return invalidFields.length === 0;
  };

  const customLabel = (label: string, hasMarginTop: boolean) => (
    <Typography display='flex' alignItems='center' colorVariant='variant' sx={{ mt: hasMarginTop ? 2 : 0 }}>
      {label}
      <InfoOutlinedIcon style={{ fontSize: '14px', marginLeft: sys.spacing.sm }} />
    </Typography>
  );

  return (
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth='sm' fullWidth>
      <DialogTitle sx={{ border: 'none', marginBottom: '0px' }}>
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          <Typography variant='headingLarge'>{t('paymentInstruction:modal.title')}</Typography>
          <IconButton data-testid='icon-btn' onClick={() => setOpen(false)}><Close /></IconButton>
        </Box>

        <Typography component={'span'} variant='bodyMedium' sx={{ fontWeight: '400', mt: 2 }}>
          <span>{t('paymentInstruction:modal.subtitle.1', { fundPrefix: fundTitle })}</span>
          <Link sx={{ mx: 0.5 }}>{t('paymentInstruction:modal.subtitle.2')}</Link>
          <span>{t('paymentInstruction:modal.subtitle.3')}</span>
        </Typography>
      </DialogTitle>

      <Form onSubmit={submit}>
        <DialogContent>
          {customLabel(t('paymentInstruction:modal.ageSelectLabel'), false)}
          <SelectField fullWidth label=''
            testId='select-age'
            value={age}
            onChange={(e: any) => setAge(e.target.value)}
            onBlur={() => setFocused([...focused, 'age'])}
            error={!age && focused.includes('age')}
          >
            {(!loading && data?.fetchUser?.user.dateOfBirth) && (
              <MenuItem value='MY_AGE' data-testid='select-age-my-age'>
                {t('paymentInstruction:modal.myAgeSelectOption', { age: getAge(data.fetchUser.user.dateOfBirth) })}
              </MenuItem>
            )}
            {(!loading && account.affiliations.length > 0) && (
              <MenuItem data-testid='select-age-spouse-age' value='SPOUSE_AGE'>
                {t('paymentInstruction:modal.mySpousesAgeSelectOption', { age: getAge(account.affiliations[0].user.dateOfBirth) })}
              </MenuItem>
            )}
          </SelectField>

          {customLabel(t('paymentInstruction:modal.totalAnnualWithdrawalLabel'), true)}
          <SelectField fullWidth label=''
            testId='total-withdrawal-amount'
            value={totalAnnualWithdrawal}
            onChange={(e: any) => setTotalAnnualWithdrawal(e.target.value)}
            onBlur={() => setFocused([...focused, 'totalAnnualWithdrawal'])}
            error={!totalAnnualWithdrawal && focused.includes('totalAnnualWithdrawal')}
          >
            <MenuItem data-testid='total-withdrawal-amount-option-minimum' value='Minimum'>
              {t('paymentInstruction:modal.totalAnnualWithdrawalMinimumAmountOption')}
            </MenuItem>
            <MenuItem data-testid='total-withdrawal-amount-option-custom' value='Custom'>
              {t('paymentInstruction:modal.totalAnnualWithdrawalCustomAmountOption')}
            </MenuItem>
          </SelectField>

          <Grid container spacing={2} sx={{ mt: 0.5 }}>
            {(totalAnnualWithdrawal.toLowerCase() === 'custom') && (
              <Grid item xs={6}>
                <AmountField
                  testId='total-annual-withdrawal'
                  label={t('paymentInstruction:modal.amountLabel')}
                  amount={amount ? (amount / 100).toString() : ''}
                  setAmount={(e) => setAmount(Number(e) * 100)}
                  onBlur={() => setFocused([...focused, 'amount'])}
                  error={!amount && focused.includes('amount')}
                />
              </Grid>
            )}
            <Grid item xs={6}>
              <SelectField fullWidth
                testId='payment-frequency'
                label={t('paymentInstruction:modal.paymentFrequencyLabel')}
                value={frequency}
                onChange={(e: any) => setFrequency(e.target.value)}
                onBlur={() => setFocused([...focused, 'paymentFrequency'])}
                error={!frequency && focused.includes('paymentFrequency')}
              >
                {['ANNUALLY', 'SEMI_ANNUALLY', 'QUARTERLY', 'MONTHLY'].map((value) => (
                  <MenuItem key={value} data-testid={`payment-frequency-option-${kebabCase(value)}`} value={value}>{t(`paymentInstruction:modal.${value}`)}</MenuItem>
                ))}
              </SelectField>
            </Grid>
            {(totalAnnualWithdrawal.toLowerCase() === 'custom') && (
              <Grid item xs={6}>
                {customLabel(t('paymentInstruction:modal.withdrawalTypeLabel'), false)}
                <SelectField fullWidth label=''
                  testId='withdrawal-type'
                  value={amountPayableType}
                  onChange={(e: any) => setAmountPayableType(e.target.value)}
                  onBlur={() => setFocused([...focused, 'amountPayableType'])}
                  error={!amountPayableType && focused.includes('amountPayableType')}
                >
                  <MenuItem data-testid='withdrawal-type-option-net' value='NET'>{t('paymentInstruction:modal.withdrawalTypeNetOption')}</MenuItem>
                  <MenuItem data-testid='withdrawal-type-option-gross' value='GROSS'>{t('paymentInstruction:modal.withdrawalTypeGrossOption')}</MenuItem>
                </SelectField>
              </Grid>
            )}
            <Grid item xs={6}>
              <DateField fullWidth
                dataTestId='start-date'
                disabled={loading}
                label={t('paymentInstruction:modal.startDateLabel')}
                value={scheduledDate}
                onChange={(date: any) => setScheduledDate(dayjs(date?.toString()).format('YYYY-MM-DD'))}
                onBlur={() => setFocused([...focused, 'scheduledDate'])}
                error={!scheduledDate && focused.includes('scheduledDate')}
              />
            </Grid>
          </Grid>

          <BankAccountSelectField
            data-testid='bank-account-select'
            sx={{ mt: 2 }}
            setBankAccount={setBankAccount}
            bankAccount={bankAccount}
            userId={userId}
            onBlur={() => setFocused([...focused, 'bankAccount'])}
            error={!bankAccount?.id && focused.includes('bankAccount')}
          />
          <Box bgcolor={sys.color.surfaceContainer} borderRadius={sys.borderRadius.md} padding={sys.spacing.xl} marginTop={sys.spacing.xxl}>
            <Typography component='span' variant='bodyMedium' sx={{ fontWeight: '400' }}>
              <p>{t('paymentInstruction:modal.note1')}</p>
              <p>{t('paymentInstruction:modal.note2')}</p>
            </Typography>
          </Box>
        </DialogContent>
        <DialogFooter>
          <Box display='flex' justifyContent='end' p={1}>
            <Button dataTestId='payment-instructions-done-btn' label={action === 'create' ? t('shared:done') : t('client:form.update')} type='submit' variant='tonal' />
          </Box>
        </DialogFooter>
      </Form>
    </Dialog>
  );
};
