import { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { Box } from '../../1-primative';
import {
  Dialog, DialogContent, DialogFooter, DialogTitle, IconButton,
  Button, Checkbox, Tooltip,
} from '../../2-component';
import { Column } from './downloadButton';

export interface Configuration {
  selectedColumns: Column[];
  useAdditionalFilter: boolean;
}

interface ConfigurationModalProps {
  open: boolean;
  columns: Column[];
  additionalColumns?: Column[];
  onClose: () => void;
  onExport: (configuration: Configuration) => void;
  children?: React.ReactNode;
  maxDateWindow?: number;
  onDateValidationError?: (message: string) => void;
  dateRangeConfig?: {
    fromDate: string | null;
    toDate: string | null;
    onFromDateChange: (date: string | null) => void;
    onToDateChange: (date: string | null) => void;
  };
  onColumnsChange?: (columns: string[]) => void;
}

const ConfigurationModal = ({
  open,
  columns,
  additionalColumns,
  onClose,
  onExport,
  children,
  maxDateWindow,
  onDateValidationError,
  dateRangeConfig,
  onColumnsChange,
}: ConfigurationModalProps) => {
  const { t } = useTranslation('components');
  const [selectedColumns, setSelectedColumns] = useState<number[]>(Object.keys(columns).map((key) => Number(key)));
  const [selectedAdditionalColumns, setSelectedAdditionalColumns] = useState<number[]>([]);
  const [useAdditionalFilter, setUseAdditionalFilter] = useState(false);

  const getSelectedColumns = () => {
    const mainColumnsArr = selectedColumns.sort((a, b) => a - b).map((index) => columns[index]);
    const additionalColumnsArr = additionalColumns?.length ? selectedAdditionalColumns.sort((a, b) => a - b).map((index) => additionalColumns[index]) : [];

    return [...mainColumnsArr, ...additionalColumnsArr];
  };

  const onModalClose = () => {
    setSelectedColumns(Object.keys(columns).map((key) => Number(key)));
    setSelectedAdditionalColumns([]);
    setUseAdditionalFilter(false);

    onClose();
  };

  const onExportLocal = () => {
    const selectedCols = getSelectedColumns();
    onExport({
      selectedColumns: selectedCols,
      useAdditionalFilter,
    });
    onColumnsChange?.(selectedCols.map((col) => col.gqlAlias));
    onModalClose();
  };

  const onToggle = (index: number, array: number[], setter: (array: number[]) => void) => () => {
    const currentIndex = array.indexOf(index);
    const newChecked = [...array];

    currentIndex === -1 ? newChecked.push(index) : newChecked.splice(currentIndex, 1);

    setter(newChecked);

    const selectedCols = getSelectedColumns();
    onColumnsChange?.(selectedCols.map((col) => col.gqlAlias));
  };

  const onSelectAllMainColumns = (value: boolean) => {
    const newColumns = value ? Object.keys(columns).map((key) => Number(key)) : [];
    setSelectedColumns(newColumns);

    const selectedCols = getSelectedColumns();
    onColumnsChange?.(selectedCols.map((col) => col.gqlAlias));
  };

  const onSelectAllAdditionalColumns = (value: boolean) => {
    const newColumns = value ? Object.keys(additionalColumns ?? []).map((key) => Number(key)) : [];
    setSelectedAdditionalColumns(newColumns);

    const selectedCols = getSelectedColumns();
    onColumnsChange?.(selectedCols.map((col) => col.gqlAlias));
  };

  const validateDateRange = (startDate: string | null | undefined, endDate: string | null | undefined) => {
    if (!startDate || !endDate || !maxDateWindow || !dateRangeConfig) return true;

    if (!dayjs(startDate).isValid() || !dayjs(endDate).isValid()) {
      onDateValidationError?.(t('exportConfiguration.invalidDateError'));
      return false;
    }

    const start = dayjs(startDate);
    const end = dayjs(endDate);
    const now = dayjs();
    const diffDays = end.diff(start, 'day');

    if (start.isAfter(now) || end.isAfter(now)) {
      onDateValidationError?.(t('exportConfiguration.futureDateError'));
      return false;
    }

    if (start.isAfter(end)) {
      onDateValidationError?.(t('exportConfiguration.startDateAfterEndError'));
      return false;
    }

    if (diffDays > maxDateWindow) {
      onDateValidationError?.(t('exportConfiguration.dateRangeError', { days: maxDateWindow }));
      return false;
    }
    return true;
  };

  return (
    <Dialog onClose={onModalClose} open={open} fullWidth>
      <DialogTitle>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          {t('exportConfiguration.title')}
          <IconButton onClick={onModalClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent>
        {!!additionalColumns?.length && <Checkbox onChange={(e) => setUseAdditionalFilter(e)} label={t('exportConfiguration.useAdditionalFilter')} />}

        <Box display='flex' justifyContent='space-between'>
          <Box>
            <Tooltip title={t('exportConfiguration.selectAllColumns')} placement='top'>
              <Checkbox checked onChange={(e) => onSelectAllMainColumns(e)} label={t('exportConfiguration.mainColumns')} />
            </Tooltip>
            <Box ml={1}>
              {columns.map((value, index) => (
                <Box key={index}>
                  <Checkbox
                    checked={selectedColumns.indexOf(index) !== -1}
                    label={value.header}
                    onChange={onToggle(index, selectedColumns, setSelectedColumns)}
                  />
                  {value.header === t('productShelf.exportHeaders.historyDate')
                    && selectedColumns.indexOf(index) !== -1 && (
                      <Box ml={3} mt={1}>
                        {children}
                      </Box>
                  )}
                </Box>
              ))}
            </Box>
          </Box>
          {!!additionalColumns?.length && (
            <Box>
              <Tooltip title={t('exportConfiguration.selectAllColumns')} placement='top'>
                <Checkbox onChange={(e) => onSelectAllAdditionalColumns(e)} label={t('exportConfiguration.additionalColumns')} />
              </Tooltip>

              <Box ml={1}>
                {additionalColumns?.map((value, index) => (
                  <Box key={index}>
                    <Checkbox
                      checked={selectedAdditionalColumns.indexOf(index) !== -1}
                      onChange={onToggle(index, selectedAdditionalColumns, setSelectedAdditionalColumns)}
                      label={value.header}
                    />
                  </Box>
                ))}
              </Box>
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogFooter>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            data-testid='confirmButton'
            type='submit'
            onClick={() => {
              if (validateDateRange(dateRangeConfig?.fromDate, dateRangeConfig?.toDate)) {
                onExportLocal();
              }
            }}
            label={t('exportConfiguration.exportButton')}
          />
        </Box>
      </DialogFooter>
    </Dialog>
  );
};

export default ConfigurationModal;
