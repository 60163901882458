import { gql, useQuery } from '@apollo/client';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../../providers/userContextProvider';
import { BillingSchedule, BillingScheduleStates } from '../../../interfaces';
import {
  MenuItem, SelectField,
} from '../../2-component';
import { useLocalization } from '../../../util/useLocalization';

export const FETCH_BILLING_SCHEDULES = gql`
  query fetchBillingSchedules($input: FetchBillingSchedulesInput!) {
    fetchBillingSchedules(input: $input) {
      billingSchedules {
        id
        frequency
        nextBillingDate
        organization {
          id
        }
      }
    }
  }
`;

export const BillingScheduleSelect = ({
  setValue, value, label, disabled = false, size, includeAnyOption = false, organizationId, showInherited,
}: {
  setValue: (event: any) => void, value: string, label: string, disabled?: boolean, size?: 'small' | 'medium',
  includeAnyOption?: boolean, organizationId?: string, showInherited?: boolean,
}) => {
  const { t } = useTranslation(['feeAndBilling', 'components']);
  const { activeOrganization } = useContext(UserContext);
  const { localizedDate } = useLocalization();
  const { data } = useQuery(FETCH_BILLING_SCHEDULES, {
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        filter: {
          organizationId: organizationId || activeOrganization.id,
          states: BillingScheduleStates.ACTIVE,
        },
        pagination: { perPage: 25 },
      },
    },
  });

  return (
    <SelectField
      value={value === '' ? 'any' : value}
      label={label}
      fullWidth
      onChange={(event: any) => { setValue(event.target.value === 'any' ? '' : event.target.value); }}
      disabled={disabled}
      size={size}
    >
      { includeAnyOption && (
        <MenuItem value="any">{t('components:any')}</MenuItem>
      )}
      { showInherited && (
        <MenuItem key="inherited" value="inherited">
          {t('feeAndBilling:feeGrid.inherited')}
        </MenuItem>
      )
      }
      { data?.fetchBillingSchedules.billingSchedules.map((x: BillingSchedule) => (
          <MenuItem key={ x.id } value={ x.id }>
            {`${t(`feeAndBilling:billingSchedule.frequencies.${x.frequency}`)} - ${localizedDate(x.nextBillingDate)}` }
          </MenuItem>
      ))
      }
    </SelectField>
  );
};

export default BillingScheduleSelect;
