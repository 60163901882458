import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { uniq } from 'lodash/fp';
import { Add } from '@mui/icons-material';
import { useLocation, useParams } from 'react-router-dom';
import {
  Typography, Box, Grid, Skeleton,
} from '../../1-primative';

import { useClientContext } from '../../../pages/client';
import { useHouseholdContext } from '../../../pages/household';
import { PageObjectType } from '../../5-page';
import SubAccountItem from '../subAccountItem/subAccountItem';
import { EmptyState, IconButton } from '../../2-component';
import { CreateSubAccountWorkflow } from '../createSubAccountWorkflow/createSubAccountWorkflow';
import { UserContext } from '../../../providers/userContextProvider';
import { CreateSubAccountWizard } from '../../../components/wizards/createSubAccountWizard/wizard';
import { getUserIdFromPath } from '../../../util/getUserIdFromPath';

export const FETCH_SUB_ACCOUNTS = gql`
  query fetchSubAccounts($input: FetchSubAccountsInput!) {
    fetchSubAccounts(input: $input) {
      totalCount
      subAccounts {
        id
        state
        statistics { marketValueCents simpleReturnAmount simpleReturnPercent }
        theme { key translatedName { en fr } iconKey }
        goal { name }
        account {
          id
          state
          type
          householdClientGroup { id name }
          user { id firstName }
        }
        allowClientDeposits
      }
    }
  }
`;

export const SubAccounts = ({
  objectType, objectId, singleColumn = false, options,
}: {
  objectType: PageObjectType, objectId: string, singleColumn?: boolean, options: any,
}) => {
  const { t } = useTranslation(['client']);
  const [subAccounts, setSubAccounts] = useState<any[]>([]);
  const [open, setOpen] = useState(false);
  const { pathname: path } = useLocation();
  const { userId: paramsUserId, clientGroupId } = useParams();
  const clientContext = useClientContext();
  const householdContext = useHouseholdContext();
  const { activeEntity } = useContext(UserContext);

  const userId = paramsUserId ?? activeEntity?.id;

  const { loading, refetch } = useQuery(FETCH_SUB_ACCOUNTS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        filter: {
          ...([PageObjectType.GOAL].includes(objectType) && {
            goalId: objectId,
            userId: getUserIdFromPath({ userId: paramsUserId, clientGroupId, path }),
          }),
          ...([PageObjectType.ACCOUNT].includes(objectType) && { accountId: objectId }),
        },
        pagination: { perPage: 1000 },
      },
    },
    onCompleted: (e) => setSubAccounts([...e.fetchSubAccounts.subAccounts]),
  });

  const accountsSorted = subAccounts.filter((x: any) => x.state !== 'INACTIVE').sort((a, b) => (b.statistics?.marketValueCents ?? 0) - (a.statistics?.marketValueCents ?? 0));

  const householdLinkedAccounts = subAccounts.filter((a) => a.account.householdClientGroup);
  const householdIds = uniq(householdLinkedAccounts.map((a) => a.householdClientGroup?.id));
  const indexedHouseholdIds = Object.fromEntries(householdIds.map((id, index) => [id, index === 0 ? 'HOUSEHOLD' : index + 1]));

  const getTag = (account: any) => {
    if (clientContext && objectType !== PageObjectType.HOUSEHOLD) {
      if (!account.householdClientGroup) return undefined;

      return {
        sequenceNumber: indexedHouseholdIds[account.householdClientGroup.id],
        text: `${account.householdClientGroup.name} ${t('shared:household')}`,
      };
    }

    if (householdContext) {
      return {
        sequenceNumber: householdContext.indexedMembers[account.user.id],
        text: account.user.firstName ?? account.user.id,
      };
    }

    return undefined;
  };

  return (
    <>
      <Box display="flex" justifyContent="space-between" marginBottom="9px">
        <Box display="flex" alignItems='center'>
          <Typography variant="headingSmall">{t('subAccountsSummary.header')}</Typography>
        </Box>
        <Box>
          {options.allowNewSubAccountsToBeCreated && (
            <>
              <IconButton data-testid='add-subaccount-button' onClick={() => setOpen(true)}><Add /></IconButton>
              {open && (
                (clientGroupId && !paramsUserId) ? (
                  <CreateSubAccountWizard forId={objectId} forObject={objectType as 'GOAL' | 'ACCOUNT'} handleClose={() => setOpen(false)} />
                ) : (
                  <CreateSubAccountWorkflow open={open} setOpen={setOpen} objectId={userId || ''} objectType={objectType} refetch={refetch} goalId={objectId} />
                )
              )}
            </>
          )}
        </Box>
      </Box>
      <Grid container spacing={2}>
        {loading ? (
          <Grid item xs={12} sm={singleColumn ? 12 : 6}><Skeleton width='100%' height='114px' variant='rectangular' /></Grid>
        ) : accountsSorted.map((account) => (
          <Grid item xs={12} sm={singleColumn || accountsSorted.length === 1 ? 12 : 6} key={account.id}>
            <SubAccountItem objectType={objectType} subAccount={account} tag={getTag(account)} />
          </Grid>
        ))}
        {!loading && accountsSorted.length === 0 && (
          <Grid item xs={12}>
            <EmptyState>
              <Typography variant='bodyLarge'>{t('subAccountsSummary.noSubAccounts')}</Typography>
            </EmptyState>
          </Grid>
        )}
      </Grid>
  </>
  );
};
