import { useContext } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { Box, Skeleton } from '../../../1-primative';
import {
  Table, TableCell, Dialog, DialogTitle,
  TableRow, TableBody,
  TablePagination, CircularProgress,
  TableHeadCell,
} from '../../../2-component';
import DownloadModal from '../../../4-module/reportBuilder/components/downloadModal';
import { UserContext } from '../../../../providers/userContextProvider';
import { usePageState } from '../../../../util/usePageState';
import ReportTableCell from '../../../4-module/reportBuilder/components/reportTableCell';

const GENERATE_REPORT = gql`
  query generateReport($input: GenerateReportInput!) {
    generateReport(input: $input) {
      data
      columns
      totalCount
    }
  }
`;

const ReportModal = ({ open, setOpen, activeWidget }: { open: boolean, setOpen: (open: boolean) => void, activeWidget: any }) => {
  const { activeOrganization } = useContext(UserContext);
  const [sortField, setSortField] = usePageState(activeWidget.sortField, 'sort');
  const [sortDesc, setSortDesc] = usePageState(activeWidget.sortDesc, 'desc');
  const [page, setPage] = usePageState(0, 'page');
  const [pageSize, setPageSize] = usePageState(15, 'pageSize');
  const { t } = useTranslation(['customReports']);

  const { loading, data, previousData } = useQuery(GENERATE_REPORT, {
    variables: {
      input: {
        filters: [{
          field: 'organization',
          comparison: 'EQUALS',
          value: activeOrganization.id,
        }, ...activeWidget.filters.map((x: any) => ({ ...x, __typename: undefined }))],
        columns: activeWidget.columns,
        type: activeWidget.reportType,
        pagination: { perPage: pageSize, page: page + 1 },
        sorting: { sortField: activeWidget.reportType === 'DAILY_STATS' ? sortField : 'createdAt', sortDesc },
      },
    },
  });

  const handleRequestSort = (
    column: string,
  ) => {
    setSortDesc(!sortDesc);
    setSortField(column);
  };

  return (
    <Dialog open={open} onClose={() => {
      setOpen(false);
      setSortField('createdAt');
      setSortDesc(true);
      setPage(0);
    }} maxWidth='md' fullWidth>
      <DialogTitle>
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          {activeWidget.title}
          <DownloadModal
            type={activeWidget.reportType}
            columns={activeWidget.columns}
            filters={activeWidget.filters.map((x: any) => ({ ...x, __typename: undefined }))}
            sortField={sortField}
            sortDesc={sortDesc}
          />
        </Box>
      </DialogTitle>
      {loading && !previousData ? (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress sx={{ m: 18 }} />
        </Box>
      ) : (
        <>
          <Table sx={{ minWidth: 650, display: 'table', overflowX: 'scroll' }} aria-label="table">
            <TableBody>
              <TableRow>
                {(data || previousData)?.generateReport.columns.map((column: string) => (
                  <TableHeadCell
                    key={column}
                    sortDirection={sortField === column ? (sortDesc ? 'desc' : 'asc') : false}
                    isSortable
                    onClick={() => handleRequestSort(column)}
                  >
                    {t(`column.${column}`)}
                  </TableHeadCell>
                ))}
              </TableRow>
              {loading && !previousData && [...Array(pageSize)].map((x: any, i: number) => (
                <TableRow key={i}>
                  {(data || previousData)?.generateReport.columns.map((column: string) => (
                    <TableCell key={column}>
                      <Skeleton />
                    </TableCell>
                  ))}
                </TableRow>
              ))}
              {(data || previousData)?.generateReport?.data?.map((row: string[], index: number) => (
                <TableRow
                  key={index}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 }, textDecoration: 'none' }}
                >
                  {row.map((column: string, index2: number) => <ReportTableCell
                    key={index2} content={column} columnName={(data || previousData)?.generateReport.columns[index2]}
                  />)}
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            count={((data || previousData)?.generateReport?.totalCount ?? 0)}
            onPageChange={(_e, newPage) => setPage(newPage)}
            page={page}
            rowsPerPage={pageSize}
            onRowsPerPageChange={(e) => setPageSize(parseInt(e.target.value, 10))}
            sx={{
              p: 1,
              textAlign: 'right',
              display: 'flex',
              justifyContent: 'end',
            }}
          />
        </>
      )}
    </Dialog>
  );
};

export default ReportModal;
