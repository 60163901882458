import { useContext, useState } from 'react';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { validateUrl } from '../../../../util';
import {
  Button, Dialog, DialogContent, DialogFooter, DialogTitle,
  IconButton,
} from '../../../2-component';
import {
  CREATE_FILE_DOCUMENT,
  FETCH_DOCUMENTS, FETCH_FILE_UPLOAD_URL,
} from '../../../3-pattern/addBankAccount/addBankAccountManually/addBankAccountManually';
import { TranslatedString } from '../../../../interfaces';
import { useThemeTokens } from '../../../../providers/themeTokenProvider';
import { UserContext } from '../../../../providers/userContextProvider';
import { CreateAndEditNewsAndInsightsForm } from './createAndEditNewsAndInsightsForm';

export const CREATE_ARTICLE = gql`
  mutation createArticle($input: CreateArticleInput!) {
    createArticle(input: $input) {
      article {
        id
      }
    }
  }
`;

export const UPDATE_ARTICLE = gql`
  mutation updateArticle($input: UpdateArticleInput!) {
    updateArticle(input: $input) {
      article {
        id
      }
    }
  }
`;

export interface Article {
  id?: string,
  articleUrl: string,
  translatedName: TranslatedString,
  translatedDescription: TranslatedString
}

export const CreateArticle = ({
  open, setOpen, refetch, loading, transitionArticle,
}: {
  open: boolean, setOpen: (open: boolean) => void, refetch: () => void, loading: boolean, transitionArticle: (input: any) => void,
}) => {
  const { t } = useTranslation('newsAndInsight');
  const { activeOrganization, userContext } = useContext(UserContext);
  const [newArticle, setNewArticle] = useState<Article>({
    id: '',
    articleUrl: '',
    translatedName: {
      en: '',
      fr: '',
    },
    translatedDescription: {
      en: '',
      fr: '',
    },
  });
  const [articleImageFile, setArticleImageFile] = useState<File>();
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const { sys } = useThemeTokens();

  const [fetchFileUploadUrl] = useLazyQuery(FETCH_FILE_UPLOAD_URL, { fetchPolicy: 'no-cache' });
  const [createFileDocument] = useMutation(CREATE_FILE_DOCUMENT);

  const doUpload = async (uploadingFile: File, articleId: string) => {
    const createFileInput: any = {
      objectType: 'ORGANIZATION',
      objectId: activeOrganization.id,
      fileName: uploadingFile?.name,
      type: 'ARTICLE',
    };

    /* (1) fetch the S3 upload URL from backend */
    const queryResult = await fetchFileUploadUrl({ variables: { input: { ...createFileInput, userId: userContext.id } } });
    const uploadUrl = queryResult?.data?.fetchFileUploadUrl.temporarySignedURL;
    if (!uploadUrl || queryResult?.error) {
      // showToast({ severity: 'error', message: 'File upload failed' });
      return;
    }
    /* (2) do the upload */
    try {
      // setUploadPending(true);
      const uploaded: Response = await fetch(new Request(uploadUrl, { method: 'PUT', body: uploadingFile }));
      if (!uploaded.ok) throw (new Error(`${uploaded.status} ${uploaded.statusText}`));
    } catch (e: any) {
      // showToast({ severity: 'error', message: 'File upload failed' });
      return;
    } finally {
      // setUploadPending(false);
    }

    /* (3) create the fileDocument within backend */
    createFileInput.name = uploadingFile.name;
    createFileInput.sourceId = articleId;
    createFileInput.mediaType = uploadingFile.type;
    createFileInput.permissionType = 'PUBLIC';
    createFileInput.sourceType = 'ARTICLE';

    try {
      await createFileDocument({
        variables: { input: createFileInput },
        refetchQueries: [FETCH_DOCUMENTS],
      });
    } catch (error) {
      // showToast({ severity: 'error', message: 'File upload failed' });
    }
    // showToast({ severity: 'success', message: `Document uploaded successfully: ${uploadingFile.name}` });
  };

  const [createArticle, { loading: createArticleLoading }] = useMutation(CREATE_ARTICLE, {
    variables: {
      input: {
        articleUrl: newArticle.articleUrl,
        translatedName: {
          en: newArticle.translatedName.en,
          fr: newArticle.translatedName.fr,
        },
        translatedDescription: {
          en: newArticle.translatedDescription.en,
          fr: newArticle.translatedDescription.fr,
        },
        organizationId: activeOrganization.id,
      },
    },
    onCompleted: async (response) => {
      if (articleImageFile) {
        doUpload(articleImageFile, response.createArticle.article.id).then();
      }
      handleCreateArticleDialogClose();
    },
  });

  const [errorFields, setErrorFields] = useState<string[]>([]);
  const resetErrorFields = () => {
    setErrorFields([]);
  };
  const handleCreateArticleDialogClose = () => {
    // reset error fields so when the dialog is opened again, no field has error lines
    resetErrorFields();
    resetFileImageAndSrc();
    setOpen(false);
    setNewArticle({
      id: '',
      articleUrl: '',
      translatedName: {
        en: '',
        fr: '',
      },
      translatedDescription: {
        en: '',
        fr: '',
      },
    });
  };

  const handleFileInputChange = (file: File) => {
    setImageSrc(URL.createObjectURL(file));
    setArticleImageFile(file);
  };

  const resetFileImageAndSrc = () => {
    setArticleImageFile(undefined);
    setImageSrc(null);
  };

  const handleValidationClear = (value: string, fieldName: string) => {
    if (value.length > 0 && errorFields.includes(fieldName)) {
      const removedField = errorFields.filter((field) => field !== fieldName);
      setErrorFields([...removedField]);
    }
  };

  const hasInValidFields = (): boolean => {
    const fieldsWithError: string[] = [];
    if (!newArticle.translatedName.en) {
      fieldsWithError.push('translatedName');
    }
    if (!validateUrl(newArticle.articleUrl)) {
      fieldsWithError.push('articleUrl');
    }
    if (!newArticle.translatedDescription.en) {
      fieldsWithError.push('translatedDescription');
    }
    setErrorFields((prevState) => [...prevState, ...fieldsWithError]);
    return fieldsWithError.length > 0;
  };

  const resetNewArticleField = () => {
    setNewArticle({
      articleUrl: '',
      translatedName: {
        en: '',
        fr: '',
      },
      translatedDescription: {
        en: '',
        fr: '',
      },
    });
  };

  const publishArticle = async () => {
    if (hasInValidFields()) return;
    const response = await createArticle().then();
    transitionArticle({
      variables: {
        input: {
          articleId: response.data.createArticle.article.id,
          transition: 'activate',
        },
      },
    });
    refetch();
    setOpen(false);
  };

  const saveAsDraft = async () => {
    if (hasInValidFields()) return;
    await createArticle();
    refetch();
    setOpen(false);
    resetNewArticleField();
  };

  return (
    <Dialog maxWidth="md" fullWidth open={open} onClose={handleCreateArticleDialogClose}>
      <DialogTitle display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
        {t('newsAndInsight:articleConfiguration')}
        <IconButton onClick={handleCreateArticleDialogClose}>
          <CloseRoundedIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <CreateAndEditNewsAndInsightsForm
            imageSrc={imageSrc}
            resetFileImageAndSrc={resetFileImageAndSrc}
            handleFileInputChange={handleFileInputChange}
            article={newArticle}
            setArticleToUpdate={setNewArticle}
            errorFields={errorFields}
            handleValidationClear={handleValidationClear}
        />
      </DialogContent>
      <DialogFooter>
        <Button
            sx={{ backgroundColor: `${sys.color.primaryVariant} !important`, color: `${sys.color.onPrimaryVariant} !important` }}
            disabled={loading}
            label={t('shared:save')}
            onClick={saveAsDraft}
            dataTestId='create-article-save-button'
        />
        <Button
            sx={{ backgroundColor: `${sys.color.primary} !important`, color: `${sys.color.onPrimary} !important` }}
            disabled={createArticleLoading}
            label={t('shared:publish')}
            onClick={publishArticle}
            dataTestId='create-article-publish-button'
        />
      </DialogFooter>
    </Dialog>
  );
};
