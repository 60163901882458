import Holidays from 'date-holidays';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

const checkBusinessDay = ( // pulled from trading-service
  date: Date,
  hd: Holidays,
): boolean => ![0, 6].includes(date.getUTCDay()) // weekends
    && !hd.isHoliday(date);

export const isBusinessDay = ({ date, country = 'CA' }: { date: Date, country: string }) => {
  if (!date) return false;
  const hd = new Holidays(country, { types: ['bank', 'public'], timezone: 'UTC' });

  return checkBusinessDay(date, hd);
};

export const isBusinessDayAndAllowWeekend = ({ date, country = 'CA' }: { date: Date, country: string }) => {
  if (!date) return false;
  const hd = new Holidays(country, { types: ['bank', 'public'], timezone: 'UTC' });
  return !hd.isHoliday(date);
};

const addOrSubtractBusinessDays = (date: Date, action: 'add' | 'subtract', days: number, country = 'CA'): Date => {
  const hd = new Holidays(country, { types: ['bank', 'public'], timezone: 'UTC' });
  let daysToIterate = days;
  let day = dayjs.utc(date).add(1, 'days');

  while (daysToIterate > 0) {
    day = day[action](1, 'day');
    if (checkBusinessDay(day.toDate(), hd)) {
      daysToIterate -= 1;
    }
  }

  while (!checkBusinessDay(day.toDate(), hd)) {
    day = day.add(1, 'day');
  }

  return day.toDate();
};

export const addBusinessDays = (date: Date, days: number, country = 'CA')
: Date => addOrSubtractBusinessDays(date, 'add', days, country);

export const subtractBusinessDays = (date: Date, days: number, country = 'CA')
: Date => addOrSubtractBusinessDays(date, 'subtract', days, country);
