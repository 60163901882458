import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Skeleton } from '../../1-primative';
import {
  Table,
  TableRow, TableCell, TableBody, Pagination, TextField,
  Badge, CardContent, TableHeadCell,
  Card,
} from '../../2-component';
import { usePermissions, UserContext } from '../../../providers/userContextProvider';
import { User } from '../../../interfaces';
import { generateClientNameString } from '../../../util';
import { usePageState } from '../../../util/usePageState';
import { useLocalization } from '../../../util/useLocalization';

const FETCH_USERS = (permissions: string[]) => gql`
  query fetchUsers($input: FetchUsersInput!) {
    fetchUsers(input: $input) {
      users {
        id
        type
        ${permissions.includes('read:client_low_risk_pii') ? 'firstName' : ''}
        ${permissions.includes('read:client_low_risk_pii') ? 'lastName' : ''}
        entityName
        ${permissions.includes('read:client_low_risk_pii') ? 'complianceIssueSource' : ''}
        organization {
          name
        }
        createdAt
        completedAt
      }
      totalCount
    }
  }
`;

export const ComplianceReview = () => {
  const { permissions } = usePermissions();
  const { activeOrganization } = useContext(UserContext);
  const { t } = useTranslation(['reports']);
  const { localizedDateTime } = useLocalization();
  const navigate = useNavigate();
  const [page, setPage] = usePageState(1, 'page');
  const [searchText, setSearchText] = usePageState('', 'q');
  const [pageSize, setPageSize] = usePageState(15, 'ps');

  const {
    loading, data, previousData,
  } = useQuery(FETCH_USERS(permissions), {
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        filter: {
          searchText, complianceState: 'NEEDS_REVIEW', iDVerified: true, organizationId: activeOrganization.id,
        },
        pagination: {
          sortField: 'createdAt', sortDesc: false, perPage: pageSize, offSet: (page - 1) * pageSize,
        },
      },
    },
  });

  return (
    <Card loading={loading}>
      <Box>
        <CardContent>
          <Box display='flex' justifyContent='space-between' flexWrap='wrap'>
            <TextField
              value={searchText}
              onChange={(event: any) => {
                setSearchText(event.target.value);
                setPage(1);
              }}
              leadingIcon='search'
            />
          </Box>
        </CardContent>
        <Box sx={{ overflowX: 'auto' }}>
          <Table sx={{ minWidth: 650 }} aria-label="table">
            <TableBody>
              <TableRow>
                {permissions.includes('read:client_low_risk_pii') ? (
                  <>
                    <TableHeadCell>{t('table.name')}</TableHeadCell>
                  </>
                ) : (
                  <TableHeadCell>{t('table.id')}</TableHeadCell>
                )}
                <TableHeadCell>
                  {t('table.complianceIssue')}
                </TableHeadCell>
                <TableHeadCell>
                  {t('table.createdAt')}
                </TableHeadCell>
                <TableHeadCell>
                  {t('table.completedAt')}
                </TableHeadCell>
                <TableHeadCell
                  right
                >
                  {t('table.organization')}
                </TableHeadCell>
              </TableRow>
              { loading && !previousData && [...Array(15)].map((x: any, i: number) => (
                <TableRow key={i}>
                  <TableCell><Skeleton width='100%' /></TableCell>
                  {permissions.includes('read:client_low_risk_pii') && (
                    <>
                      <TableCell><Skeleton width='100%' /></TableCell>
                    </>
                  )}
                  <TableCell><Skeleton width='100%' /></TableCell>
                  <TableCell><Skeleton width='100%' /></TableCell>
                  <TableCell><Skeleton width='100%' /></TableCell>
                </TableRow>
              ))}
              {(data || previousData)?.fetchUsers?.users?.map((user: User) => (
                <TableRow
                  hover
                  key={user.id}
                  sx={{ textDecoration: 'none', cursor: 'pointer' }}
                  onClick={() => navigate(`/clients/${user.id}`)}
                >
                  {permissions.includes('read:client_low_risk_pii') ? (
                    <>
                      <TableCell>
                        {generateClientNameString(user)}
                      </TableCell>
                    </>
                  ) : (
                    <TableCell>
                      {user.id}
                    </TableCell>
                  )}
                  <TableCell>{user.complianceIssueSource}</TableCell>
                  <TableCell>{localizedDateTime(user.createdAt)}</TableCell>
                  <TableCell>
                    {user.completedAt ? localizedDateTime(user.completedAt) : (
                      <Badge label={t('table.notCompleted')} color='negative' />
                    ) }
                  </TableCell>
                  <TableCell right>{user.organization?.name}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
        <Pagination
          count={Math.ceil(((data || previousData)?.fetchUsers?.totalCount ?? 0) / pageSize)}
          page={page}
          perPage={pageSize}
          onChangePerPage={(newPageSize) => setPageSize(newPageSize)}
          onChange={(_e, newPage) => setPage(newPage)}
          sx={{
            p: 1,
            textAlign: 'right',
            '.MuiPagination-ul': {
              justifyContent: 'end',
            },
          }}
        />
      </Box >
    </Card>
  );
};

export default ComplianceReview;
