import { useNavigate, useParams } from 'react-router-dom';
import { gql, useQuery, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { BillingCycle, BillingCycleStates } from '../../../interfaces/billingCycle';
import { UserContext, usePermissions } from '../../../providers/userContextProvider';
import { usePageState } from '../../../util/usePageState';
import { GroupedBilling } from '../../../interfaces/groupedBilling';
import { Box, Skeleton } from '../../1-primative';
import {
  Table, TableHeadCell,
  TableRow, TableBody, Pagination, Button, Badge,
  CardContent, Card, DateField,
  TableCell,
} from '../../2-component';
import {
  ConfirmationModal, BillingScheduleSelect, FeeGridSelect,
  FilterModal,
} from '../../3-pattern';
import { useGlobalToast } from '../../../providers/globalToastProvider';
import { FeeReportRow, FeeReportDataRow } from './components/feeReportRow';
import DownloadBillingCycle from './components/downloadBillingCycle';

dayjs.extend(utc);

const SUB_ACCOUNT_BILLING_RESPONSE = `
  id
  billingCycle { id }
  subAccount {
    id
    statistics { moneyAvailableCents }
    account {
      id
      type
      custodianAccountNumber
    }
    goal { id type }
    financialProduct { id translatedName { en } }
  }
  feeCents
  salesTaxCents
  adjustedFeeCents
  adjustedSalesTaxCents
  projectedFeeAndTaxCents
  feePaymentAccount {
    id
    type
    custodianAccountNumber
    statistics { moneyAvailableCents }
  }
  marketValueCentsOnLastDay
  chargeableMarketValueCentsOnLastDay
  feeTier { id name }
  billingSchedule { id frequency }
  billingDate
  startDate
  endDate
`;

export const TRANSITION_BILLING_CYCLE = gql`
  mutation transitionBillingCycle($input: TransitionBillingCycleInput!) {
    transitionBillingCycle(input: $input) {
      billingCycle {
        id
      }
    }
  }
`;

export const FETCH_GROUPED_BILLINGS = gql`
  query fetchGroupedBillings($input: FetchGroupedBillingsInput!) {
    fetchGroupedBillings(input: $input) {
      totalCount
      groupedBillings {
        id
        clientGroup { id type name organization { id name } }
        user { id firstName lastName entityName organization { id name } }
        accounts {
          account { id type }
          subAccountBillings {
            ${SUB_ACCOUNT_BILLING_RESPONSE}
          }
        }
        users {
          user { id firstName lastName entityName }
          accounts {
            account { id type }
            subAccountBillings {
              ${SUB_ACCOUNT_BILLING_RESPONSE}
            }
          }
        }
      }
    }
  }
`;

export const FETCH_BILLING_CYCLE = gql`
  query fetchBillingCycle ($billingCycleId: ObjectID!) {
    fetchBillingCycle (billingCycleId: $billingCycleId) {
      billingCycle { id state endDate organization { id } }
    }
  }
`;

export const FeeReport = () => {
  const { permissions } = usePermissions();
  const navigate = useNavigate();
  const { activeOrganization } = useContext(UserContext);
  const { showToast } = useGlobalToast();
  const { t } = useTranslation(['feeAndBilling', 'shared', 'components']);
  const { billingCycleId } = useParams();
  const [userId, setUserId] = usePageState('', 'userId');
  const [organizationId, setOrganizationId] = usePageState(activeOrganization.id ?? '', 'org');
  const [billingScheduleId, setBillingScheduleId] = usePageState('', 'billingSchedule');
  const [feeTierId, setFeeTierId] = usePageState('', 'feeTier');
  const [dateAfter, setDateAfter] = usePageState('', 'dateAfter');
  const [dateBefore, setDateBefore] = usePageState('', 'dateBefore');
  const [page, setPage] = usePageState(1, 'page');
  const [billingCycleInfo, setBillingCycleInfo] = useState<BillingCycle | null>(null);
  const [approveConfirmationOpen, setApproveConfirmationOpen] = useState(false);
  const [transitionBillingCycle] = useMutation(TRANSITION_BILLING_CYCLE);

  useEffect(() => {
    if (activeOrganization.id) {
      setOrganizationId(activeOrganization.id);
      setFeeTierId('');
      setBillingScheduleId('');
      setUserId('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeOrganization.id]);

  const pageSize = 15;
  const {
    loading, data, previousData,
  } = useQuery(FETCH_GROUPED_BILLINGS, {
    variables: {
      input: {
        filter: {
          organizationId: organizationId || activeOrganization.id,
          feeTierIds: feeTierId || undefined,
          billingScheduleIds: billingScheduleId || undefined,
          userIds: userId || undefined,
          billingDateAfter: dateAfter || undefined,
          billingDateBefore: dateBefore || undefined,
          billingCycleIds: billingCycleId || undefined,
        },
        pagination: { perPage: pageSize, offSet: (page - 1) * pageSize },
      },
    },
  });

  const { loading: billingCycleLoading, refetch } = useQuery(FETCH_BILLING_CYCLE, {
    variables: { billingCycleId },
    onCompleted: (res: any) => {
      setBillingCycleInfo(res.fetchBillingCycle?.billingCycle);
    },
    skip: !billingCycleId,
  });

  const stateColor = (state?: BillingCycleStates) => (state && [BillingCycleStates.APPROVED, BillingCycleStates.PROCESSED].includes(state) ? 'positive' : 'warning');

  return (
    <Card loading={billingCycleLoading || loading}>
      <CardContent>
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          <Box>
            {billingCycleId && billingCycleInfo?.state && (
              <>
                <Badge
                  color={stateColor(billingCycleInfo?.state)}
                  label={(t(`feeAndBilling:billingCycle.${billingCycleInfo?.state}`)).toUpperCase()}
                />
              </>
            )}
          </Box>
          <Box display='flex' gap={1}>
            <FilterModal>
              <Box display='flex' gap={2} flexDirection='column'>
                <FeeGridSelect
                  label={t('feeAndBilling:feeReport.table.feeGrid')}
                  value={feeTierId}
                  setValue={(newValue) => setFeeTierId(newValue)}
                  organizationId={billingCycleInfo?.organization?.id || organizationId}
                  includeAnyOption
                />
                {!billingCycleId && (
                  <>
                    <BillingScheduleSelect
                      label={t('feeAndBilling:feeReport.table.billingSchedule')}
                      setValue={(newValue) => setBillingScheduleId(newValue)}
                      value={billingScheduleId}
                      organizationId={organizationId}
                      includeAnyOption
                    />
                    <DateField
                      label={t('feeAndBilling:feeReport.filters.billingDateFrom')}
                      value={dateAfter || null}
                      onChange={(date: any) => setDateAfter(dayjs(date).format('YYYY-MM-DD'))}
                    />
                    <DateField
                      label={t('feeAndBilling:feeReport.filters.billingDateTo')}
                      value={(dateBefore || null)}
                      onChange={(date: any) => setDateBefore(dayjs(date).format('YYYY-MM-DD'))}
                    />
                  </>
                )}
              </Box>
            </FilterModal>
            {permissions.includes('read:api_exports') && billingCycleId && (
              <DownloadBillingCycle filter={{ billingCycleIds: billingCycleId, organizationId }} queryFilter={{ feeTierIds: feeTierId }} />
            )}
            {billingCycleInfo?.state === BillingCycleStates.AWAITING_REVIEW && (
              <Button
                type='submit'
                sx={{ marginLeft: 1 }}
                // value={billingCycleId}
                onClick={() => setApproveConfirmationOpen(true)}
                label={t('Approve')}
              />
            )}
          </Box>
        </Box>
      </CardContent>
      <Table aria-label="table" sx={{ display: 'block' }}>
        <TableBody>
          <TableRow>
            <TableHeadCell sx={{
              position: 'sticky',
              left: 0,
            }}>{t('feeAndBilling:feeReport.table.client')}</TableHeadCell>
            <TableHeadCell>{t('feeAndBilling:feeReport.table.organization')}</TableHeadCell>
            <TableHeadCell>{t('feeAndBilling:feeReport.table.billingSchedule')}</TableHeadCell>
            <TableHeadCell>{t('feeAndBilling:feeReport.table.billingDate')}</TableHeadCell>
            <TableHeadCell>{t('feeAndBilling:feeReport.table.feeGrid')}</TableHeadCell>
            <TableHeadCell>{t('feeAndBilling:feeReport.table.from')}</TableHeadCell>
            <TableHeadCell>{t('feeAndBilling:feeReport.table.to')}</TableHeadCell>
            <TableHeadCell number>{t('feeAndBilling:feeReport.table.billableAumLastDay')}</TableHeadCell>
            <TableHeadCell number>{t('feeAndBilling:feeReport.table.billableAumWithReductionsApplied')}</TableHeadCell>
            <TableHeadCell number>{t('feeAndBilling:feeReport.table.salesTax')}</TableHeadCell>
            <TableHeadCell number>{t('feeAndBilling:feeReport.table.accuredFees')}</TableHeadCell>
            <TableHeadCell number>{t('feeAndBilling:feeReport.table.totalFeesAndTax')}</TableHeadCell>
            <TableHeadCell number>{t('feeAndBilling:feeReport.table.projectedFeeAndTax')}</TableHeadCell>
            <TableHeadCell>{t('feeAndBilling:feeReport.table.feePaymentAccount')}</TableHeadCell>
            <TableHeadCell number>{t('feeAndBilling:feeReport.table.cashAvailable')}</TableHeadCell>
          </TableRow>
          { loading && !previousData && [...Array(15)].map((x: any, i: number) => (
            <TableRow key={i}>
              <TableCell><Skeleton width='100%' /></TableCell>
              <TableCell><Skeleton width='100%' /></TableCell>
              <TableCell><Skeleton width='100%' /></TableCell>
              <TableCell><Skeleton width='100%' /></TableCell>
              <TableCell><Skeleton width='100%' /></TableCell>
              <TableCell><Skeleton width='100%' /></TableCell>
              <TableCell><Skeleton width='100%' /></TableCell>
              <TableCell><Skeleton width='100%' /></TableCell>
              <TableCell><Skeleton width='100%' /></TableCell>
              <TableCell><Skeleton width='100%' /></TableCell>
              <TableCell><Skeleton width='100%' /></TableCell>
              <TableCell><Skeleton width='100%' /></TableCell>
              <TableCell><Skeleton width='100%' /></TableCell>
              <TableCell><Skeleton width='100%' /></TableCell>
              <TableCell><Skeleton width='100%' /></TableCell>
            </TableRow>
          ))}
          {data?.fetchGroupedBillings?.groupedBillings?.map((groupedBilling: GroupedBilling) => (
            <FeeReportRow
              groupedBilling={groupedBilling}
              key={groupedBilling.id}
              onSelectFeeRow={(row: FeeReportDataRow) => {
                if (row.objectType === 'SUB_ACCOUNT') {
                  navigate(`/billingManagement/${billingCycleId}/subAccount/${row.objectId}`);
                }
              }}
            />
          ))}
        </TableBody>
      </Table>
      <Pagination
        count={Math.ceil(((data || previousData)?.fetchGroupedBillings?.totalCount ?? 0) / pageSize)}
        page={page}
        onChange={(_e, newPage) => setPage(newPage)}
        sx={{
          p: 1,
          textAlign: 'right',
          '.MuiPagination-ul': {
            justifyContent: 'end',
          },
        }}
      />
      <ConfirmationModal
        open={approveConfirmationOpen}
        onCancel={() => setApproveConfirmationOpen(false)}
        onConfirm={async () => {
          await transitionBillingCycle({
            variables: {
              input: { billingCycleId, transition: 'approve' },
            },
          });
          setApproveConfirmationOpen(false);
          showToast({ severity: 'info', message: t('feeAndBilling:feeReport.approveBillingCycleConfirmationDialog.toastMessage') });
          refetch();
        }}
        title={t('feeAndBilling:feeReport.approveBillingCycleConfirmationDialog.title')}
        bodyText={t('feeAndBilling:feeReport.approveBillingCycleConfirmationDialog.text')}
      />
    </Card>
  );
};

export default FeeReport;

export { FeeReportBreadcrumb } from './feeReportBreadcrumb';
