import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Autorenew, Download } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { gql, useQuery } from '@apollo/client';
import {
  Badge, Card, CardContent, IconButton,
} from '../../../../2-component';
import { Box, Grid, Typography } from '../../../../1-primative';
import { useThemeTokens } from '../../../../../providers/themeTokenProvider';
import { useLocalization } from '../../../../../util/useLocalization';

const FETCH_DOCS = gql`
  query fetchFileDocuments($input: FetchFileDocumentsInput!) {
    fetchFileDocuments(input: $input) {
      fileDocuments {
        id
        type
        name
        fileName
        uploadedAt
        downloadUrl
      }
    }
  }
`;

export const IdCard = ({
  idVerification, index, onClick, options, setActiveImages, updateNumber,
}: {
  idVerification: any, index: number, onClick: () => void, options: any, setActiveImages: any, updateNumber: number,
}) => {
  const { t } = useTranslation(['client', 'shared']);
  const { sys } = useThemeTokens();
  const { localizedDate } = useLocalization();
  const [showDual, setShowDual] = useState<number[]>([]);

  const { data, refetch } = useQuery(FETCH_DOCS, {
    variables: {
      input: {
        filter: { sourceIds: [idVerification.id], types: ['ID_VERIFICATION'] },
      },
    },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    refetch();
  }, [updateNumber, refetch]);

  const SingleIdCard = ({ id }: { id: any }) => (
    <Box width='100%' display='flex' flexDirection='column' justifyContent='space-between' >
      <Box mb={1} display='flex' justifyContent='space-between'>
        <Box>
          <Badge
            label={ id.documentType ? t(`documentTypes.${id.documentType}`) : t('edit.noJDocumentType')}
            color={ dayjs(id.documentExpiryDate).isBefore(dayjs()) ? 'negative' : 'neutral' }
            data-testid={`verification-badge-${index}`}
          />
          <Typography variant='headingXSmall' mt={0.5} data-testid={`verification-username-${index}`}>{id.userName}</Typography>
          <Typography data-testid={`verification-jurisdiction-${index}`}>{id.documentIssuingJurisdication}</Typography>
        </Box>
        {(data?.fetchFileDocuments?.fileDocuments?.length || 0) > 0 && (
          <IconButton data-testid={`verification-download-${index}`} onClick={(e: any) => {
            e.stopPropagation();
            data?.fetchFileDocuments.fileDocuments.forEach((doc: any) => {
              window.open(doc.downloadUrl, '_blank');
            });
          }}>
            <Download />
          </IconButton>
        )}
      </Box>
      <Box display='flex' justifyContent='space-between'>
        <Box>
          <Typography variant='labelSmall' data-testid={`verification-verified-date-${index}`}>{t('idVerifications.verifiedDate')}</Typography>
          <Typography data-testid={`verification-verified-date-value-${index}`}>{localizedDate(id.verifiedDate)}</Typography>
        </Box>
        <Box>
          <Typography variant='labelSmall' data-testid={`verification-expiry-date-${index}`}>{t('idVerifications.expiryDate')}</Typography>
          <Typography data-testid={`verification-expiry-date-value-${index}`}>{localizedDate(id.documentExpiryDate)}</Typography>
        </Box>
      </Box>
    </Box>
  );

  return (
    <Grid item xs={12} md={6} key={index} data-testid={`verification-item-${index}`}>
      <Card hover={options.editIdVerification} data-testid={`verification-card-${index}`} onClick={() => {
        if (options.editIdVerification) {
          setActiveImages(data?.fetchFileDocuments?.fileDocuments || []);
          onClick();
        }
      }}>
        <Box sx={{ background: sys.color.neutralPressed, minHeight: '24px' }} p={2} display='flex' justifyContent='space-between' alignItems='center'>
          <Typography weight='bold' data-testid={`verification-method-${index}`}>{t(`edit.methodOfIDVerificationOptions.${idVerification.methodOfIDVerification}`)}</Typography>
          {idVerification.methodOfIDVerification === 'DUAL_METHOD' && (
            <IconButton data-testid={`verification-toggle-${index}`} size='small' onClick={(e: any) => {
              e.stopPropagation();
              if (showDual.includes(index)) {
                setShowDual(showDual.filter((item) => item !== index));
                return;
              }
              setShowDual([...showDual, index]);
            }}>
              <Autorenew sx={{ fontSize: '16px' }}/>
            </IconButton>
          )}
        </Box>
        <CardContent>
          <Box display='flex' justifyContent='flex-start'>
            {showDual.includes(index) ? (
              <SingleIdCard id={{
                documentType: idVerification.secondaryDocumentType,
                documentExpiryDate: idVerification.secondaryDocumentExpiryDate,
                userName: idVerification.secondaryDocumentUserName,
                documentIssuingJurisdication: idVerification.secondaryDocumentIssuingJurisdication,
                verifiedDate: idVerification.verifiedDate,
              }} />
            ) : (
              <SingleIdCard id={idVerification} />
            )}
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
};
