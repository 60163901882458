import { TFunction } from 'i18next';

export const getAccountNameForTransactionTable = (transaction: any, t: TFunction): string => {
  const subAccountName = transaction?.subAccount?.goal?.name;
  const accountType = transaction.subAccount?.account?.type
    ? t(`accountTypes:${transaction.subAccount.account.type}`)
    : transaction?.account?.type
      ? t(`accountTypes:${transaction.account.type}`)
      : undefined;

  if (!subAccountName && !accountType) return '-';
  return `${subAccountName || ''}${subAccountName && accountType ? ' - ' : ''}${accountType || ''}`;
};
