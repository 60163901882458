import { useTranslation } from 'react-i18next';
import { gql, useQuery } from '@apollo/client';
import { useLocation, useParams } from 'react-router-dom';
import { useState } from 'react';
import { isNil, uniq } from 'lodash/fp';
import { translateBackend } from '../../../../assets/i18n/config';
import { Box, Typography } from '../../../1-primative';
import { PageObjectType } from '../../../5-page';
import { objectTypeMapping } from '../holdings/holdings';
import { ObjectType } from '../../../../providers/statsHooks';
import { AccordionModelPortfolioHoldings } from './components/accordionModelPortfolioHoldings';
import { FinancialProduct, SubAccount } from '../../../../interfaces';
import { getUserIdFromPath } from '../../../../util/getUserIdFromPath';

const FINANCIAL_PRODUCT = `
  financialProduct {
    id
    translatedName { en fr }
    url
    theme {
      translatedShortDescription { en fr }
    }
    children {
      id
      percentage
      financialProduct {
        id
        translatedName { en fr }
        primaryAssetClass {
          id
          translatedName { en fr }
          translatedDescription { en fr }
          key
        }
        secondaryAssetClass {
          id
          translatedName { en fr }
          translatedDescription { en fr }
          key
        }
        tertiaryAssetClass {
          id
          translatedName { en fr }
          translatedDescription { en fr }
          key
        }
      }
    }
  }
`;

const FETCH_GOAL_FINANCIAL_PRODUCT = gql`
  query fetchGoal($goalId: ObjectID!) {
    fetchGoal(goalId: $goalId) {
      goal {
        id
        ${FINANCIAL_PRODUCT}
        subAccounts {
          id
          account {
            id
            user {
              id
            }
          }
          ${FINANCIAL_PRODUCT}
        }
      }
    }
  }
`;

const FETCH_ACCOUNT_FINANCIAL_PRODUCT = gql`
  query fetchAccount($accountId: ObjectID!) {
    fetchAccount(accountId: $accountId) {
      account {
        id
        subAccounts {
          id
          ${FINANCIAL_PRODUCT}
        }
      }
    }
  }
`;

const FETCH_SUB_ACCOUNT_FINANCIAL_PRODUCT = gql`
  query fetchSubAccount($subAccountId: ObjectID!) {
    fetchSubAccount(subAccountId: $subAccountId) {
      subAccount {
        id
        ${FINANCIAL_PRODUCT}
      }
    }
  }
`;

const FETCH_USER_FINANCIAL_PRODUCT = gql`
  query fetchUser($userId: ObjectID!) {
    fetchUser(userId: $userId) {
      user {
        id
        subAccounts {
          id
          ${FINANCIAL_PRODUCT}
        }
      }
    }
  }
`;

const query: any = {
  Client: FETCH_USER_FINANCIAL_PRODUCT,
  Goal: FETCH_GOAL_FINANCIAL_PRODUCT,
  Account: FETCH_ACCOUNT_FINANCIAL_PRODUCT,
  SubAccount: FETCH_SUB_ACCOUNT_FINANCIAL_PRODUCT,
};

const field: any = {
  Client: 'user',
  Goal: 'goal',
  Account: 'account',
  SubAccount: 'subAccount',
};

export const ModelPortfolioHoldings = ({
  objectId,
  objectType,
  options = {},
}: {
  objectId: string,
  objectType: PageObjectType,
  options?: any,
}): JSX.Element => {
  const { t } = useTranslation(['client']);
  const [modelPortfolioHoldings, setModelPortfolioHoldings] = useState<FinancialProduct[]>([]);
  const { userId, clientGroupId } = useParams();
  const { pathname: path } = useLocation();
  const type = objectTypeMapping(objectType);
  const variables: any = {
    Client: { userId: objectId },
    Goal: { goalId: objectId },
    Account: { accountId: objectId },
    SubAccount: { subAccountId: objectId },
  };
  const userIdFromPath = getUserIdFromPath({ userId, clientGroupId, path });

  const getDataModelPortfolioHoldings = (object: { subAccounts: SubAccount[] }): FinancialProduct[] => (
    (object.subAccounts ?? []).reduce((acc: FinancialProduct[], subAccount: SubAccount) => {
      let financialProduct: FinancialProduct | undefined;
      if (objectType === PageObjectType.GOAL && userIdFromPath) {
        if (subAccount.account.user.id === userIdFromPath) {
          financialProduct = subAccount.financialProduct;
        }
      } else {
        financialProduct = subAccount.financialProduct;
      }
      if (!isNil(financialProduct)) acc.push(financialProduct);
      return acc;
    }, [])
  );

  const { loading } = useQuery(
    query[type],
    {
      variables: variables[type],
      onCompleted: (data) => {
        const firstKey = Object.keys(data)[0];
        const object = data[firstKey][field[type]];
        switch (type) {
          case ObjectType.USER: {
            const dataModelPortfolioHoldings = getDataModelPortfolioHoldings(object);
            setModelPortfolioHoldings(uniq(dataModelPortfolioHoldings));
            break;
          }
          case ObjectType.GOAL: {
            if (object.financialProduct) {
              setModelPortfolioHoldings([object.financialProduct]);
            } else {
              const dataModelPortfolioHoldings = getDataModelPortfolioHoldings(object);
              setModelPortfolioHoldings(uniq(dataModelPortfolioHoldings));
            }
            break;
          }
          case ObjectType.ACCOUNT: {
            const dataModelPortfolioHoldings = getDataModelPortfolioHoldings(object);
            setModelPortfolioHoldings(uniq(dataModelPortfolioHoldings));
            break;
          }
          case ObjectType.SUB_ACCOUNT: {
            if (object.financialProduct) setModelPortfolioHoldings([object.financialProduct]);
            break;
          }
        }
      },
    },
  );

  return (
    <>
      <Box display="flex" alignItems={'center'}>
        <Typography variant='headingSmall'>{options.customTitle ? translateBackend(options.customTitle) : t('portfolioTargetHoldings')}</Typography>
      </Box>
      <AccordionModelPortfolioHoldings loading={loading} modelPortfolioHoldings={modelPortfolioHoldings} options={options} />
    </>
  );
};
