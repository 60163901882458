import { useTranslation } from 'react-i18next';
import { formatMoneyValue, generateTransferSourceText } from '../../../../../util';
import { Badge, TableCell } from '../../../../2-component';
import { useLocalization } from '../../../../../util/useLocalization';
import { getAccountNameForTransactionTable } from '../../../../../util/appUtils';

export const PendingTransferCell = ({
  transaction, type, isFirst,
}: {
  transaction: any, type: string, isFirst?: boolean,
}) => {
  const { t } = useTranslation(['components', 'accountTypes']);
  const { localizedDate } = useLocalization();

  const color = (state: string) : 'neutral' | 'positive' | 'negative' | 'warning' => {
    switch (state) {
      case 'INITIATED':
      case 'READY':
      case 'PROCESSING':
      case 'REQUESTED':
      case 'REVIEWING':
        return 'neutral';
      case 'DRAFT':
        return 'warning';
      case 'RECONCILED':
      case 'ACTIVE':
        return 'positive';
      case 'FAILED':
      case 'CANCELED':
        return 'negative';
      default:
        return 'neutral';
    }
  };

  switch (type) {
    case 'account':
      return (
        <TableCell isFirst={isFirst} dense>
          {getAccountNameForTransactionTable(transaction, t)}
        </TableCell>
      );
    case 'type':
      return (
        <TableCell isFirst={isFirst} dense>
            {t(`components:pendingTransfers.types.${transaction.type}`)}
        </TableCell>
      );
    case 'source':
      if (transaction.source) {
        return (
            <TableCell isFirst={isFirst} dense>{t(`components:transferSources.${transaction.source}`)}</TableCell>
        );
      }
      return (<TableCell dense>{generateTransferSourceText(t, transaction)}</TableCell>);
    case 'state':
      return (
        <TableCell isFirst={isFirst} dense>
          <Badge label={t(`components:pendingTransfers.states.${transaction.state}`)} color={color(transaction.state)}/>
        </TableCell>
      );
    case 'createdAt':
      return (
        <TableCell isFirst={isFirst} dense>{localizedDate(transaction.createdAt) ?? '' }</TableCell>
      );
    case 'value':
      return (
        <TableCell isFirst={isFirst} dense number>{formatMoneyValue(transaction.amountCents * (transaction.type === 'WITHDRAW' ? -1 : 1))}</TableCell>
      );
    case 'scheduledAt':
      return (
        <TableCell isFirst={isFirst} dense>{localizedDate(transaction.scheduledDate) ?? ''}</TableCell>
      );
    case 'securityName':
      return (
        <TableCell isFirst={isFirst} dense>{transaction?.financialProduct?.cusip || ''}</TableCell>
      );
    case 'frequency':
      return (
        <TableCell isFirst={isFirst} dense >{transaction.frequency ? t(`components:transfersTable.frequencyOption.${transaction.frequency}`) : ''}</TableCell>
      );
    case 'bankAccount':
      return (
        <TableCell isFirst={isFirst} dense>
          {
            (
              `${transaction.bankAccount?.institutionNumber ?? ''}-${transaction.bankAccount?.transitNumber ?? ''}-${transaction.bankAccount?.bankAccountNumber ?? ''}`
            ).split('-').filter((str: string) => !!str).join('-')
          }
        </TableCell>
      );
    default:
      return (
        <TableCell isFirst={isFirst}>
          {type}
        </TableCell>
      );
  }
};
