import { useState, useEffect, useRef } from 'react';
import { debounce } from 'lodash';
/**
 * A helper hook for text inputs such as search bars that are fed into an api call.\
 * This allows you to 'debounce' the text value so that text is filled after a set delay (in ms) and not on every key stroke.
 *
 * Usage:
 * ```
 *   const [searchText, useSearchText] = useState('')
 *   const debounceSearchText = useTextDebounce({value: searchText, 500})
 * ```
 */

const useTextDebounce = ({ value, delay }: { value: string, delay: number }) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  const debouncedSetter = useRef(
    debounce((newValue: string) => {
      setDebouncedValue(newValue);
    }, delay),
  );

  useEffect(() => {
    const debounced = debouncedSetter.current;

    debounced(value);

    return () => {
      debounced.cancel();
    };
  }, [value]);

  return debouncedValue;
};

export default useTextDebounce;
