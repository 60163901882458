import { ListItem, MenuItem, TextField } from '@mui/material';
import { gql, useMutation } from '@apollo/client';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { pick } from 'lodash/fp';
import dayjs from 'dayjs';
import { useGlobalToast } from '../../../providers/globalToastProvider';
import AmountField from '../../inputs/amountField';
import {
  ADJUSTED_COST_BASE_TYPES, Currencies, HOLDING_CONTRIBUTION_TRANSACTION_TYPES, TRADE_TYPES, Transaction, TransactionTypes,
} from '../../../interfaces/transaction';
import FormModal from '../../modals/formModal';
import SecuritySelect from '../../inputs/securitySelect';
import NumberField from '../../inputs/numberField';
import { LocalizedDatePicker } from '../../fields/localizedDatePicker';
import { UserContext } from '../../../providers/userContextProvider';

export const UPDATE_TRANSACTION = gql`
  mutation updateTransaction($input: UpdateTransactionInput!) {
    updateTransaction(input: $input) {
      transaction {
        id
      }
    }
  }
`;

export interface TransactionUpdateInputPros {
  date?: string,
  valueCents?: number,
  description?: string,
  financialProductId?: string,
  quantity?: number,
  priceCents?: number,
  currency?: Currencies,
}

const UpdateTransaction = ({
  afterUpdate, transaction, open, handleClose,
}: {
  afterUpdate: () => void, transaction: Transaction, open: boolean, handleClose: () => void,
}) => {
  const { t } = useTranslation(['components', 'shared']);
  const { showToast } = useGlobalToast();
  const [updateInput, setUpdateInput] = useState<TransactionUpdateInputPros>({});
  const { availableCurrencies } = useContext(UserContext);

  useEffect(() => {
    setUpdateInput({
      date: transaction.date ? dayjs(transaction.date).format('YYYY-MM-DD') : undefined,
      valueCents: Math.abs(transaction.valueCents ?? 0),
      description: transaction.description,
      financialProductId: transaction.financialProduct?.id,
      quantity: Math.abs(transaction.quantity ?? 0),
      priceCents: transaction.priceCents,
      currency: transaction.currency,
    });
  }, [transaction]);

  const [updateTransaction, { loading }] = useMutation(UPDATE_TRANSACTION, {
    variables: {
      input: {
        transactionId: transaction.id,
        ...pick(['description', 'date'], updateInput),
        ...(TRADE_TYPES.includes(transaction.type)
          ? {
            ...pick(['priceCents', 'financialProductId'], updateInput),
            quantity: ((transaction.quantity ?? 0) >= 0 ? 1 : -1) * (updateInput.quantity ?? 0),
          }
          : pick(['currency'], updateInput)),
        valueCents: (transaction.type === TransactionTypes.BUY) ? (-1 * (updateInput.valueCents ?? 0)) : ((transaction.valueCents ?? 0) >= 0 ? 1 : -1) * (updateInput.valueCents ?? 0),
      },
    },
  });

  const update = async (event: any) => {
    event.preventDefault();
    const response = await updateTransaction();
    if (response?.data) {
      showToast({ severity: 'info', message: t('components:transaction.updateTransaction.updatedToastMessage') });
    }
    setUpdateInput({});
    afterUpdate();
    handleClose();
  };

  const disabled = !(updateInput.date && (updateInput.valueCents !== undefined && updateInput.valueCents >= 0));

  const getPriceFieldLabel = () => t(`components:transaction.newTransaction.${ADJUSTED_COST_BASE_TYPES.includes(transaction.type) ? 'adjustedCostBase' : 'price'}`);

  return (
    <FormModal
      open={open}
      loading={loading}
      disabled={disabled}
      title={t('components:transaction.updateTransaction.title')}
      formButton={t('shared:update')} onSubmit={update}
      handleClose={handleClose}
    >
      <ListItem>
        <LocalizedDatePicker
          label={t('components:transaction.newTransaction.date')}
          value={updateInput.date}
          onChange={(date) => setUpdateInput((prev) => ({ ...prev, date: dayjs(date).format('YYYY-MM-DD') }))}
          renderInput={(params) => <TextField fullWidth {...params} />}
          disableFuture={true}
        />
      </ListItem>
      {transaction.type && TRADE_TYPES.includes(transaction.type) && (
        <>
          <ListItem>
            <SecuritySelect
              value={updateInput.financialProductId ?? ''}
              setSecurity={(e) => setUpdateInput((prev) => ({ ...prev, financialProductId: e.id }))}
              label={t('components:transaction.newTransaction.financialProduct')}
            />
          </ListItem>
          {transaction.type !== TransactionTypes.DIVIDEND && (
            <>
              <ListItem>
                <NumberField
                  label={t('components:transaction.newTransaction.quantity')}
                  fullWidth
                  decimalPlaces={6}
                  value={updateInput.quantity ?? ''}
                  setNumberValue={(value) => setUpdateInput((prev) => ({ ...prev, quantity: value }))}
                />
              </ListItem>
              <ListItem>
                <AmountField
                  label={getPriceFieldLabel()}
                  variant="outlined"
                  decimalPlaces={6}
                  value={updateInput.priceCents}
                  fullWidth
                  onChange={(e: any) => setUpdateInput((prev) => ({ ...prev, priceCents: e.target.valueCents }))}
                />
              </ListItem>
            </>
          )}
        </>
      )}
      {!HOLDING_CONTRIBUTION_TRANSACTION_TYPES.includes(transaction.type) && (
        <ListItem>
          <AmountField
            label={t('components:transaction.newTransaction.amount')}
            variant="outlined"
            value={updateInput.valueCents}
            fullWidth
            onChange={(e: any) => setUpdateInput((prev) => ({ ...prev, valueCents: e.target.valueCents }))}
          />
        </ListItem>
      )}
      <ListItem>
        <TextField
          label={t('components:transaction.newTransaction.details')}
          fullWidth
          value={updateInput.description ?? ''}
          onChange={(e) => setUpdateInput((prev) => ({ ...prev, description: e.target.value }))}
        />
      </ListItem>
      {!TRADE_TYPES.includes(transaction.type) && (
        <ListItem>
          <TextField
            select
            label={t('components:transaction.table.currency')}
            fullWidth
            value={updateInput.currency ?? ''}
            placeholder={updateInput.currency ?? ''}
            onChange={(e) => setUpdateInput((prev) => ({
              ...prev,
              currency: e.target.value as Currencies,
            }))}
          >
            {availableCurrencies.map((currency: string) => (
              <MenuItem
                value={currency}
                key={currency}
              >
                {t(`components:transaction.currencies.${currency}`)}
              </MenuItem>
            ))}
          </TextField>
        </ListItem>
      )}
    </FormModal>
  );
};

export default UpdateTransaction;
