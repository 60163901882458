import { useContext, useEffect, useState } from 'react';
import { Pagination } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { kebabCase } from 'lodash';
import { Box, Skeleton } from '../../../../1-primative';
import {
  Table, TableBody, TableCell, TableHeadCell, TableRow,
} from '../../../../2-component';
import { translateBackend } from '../../../../../assets/i18n/config';
import { AccountCell } from './accountCell';
import { AccountTotalCell } from './accountTotalCell';
import { UserContext } from '../../../../../providers/userContextProvider';
import { Account, NavigationStyles } from '../../../../../interfaces';

const DEFAULT_ACCOUNT_TABLE = [
  { label: { en: 'Holder', fr: 'Titulaire' }, type: 'holder' },
  { label: { en: 'Nickname', fr: 'Surnom' }, type: 'nickname' },
  { label: { en: 'Name', fr: 'Nom' }, type: 'name' },
  { label: { en: 'Account #', fr: 'Compte #' }, type: 'accountNumber' },
  { label: { en: 'Program', fr: 'Programme' }, type: 'program' },
  { label: { en: 'G/L Unrealized', fr: 'G/L non réalisé' }, type: 'unrealizedGainLoss' },
  { label: { en: '% of Total', fr: '% du total' }, type: 'percentOfTotal' },
  { label: { en: 'Current Value', fr: 'Valeur actuelle' }, type: 'currentValue' },
];

const PAGE_SIZE = 15;
const RIGHT_ALIGNED_HEADERS = ['unrealizedGainLoss', 'percentOfTotal', 'currentValue', 'mwrr', 'twrr', 'simpleReturn', 'bookCost'];

export const AccountTable = ({
  accounts, totalCount, page, setPage, loading, options = {},
}: { accounts: Account[], totalCount: number, loading?: boolean, page: number, setPage: (page: number) => void, options?: any }) => {
  const { custodianConnection, userContext } = useContext(UserContext);
  const [totalMarketValue, setTotalMarketValue] = useState(0);
  const navigate = useNavigate();

  const isUnifiedClientExperience = userContext.role?.navigationStyle === NavigationStyles.SIMPLE;
  const useExternalStatisticsEnabled = isUnifiedClientExperience ? options.useExternalStatisticsEnabled : options.useExternalStatisticsEnabled && custodianConnection?.enableFetchCustodianStatistics;

  useEffect(() => {
    if (!loading) {
      if (useExternalStatisticsEnabled) {
        // Note if custodianStatistics is empty, use statistics as fallback.
        setTotalMarketValue(accounts.reduce((acc, account) => {
          if (account?.custodianStatistics) {
            return acc + (account?.custodianStatistics?.marketValueCents ?? 0);
          }
          return acc + (account?.statistics?.marketValueCents ?? 0);
        }, 0));
      } else {
        setTotalMarketValue(accounts.reduce((acc, account) => acc + (account?.statistics?.marketValueCents ?? 0), 0));
      }
    }
  }, [accounts, loading, useExternalStatisticsEnabled]);

  return (
    <Box sx={{ overflowX: 'auto' }}>
      <Table>
        <TableBody data-testid='accounts-table-body'>
          <TableRow testId='accounts-table-header'>
            {(options.accountTable || DEFAULT_ACCOUNT_TABLE).map((opt: any, idx: number) => (
              <TableHeadCell testId={`accounts-table-header-${opt.type}`} key={opt.type} right={RIGHT_ALIGNED_HEADERS.includes(opt.type)} isFirst={idx === 0}>
                {translateBackend(opt.label)}
              </TableHeadCell>
            ))}
          </TableRow>
          {loading && (
            <>
              {[...Array(4)].map((x, i) => (
                <TableRow key={i}>
                  {(options.accountTable || DEFAULT_ACCOUNT_TABLE).map((opt: any, idx: number) => (
                    <TableCell dense isFirst={idx === 0}><Skeleton width='100%' height='16px' /></TableCell>
                  ))}
                </TableRow>
              ))}
            </>
          )}
          {!loading && (
            accounts.map((account, idx: number) => (
              <TableRow testId={`account-${account.id}`} key={account.id} hover pointer onClick={() => navigate(`account/${account.id}`)} sx={{ cursor: 'pointer' }}>
                {(options.accountTable || DEFAULT_ACCOUNT_TABLE).map((opt: any, index: number) => (
                  <AccountCell
                    dataTestId={`accounts-table-${kebabCase(opt.type)}-${idx}`}
                    displayCurrency={index === 0 && options.displayAccountCurrency}
                    useExternalStatisticsEnabled={useExternalStatisticsEnabled}
                    key={`${opt.type}-${account.id}`}
                    account={account}
                    type={opt.type}
                    totalMarketValue={totalMarketValue}
                    isFirst={index === 0}
                  />
                ))}
              </TableRow>
            ))
          )
          }
          {!loading && (
            <TableRow testId='accounts-table-footer'>
              {(options.accountTable || DEFAULT_ACCOUNT_TABLE).map((opt: any, idx: number) => (
                <AccountTotalCell
                  dataTestId={`accounts-table-footer-${kebabCase(opt.type)}`}
                  key={opt.type}
                  accounts={accounts}
                  type={opt.type}
                  totalMarketValue={totalMarketValue}
                  useExternalStatisticsEnabled={useExternalStatisticsEnabled}
                  isFirst={idx === 0}
                />
              ))}
            </TableRow>
          )}
        </TableBody>
      </Table>
      {
        totalCount > PAGE_SIZE ? (
          <Box display='flex' flexDirection='column' alignItems='end' mt={1}>
            <Pagination
              count={Math.ceil(totalCount / PAGE_SIZE)}
              sx={{ marginBottom: '10px' }}
              page={page}
              onChange={(_e, newPage) => {
                setPage(newPage);
              }}
            />
          </Box>
        ) : undefined
      }
    </Box>
  );
};
