import { gql, useMutation } from '@apollo/client';
import { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { round } from 'lodash';
import { CurrencyCodes } from '@onevesthq/ov-enums';
import { useGlobalToast } from '../../../../providers/globalToastProvider';
import { UserContext } from '../../../../providers/userContextProvider';
import { EXCHANGES_COUNTRIES_MAP, Exchanges } from '../../../../interfaces/financialProduct';
import { isAlphaNumeric, isAlphanumericOrHasFullstop, isValidCusipLength } from './editProduct';
import { Box } from '../../../1-primative';
import {
  TextField, MenuItem, Switch, InputAdornment, TranslatableTextField,
} from '../../../2-component';
import {
  CreateNewModal, AssetClassSelect, OrganizationSelect, SecuritySelect, ScheduleSelect,
} from '../../../3-pattern';

const CREATE_PRODUCT = gql`
mutation createFinancialProduct($input: CreateFinancialProductInput!) {
  createFinancialProduct(input: $input) {
    financialProduct {
      id
    }
  }
}
`;

const NewProduct = ({ afterCreate }: { afterCreate: () => void }) => {
  const { t } = useTranslation(['configureModels']);
  const { showToast } = useGlobalToast();
  const { activeOrganization } = useContext(UserContext);
  const [enableSchedules, setEnableSchedules] = useState(false);
  const [enableTertiaryAssetClass, setEnableTertiaryAssetClass] = useState(false);
  const [nextDate, setNextDate] = useState('');
  const emptyProduct = {
    ticker: '',
    url: '',
    translatedName: {
      en: '',
    },
    primaryAssetClassId: '',
    secondaryAssetClassId: '',
    tertiaryAssetClassId: null,
    substituteFinancialProductId: null,
    exchange: '',
    autoUpdatePrices: false,
    organizationId: activeOrganization.id,
    scheduleId: null,
    waivedFeePercentage: null as null | number,
    taxRank: null as null | number,
    settlementDays: 2,
    volatile: false,
    isPartial: true,
    cusip: undefined,
    isin: undefined,
    ric: undefined,
    currency: CurrencyCodes.CAD,
  };
  const [product, setProduct] = useState(emptyProduct);
  const [initialState] = useState({
    ticker: '',
    translatedName: {
      en: '',
    },
    primaryAssetClassId: '',
    secondaryAssetClassId: '',
    tertiaryAssetClassId: null,
    substituteFinancialProductId: null,
    exchange: '',
    autoUpdatePrices: false,
    organizationId: activeOrganization.id,
    scheduleId: null,
    waivedFeePercentage: null as null | number,
    taxRank: null as null | number,
    settlementDays: 2,
    volatile: false,
    isPartial: true,
  });

  const [invalidCusip, setInvalidCusip] = useState<boolean | undefined>(undefined);
  const [invalidIsin, setInvalidIsin] = useState<boolean | undefined>(undefined);
  const [invalidRic, setInvalidRic] = useState<boolean | undefined>(undefined);

  const onClose = () => {
    setProduct(emptyProduct);
    setEnableTertiaryAssetClass(false);
  };

  const [createProduct, { loading }] = useMutation(CREATE_PRODUCT, {
    variables: {
      input: product,
    },
  });

  useEffect(() => {
    if (!activeOrganization) return;

    const defaultCurrency = activeOrganization.applicableLocalization.defaultCurrency as CurrencyCodes;
    const hasDefaultCurrencyInShelf = activeOrganization.productShelfCurrencies?.includes(defaultCurrency);

    setProduct((prevProduct) => ({
      ...prevProduct,
      organizationId: activeOrganization.id,
      currency: hasDefaultCurrencyInShelf
        ? defaultCurrency
        : (activeOrganization.productShelfCurrencies?.[0] || CurrencyCodes.CAD),
    }));
  }, [activeOrganization]);

  const create = async (event: any) => {
    if (!product.scheduleId) {
      setProduct((current: any) => {
        //  remove scheduleId key from state if it is not assigned
        const { scheduleId, ...rest } = current;
        return rest;
      });
    }
    const response = await createProduct();
    if (response?.data) {
      showToast({ severity: 'success', message: t('productModal.newProductToastMessage') });
      setProduct({ ...emptyProduct });
    }
    afterCreate();
  };
  const disabled = (enableSchedules && !product.scheduleId)
  || !product.translatedName.en
  || !product.exchange
  || !product.primaryAssetClassId
  || !product.secondaryAssetClassId
  || invalidCusip;

  const exchanges = Object.keys(Exchanges).filter((key) => isNaN(Number(key))).sort();
  return (
    <CreateNewModal
      initialState={initialState}
      state={product}
      onClose={onClose}
      loading={loading} disabled={disabled} title={t('productModal.title')} onSubmit={create} fullWidth sx={{ ml: 1 }}>
      <Box display='flex' flexDirection='column' gap={2}>
        <TextField label={t('productModal.ticker')} fullWidth value={product.ticker} onChange={(e: any) => setProduct({ ...product, ticker: e.target.value })} />
        <TranslatableTextField label={t('productModal.name')} fullWidth value={product.translatedName} onChange={(e: any) => setProduct({ ...product, translatedName: e })} />
        <TextField select label={t('productModal.exchange')} fullWidth value={product.exchange} onChange={(e: any) => setProduct({ ...product, exchange: e.target.value })}>
        {exchanges.map((option) => (
          <MenuItem key={option} value={option}>
            {option} {EXCHANGES_COUNTRIES_MAP[option].country ? `(${EXCHANGES_COUNTRIES_MAP[option].country})` : ''}
          </MenuItem>
        ))}
        </TextField>

        <TextField
          label={t('productModal.originalCurrency')}
          fullWidth
          value={product.exchange ? EXCHANGES_COUNTRIES_MAP[product.exchange].currency : ''}
          disabled // This field is read-only as it's determined by the exchange
        />

        <TextField
          select
          label={t('productModal.displayCurrency')}
          fullWidth
          value={product.currency || CurrencyCodes.CAD}
          onChange={(e: any) => {
            setProduct((prevProduct) => ({
              ...prevProduct,
              currency: e.target.value as CurrencyCodes,
            }));
          }}
        >
          {activeOrganization?.productShelfCurrencies && activeOrganization.productShelfCurrencies.length > 0
            ? activeOrganization.productShelfCurrencies.map((currency) => (
                <MenuItem key={currency} value={currency}>
                  {currency}
                </MenuItem>
            ))
            : <MenuItem value={CurrencyCodes.CAD}>{CurrencyCodes.CAD}</MenuItem>
          }
        </TextField>

        <AssetClassSelect
          value={product.primaryAssetClassId}
          setAssetClass={(e: any) => {
            setProduct({ ...product, primaryAssetClassId: e.target.value });
          }}
          label={t('productModal.primaryAssetClass')}
        />
        <AssetClassSelect
          value={product.secondaryAssetClassId}
          setAssetClass={(e: any) => {
            setProduct({ ...product, secondaryAssetClassId: e.target.value });
          }}
          label={t('productModal.secondaryAssetClass')}
        />
        <SecuritySelect
          value={product.substituteFinancialProductId ?? ''}
          setSecurity={(chosenProduct) => {
            setProduct({ ...product, substituteFinancialProductId: chosenProduct.id });
          }}
          label={t('productModal.substituteProduct')}
        />
        <OrganizationSelect
          value={product.organizationId}
          onChange={(e: any) => {
            setProduct({ ...product, organizationId: e.target.value });
          }}
          label={t('productModal.organization')}
        />
        <Switch
          label={t('productModal.autoUpdatePrices')}
          checked={product.autoUpdatePrices}
          onChange={async (checked) => setProduct({ ...product, autoUpdatePrices: checked })}
        />
        <Switch
          checked={enableSchedules}
          label={t('productModal.illiquidMessage')}
          onChange={async (checked) => {
            setEnableSchedules(checked);
          }}
        />
        <Switch
          label={t('productModal.volatile')}
          checked={product.volatile}
          onChange={async (checked) => setProduct({ ...product, volatile: checked })}
        />
        <Switch
          checked={product.isPartial}
          onChange={async (checked) => setProduct({ ...product, isPartial: checked })}
          label={t('productModal.isPartial')}
        />
        <Switch
          checked={enableTertiaryAssetClass}
          label={t('productModal.tertiaryAssetClass')}
          onChange={(checked) => {
            setEnableTertiaryAssetClass(checked);
            if (!checked) setProduct({ ...product, tertiaryAssetClassId: null });
          }}
        />
        {enableTertiaryAssetClass && (
            <AssetClassSelect
              value={product?.tertiaryAssetClassId ?? ''}
              setAssetClass={(e: any) => {
                setProduct({ ...product, tertiaryAssetClassId: e.target.value });
              }}
              label={t('productModal.tertiaryAssetClass')}
            />
        )}
        {enableSchedules ? (
          <ScheduleSelect
            value={product?.scheduleId ? product.scheduleId : ''}
            setSchedule={async (e: any) => {
              setProduct({ ...product, scheduleId: e.target.value });
            }}
            setScheduleData={(schedule: any) => {
              setNextDate(schedule.nextDate);
            }}
            label={t('productModal.schedule')}
          />) : null}
        {nextDate ? (
            <TextField
              label={t('productModal.nextDate')}
              fullWidth
              value={nextDate}
              disabled />
        ) : null}
        <TextField
          fullWidth
          label={t('productModal.waivedFeePercentage')}
          type='number'
          value={product.waivedFeePercentage ?? ''}
          onChange={(e: any) => {
            if (e.target.value && !Number.isNaN(e.target.value)) {
              const waivedFeePercentage = round(parseFloat(e.target.value), 2);
              if (waivedFeePercentage >= 0 && waivedFeePercentage <= 100) {
                setProduct((prev) => ({ ...prev, waivedFeePercentage }));
              }
            } else {
              setProduct((prev) => ({ ...prev, waivedFeePercentage: null }));
            }
          }}
          InputProps={{
            endAdornment: <InputAdornment position="start">%</InputAdornment>,
          }}
        />
        <TextField
          fullWidth
          label={t('productModal.taxRank')}
          type='number'
          value={product.taxRank ?? ''}
          onChange={(e: any) => {
            if (e.target.value && !Number.isNaN(e.target.value)) {
              const taxRank = parseInt(e.target.value, 10);
              if (taxRank >= 0) {
                setProduct((prev) => ({ ...prev, taxRank }));
              }
            } else {
              setProduct((prev) => ({ ...prev, taxRank: null }));
            }
          }}
        />
        <TextField
          fullWidth
          label={t('productModal.settlementDays')}
          type='number'
          value={product.settlementDays ?? ''}
          onChange={(e: any) => {
            if (e.target.value && !Number.isNaN(e.target.value)) {
              const settlementDays = round(parseFloat(e.target.value), 2);
              if (settlementDays >= 0) {
                setProduct((prev: any) => ({ ...prev, settlementDays }));
              }
            } else {
              setProduct((prev: any) => ({ ...prev, settlementDays: null }));
            }
          }}
        />
        <TextField
          fullWidth
          label={t('productModal.url')}
          value={product.url ?? ''}
          onChange={(e: any) => setProduct((prev: any) => ({ ...prev, url: e.target.value }))}
        />
        <TextField
          fullWidth
          label={t('productModal.cusip')}
          value={product.cusip ?? ''}
          onChange={(e: any) => {
            const cusipValue = e.target.value;
            setInvalidCusip(cusipValue.length ? !isAlphaNumeric(cusipValue) || !isValidCusipLength(cusipValue) : undefined);
            setProduct((prev: any) => ({ ...prev, cusip: cusipValue || undefined }));
          }}
          onBlur={() => setProduct((prev: any) => ({ ...prev, cusip: product.cusip }))}
          error={invalidCusip}
          infoTooltip={invalidCusip ? t('productModal.cusipLengthErrorMessage') : t('productModal.alphaNumericErrorMessage')}
          inputProps={{ maxLength: 22 }}
        />
        <TextField
          fullWidth
          label={t('productModal.isin')}
          value={product.isin ?? ''}
          onChange={(e: any) => {
            if (e.target.value !== '') {
              setInvalidIsin(!isAlphaNumeric(e.target.value));
              setProduct((prev: any) => ({ ...prev, isin: e.target.value }));
            } else {
              setInvalidIsin(undefined);
              setProduct((prev: any) => ({ ...prev, isin: undefined }));
            }
          }}
          onBlur={() => setProduct((prev: any) => ({ ...prev, isin: product.isin }))}
          error={invalidIsin}
          infoTooltip={invalidIsin ? t('productModal.alphaNumericErrorMessage') : '12 characters are required.'}
          inputProps={{ maxLength: 12 }}
        />
        <TextField
          fullWidth
          label={t('productModal.ric')}
          value={product.ric ?? ''}
          onChange={(e: any) => {
            if (e.target.value !== '') {
              setInvalidRic(!isAlphanumericOrHasFullstop(e.target.value));
              setProduct((prev: any) => ({ ...prev, ric: e.target.value }));
            } else {
              setInvalidRic(undefined);
              setProduct((prev: any) => ({ ...prev, ric: undefined }));
            }
          }}
          onBlur={() => setProduct((prev: any) => ({ ...prev, ric: product.ric }))}
          error={invalidRic}
          infoTooltip={invalidRic ? t('productModal.alphaNumericWithDotErrorMessage') : ''}
        />
      </Box>
    </CreateNewModal>
  );
};

export default NewProduct;
