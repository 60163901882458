export const getUserIdFromPath = ({ userId, clientGroupId, path }: { userId?: string, clientGroupId?: string, path: string }) => {
  if (clientGroupId && userId) {
    const userIdIndex = path.indexOf(userId);
    const clientGroupIdIndex = path.indexOf(clientGroupId);
    if (userIdIndex > clientGroupIdIndex) {
      return userId;
    }
    return undefined;
  }
  return userId;
};
