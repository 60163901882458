import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { usePermissions } from '../../../../../providers/userContextProvider';
import { Column, DownloadButton, ExportApiReportFormatters } from '../../../../3-pattern';
import { translateBackend } from '../../../../../assets/i18n/config';

export const FETCH_TRANSACTIONS_QUERY = (permissions: string[]) => `#graphql
  query fetchTransactions($input: FetchTransactionsInput!) {
    fetchTransactions(input: $input) {
      transactions {
        id
        valueCents
        date
        description
        currency
        type
        quantity
        priceCents
        bookValueCents
        pending
        risky
        isSellAll
        settleDate
        settleDateToWithdraw
        financialProduct { id ticker }
        account {
          id
          type
          ${permissions.includes('read:account_number') ? 'custodianAccountNumber' : ''}
          translatedNickName { en fr }
          custodianAccountName
          user { id firstName lastName entityName }
        }
        subAccount {
          id
          goal { name type }
          account { id type householdClientGroup { id } }
        }
        goal { id }
        organization { id }
        objectType
        objectId
        user { id ${permissions.includes('read:client_low_risk_pii') ? 'firstName middleName lastName' : ''} }
      }
      totalCount
    }
  }
`;

export const FETCH_CUSTODIAN_TRANSACTIONS_QUERY = (permissions: string[]) => `#graphql
  query fetchCustodianTransactions($input: FetchCustodianTransactionsInput!) {
    fetchCustodianTransactions(input: $input) {
      transactions {
        valueCents
        date
        description
        currency
        type
        quantity
        priceCents
        bookValueCents
        custodianTransactionType
        financialProduct { ticker }
        account {
          id
          type
          ${permissions.includes('read:account_number') ? 'custodianAccountNumber' : ''}
          translatedNickName { en fr }
          custodianAccountName
          user { id firstName lastName entityName}
        }
      }
      totalCount
    }
  }
`;

const aliasMap: any = {
  accountType: 'account.type',
  type: 'type',
  costPrice: 'priceCents',
  value: 'valueCents',
  date: 'date',
  security: 'financialProduct.ticker',
  quantity: 'quantity',
  account_id: 'subAccount.account.id',
  accountNumber: 'account.custodianAccountNumber',
  accountName: 'account.custodianAccountName',
  nickname: 'account.translatedNickName.en',
  firstName: 'user.firstName',
  lastName: 'user.lastName',
  currency: 'currency',
  description: 'description',
  subAccount: 'subAccount.id',
  goal: 'goal.id',
  settleDate: 'settleDate',
  settleDateToWithdraw: 'settleDateToWithdraw',
  user: 'user.id',
  organization: 'organization.id',
  bookCost: 'bookValueCents',
  objectType: 'objectType',
  objectId: 'objectId',
  householdClientGroup: 'subAccount.account.householdClientGroup.id',
};

interface DownloadLedgerProps {
  filter: any;
  options: any;
  useCustodianData: boolean;
}

const DownloadLedger = ({
  filter,
  options,
  useCustodianData,
}: DownloadLedgerProps) => {
  const { t } = useTranslation(['components', 'customReports', 'client', 'pageConfiguration']);
  const { permissions } = usePermissions();

  const DOWNLOAD_COLUMNS = [
    { type: 'firstName', label: t('components:activityTable.userFirstName') },
    { type: 'account_id', label: t('customReports:column.accountId') },
    { type: 'lastName', label: t('components:activityTable.userLastName') },
    { type: 'accountType', label: t('components:activityTable.accountType') },
    { type: 'type', label: t('components:activityTable.type') },
    { type: 'security', label: t('components:trades.security') },
    { type: 'costPrice', label: t('components:transaction.table.priceCents') },
    { type: 'quantity', label: t('components:trades.quantity') },
    { type: 'value', label: t('components:tradeTable.totalValue') },
    { type: 'bookCost', label: t('components:transaction.table.bookCost') },
    { type: 'date', label: t('components:transaction.table.date') },
    { type: 'description', label: t('components:activityTable.alertDescription') },
    { type: 'accountNumber', label: t('client:accountNumber') },
    { type: 'accountName', label: t('client:accountName') },
    { type: 'nickname', label: t('pageConfiguration:nickName') },
  ];

  const DOWNLOAD_ADDITIONAL_COLUMNS = [
    { type: 'currency', label: t('components:tradeTable.currency') },
    { type: 'settleDate', label: t('components:tradeTable.settlementDate') },
    { type: 'settleDateToWithdraw', label: t('components:transaction.table.settleDateToWithdraw') },
    { type: 'subAccount', label: t('components:subAccount') },
    { type: 'goal', label: t('components:transaction.table.goal') },
    { type: 'user', label: t('components:transaction.table.user') },
    { type: 'organization', label: t('components:transaction.table.organization') },
    { type: 'objectType', label: t('components:transaction.table.objectType') },
    { type: 'objectId', label: t('components:transaction.table.objectId') },
    { type: 'householdClientGroup', label: t('components:transaction.table.householdClientGroup') },
  ];

  const getColumn = (item: any): Column => {
    const gqlAlias = aliasMap[item.type] ?? `unknown.${item.type}`;
    const formatter = ['value', 'costPrice', 'bookCost'].includes(item.type) ? ExportApiReportFormatters.DIVIDE_BY_100 : undefined;
    const tableItem = options.table?.find((elem: any) => elem.type === item.type);
    return { gqlAlias, formatter, header: translateBackend(tableItem?.label) || item.label };
  };

  const columns: Column[] = DOWNLOAD_COLUMNS.map(getColumn);
  const additionalColumns: Column[] = DOWNLOAD_ADDITIONAL_COLUMNS.map(getColumn);

  const baseQuery = useCustodianData
    ? FETCH_CUSTODIAN_TRANSACTIONS_QUERY(permissions)
    : FETCH_TRANSACTIONS_QUERY(permissions);

  const fileName = options.customTitle ? translateBackend(options.customTitle) : t('components:transaction.fileName');

  const config = {
    gql: baseQuery,
    fileName: `${fileName}_${dayjs().format('YYYY-MM-DD')}`,
    filter,
    queryPath: useCustodianData ? 'fetchCustodianTransactions' : 'fetchTransactions',
    datasetPath: 'transactions',
    columns,
    additionalColumns,
    sortField: 'date',
    sortDesc: false,
    progressTitle: t('components:transaction.progressTitle'),
  };

  return (
    <DownloadButton
      configurable
      {...config}
    />
  );
};

export default DownloadLedger;
