import { useContext, useEffect, useState } from 'react';
import {
  gql, useLazyQuery, useMutation, useQuery,
} from '@apollo/client';
import { useTranslation } from 'react-i18next';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { CircularProgress } from '@mui/material';
import { validateUrl } from '../../../../util';
import {
  Button, Dialog, DialogContent, DialogFooter, DialogTitle,
  IconButton,
} from '../../../2-component';
import { TranslatedString } from '../../../../interfaces';
import { useThemeTokens } from '../../../../providers/themeTokenProvider';
import { CreateAndEditNewsAndInsightsForm } from './createAndEditNewsAndInsightsForm';
import { Box } from '../../../1-primative';
import { UserContext } from '../../../../providers/userContextProvider';
import {
  CREATE_FILE_DOCUMENT,
  FETCH_FILE_UPLOAD_URL,
} from '../../../3-pattern/addBankAccount/addBankAccountManually/addBankAccountManually';

export const FETCH_DOCUMENTS = gql`
query fetchFileDocuments($filter: FileDocumentQueryFilter) {
  fetchFileDocuments(input:{
    filter: $filter
    pagination: {perPage: 1000}
  }) {
    fileDocuments {
      id type name fileName mediaType uploadedAt updatedAt
      objectType objectId
      sharedClient sharedCustodian
      creator { id }
      user { id firstName lastName }
    }
  }
}`;

export const FETCH_ARTICLE = gql`
  query fetchArticle($articleId: ObjectID!) {
    fetchArticle(articleId: $articleId) {
      article {
        id
        articleUrl
        createdAt
        createdBy {
          id
          firstName
          lastName
        }
        organization {
          id
        }
        state
        translatedDescription {
          en
          fr
        }
        translatedName {
          en
          fr
        }
        imageUrl
        updatedAt
      }
    }
  }
`;

export const UPDATE_ARTICLE = gql`
  mutation updateArticle($input: UpdateArticleInput!) {
    updateArticle(input: $input) {
      article {
        id
      }
    }
  }
`;

export const DELETE_FILE_DOCUMENT = gql`
  mutation deleteFileDocument($fileDocumentId: ObjectID!) {
    deleteFileDocument(input: { fileDocumentId: $fileDocumentId }) {
      fileDocument {
        id
      }
    }
  }
`;

export const DELETE_ARTICLE_IMAGE = gql`
  mutation deleteArticleImage($input: DeleteArticleImageInput!) {
    deleteArticleImage(input: $input) {
      article {
        id
      }
    }
  }
`;

export interface Article {
  id?: string,
  articleUrl: string,
  translatedName: TranslatedString,
  translatedDescription: TranslatedString
}

export const EditArticle = ({
  open, setOpen, article, refetch, loading, transitionArticle,
}: {
  open: boolean, setOpen: (open: boolean) => void, article: any, refetch: () => void, loading: boolean, transitionArticle: (input: any) => void,
}) => {
  const { activeOrganization, userContext } = useContext(UserContext);
  const [createFileDocument] = useMutation(CREATE_FILE_DOCUMENT);
  const [fetchDocuments] = useLazyQuery(FETCH_DOCUMENTS, {
    variables: {
      filter: {
        sourceIds: [article.id],
        types: ['ARTICLE'],
      },
    },
    fetchPolicy: 'network-only',
  });
  const [deleteArticleImageMutation] = useMutation(DELETE_ARTICLE_IMAGE, {
    variables: {
      input: {
        articleId: article.id,
      },
    },
    fetchPolicy: 'network-only',
  });
  const { loading: fetchArticleLoading, data } = useQuery(FETCH_ARTICLE, {
    variables: {
      articleId: article.id,
    },
    fetchPolicy: 'network-only',
    onError: () => {
      setArticleToUpdate({
        id: article.id,
        articleUrl: article.articleUrl,
        translatedName: article.translatedName,
        translatedDescription: article.translatedDescription,
      });
      setImageSrc(data.fetchArticlearticle.imageUrl);
    },
  });
  const { t } = useTranslation('newsAndInsight');
  const [articleToUpdate, setArticleToUpdate] = useState<Article>({
    id: '',
    articleUrl: '',
    translatedName: {
      en: '',
      fr: '',
    },
    translatedDescription: {
      en: '',
      fr: '',
    },
  });
  const [articleImageFile, setArticleImageFile] = useState<File>();
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const { sys } = useThemeTokens();
  const [updateArticleMutation, { loading: updateArticleLoading }] = useMutation(UPDATE_ARTICLE, {
    variables: {
      input: {
        articleId: articleToUpdate.id,
        articleUrl: articleToUpdate.articleUrl,
        translatedDescription: {
          en: articleToUpdate.translatedDescription.en,
          fr: articleToUpdate.translatedDescription.fr,
        },
        translatedName: {
          en: articleToUpdate.translatedName.en,
          fr: articleToUpdate.translatedName.fr,
        },
      },
    },
    onCompleted: () => {
      handleCreateArticleDialogClose();
      refetch();
    },
  });

  const [errorFields, setErrorFields] = useState<string[]>([]);
  const resetErrorFields = () => {
    setErrorFields([]);
  };
  const handleCreateArticleDialogClose = () => {
    // reset error fields so when the dialog is opened again, no field has error lines
    resetErrorFields();
    resetFileImageAndSrc();
    setOpen(false);
    setArticleToUpdate({
      id: '',
      articleUrl: '',
      translatedName: {
        en: '',
        fr: '',
      },
      translatedDescription: {
        en: '',
        fr: '',
      },
    });
  };

  const doUpload = async (uploadingFile: File) => {
    const createFileInput: any = {
      objectType: 'ORGANIZATION',
      objectId: activeOrganization.id,
      fileName: uploadingFile?.name,
      type: 'ARTICLE',
    };

    /* (1) fetch the S3 upload URL from backend */
    const queryResult = await fetchFileUploadUrl({ variables: { input: { ...createFileInput, userId: userContext.id } } });
    const uploadUrl = queryResult?.data?.fetchFileUploadUrl.temporarySignedURL;
    if (!uploadUrl || queryResult?.error) {
      // showToast({ severity: 'error', message: 'File upload failed' });
      return;
    }
    /* (2) do the upload */
    try {
      // setUploadPending(true);
      const uploaded: Response = await fetch(new Request(uploadUrl, { method: 'PUT', body: uploadingFile }));
      if (!uploaded.ok) throw (new Error(`${uploaded.status} ${uploaded.statusText}`));
    } catch (e: any) {
      // showToast({ severity: 'error', message: 'File upload failed' });
      return;
    } finally {
      // setUploadPending(false);
    }

    /* (3) create the fileDocument within backend */
    createFileInput.name = uploadingFile.name;
    createFileInput.sourceId = article.id;
    createFileInput.mediaType = uploadingFile.type;
    createFileInput.permissionType = 'PUBLIC';
    createFileInput.sourceType = 'ARTICLE';

    try {
      await createFileDocument({
        variables: { input: createFileInput },
        refetchQueries: [FETCH_DOCUMENTS],
      });
    } catch (error) {
      // showToast({ severity: 'error', message: 'File upload failed' });
    }
    // showToast({ severity: 'success', message: `Document uploaded successfully: ${uploadingFile.name}` });
  };

  const handleFileInputChange = (file: File) => {
    setImageSrc(URL.createObjectURL(file));
    setArticleImageFile(file);
  };

  const resetFileImageAndSrc = () => {
    setImageSrc(null);
    setArticleImageFile(undefined);
  };

  const handleValidationClear = (value: string, fieldName: string) => {
    if (value.length > 0 && errorFields.includes(fieldName)) {
      const removedField = errorFields.filter((field) => field !== fieldName);
      setErrorFields([...removedField]);
    }
  };

  const hasInValidFields = (): boolean => {
    const fieldsWithError: string[] = [];
    if (!articleToUpdate.translatedName.en) {
      fieldsWithError.push('translatedName');
    }
    if (!validateUrl(articleToUpdate.articleUrl)) {
      fieldsWithError.push('articleUrl');
    }
    if (!articleToUpdate.translatedDescription.en) {
      fieldsWithError.push('translatedDescription');
    }
    setErrorFields((prevState) => [...prevState, ...fieldsWithError]);
    return fieldsWithError.length > 0;
  };

  const updateArticle = async () => {
    if (hasInValidFields()) return;
    const documentsResponse = await fetchDocuments();
    if (documentsResponse.data.fetchFileDocuments.fileDocuments.length === 0 && articleImageFile) {
      await doUpload(articleImageFile);
    } else if (documentsResponse.data.fetchFileDocuments.fileDocuments.length > 0 && (imageSrc === null || articleImageFile)) {
      await deleteArticleImageMutation();
      if (articleImageFile) {
        await doUpload(articleImageFile);
      }
    }
    updateArticleMutation().then();
  };

  useEffect(() => {
    if (data) {
      setArticleToUpdate({
        id: data.fetchArticle.article.id,
        articleUrl: data.fetchArticle.article.articleUrl,
        translatedName: data.fetchArticle.article.translatedName,
        translatedDescription: data.fetchArticle.article.translatedDescription,
      });
      setImageSrc(data.fetchArticle.article.imageUrl);
    }
  }, [data]);

  const [fetchFileUploadUrl] = useLazyQuery(FETCH_FILE_UPLOAD_URL, { fetchPolicy: 'no-cache' });

  return (
    <Dialog maxWidth="md" fullWidth open={open} onClose={handleCreateArticleDialogClose}>
      <DialogTitle display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
        {t('newsAndInsight:articleConfiguration')}
        <IconButton onClick={handleCreateArticleDialogClose} testId='edit-article-close-button'>
          <CloseRoundedIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {fetchArticleLoading ? (
            <Box display='flex' justifyContent='center' width='100%'>
              <CircularProgress />
            </Box>
        ) : (<CreateAndEditNewsAndInsightsForm
            imageSrc={imageSrc}
            resetFileImageAndSrc={resetFileImageAndSrc}
            handleFileInputChange={handleFileInputChange}
            article={articleToUpdate}
            setArticleToUpdate={setArticleToUpdate}
            errorFields={errorFields}
            handleValidationClear={handleValidationClear}
        />)}
      </DialogContent>
      <DialogFooter>
        <Button
          sx={{ backgroundColor: sys.color.primary, color: sys.color.onPrimary }}
          disabled={updateArticleLoading}
          label={t('shared:update')}
          onClick={updateArticle}
          dataTestId='edit-article-update-button'
        />
      </DialogFooter>
    </Dialog>
  );
};
