import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  gql, useMutation, useQuery,
} from '@apollo/client';
import { Box, Grid } from '../../../1-primative';
import {
  Button, ColorPickerField, Form, TextField,
} from '../../../2-component';
import { SettingsCard } from '../../../3-pattern';
import { UserContext } from '../../../../providers/userContextProvider';
import { useGlobalToast } from '../../../../providers/globalToastProvider';

const FETCH_ORGANIZATION = gql`
  query fetchOrganization($organizationId: String!) {
    fetchOrganization(organizationId: $organizationId) {
      organization {
        id
        name
        theme {
          authenticationTheme {
            logo primaryColor pageBackgroundColor
          }
        }
      }
    }
  }
`;

const UPDATE_ORGANIZATION = gql`
  mutation updateOrganization($input: UpdateOrganizationInput!) {
    updateOrganization(input: $input) {
      organization {
        id
      }
    }
  }
`;

export const AuthenticationDesignerSettings = () => {
  const { activeOrganization } = useContext(UserContext);
  const { showToast } = useGlobalToast();
  const { t } = useTranslation(['settings', 'shared']);
  const [organization, setOrganization] = useState<any>({});

  const { data, loading } = useQuery(FETCH_ORGANIZATION, {
    variables: { organizationId: activeOrganization?.id },
  });

  const [updateOrganization] = useMutation(UPDATE_ORGANIZATION, {
    variables: {
      input: {
        organizationId: activeOrganization?.id,
        theme: {
          authenticationTheme: {
            logo: organization?.theme?.authenticationTheme?.logo,
            primaryColor: organization?.theme?.authenticationTheme?.primaryColor,
            pageBackgroundColor: organization?.theme?.authenticationTheme?.pageBackgroundColor,
          },
        },
      },
    },
    onCompleted: async () => {
      showToast({
        message: t('shared:successfullySaved'),
        severity: 'success',
      });
    },
  });

  useEffect(() => {
    if (data?.fetchOrganization?.organization) {
      setOrganization(data?.fetchOrganization?.organization);
    }
  }, [data]);

  return (
    <SettingsCard
      title={t('authenticationDesigner.title')}
      description={t('authenticationDesigner.description')}
    >
      <Form onSubmit={updateOrganization}>
        <Box display='flex' flexDirection='column' gap={2}>
          <TextField
            testId='settings-authentication-designer-logo-link'
            label={t('authenticationDesigner.logoLink')}
            value={organization?.theme?.authenticationTheme?.logo}
            onChange={(e: any) => setOrganization({
              ...organization,
              theme: {
                ...organization.theme,
                authenticationTheme: { ...organization.authenticationTheme, logo: e.target.value },
              },
            })}
            helpText={t('authenticationDesigner.logoLinkHelpText')}
            fullWidth
          />
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <ColorPickerField
                testId='settings-authentication-designer-primary-color'
                fullWidth
                label={t('authenticationDesigner.primaryColor')}
                value={organization?.theme?.authenticationTheme?.primaryColor ?? ''}
                onChange={(e) => setOrganization(
                  {
                    ...organization,
                    theme: {
                      ...organization?.theme,
                      authenticationTheme: {
                        ...organization?.theme?.authenticationTheme,
                        primaryColor: e,
                      },
                    },
                  },
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <ColorPickerField
                testId='settings-authentication-designer-page-background-color'
                fullWidth
                label={t('authenticationDesigner.pageBackgroundColor')}
                value={organization?.theme?.authenticationTheme?.pageBackgroundColor ?? ''}
                onChange={(e) => setOrganization(
                  {
                    ...organization,
                    theme: {
                      ...organization?.theme,
                      authenticationTheme: {
                        ...organization?.theme?.authenticationTheme,
                        pageBackgroundColor: e,
                      },
                    },
                  },
                )}
              />
            </Grid>
          </Grid>
          <Box display='flex' justifyContent='flex-end'>
            <Button type='submit' disabled={loading} label={t('shared:save')} dataTestId='settings-authentication-designer-submit-button'/>
          </Box>
        </Box>
      </Form>
    </SettingsCard>
  );
};
