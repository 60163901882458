import { Box, Avatar as MuiAvatar } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import CircleIcon from '@mui/icons-material/Circle';
import { colors } from '../../0-tokens';
import { Typography } from '../../1-primative';
import { InlineImage } from '../inlineImage/inlineImage';
import { useThemeTokens } from '../../../providers/themeTokenProvider';

const sizeMap = {
  small: 16,
  medium: 24,
  large: 32,
  xlarge: 48,
};
const borderWidthMap = {
  small: 1,
  medium: 1.5,
  large: 2,
  xlarge: 3,
};
// Font sizes for 1-character avatar
const fontSizeMap1: FontSizeMap = {
  small: 8,
  medium: 12,
  large: 18,
  xlarge: 24,
};
// Font sizes for 2-character avatar
const fontSizeMap2: FontSizeMap = {
  small: 6,
  medium: 10,
  large: 14,
  xlarge: 20,
};

const DEFAULT_TEXT_VARIANT = 'trimmedMedium';
const ATB_FONT = 'ATB TT Norms';

interface AvatarProps {
  size?: 'small' | 'medium' | 'large' | 'xlarge';
  children?: JSX.Element | string;
  state?: 'default' | 'borderless' | 'highlight' | 'clear'
  withShadow?: boolean,
  m?: number
  bgColor?: string
  fgColor?: string
  avatar?: string,
}

interface FontSizeMap {
  small: number;
  medium: number;
  large: number;
  xlarge: number;
}

export const Avatar = ({
  size = 'medium', children, state = 'default', withShadow = false, m = 0, bgColor, fgColor, avatar,
}: AvatarProps) => {
  const { comp } = useThemeTokens();

  const transform = comp?.typography?.[DEFAULT_TEXT_VARIANT]?.fontFamily?.includes(ATB_FONT) ? 'translateY(5%)' : undefined;
  const borderWidth = state === 'borderless' ? 0 : borderWidthMap[size];
  const diameter = sizeMap[size];

  let border = `${borderWidth}px solid ${colors.neutral100}`;
  if (state === 'borderless') border = 'none';
  if (state === 'clear' || state === 'highlight') border = `${borderWidth}px solid ${fgColor ?? colors.primary600}`;

  return (
    <Box sx={{ position: 'relative', width: diameter, height: diameter }}>
      {state === 'clear' && (
        <>
          <CircleIcon sx={{ ...iconSx(size), color: 'white', zIndex: 2000 }} />
          <CancelIcon sx={{ ...iconSx(size), zIndex: 2001 }} />
        </>
      )}
      <MuiAvatar sx={{
        m,
        color: fgColor ?? colors.primary600,
        backgroundColor: bgColor ?? colors.primary300,
        width: diameter - borderWidth * 2,
        height: diameter - borderWidth * 2,
        border,
        boxShadow: withShadow ? '0px 2px 4px 0px #00000026;' : undefined,
      }}>
        {avatar ? (
          <InlineImage fileDocumentId={avatar} style={{ objectFit: 'cover', height: '100%' }} />
        ) : typeof children === 'string' ? (
          <Typography variant={DEFAULT_TEXT_VARIANT} sx={{ ...typographySx(children.length, size), transform }}>{children}</Typography>
        ) : (
          children
        )}
      </MuiAvatar>
    </Box>
  );
};

const typographySx = (length: number, size: keyof typeof fontSizeMap1) => ({
  fontSize: length === 1 ? fontSizeMap1[size] : fontSizeMap2[size],
  letterSpacing: length === 1 ? undefined : 0,
});

const iconSx = (size: keyof typeof fontSizeMap1) => ({
  position: 'absolute',
  height: size === 'small' ? 10 : 13.33,
  width: size === 'small' ? 10 : 13.33,
  overflow: 'visible',
  top: -3,
  right: -3,
});
