import { useTranslation } from 'react-i18next';
import { formatMoneyValue, generateTransferSourceText } from '../../../../util';
import { Badge, TableCell } from '../../../2-component';
import { useLocalization } from '../../../../util/useLocalization';
import { getAccountNameForTransactionTable } from '../../../../util/appUtils';

export const ScheduleTransferCell = ({
  scheduleTransfer, type,
}: {
  scheduleTransfer: any, type: string,
}) => {
  const { t } = useTranslation(['components', 'shared', 'accountTypes', 'transfer']);
  const { localizedDate } = useLocalization();

  switch (type) {
    case 'account':
      return (
        <TableCell dense>
          {getAccountNameForTransactionTable(scheduleTransfer, t)}
        </TableCell>
      );
    case 'type':
      return (
        <TableCell dense>
          {t(`components:transferModal.${scheduleTransfer.type.toLowerCase()}`)}
        </TableCell>
      );
    case 'source':
      return (
        <TableCell dense>{generateTransferSourceText(t, scheduleTransfer)}</TableCell>
      );
    case 'frequency':
      return (
        <TableCell dense>{t(`transfersTable.frequencyOption.${scheduleTransfer.frequency}`)}</TableCell>
      );
    case 'amount':
      return (
        <TableCell dense number>{formatMoneyValue(scheduleTransfer.amountCents)}</TableCell>
      );
    case 'state':
      return (
        <TableCell dense>
          <Badge label={t(`transfer:states.${scheduleTransfer.state}`)} color={scheduleTransfer.state === 'DRAFT' ? 'warning' : scheduleTransfer.state === 'CANCELED' ? 'negative' : 'neutral'}/>
        </TableCell>
      );
    case 'nextScheduledDate':
      return (
        <TableCell dense>{localizedDate(scheduleTransfer.scheduledDate)}</TableCell>
      );
    default:
      return (
        <TableCell>{type}</TableCell>
      );
  }
};
