import {
  TextField, ListItem, MenuItem, FormControl, RadioGroup, FormLabel, FormControlLabel, Radio, FormGroup, Switch,
} from '@mui/material';
import { gql, useMutation } from '@apollo/client';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import OrganizationSelect from '../../../components/inputs/organizationSelect';
import RoleSelect from '../../../components/inputs/roleSelect';
import CreateNewModal from '../../../components/modals/createNewModal';
import { Languages, OrganizationUserAccessTypes } from '../../../interfaces';

const CREATE_ORGANIZATION = gql`
  mutation createOrganizationUser($input: CreateOrganizationUserInput!) {
    createOrganizationUser(input: $input) {
      organizationUser {
        id
      }
    }
  }
`;

const NewOrganizationUser = ({ afterCreate, defaultOrg }: { afterCreate: () => void; defaultOrg?: string }) => {
  const { t } = useTranslation(['orgSettings']);
  const languages = Object.values(Languages);

  const [focused, setFocused] = useState<string[]>([]);
  const [organizationUser, setOrganizationUser] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    language: Languages.ENGLISH,
    roleId: '',
    organizationId: defaultOrg ?? '',
    accessType: OrganizationUserAccessTypes.ORGANIZATION,
    autoInviteUser: true,
  });

  const [createOrganizationUser, { loading }] = useMutation(CREATE_ORGANIZATION, {
    variables: {
      input: organizationUser,
    },
  });

  const resetFocus = () => {
    setFocused([]);
  };

  const validate = () => {
    const invalidFields: string[] = [];

    if (!organizationUser.firstName) invalidFields.push('firstName');
    if (!organizationUser.lastName) invalidFields.push('lastName');
    if (!organizationUser.email) invalidFields.push('email');
    if (!organizationUser.roleId) invalidFields.push('roleId');
    if (!organizationUser.organizationId) invalidFields.push('organizationId');
    setFocused(invalidFields);

    return invalidFields.length === 0;
  };

  const updateField = (event: any) => {
    const key: 'name' | 'subdomain' = event.target.id;
    setOrganizationUser({ ...organizationUser, [key]: event.target.value as string });
  };

  const createOrg = () => {
    if (!validate()) throw new Error('Error Occur');

    createOrganizationUser().then(() => {
      setOrganizationUser({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        roleId: '',
        language: Languages.ENGLISH,
        organizationId: defaultOrg ?? '',
        accessType: OrganizationUserAccessTypes.ORGANIZATION,
        autoInviteUser: true,
      });
      afterCreate();
    });
  };

  return (
    <CreateNewModal loading={loading} onClose={resetFocus} title={t('userModal.title')} onSubmit={createOrg} sx={{ float: 'right' }}>
      <ListItem>
        <TextField
          label={t('userModal.firstName')}
          sx={{ width: '100%' }}
          value={organizationUser.firstName}
          onChange={updateField}
          id='firstName'
          onBlur={() => setFocused([...focused, 'firstName'])}
          error={!organizationUser.firstName && focused.includes('firstName')}
        />
      </ListItem>
      <ListItem>
        <TextField
          label={t('userModal.lastName')}
          sx={{ width: '100%' }}
          value={organizationUser.lastName}
          onChange={updateField}
          id='lastName'
          onBlur={() => setFocused([...focused, 'lastName'])}
          error={!organizationUser.lastName && focused.includes('lastName')}
        />
      </ListItem>
      <ListItem>
        <TextField
          label={t('userModal.email')}
          sx={{ width: '100%' }}
          value={organizationUser.email}
          onChange={updateField}
          id='email'
          onBlur={() => setFocused([...focused, 'email'])}
          error={!organizationUser.email && focused.includes('email')}
        />
      </ListItem>
      <ListItem>
        <TextField label={t('userModal.phone')} sx={{ width: '100%' }} value={organizationUser.phone} onChange={updateField} id='phone' />
      </ListItem>
      <ListItem>
        <FormControl>
          <FormLabel>{t('userModal.language')}</FormLabel>
          <RadioGroup row value={organizationUser.language} onChange={(e) => setOrganizationUser({ ...organizationUser, language: e.target.value as Languages })}>
            {languages.map((lang) => (
              <FormControlLabel key={lang as string} value={lang} control={<Radio />} label={t(`userModal.languageList.${lang}`)} />
            ))}
          </RadioGroup>
        </FormControl>
      </ListItem>
      <ListItem>
        <OrganizationSelect
          value={organizationUser.organizationId}
          label={t('userModal.organization')}
          onChange={(event: any) => setOrganizationUser({ ...organizationUser, organizationId: event.target.value as string })}
          error={!organizationUser.organizationId && focused.includes('organizationId')}
        />
      </ListItem>
      <ListItem>
        <RoleSelect
          value={organizationUser.roleId}
          label={t('userModal.role')}
          onChange={(event: any) => setOrganizationUser({ ...organizationUser, roleId: event.target.value as string })}
          organizationId={organizationUser.organizationId}
          disabled={organizationUser.organizationId === ''}
          onBlur={() => setFocused([...focused, 'roleId'])}
          error={!organizationUser.roleId && focused.includes('roleId')}
        />
      </ListItem>
      <ListItem>
        <FormGroup sx={{ height: '100%', alignItems: 'center', justifyContent: 'center' }}>
          <FormControlLabel
            control={<Switch checked={organizationUser?.autoInviteUser ?? true} onChange={async (event) => setOrganizationUser({ ...organizationUser, autoInviteUser: event.target.checked })} />}
            label={t('userModal.autoInviteUser')}
          />
        </FormGroup>
      </ListItem>
      <ListItem>
        <TextField
          select
          value={organizationUser.accessType}
          label={t('userModal.accessType')}
          sx={{ width: '100%' }}
          onChange={(event: any) => setOrganizationUser({ ...organizationUser, accessType: event.target.value })}
        >
          <MenuItem key='1' value={OrganizationUserAccessTypes.ORGANIZATION}>
            {OrganizationUserAccessTypes.ORGANIZATION}
          </MenuItem>
          <MenuItem key='2' value={OrganizationUserAccessTypes.ENTITY}>
            {OrganizationUserAccessTypes.ENTITY}
          </MenuItem>
        </TextField>
      </ListItem>
    </CreateNewModal>
  );
};

export default NewOrganizationUser;
