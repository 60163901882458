/* eslint-disable object-curly-newline */
/* eslint-disable no-continue */
import { useCallback, useContext, useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { round } from 'lodash';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';
import { translateBackend } from 'assets/i18n/config';
import GenerateTradesModal from './components/generateTradesModal';
import SmallDollarPercentage from './components/smallDollarPercentage';
import { delay } from '../../../../util';
import { usePermissions, UserContext } from '../../../../providers/userContextProvider';
import { PortfolioReport, PortfolioReportRefreshStates, SubAccountStates } from '../../../../interfaces';
import SubTradeRequestPairsModal from './components/subTradeRequestPairsModal';
import { Box, Typography, Grid } from '../../../1-primative';
import { Switch, Table, TableBody, TableCell, TableHead, TableRow, CircularProgress, Card, Button, TableHeadCell, Menu, MenuItem, Tooltip } from '../../../2-component';
import { PageObjectType } from '../../../5-page';
import { useThemeTokens } from '../../../../providers/themeTokenProvider';
import { SubTradeRequest, SubTradeRequestStates } from '../../../../interfaces/subTradeRequest';
import { PortfolioOptimizer, PortfolioOptimizerStates } from '../../../../interfaces/portfolioOptimizer';
import { DraftSubTradeRequest, PortfolioSecurity, PortfolioSubAccount } from './components/interfaces';
import { FETCH_OPTIMAL_PORTFOLIO, FETCH_PORTFOLIO_REPORTS, FETCH_SUB_TRADE_REQUESTS, REFRESH_PORTFOLIO_REPORT } from './components/queries';
import {
  areTradesAmountCentsDifferent,
  buildDraftSubTradeRequest,
  buildNewOptimalDraftTrade,
  buildPortfolioSubAccount,
  buildSecurity,
  getKey,
  getSubAccountCurrentCents,
  sortPortfolioReportSubAccounts,
  sortSecurities,
  sumPendingTradesCentsByCondition,
  updateDraftTrades,
} from './components/utils';
import { SecurityTableRow } from './components/securityTableRow';
import { SecurityTotalTableRow } from './components/securityTotalTableRow';
import { SubAccountTableHeadCell } from './components/subAccountTableHeadCell';
import { SubAccountTableCell } from './components/subAccountTableCell';
import { SubAccountHoldingTableCell } from './components/subAccountHoldingTableCell';
import { useLocalization } from '../../../../util/useLocalization';
import { RUN_PORTFOLIO_OPTIMIZER } from '../../../../pages/portfolioOptimizers/components/selectOptimizerModal';
import { FETCH_PORTFOLIO_OPTIMIZER } from '../../../../pages/portfolioOptimizer';
import { OptimalPortfolio } from '../../../../interfaces/optimalPortfolio';
import {
  addPortfolioWarning,
  buildIlliquidProduct,
  checkAndSetMFSellAllTicker,
  checkAndSetUsdTicker,
  IlliquidProductsMap,
  illiquidProductsToString,
  joinSetValues,
  PortfolioWarningKeys,
  PortfolioWarnings,
  PortfolioWarningsMap,
} from './components/portfolioWarnings';
import SubTradeRequests from './components/subTradeRequests';
import SubTradeRequestsTable from './components/subTradeRequestsTable';

const POLLING_REFRESH_INTERVAL = 500; // milliseconds (0.5 second)
const TIMEOUT_INTERVAL = 10000; // milliseconds (10 seconds)
const DELAY = 1000; // milliseconds (1 second)
const DEBOUNCE_DELAY = 300; // milliseconds (0.3 second)

export const TESTID_PORTFOLIO_WIDGET = 'portfolio-widget';
export const TESTID_PORTFOLIO_WIDGET_SECURITIES_TABLE_HEAD_CELL = (cell: string) => `${TESTID_PORTFOLIO_WIDGET}-securities-table-head-cell-${cell}`;
export const TESTID_PORTFOLIO_WIDGET_SECURITIES_TABLE_ROW = (index: number) => `${TESTID_PORTFOLIO_WIDGET}-securities-table-row-${index}`;
export const TESTID_PORTFOLIO_WIDGET_SECURITIES_TABLE_ROW_CELL = (index: number, cell: string) => `${TESTID_PORTFOLIO_WIDGET}-securities-table-row-${index}-cell-${cell}`;
export const TESTID_PORTFOLIO_WIDGET_HOLDINGS_TABLE_HEAD_CELL = (index: number) => `${TESTID_PORTFOLIO_WIDGET}-holdings-table-head-cell-${index}`;
export const TESTID_PORTFOLIO_WIDGET_HOLDINGS_TABLE_ROW = (index: number) => `${TESTID_PORTFOLIO_WIDGET}-holdings-table-row-${index}`;
export const TESTID_PORTFOLIO_WIDGET_HOLDINGS_TABLE_ROW_CELL = (index: number, cellSubIndex: string | number) => `${TESTID_PORTFOLIO_WIDGET}-holdings-table-row-${index}-cell-${cellSubIndex}`;

export const Portfolio = ({
  id,
  type,
  options,
  portfolioReportId,
  setIsTradesUpdated,
  onPortfolioRefresh,
  hideTitle = false,
}: {
  id: string;
  type: PageObjectType;
  options?: Record<string, any>;
  portfolioReportId?: string;
  setIsTradesUpdated?: (state: boolean) => void;
  onPortfolioRefresh?: (portfolioReport: PortfolioReport) => void;
  hideTitle?: boolean;
}) => {
  const { t } = useTranslation(['components', 'accountTypes', 'shared']);
  const { permissions } = usePermissions();
  const { activeOrganization } = useContext(UserContext);
  const { sys } = useThemeTokens();
  const { localizedDateTime, localizedDate } = useLocalization();

  const [securities, setSecurities] = useState<PortfolioSecurity[]>([]);
  const [subAccounts, setSubAccounts] = useState<PortfolioSubAccount[]>([]);
  const [portfolioReport, setPortfolioReport] = useState<PortfolioReport | undefined>(undefined);

  const [draftTrades, setDraftTrades] = useState<Map<string, DraftSubTradeRequest>>(new Map());
  const [initialDraftTrades, setInitialDraftTrades] = useState<Map<string, DraftSubTradeRequest>>(new Map());

  const [autoFilled, setAutoFilled] = useState<boolean>(false);
  const [openTradePairs, setOpenTradePairs] = useState<boolean>(false);
  const [viewPreTradePositions, setViewPreTradePositions] = useState<boolean>(false);
  const [viewInactiveSubAccounts, setViewInactiveSubAccounts] = useState<boolean>(false);
  const [isDraftTradesUpdated, setIsDraftTradesUpdated] = useState<boolean>(false);
  const [isErrorOccurred, setErrorOccurred] = useState<boolean>(false);
  const [portfolioNotFound, setPortfolioNotFound] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isRefreshRequested, setIsRefreshRequested] = useState<boolean>(false);
  const [isTimedOut, setIsTimedOut] = useState<boolean>(false);
  const [loadingPortfolioOptimizerPolling, setLoadingPortfolioOptimizerPolling] = useState(false);
  const [loadingPortfolioReportsPolling, setLoadingPortfolioReportsPolling] = useState<boolean>(false);
  const [portfolioWarnings, setPortfolioWarnings] = useState<PortfolioWarningsMap>(new Map());
  const [subTradeRequestsBySubAccount, setSubTradeRequestsBySubAccount] = useState<Map<string, SubTradeRequest[]>>(new Map());

  const canSeePortfolio = permissions.some((x) => ['read:rebalance_basic', 'read:portfolio_optimizer'].includes(x)) && permissions.includes('read:bulk_trade_request');
  const canSeeTradePairsModal = activeOrganization?.allowViewSubTradeRequestPairs && permissions.includes('write:sub_trade_request');
  const canSeeAutoRebalance = permissions.includes('write:portfolio_optimizer') && permissions.includes('read:portfolio_optimizer');

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const resetAllMaps = () => {
    setDraftTrades(new Map());
    setInitialDraftTrades(new Map());
    setPortfolioWarnings(new Map());
  };

  const refreshPortfolio = () => {
    setViewInactiveSubAccounts(false);
    resetAllMaps();

    if (portfolioReportId || portfolioReport?.id) {
      refreshPortfolioReport({
        variables: { input: { portfolioReportId: portfolioReportId ?? portfolioReport?.id } },
      });

      return;
    }

    refetchPortfolioReports();
  };

  const processPortfolioReportData = (portfolioReportData: PortfolioReport) => {
    setPortfolioReport(portfolioReportData);

    if (onPortfolioRefresh) onPortfolioRefresh(portfolioReportData);

    fetchSubTrades();
  };

  const recordTimeOut = () => setTimeout(() => setIsTimedOut(true), TIMEOUT_INTERVAL);

  // eslint-disable-next-line operator-linebreak
  const [fetchPortfolioReports, { loading: loadingPortfolioReports, refetch: refetchPortfolioReports, startPolling: startPollingPortfolioReports, stopPolling: stopPollingPortfolioReports }] =
    useLazyQuery(FETCH_PORTFOLIO_REPORTS, {
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
      variables: {
        input: {
          filter: {
            ...(type === PageObjectType.GOAL ? { goalId: id } : { subAccountId: id }),
            organizationId: activeOrganization?.id,
          },
        },
      },
      onCompleted: (data: any) => {
        const portfolioReportData: PortfolioReport = data?.fetchPortfolioReports?.portfolioReports?.[0] ?? null;

        if (!portfolioReportData) {
          setPortfolioNotFound(true);

          return;
        }

        const isUpdating = portfolioReportData.refreshState === PortfolioReportRefreshStates.UPDATING;
        const isUpdated = portfolioReportData.refreshState === PortfolioReportRefreshStates.UPDATED;

        if (!isRefreshRequested && isUpdating) {
          setLoadingPortfolioReportsPolling(true);
          setIsRefreshRequested(true);
          startPollingPortfolioReports(POLLING_REFRESH_INTERVAL);
          recordTimeOut();
        }

        if (!isRefreshRequested && isUpdated) {
          setIsRefreshRequested(true);

          refreshPortfolioReport({
            variables: { input: { portfolioReportId: portfolioReportData?.id } },
          });
        }

        if (isRefreshRequested && (isUpdated || (isUpdating && loadingPortfolioReportsPolling && isTimedOut))) {
          if (isTimedOut) setIsTimedOut(false);

          setIsRefreshRequested(false);
          setLoadingPortfolioReportsPolling(false);
          stopPollingPortfolioReports();

          processPortfolioReportData(portfolioReportData);
        }
      },
      onError: () => {
        setErrorOccurred(true);
      },
    });

  const [refreshPortfolioReport, { loading: loadingRefreshPortfolioReport }] = useMutation(REFRESH_PORTFOLIO_REPORT, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data: any) => {
      const portfolioReportData: PortfolioReport = data?.refreshPortfolioReport?.portfolioReport ?? null;

      if (!portfolioReportData) {
        setPortfolioNotFound(true);

        return;
      }

      if (portfolioReportData.refreshState === PortfolioReportRefreshStates.UPDATING) {
        setIsRefreshRequested(true);
        setLoadingPortfolioReportsPolling(true);
        startPollingPortfolioReports(POLLING_REFRESH_INTERVAL);
        recordTimeOut();

        return;
      }

      processPortfolioReportData(portfolioReportData);
    },
    onError: () => {
      setErrorOccurred(true);
    },
  });

  const [runPortfolioOptimizer] = useMutation(RUN_PORTFOLIO_OPTIMIZER, {
    onError: () => {
      setErrorOccurred(true);
    },
  });

  const [fetchPortfolioOptimizer, { stopPolling: stopPollingPortfolioOptimizer }] = useLazyQuery(FETCH_PORTFOLIO_OPTIMIZER, {
    pollInterval: POLLING_REFRESH_INTERVAL,
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    onCompleted: (response: any) => {
      const { portfolioOptimizer }: { portfolioOptimizer: PortfolioOptimizer } = response.fetchPortfolioOptimizer;

      if ([PortfolioOptimizerStates.COMPLETED, PortfolioOptimizerStates.CANCELED].includes(portfolioOptimizer?.state)) {
        stopPollingPortfolioOptimizer();
        setLoadingPortfolioOptimizerPolling(false);

        if ([PortfolioOptimizerStates.COMPLETED].includes(portfolioOptimizer?.state)) {
          fetchOptimalPortfolio();
        }
      }
    },
    onError: () => {
      setErrorOccurred(true);
    },
  });

  const [fetchOptimalPortfolio, { loading: loadingOptimalPortfolio }] = useLazyQuery(FETCH_OPTIMAL_PORTFOLIO, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    variables: { input: { optimalPortfolioId: portfolioReport?.id } },
    onCompleted: (response: any) => {
      const { optimalPortfolio }: { optimalPortfolio: OptimalPortfolio } = response.fetchOptimalPortfolio;

      if (optimalPortfolio) {
        const localDraftTrades = new Map<string, DraftSubTradeRequest>();

        for (const subAccount of optimalPortfolio.subAccounts) {
          for (const optimalHolding of subAccount.optimalHoldings) {
            const key = getKey(optimalHolding.financialProduct, subAccount.subAccount);

            if (initialDraftTrades.has(key)) continue;

            const trade: DraftSubTradeRequest | null = buildNewOptimalDraftTrade(optimalHolding, subAccount, securities, subAccounts);

            if (!trade) continue;

            localDraftTrades.set(key, trade);
          }
        }

        if (localDraftTrades.size) {
          setDraftTrades(new Map(localDraftTrades));
          setAutoFilled(true);
        }
      }
    },
    onError: () => {
      setErrorOccurred(true);
    },
  });

  // !todo: pass down to the BulkTradeRequests and SubTradeRequestPairsModal instead of making 3 separate calls to fetch the same sub-trade requests
  const [fetchSubTrades, { loading: loadingSubTrades }] = useLazyQuery(FETCH_SUB_TRADE_REQUESTS(true), {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    variables: {
      input: {
        filter: {
          ...(type === PageObjectType.GOAL ? { goalId: id } : { subAccountId: id }),
          states: [SubTradeRequestStates.INITIATED, SubTradeRequestStates.REQUESTED, SubTradeRequestStates.READY],
        },
        pagination: {
          perPage: 100,
        },
      },
    },
    onCompleted: (response: any) => {
      if (response?.fetchSubTradeRequests?.subTradeRequests?.length) {
        const { subTradeRequests }: { subTradeRequests: SubTradeRequest[] } = response.fetchSubTradeRequests;

        const localDraftTrades = new Map<string, DraftSubTradeRequest>();
        const localSubTradeRequestsBySubAccount = new Map<string, SubTradeRequest[]>();

        let blockedUntilAccountActive = false;
        let blockedUntilSubAccountActive = false;
        const illiquidProducts: IlliquidProductsMap = new Map();
        const usdTickers: Set<string> = new Set();
        const mfSellAllTickers: Set<string> = new Set();

        for (const subTradeRequest of subTradeRequests) {
          if (subTradeRequest.state === SubTradeRequestStates.REQUESTED) {
            if (activeOrganization?.allowPendingTransactions) {
              checkAndSetUsdTicker(subTradeRequest, usdTickers);
              checkAndSetMFSellAllTicker(subTradeRequest, mfSellAllTickers);

              const subAccountId = subTradeRequest.subAccount?.id;

              if (subAccountId) {
                if (!localSubTradeRequestsBySubAccount.has(subAccountId)) {
                  localSubTradeRequestsBySubAccount.set(subAccountId, []);
                }

                localSubTradeRequestsBySubAccount.get(subAccountId)?.push(subTradeRequest);
              }

              continue;
            }
          }

          const trade = buildDraftSubTradeRequest(subTradeRequest, t);

          if (!trade.financialProductId || !trade.subAccountId) continue;

          const key = getKey(trade.financialProductId, trade.subAccountId);

          localDraftTrades.set(key, trade as DraftSubTradeRequest);

          if (subTradeRequest?.blockedUntilAccountActive) blockedUntilAccountActive = true;
          if (subTradeRequest?.blockedUntilSubAccountActive) blockedUntilSubAccountActive = true;
          if (subTradeRequest?.blockedUntil) buildIlliquidProduct(subTradeRequest, illiquidProducts);
        }

        if (localSubTradeRequestsBySubAccount) setSubTradeRequestsBySubAccount(localSubTradeRequestsBySubAccount);

        if (blockedUntilAccountActive) addPortfolioWarning(PortfolioWarningKeys.ACCOUNT_IS_BLOCKED, portfolioWarnings);
        if (blockedUntilSubAccountActive) addPortfolioWarning(PortfolioWarningKeys.SUB_ACCOUNT_IS_BLOCKED, portfolioWarnings);
        if (illiquidProducts.size) addPortfolioWarning(PortfolioWarningKeys.ILLIQUID_PRODUCTS, portfolioWarnings, { datesAndTickers: illiquidProductsToString(illiquidProducts, localizedDate) });
        if (usdTickers.size) addPortfolioWarning(PortfolioWarningKeys.PENDING_TRANSACTION_USD_CURRENCY, portfolioWarnings, { tickers: joinSetValues(usdTickers) });
        if (mfSellAllTickers.size) addPortfolioWarning(PortfolioWarningKeys.PENDING_TRANSACTION_MF_SELL_ALL, portfolioWarnings, { tickers: joinSetValues(mfSellAllTickers) });

        setInitialDraftTrades(new Map(localDraftTrades));
        setPortfolioWarnings(portfolioWarnings);
      }
    },
  });

  const buildPortfolio = useCallback(() => {
    if (!portfolioReport) return;

    const localPortfolioSubAccounts: PortfolioSubAccount[] = [];

    for (const subAccountReport of portfolioReport?.subAccounts ?? []) {
      const { subAccount } = subAccountReport;

      if (!subAccount?.id) continue;

      localPortfolioSubAccounts.push(buildPortfolioSubAccount(subAccountReport, t));
    }

    localPortfolioSubAccounts.sort(sortPortfolioReportSubAccounts);

    setSubAccounts(localPortfolioSubAccounts);

    const localSecurities: PortfolioSecurity[] = [];

    for (const security of portfolioReport?.holdings ?? []) {
      const { financialProduct } = security;

      if (!financialProduct?.id) continue;

      if (!security.taxRank && security.expectedValueCents === 0 && security.valueCents === 0) continue;

      const localSubAccounts = localPortfolioSubAccounts.map((subAccount) => {
        // find a sub-account's holding for this security
        const holding = subAccount?.holdings?.find((h) => h.financialProduct.id === financialProduct.id);

        return { ...subAccount, holding };
      });

      localSecurities.push(buildSecurity(security, localSubAccounts));
    }

    localSecurities.sort(sortSecurities);

    setSecurities(localSecurities);
  }, [t, portfolioReport]);

  useEffect(() => {
    if (!portfolioReportId && canSeePortfolio && id && activeOrganization?.id) {
      fetchPortfolioReports();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (portfolioReportId) {
      refreshPortfolioReport({
        variables: { input: { portfolioReportId } },
      });
    }
  }, [portfolioReportId, refreshPortfolioReport]);

  useEffect(() => {
    if (portfolioReport) {
      buildPortfolio();
    }
  }, [portfolioReport, buildPortfolio]);

  useEffect(() => {
    const isTradesUpdated = areTradesAmountCentsDifferent(draftTrades, initialDraftTrades);

    setIsDraftTradesUpdated(isTradesUpdated);

    if (setIsTradesUpdated) setIsTradesUpdated(isTradesUpdated);
  }, [draftTrades, initialDraftTrades, setIsTradesUpdated]);

  const handleAutoFillToTarget = useCallback(() => {
    if (securities.length) {
      for (const security of securities) {
        if (security.expectedValueCents === (security.subAccounts[0]?.holding?.valueCents ?? 0)) continue;

        const key = getKey(security.id, security.subAccounts[0].id);

        if (initialDraftTrades.has(key)) continue;

        updateDraftTrades(security, security.subAccounts[0], `${round(security.expectedValueCents / 100, 2)}`, draftTrades);
      }

      setDraftTrades(new Map(draftTrades));
    }
  }, [draftTrades, initialDraftTrades, securities]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleHoldingChange = useCallback(
    debounce((security: PortfolioSecurity, subAccount: PortfolioSubAccount, amount: string) => {
      updateDraftTrades(security, subAccount, amount, draftTrades);

      setDraftTrades(new Map(draftTrades));
    }, DEBOUNCE_DELAY),
    [draftTrades],
  );

  const isCashNegative = useCallback((): boolean => {
    const negativeSubAccounts = subAccounts.filter((subAccount) => {
      const pendingTradesCents = sumPendingTradesCentsByCondition([draftTrades, initialDraftTrades], (trade) => trade.subAccountId === subAccount.id);

      return getSubAccountCurrentCents(subAccount, pendingTradesCents) < 0;
    });

    return negativeSubAccounts.length > 0;
  }, [subAccounts, draftTrades, initialDraftTrades]);

  const isConfirmTradesDisabled = useCallback(() => {
    if (isCashNegative()) return true;

    if (!isDraftTradesUpdated) return true;

    return false;
  }, [isCashNegative, isDraftTradesUpdated]);

  const isAutoFillToTargetDisabled = useCallback(() => {
    for (const security of securities) {
      if (security.expectedValueCents !== (security.subAccounts[0]?.holding?.valueCents ?? 0)) return false;
    }

    return true;
  }, [securities]);

  const filterSubAccounts = useCallback(
    (items: PortfolioSubAccount[]) => {
      if (viewInactiveSubAccounts) return items;

      return items.filter((item) => item.state === SubAccountStates.ACTIVE);
    },
    [viewInactiveSubAccounts],
  );

  const isExtendTableRow = !!draftTrades?.size || !!initialDraftTrades.size;

  const autoFillToTarget = () => {
    if (!autoFilled) {
      setAutoFilled(true);
      handleAutoFillToTarget();
    }
  };

  const optimize = async () => {
    if (portfolioReport?.id) {
      setLoadingPortfolioOptimizerPolling(true);

      const portfolioOptimizer = await runPortfolioOptimizer({
        variables: {
          input: {
            organizationId: activeOrganization.id,
            portfolioReportId: portfolioReport.id,
            dryRun: true,
          },
        },
      });

      const portfolioOptimizerId = portfolioOptimizer?.data?.runPortfolioOptimizer?.portfolioOptimizer?.id ?? null;

      if (portfolioOptimizerId) fetchPortfolioOptimizer({ variables: { input: { portfolioOptimizerId } } });
    }
  };

  const canUsePortfolioOptimizer = useCallback(() => {
    if (!activeOrganization.allowPendingTransactions) return true;
    const hasPendingTransactions = subTradeRequestsBySubAccount.size;

    return !hasPendingTransactions;
  }, [activeOrganization?.allowPendingTransactions, subTradeRequestsBySubAccount]);

  if (!canSeePortfolio) {
    return <>{t('shared:noPermission')}</>;
  }

  if (loadingPortfolioReports || loadingRefreshPortfolioReport || loadingSubTrades || loadingPortfolioOptimizerPolling || loadingOptimalPortfolio || loadingPortfolioReportsPolling) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <CircularProgress sx={{ m: 18 }} />
      </Box>
    );
  }

  if (portfolioNotFound) {
    return type === PageObjectType.GOAL ? (
      <Typography color={sys.color.negative}>{t('errorPortfolioViewUnderSubAccount')}</Typography>
    ) : (
      <Typography color={sys.color.negative}>{t('errorPortfolioViewUnderGoal')}</Typography>
    );
  }

  if (isErrorOccurred) {
    return <Typography color={sys.color.negative}>{t('errorOccurredWhileLoadingPortfolio')}</Typography>;
  }

  return (
    <>
      {!hideTitle && (
        <Typography variant='headingSmall' mb={2} data-testid={`${TESTID_PORTFOLIO_WIDGET}-title`}>
          {options?.customTitle ? translateBackend(options.customTitle) : t('portfolio')}
        </Typography>
      )}

      <Card sx={{ overflowX: 'auto' }}>
        <PortfolioWarnings portfolioWarnings={portfolioWarnings} />

        <Box p={2} justifyContent='space-between' display='flex' alignItems='center'>
          <div>
            <Switch size='small' label={t('viewPreTradePositions')} onChange={(e) => setViewPreTradePositions(e)} testId={`${TESTID_PORTFOLIO_WIDGET}-view-pre-trade-positions`} />
            <Switch size='small' label={t('viewInactiveSubAccounts')} onChange={(e) => setViewInactiveSubAccounts(e)} sx={{ mt: 1 }} testId={`${TESTID_PORTFOLIO_WIDGET}-view-inactive-sub-accounts`} />
          </div>

          <div>
            <Grid>
              <Button
                variant='outlined'
                sx={{ mr: '10px' }}
                disabled={!isDraftTradesUpdated}
                onClick={() => {
                  setAutoFilled(false);
                  setDraftTrades(new Map());
                }}
                label={t('revert')}
                dataTestId={`${TESTID_PORTFOLIO_WIDGET}-revert-button`}
              />

              {canSeeAutoRebalance && (
                <>
                  <Button
                    label={t('autoRebalance')}
                    variant='filled'
                    sx={{ mr: '10px' }}
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup='true'
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                    disabled={isAutoFillToTargetDisabled()}
                    dataTestId={`${TESTID_PORTFOLIO_WIDGET}-auto-rebalance-button`}
                  />

                  <Menu anchorEl={anchorEl} open={open} onClose={handleClose} sx={{ mt: 1 }} disableAutoFocus={true} disableAutoFocusItem={true}>
                    <Tooltip
                      title={
                        !canUsePortfolioOptimizer()
                          ? t('optimizerDisabledReason')
                          : ''
                      }
                      placement='top'
                    >
                      <MenuItem disabled={!canUsePortfolioOptimizer()}
                        onClick={() => {
                          optimize();
                          handleClose();
                        }}
                      >
                        {t('optimizer')}
                      </MenuItem>
                    </Tooltip>

                    {subAccounts.length === 1 && (
                      <MenuItem
                        onClick={() => {
                          autoFillToTarget();
                          handleClose();
                        }}
                      >
                        {t('autoFillToTarget')}
                      </MenuItem>
                    )}
                  </Menu>
                </>
              )}

              {!canSeeAutoRebalance && subAccounts.length === 1 && (
                <Button
                  variant='outlined'
                  sx={{ mr: '10px' }}
                  disabled={isAutoFillToTargetDisabled() || autoFilled}
                  onClick={autoFillToTarget}
                  label={t('autoFillToTarget')}
                  dataTestId={`${TESTID_PORTFOLIO_WIDGET}-auto-fill-to-target-button`}
                />
              )}

              <GenerateTradesModal
                type={type}
                id={id}
                trades={Array.from(draftTrades?.values() ?? []).filter((trade: any) => trade.amountCents !== 0)}
                afterCreate={async () => {
                  // await the update of a portfolio report to sync
                  await delay(DELAY);
                  refreshPortfolio();
                  setAutoFilled(false);

                  if (canSeeTradePairsModal) {
                    setOpenTradePairs(true);
                  }
                }}
                modalOpenDisabled={isConfirmTradesDisabled()}
              />
            </Grid>

            <Grid pt={1} textAlign={'right'}>
              {!!portfolioReport?.updatedAt && (
                <Typography color={sys.color.onSurfaceVariant}>
                  {t('portfolioLastUpdated')} {localizedDateTime(portfolioReport?.updatedAt)}
                </Typography>
              )}
            </Grid>
          </div>
        </Box>

        <Grid container>
          <Grid item xs='auto'>
            <Table>
              <TableHead>
                <TableRow>
                  <TableHeadCell testId={TESTID_PORTFOLIO_WIDGET_SECURITIES_TABLE_HEAD_CELL('security')}>{t('portfolioTable.security')}</TableHeadCell>
                  <TableHeadCell testId={TESTID_PORTFOLIO_WIDGET_SECURITIES_TABLE_HEAD_CELL('target')} number>
                    {t('portfolioTable.target')}
                  </TableHeadCell>
                  <TableHeadCell testId={TESTID_PORTFOLIO_WIDGET_SECURITIES_TABLE_HEAD_CELL('current')} number>
                    {t('portfolioTable.current')}
                  </TableHeadCell>
                  <TableHeadCell testId={TESTID_PORTFOLIO_WIDGET_SECURITIES_TABLE_HEAD_CELL('deviation')} number>
                    {t('portfolioTable.deviation')}
                  </TableHeadCell>
                </TableRow>
              </TableHead>

              <TableBody>
                <SecurityTableRow
                  isCash={true}
                  security={{
                    id: 'CASH',
                    ticker: t('portfolioTable.cash'),
                    expectedValueCents: portfolioReport?.expectedCashCents ?? 0,
                    expectedPercentage: portfolioReport?.expectedCashPercentage ?? 0,
                    currentPriceCents: 100,
                    translatedName: t('canadianCash'),
                    taxRank: null,
                    financialProductTaxRank: 99,
                    valueCents: 0,
                    subAccounts: [],
                  }}
                  subAccounts={subAccounts}
                  portfolioReport={portfolioReport}
                  draftTrades={draftTrades}
                  pendingTrades={initialDraftTrades}
                  isMakeHigher={viewPreTradePositions && isExtendTableRow}
                  index={0}
                />

                {securities?.map((security, index) => (
                  <SecurityTableRow
                    key={security.id}
                    security={security}
                    subAccounts={subAccounts}
                    portfolioReport={portfolioReport}
                    draftTrades={draftTrades}
                    pendingTrades={initialDraftTrades}
                    isMakeHigher={viewPreTradePositions && isExtendTableRow}
                    index={index + 1}
                  />
                ))}

                <SecurityTotalTableRow securities={securities} subAccounts={subAccounts} portfolioReport={portfolioReport} draftTrades={draftTrades} pendingTrades={initialDraftTrades} />
              </TableBody>
            </Table>
          </Grid>

          <Grid item sx={{ overflowX: 'auto' }} xs>
            <Table>
              <TableHead>
                <TableRow>
                  {filterSubAccounts(subAccounts).map((subAccount, index) => (
                    <SubAccountTableHeadCell key={subAccount.id} subAccount={subAccount} index={index} />
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                <TableRow key={'subAccount_stats'}>
                  {filterSubAccounts(subAccounts).map((subAccount, index) => (
                    <SubAccountTableCell key={subAccount.id} subAccount={subAccount} draftTrades={draftTrades} pendingTrades={initialDraftTrades} index={index} />
                  ))}
                </TableRow>

                {securities?.map((security, index) => (
                  <TableRow key={security.id} testId={TESTID_PORTFOLIO_WIDGET_HOLDINGS_TABLE_ROW(index)}>
                    {filterSubAccounts(security.subAccounts).map((subAccount, subIndex) => {
                      const subAccountRequests = subTradeRequestsBySubAccount.get(subAccount.id) || [];
                      return (
                        <SubAccountHoldingTableCell
                          key={getKey(security.id, subAccount.id)}
                          security={security}
                          subAccount={subAccount}
                          draftTrades={draftTrades}
                          pendingTrades={initialDraftTrades}
                          handleHoldingChange={handleHoldingChange}
                          viewPreTradePositions={viewPreTradePositions}
                          isMakeHigher={viewPreTradePositions && isExtendTableRow}
                          subAccountTradeRequests={subAccountRequests}
                          index={index}
                          subIndex={subIndex}
                        />
                      );
                    })}
                  </TableRow>
                ))}

                <TableRow key={'subAccount_totals'}>
                  {filterSubAccounts(subAccounts).map((subAccount, index) => (
                    <TableCell key={subAccount.id} testId={TESTID_PORTFOLIO_WIDGET_HOLDINGS_TABLE_ROW_CELL((securities?.length ?? 0) + 1, index)}>
                      <SmallDollarPercentage bold cents={subAccount.marketValueCents} showPercentage={false} />
                    </TableCell>
                  ))}
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
        </Grid>

        <SubTradeRequests
          id={id}
          type={type}
          showComponent={!!initialDraftTrades.size}
          isCancelDisabled={!initialDraftTrades.size}
          openTradePairs={() => setOpenTradePairs(true)}
          showTradePairs={canSeeTradePairsModal}
          afterCancel={async () => {
            // await the update of a portfolio report to sync
            await delay(DELAY);
            setAutoFilled(false);
            refreshPortfolio();
          }}
          afterVerified={async () => {
            // await the update of a portfolio report to sync
            await delay(DELAY);
            refreshPortfolio();
          }}
          testIdPrefix={`${TESTID_PORTFOLIO_WIDGET}-active-sub-trades-table`}
        />

        <SubTradeRequestsTable
          type={type}
          id={id}
          useHeader
          useFilter
          usePagination
          translationStrings={{ noRecordsFound: 'components:generateTrades.noCompletedTradeRequests', header: 'components:generateTrades.completedTradeRequests' }}
          testIdPrefix={`${TESTID_PORTFOLIO_WIDGET}-completed-sub-trades-table`}
        />
      </Card>

      {canSeeTradePairsModal && (
        <SubTradeRequestPairsModal
          open={openTradePairs}
          onClose={() => setOpenTradePairs(false)}
          id={id}
          type={type}
          refetch={refreshPortfolio}
          testIdPrefix={`${TESTID_PORTFOLIO_WIDGET}-paired-sub-trades-table`}
        />
      )}
    </>
  );
};
