import { useTranslation } from 'react-i18next';
import { TextField } from '../..';
import { LocalizedDatePicker, LocalizedDateTimePicker } from '../../../components/fields/localizedDatePicker';

export const DateField = (props: any) => {
  const { t } = useTranslation('pageConfiguration');

  const {
    dataTestId,
    ...otherProps
  } = props;

  if (props?.type === 'DATE_TIME') {
    return (
      <LocalizedDateTimePicker
        label={props.label}
        value={props.value || null}
        disabled={props.disabled}
        openTo={props.openTo ?? 'year'}
        onChange={(date) => props.onChange && props.onChange(date)}
        onAccept={(date) => props.onAccept && props.onAccept(date)}
        renderInput={(params: any) => (
          <TextField
            {...params}
            {...otherProps}
            testId={dataTestId ?? 'custom-datepicker'}
            trailingIcon={props.disabled ? undefined : 'calendar'}
            locked={props.disabled}
            lockMessage={props.lockMessage ?? t('pageConfiguration:notEditableMessage')}
          />
        )}
        minDate={props.minDate}
        shouldDisableDate={props.shouldDisableDate}
        DialogProps={{
          sx: {
            ...(props.hideTimeContainer ? { '.MuiDateTimePickerToolbar-timeContainer': { display: 'none' } } : {}),
          },
        }}
      />
    );
  }
  return (
    <LocalizedDatePicker
      label={props.label}
      value={props.value || null}
      disabled={props.disabled}
      openTo={props.openTo ?? 'year'}
      onChange={(date) => props.onChange && props.onChange(date)}
      onAccept={(date) => props.onAccept && props.onAccept(date)}
      renderInput={(params: any) => (
          <TextField
            {...params}
            {...otherProps}
            testId={dataTestId ?? 'custom-datepicker'}
            trailingIcon={props.disabled ? undefined : 'calendar'}
            locked={props.disabled} lockMessage={props.lockMessage ?? t('pageConfiguration:notEditableMessage')}
          />
      )}
      minDate={props.minDate}
      shouldDisableDate={props.shouldDisableDate}
    />
  );
};

export default DateField;
