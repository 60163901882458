/* eslint-disable react-hooks/exhaustive-deps */
import { gql, useLazyQuery } from '@apollo/client';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import {
  Box, Grid, Skeleton, Typography,
} from '../../../1-primative';
import {
  Card, CardContent, DateField, MenuItem, Pagination, SelectField, Table, TableBody, TableCell, TableHeadCell, TableRow,
} from '../../../2-component';
import {
  SubAccountSelect, AccountTypeSelect, FilterModal,
  SecuritySelect,
} from '../../../3-pattern';
import { TransactionTypes, Currencies } from '../../../../interfaces/transaction';
import { PageObjectType } from '../../../5-page';
import { translateBackend } from '../../../../assets/i18n/config';
import { LedgerCell } from './components/ledgerCell';
import { usePermissions, UserContext } from '../../../../providers/userContextProvider';
import NewTransaction from './components/newTransaction';
import UpdateTransaction from './components/updateTransaction';
import { getUserIdFromPath } from '../../../../util/getUserIdFromPath';
import DownloadLedger, { FETCH_CUSTODIAN_TRANSACTIONS_QUERY, FETCH_TRANSACTIONS_QUERY } from './components/downloadLedger';

const LEFT_ALIGNED_HEADERS = [
  'account', 'client', 'type', 'date', 'currency', 'description', 'custodianType', 'bookValue', 'security', 'accountNumber',
  'accountName', 'nickname',
];

const FETCH_LEDGER = (permissions: string[]) => gql`${FETCH_TRANSACTIONS_QUERY(permissions)}`;

const FETCH_CUSTODIAN_LEDGER = (permissions: string[]) => gql`${FETCH_CUSTODIAN_TRANSACTIONS_QUERY(permissions)}`;

const baseFilter = (objectType: PageObjectType, objectId: string, userId?: string) => {
  switch (objectType) {
    case PageObjectType.INDIVIDUAL:
      return { userId: objectId };
    case PageObjectType.NON_INDIVIDUAL:
      return { userId: objectId };
    case PageObjectType.HOUSEHOLD:
      return { clientGroupId: objectId };
    case PageObjectType.GOAL:
      return {
        goalIds: [objectId],
        userId,
      };
    case PageObjectType.ACCOUNT:
      return { accountIds: [objectId] };
    case PageObjectType.SUB_ACCOUNT:
      return { subAccountIds: [objectId] };
    default:
      return {};
  }
};

export const baseSubAccountFilter = (objectType: PageObjectType, objectId: string, filter: any) => {
  const availableAccountType = filter.accountType && filter.accountType !== 'ANY' ? filter.accountType : undefined;
  switch (objectType) {
    case PageObjectType.INDIVIDUAL:
      return { userId: objectId, accountType: availableAccountType };
    case PageObjectType.NON_INDIVIDUAL:
      return { userId: objectId, accountType: availableAccountType };
    case PageObjectType.HOUSEHOLD:
      return { clientGroupId: objectId, accountType: availableAccountType };
    case PageObjectType.GOAL:
      return { goalId: objectId, accountType: availableAccountType };
    default:
      return { accountType: availableAccountType };
  }
};

export const Ledger = ({ objectId, objectType, options }: { objectId: string, objectType: PageObjectType, options: any }) => {
  const { activeOrganization } = useContext(UserContext);
  const { t } = useTranslation(['components', 'shared', 'accountTypes']);
  const { permissions } = usePermissions();
  const { userId, clientGroupId } = useParams();
  const { pathname: path } = useLocation();
  const [filter, setFilter] = useState<any>({});
  const [page, setPage] = useState<any>(1);
  const [pagination, setPagination] = useState<any>({ offSet: 0, perPage: 15 });
  const [transactions, setTransactions] = useState<any[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [open, setOpen] = useState(false);
  const [activeTransaction, setActiveTransaction] = useState({ id: '', type: TransactionTypes.ADJUSTMENT });

  const useCustodianData = [PageObjectType.ACCOUNT, PageObjectType.HOUSEHOLD, PageObjectType.INDIVIDUAL, PageObjectType.NON_INDIVIDUAL].includes(objectType) && options.useCustodianStatistics;

  const enableAccountTypeFilter = [PageObjectType.HOUSEHOLD, PageObjectType.INDIVIDUAL, PageObjectType.NON_INDIVIDUAL].includes(objectType);

  const DEFAULT_TABLE = [
    { label: t('customReports:column.accountId'), type: 'account' as const },
    { label: t('components:activityTable.userFirstName'), type: 'client' as const },
    { label: t('components:activityTable.type'), type: 'type' as const },
    { label: t('components:trades.security'), type: 'security' as const },
    { label: t('components:tradeTable.currency'), type: 'currency' as const },
    { label: t('components:transaction.table.priceCents'), type: 'costPrice' as const },
    { label: t('components:trades.quantity'), type: 'quantity' as const },
    { label: t('components:tradeTable.totalValue'), type: 'value' as const },
    { label: t('components:transaction.table.date'), type: 'date' as const },
  ];

  const [fetchLedger, { loading, refetch }] = useLazyQuery(FETCH_LEDGER(permissions), {
    fetchPolicy: 'no-cache',
    onCompleted: (data: any) => {
      setTransactions(data.fetchTransactions.transactions);
      setTotalCount(Number(data.fetchTransactions.totalCount || 0));
    },
  });

  const [fetchCustodianLedger, { loading: custodianLoading }] = useLazyQuery(FETCH_CUSTODIAN_LEDGER(permissions), {
    fetchPolicy: 'no-cache',
    onCompleted: (data: any) => {
      setTransactions(data.fetchCustodianTransactions.transactions);
      setTotalCount(Number(data.fetchCustodianTransactions.totalCount || 0));
    },
  });

  const dateAfter = dayjs(filter?.dateAfter).format('YYYY-MM-DD');
  const dateBefore = dayjs(filter?.dateBefore).format('YYYY-MM-DD');

  const queryFilter = {
    ...(useCustodianData ? {} : { subAccountIds: filter.subAccount ? [filter.subAccount.id] : undefined }),
    ...baseFilter(objectType, objectId, getUserIdFromPath({ userId, clientGroupId, path })),
    ...(!enableAccountTypeFilter ? {} : { accountType: filter.accountType || undefined }),
    types: filter.type ? [filter.type] : undefined,
    dateAfter: filter.dateAfter && dayjs(dateAfter).isValid() ? dateAfter : undefined,
    dateBefore: filter.dateBefore && dayjs(dateBefore).isValid() ? dateBefore : undefined,
    pending: filter.pending === 'true' ? true : (filter.pending === 'false' ? false : undefined),
    financialProductIds: filter.financialProductId ? [filter.financialProductId] : undefined,
    currency: filter.currency && filter.currency !== 'ANY' ? filter.currency : undefined,
  };

  useEffect(() => {
    const variables = {
      input: {
        filter: queryFilter,
        pagination: { ...pagination, sortField: 'date', sortDesc: false },
      },
    };
    if (useCustodianData) {
      fetchCustodianLedger({ variables });
    } else {
      fetchLedger({ variables });
    }
  }, [objectType, objectId, filter, pagination]);

  useEffect(() => {
    setPagination({ offSet: (page - 1) * pagination.perPage, perPage: pagination.perPage });
  }, [page]);

  return (
    <>
      <Typography variant='headingSmall'>{options.customTitle ? translateBackend(options.customTitle) : t('components:transaction.title')}</Typography>
      <Card sx={{ mt: 2 }}>
        <CardContent sx={{ pb: 2 }}>
          <Box display='flex' justifyContent='end' width='100%' alignItems='end' >
            <Box display='flex'>
              <FilterModal filterExists={filter.type || filter.subAccount || filter.dateAfter || filter.dateBefore || filter.pending || filter.financialProductId || filter.currency}>
                {/* Type filter */}
                <SelectField
                  value={filter.type}
                  label={t('components:type')}
                  onChange={(e: any) => {
                    setFilter({ ...filter, type: e.target.value });
                    setPage(1);
                  }}
                  fullWidth
                  placeholder={t('components:any')}
                  sx={{ mb: 2 }}
                  clearable
                >
                  {Object.values(TransactionTypes).map((x: TransactionTypes) => (
                    <MenuItem value={x} key={x}>{t(`components:transaction.types.${x}`)}</MenuItem>
                  ))}
                </SelectField>

                {/* Account type filter */}
                {enableAccountTypeFilter && (
                  <Grid container spacing={1} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={12} md={12}>
                      {![PageObjectType.SUB_ACCOUNT, PageObjectType.ACCOUNT].includes(objectType) && (
                        <AccountTypeSelect
                          omitAny
                          bypassFilteringByAvailableFeatureFlags
                          householdId={objectType === PageObjectType.HOUSEHOLD ? objectId : undefined}
                          entityId={objectType === PageObjectType.INDIVIDUAL ? objectId : undefined}
                          size='small'
                          value={filter.accountType}
                          onChange={(accountType: any) => {
                            setFilter({ ...filter, accountType });
                            setPage(1);
                          }}
                        />
                      )}
                    </Grid>
                  </Grid>
                )}

                {/* Sub account filter */}
                {objectType !== PageObjectType.SUB_ACCOUNT && !useCustodianData && (
                  <SubAccountSelect
                    sx={{ mb: 2 }}
                    filter={baseSubAccountFilter(objectType, objectId, filter)}
                    selectedSubAccount={filter.subAccount}
                    onSubAccountSelect={(subAccount: any) => {
                      setFilter({ ...filter, subAccount });
                      setPage(1);
                    }}
                    label={t('components:subAccount')}
                  />
                )}

                {/* Requested/Reconciled filter */}
                {activeOrganization?.allowPendingTransactions && (
                  <SelectField
                    value={filter.pending}
                    label={t('components:transaction.requested')}
                    onChange={(e: any) => {
                      setFilter({ ...filter, pending: e.target.value });
                      setPage(1);
                    }}
                    fullWidth
                    placeholder={t('components:any')}
                    sx={{ mb: 2 }}
                    clearable
                  >
                    <MenuItem value='true'>{t('components:transaction.requested')}</MenuItem>
                    <MenuItem value='false'>{t('components:transaction.reconciled')}</MenuItem>
                  </SelectField>
                )}

                {/* Date filter */}
                <DateField label={t('components:fromDate')} value={filter.dateAfter}
                  onChange={(e: any) => {
                    setFilter({ ...filter, dateAfter: e });
                    setPage(1);
                  }}
                  fullWidth sx={{ mb: 2 }}
                />
                <DateField label={t('components:toDate')} value={filter.dateBefore}
                  onChange={(e: any) => {
                    setFilter({ ...filter, dateBefore: e });
                    setPage(1);
                  }}
                  fullWidth sx={{ mb: 2 }}
                />

                {/* Security/Financial Product filter */}
                <SecuritySelect
                  value={filter.financialProductId ?? ''}
                  setSecurity={(security) => {
                    setFilter({ ...filter, financialProductId: security.id });
                    setPage(1);
                  }}
                  label={t('components:transaction.newTransaction.security')}
                  sx={{ mb: 2 }}
                  withAny
                />

                {/* Currency filter */}
                <SelectField
                  value={filter.currency ?? ''}
                  label={t('components:transaction.table.currency')}
                  onChange={(e: any) => {
                    setFilter({ ...filter, currency: e.target.value });
                    setPage(1);
                  }}
                  fullWidth
                  placeholder={t('components:any') ?? ''}
                  sx={{ mb: 2 }}
                  clearable
                >
                  {Object.values(Currencies).map((currency: string) => (
                    <MenuItem value={currency} key={currency}>
                      {t(`components:transaction.currencies.${currency}`)}
                    </MenuItem>
                  ))}
                </SelectField>

              </FilterModal>
              {(options.enableDownload && permissions.includes('read:api_exports')) && (
                <Box sx={{ ml: 1 }}>
                  <DownloadLedger
                    filter={queryFilter}
                    options={options}
                    useCustodianData={useCustodianData}
                  />
                </Box>
              )}
              {options.canAdd && (
                <Box sx={{ ml: 1 }}>
                  <NewTransaction forObject={objectType} forId={objectId} afterCreate={refetch} />
                </Box>
              )}
            </Box>
          </Box>
        </CardContent>
        <Box sx={{ overflowX: 'auto' }}>
          <Table>
            <TableBody>
              <TableRow>
                {(options.table || DEFAULT_TABLE).map((x: any, idx: number) => (
                  <TableHeadCell key={x} isFirst={idx === 0} right={!LEFT_ALIGNED_HEADERS.includes(x.type)}>{translateBackend(x.label)}</TableHeadCell>
                ))}
              </TableRow>
              {(useCustodianData ? custodianLoading : loading) ? (
                <>
                  {[...Array(15)].map((x, i) => (
                    <TableRow key={i}>
                      {(options.table || DEFAULT_TABLE).map((_: any, idx: number) => (
                        <TableCell dense isFirst={idx === 0}><Skeleton width='100%' height='16px' /></TableCell>
                      ))}
                    </TableRow>
                  ))}
                </>
              ) : (
                <>
                  {transactions.map((transaction: any) => (
                    <TableRow key={transaction.id} hover pointer={options.canEdit} onClick={() => {
                      if (options.canEdit) {
                        setActiveTransaction(transaction);
                        setOpen(true);
                      }
                    }}>
                      {(options.table || DEFAULT_TABLE).map((x: any, idx: number) => (
                        <LedgerCell
                          key={`${transaction.id}-${x.type}`}
                          isFirst={idx === 0}
                          transaction={transaction}
                          type={x.type}
                          view={objectType}
                        />
                      ))}
                    </TableRow>
                  ))}
                </>
              )}
              {transactions.length === 0 && !(useCustodianData ? custodianLoading : loading) && (
                <TableRow>
                  <TableCell colSpan={(options.table || DEFAULT_TABLE).length} sx={{ textAlign: 'center' }}>{t('components:transaction.noTransactions')}</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Box>
        <UpdateTransaction open={open} handleClose={() => setOpen(false)} transaction={activeTransaction} afterUpdate={refetch} options={options} />
        <Box display='flex' justifyContent='flex-end' sx={{ p: 1 }}>
          <Pagination
            size='small'
            page={page}
            perPage={pagination.perPage}
            count={Math.ceil(totalCount / pagination.perPage)}
            onChange={(e, newPage) => setPage(newPage)}
            onChangePerPage={(newPerPage) => setPagination({ ...pagination, perPage: newPerPage })}
          />
        </Box>
      </Card>
    </>
  );
};
