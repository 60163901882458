import { useContext, useState } from 'react';
import { ButtonBase, Icon } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {
  Box, ClientPageInterface, PageObjectType, TabInterface, Typography,
} from '../..';
import { useThemeTokens } from '../../../providers/themeTokenProvider';
import { useTabNavTokens } from './tabNav.tokens';
import { translateBackend } from '../../../assets/i18n/config';
import { EditTab } from '../../5-page/pageConfiguration/components/editTab';
import { AnalyticsContext } from '../../../providers/analyticsProvider';
import { ovAnalyticsEvents } from '../../../util/analytics/analytics';

export const NewTab = ({
  icon, label, isActive = false, i, url, setActiveTab, isPageConfigurationView,
  page, setPage, testId = 'tab',
}: {
  icon?: string, label: string, i: number, isActive?: boolean, url?: string, setActiveTab: (tab: any) => void, isPageConfigurationView?: boolean,
  page?: ClientPageInterface, setPage?: (page: any) => void, testId?: string,
}) => {
  const [hover, setHover] = useState(false);
  const tokens = useThemeTokens(useTabNavTokens());
  const { comp } = tokens;

  return (
    <ButtonBase
      data-testid={testId}
      sx={{
        justifyContent: 'center',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        color: isActive ? comp.tabNav.text.active : comp.tabNav.text.default,
        borderRadius: comp.tabNav.borderRadius,
        backgroundColor: isActive ? comp.tabNav.background.active : comp.tabNav.background.default,
        height: comp.tabNav.height,
        width: comp.tabNav.width,
        whiteSpace: comp.tabNav.width === 'auto' ? 'nowrap' : 'normal',
        padding: comp.tabNav.padding,
        mr: 1.5,
        '&:hover': {
          color: comp.tabNav.text.hover,
          cursor: 'pointer',
        },
        '&:focus': {
          backgroundColor: comp.tabNav.background.hover,
          color: comp.tabNav.text.hover,
          cursor: 'pointer',
        },
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={() => {
        if (url) {
          window.open(url, '_blank');
          if (isPageConfigurationView) setActiveTab(i);
        } else {
          setActiveTab(i);
        }
      }}>
      {icon && (
        <Icon sx={{ fontSize: '14px !important', mr: 0.5 }}>{icon}</Icon>
      )}
      <Typography variant='bodySmall' weight='bold'>{label}</Typography>
      {url && hover && (
        <OpenInNewIcon sx={{ fontSize: '14px', ml: 1 }} />
      )}
      {isPageConfigurationView && hover && page && setPage && (
        <EditTab page={page} activeTab={i} setPage={setPage} resetHoverState={() => setHover(false)} />
      )
      }
    </ButtonBase>
  );
};

export const TabNav = (
  {
    tabs, activeTab, setActiveTab, isPageConfigurationView, page, setPage, objectType,
  }: {
    tabs: TabInterface[], activeTab: number, setActiveTab: (tab: number) => void, isPageConfigurationView?: boolean,
    page?: ClientPageInterface, setPage?: (page: any) => void, objectType?: PageObjectType,
  },
) => {
  const { sendAnalytic } = useContext(AnalyticsContext);
  const pageObjectTypeAnalyticsName: Map<PageObjectType, string> = new Map([
    [PageObjectType.INDIVIDUAL, 'clients_profile'],
    [PageObjectType.GOAL, 'clients_goal_profile'],
    [PageObjectType.SUB_ACCOUNT, 'clients_subAccount_profile'],
    [PageObjectType.ACCOUNT, 'clients_account_profile'],
    [PageObjectType.NON_INDIVIDUAL, 'clients_non_individual_profile'],
    [PageObjectType.HOUSEHOLD, 'clients_household_profile'],
  ]);
  const handleAnalytics = (name: string): void => {
    if (objectType) {
      const prefix = pageObjectTypeAnalyticsName.get(objectType);
      if (prefix) sendAnalytic(ovAnalyticsEvents.tabSelect(prefix, name));
    }
  };
  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'start',
      flexDirection: 'row',
      mt: 3,
      mb: 2,
      overflow: 'auto',
      width: '100%',
    }}>
      {tabs.map((tab, index) => (
        <NewTab
          testId={`tab-${index}`}
          key={index}
          icon={tab.icon}
          label={translateBackend(tab.label)}
          isActive={index === activeTab}
          i={index}
          url={tab.url}
          setActiveTab={(indexOfActiveTab) => {
            handleAnalytics((tab.label.en ?? '').toLowerCase());
            setActiveTab(indexOfActiveTab);
          }}
          isPageConfigurationView={isPageConfigurationView}
          page={page}
          setPage={setPage}
        />
      ))}
    </Box>
  );
};
