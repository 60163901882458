import { useContext } from 'react';
import { gql, useQuery } from '@apollo/client';
import { kebabCase } from 'lodash';
import { UserContext } from '../../../providers/userContextProvider';
import { Autocomplete, MenuItem, SelectField } from '../../2-component';

export const FETCH_ROLES = gql`
  query fetchRoles($input: FetchRolesInput!) {
    fetchRoles(input: $input) {
      roles {
        id
        organization {
          id
        }
        translatedName {
          en
        }
        translatedDescription {
          en
        }
      }
    }
  }
`;

export const MulitRoleSelect = ({
  selectedRoleIds, onChange, label, onBlur, error,
}: { selectedRoleIds: any[]; onChange: (e: any) => void; label: string; onBlur?: () => void; error?: boolean }) => {
  const { activeOrganization } = useContext(UserContext);
  const roles = useQuery(FETCH_ROLES, {
    variables: {
      input: {
        filter: { organizationId: activeOrganization.id },
      },
    },
  });

  if (roles.loading) return <div></div>;

  const getRoleNames = (roleIds: any): string[] => roleIds
    .map((role: any) => {
      const fetchedRole = roles?.data?.fetchRoles?.roles?.find((fetched: any) => fetched?.id === (role.id || role));
      return { id: fetchedRole?.id, name: fetchedRole?.translatedName?.en };
    })
    .filter((obj: any) => Object.keys(obj).length !== 0);

  return (
    <Autocomplete
      label={label}
      options={
        roles?.data?.fetchRoles?.roles?.map((x: any) => ({
          key: x.id,
          label: x.translatedName.en,
        })) || []
      }
      value={
        getRoleNames(selectedRoleIds || [])?.map((x: any) => ({
          key: x.id,
          label: x.name,
        })) || []
      }
      isOptionEqualToValue={(option: any, value: any) => option.key === value.key}
      multiple
      fullWidth
      onChange={(e: React.SyntheticEvent, value: any) => onChange(value.map((x: any) => x.key))}
      onBlur={onBlur}
      error={error}
    />
  );
};

export const RoleSelect = ({
  onChange,
  value,
  label,
  disabled = false,
  includeEmpty = false,
  size,
  testId,
}: {
  onChange: (event: any) => void;
  value: string;
  label: string;
  disabled?: boolean;
  includeEmpty?: boolean;
  size?: 'small' | 'medium' | undefined;
  testId?: string;
}) => {
  const { activeOrganization } = useContext(UserContext);
  const { loading, data } = useQuery(FETCH_ROLES, {
    variables: {
      input: {
        filter: { organizationId: activeOrganization.id },
        pagination: { perPage: 50 },
      },
    },
  });

  return (
    <>
      <SelectField value={data ? value : ''} label={label} fullWidth onChange={onChange} size={size} disabled={disabled} testId={testId ?? 'role-select'}>
        {includeEmpty && (
          <MenuItem key='empty' value=''>
            -
          </MenuItem>
        )}
        {loading ? (
          <MenuItem>...</MenuItem>
        ) : (
          data.fetchRoles.roles.map((x: any) => (
            <MenuItem data-testId={testId ? `${testId}-${kebabCase(x.translatedName.en)}` : `role-select-${kebabCase(x.translatedName.en)}`} key={x.id} value={x.id}>
              {x.translatedName.en}
            </MenuItem>
          ))
        )}
      </SelectField>
    </>
  );
};
