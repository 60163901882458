import { useContext, useEffect } from 'react';
import { CurrencyCodes } from '@onevesthq/ov-enums';
import { gql, useLazyQuery } from '@apollo/client';
import { Selector } from '../../2-component';
import { UserContext } from '../../../providers/userContextProvider';
import { getCurrencyFlag, processAvailableCurrencies, processClientGroupCurrencies } from '../../../util/currency';

export const FETCH_USER_AVAILABLE_CURRENCIES = gql`
  query fetchUserAvailableCurrencies($userId: ObjectID!) {
    fetchUser(userId: $userId) {
      user{
        id
        availableCurrencies
        households {
          id
          availableCurrencies
          relationships {
            user {
              id
            }
            accessType
          }
        }
      }
    }
  }
`;

export const FETCH_CLIENT_GROUP_AVAILABLE_CURRENCIES = gql`
  query fetchClientGroupAvailableCurrencies($clientGroupId: ObjectID!) {
    fetchClientGroup(clientGroupId: $clientGroupId) {
      clientGroup{
        id
        availableCurrencies
        relationships{
          user {
            id
            availableCurrencies
          }
        }
      }
    }
  }
`;

interface UseAvailableCurrenciesProps {
  userId?: string;
  clientGroupId?: string;
}

export const useAvailableCurrencies = ({ userId, clientGroupId }: UseAvailableCurrenciesProps) => {
  const { baseCurrency, setAvailableCurrencies } = useContext(UserContext);

  const [fetchUserCurrencies] = useLazyQuery(FETCH_USER_AVAILABLE_CURRENCIES, {
    variables: { userId },
    onCompleted: (data) => {
      if (data?.fetchUser?.user) {
        const { user } = data.fetchUser;
        const processedCurrencies = processAvailableCurrencies(
          baseCurrency,
          user?.availableCurrencies,
          user?.households,
        );
        setAvailableCurrencies(processedCurrencies);
      }
    },
  });

  const [fetchClientGroupCurrencies] = useLazyQuery(FETCH_CLIENT_GROUP_AVAILABLE_CURRENCIES, {
    variables: { clientGroupId },
    onCompleted: (data) => {
      if (data?.fetchClientGroup?.clientGroup) {
        const { clientGroup } = data.fetchClientGroup;
        const processedCurrencies = processClientGroupCurrencies(
          baseCurrency,
          clientGroup,
        );
        setAvailableCurrencies(processedCurrencies);
      }
    },
  });

  useEffect(() => {
    if (userId) {
      fetchUserCurrencies();
    } else if (clientGroupId) {
      fetchClientGroupCurrencies();
    }
  }, [userId, clientGroupId, baseCurrency, fetchUserCurrencies, fetchClientGroupCurrencies]);

  return {
    fetchUserCurrencies,
    fetchClientGroupCurrencies,
  };
};

export const MultiCurrencySelector = ({ userId, clientGroupId }: { userId?: string, clientGroupId?: string }) => {
  const {
    activeCurrency,
    setActiveCurrency,
    availableCurrencies,
  } = useContext(UserContext);

  useAvailableCurrencies({ userId, clientGroupId });

  const options = availableCurrencies.map((elem: string) => ({
    label: `Show all in ${elem}`,
    avatar: getCurrencyFlag(elem as CurrencyCodes),
    type: elem,
  }));

  return (
    <Selector
      testId='multi-currency-selector'
      options={options}
      sx={{
        ml: 2, mt: 3, mb: 2,
      }}
      disabled={options.length === 1}
      optionsPosition='right'
      selectedOptionType={activeCurrency}
      onChange={(e: any) => setActiveCurrency(e.target.value)}
      renderValue={(selected: string) => selected.slice(-3)}
    />
  );
};
