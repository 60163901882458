import ReactMarkdown from 'react-markdown';
import { translateBackend } from 'assets/i18n/config';
import { useTranslation } from 'react-i18next';
import remarkGfm from 'remark-gfm';
import {
  Typography, Form, Box, Button,
} from '../../../..';
import { PieChart } from '../../../../2-component/pieChart/pieChart';
import { Divider } from '../../../../2-component/divider/divider';
import { RiskLevelBox } from '../../../../3-pattern/riskLevelBox/riskLevelBox';
import { useThemeTokens } from '../../../../../providers/themeTokenProvider';

export const RiskProfileVisual = ({
  options,
  riskProfileData,
  loading,
  continueFunc,
}: {
  options: any,
  riskProfileData?: any,
  loading: boolean,
  continueFunc: any,
}) => {
  const getMarkdown = (): string => {
    if (options?.filledTemplate) {
      return translateBackend(options?.filledTemplate);
    }
    return translateBackend(options?.template);
  };

  const { t } = useTranslation('riskProfile');
  const { ref } = useThemeTokens();
  const { children } = riskProfileData;
  let dataPercentage: number[] = [];
  let dataLabels: string[] = [];
  const groupedData: Record<string, { percentage: number, translatedName: string }> = {};
  const submit = () => {
    continueFunc();
  };

  const colors = [
    ref.palette.supportOne50,
    ref.palette.supportTwo50,
    ref.palette.supportThree50,
    ref.palette.supportFour50,
    ref.palette.supportFive50,
    ref.palette.supportSix50,
  ];

  if (children) {
    children.forEach(({ percentage, financialProduct }: any) => {
      const { key, translatedName } = financialProduct.primaryAssetClass;

      if (groupedData[key]) {
        groupedData[key].percentage += percentage;
      } else {
        groupedData[key] = { percentage, translatedName: translatedName.en };
      }
    });

    const sortedData = Object.entries(groupedData).map(([key, value]) => ({
      key,
      dataPercentage: value.percentage,
      dataLabels: value.translatedName,
    })).sort((a, b) => b.dataPercentage - a.dataPercentage);

    dataPercentage = Object.values(sortedData).map((group) => group.dataPercentage);
    dataLabels = Object.values(sortedData).map((group) => group.dataLabels);
  }

  const riskProfileIsnotAvailable = t('riskProfileIsNotAvailable');
  const riskLevel = (riskProfileData?.riskProfileName) ? (riskProfileData?.riskProfileName) : riskProfileIsnotAvailable;
  const riskLevelDescription = riskProfileData?.riskProfileDescription || '';
  const isEmptyData = dataPercentage.length === 0 && dataLabels.length === 0;

  return (
    <Form onSubmit={submit}>
      <Typography variant='displayLarge' sx={{ mt: 1, mb: 3 }}>{translateBackend(options?.title)}</Typography>
      <Typography variant='bodyLarge' sx={{ mb: 3 }}>{translateBackend(options?.subtitle)}</Typography>
      <RiskLevelBox riskLevel={riskLevel} riskLevelDescription={riskLevelDescription} ></RiskLevelBox>

      {(options?.displayAssetMix && !isEmptyData) && (
        <>
          <Typography weight='bold' variant='bodyLarge'>{t('recommendedAssetMix')}</Typography>
          <Box display={'flex'} justifyContent={'start'} gap={4} paddingTop={'5%'}>
            <Box width={'30%'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
              <PieChart
                datasets={[{
                  label: '',
                  data: dataPercentage,
                  backgroundColors: colors,
                }]}
                labels={dataLabels}
                height='165px'
                width='165px'
              />
            </Box>
            <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} gap={1}>
              {dataLabels.map((label, index) => (
                <Box key={label} display={'flex'} alignItems={'center'} marginBottom={'2%'}>
                  <Box sx={{
                    width: 15,
                    height: 15,
                    backgroundColor: colors[index],
                    borderRadius: '25%',
                    marginRight: '10px',
                  }}
                  />
                  <Box display={'flex'} flexGrow={1} marginRight={'25px'}>
                    <Typography>{label} </Typography>
                  </Box>
                  <Box textAlign={'center'}>
                    <Typography weight='bold'>{dataPercentage[index]}%</Typography>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        </>
      )}
      {(options?.factorsAffectingScore && !isEmptyData) && (
        <>
          <Box>
            <Typography paddingTop={'5%'} weight='bold' variant='bodyLarge' sx={{ mb: 1 }}>{t('factorAffectingScore')}
            </Typography>
            <Divider />
            {options?.template && (
              <>
                <Typography sx={{
                  mt: 1, mb: 2, fontSize: '14px', lineHeight: '20px', letterSpacing: '0.5px',
                }}>
                  <ReactMarkdown remarkPlugins={[remarkGfm]}>{getMarkdown()}</ReactMarkdown>
                </Typography>
              </>
            )}
          </Box>
          {options?.displayInvestmentKnowledge && (
            <Typography sx={{ lineHeight: '20px', letterSpacing: '0.5px' }} mb={2} mr={1} variant='bodyMedium'>{t('investmentKnowledge')} :
              <Box sx={{ fontWeight: 'bold', display: 'inline-block' }} ml={1}>{t(`investmentKnowledgeOptions.${riskProfileData.investmentKnowledge}`)}</Box>
            </Typography>
          )}
          {options?.displayTimeHorizon && (riskProfileData.timeHorizon !== null) && (
            <Typography mb={2} mr={1} variant='bodyMedium'>{t('timeHorizon')} :
            <Box sx={{ fontWeight: 'bold', display: 'inline-block' }} ml={1}>{t(`goalTimeHorizon.${riskProfileData.timeHorizon}`)}</Box>
            </Typography>
          )}
        </>
      )}
      <Box display='flex' justifyContent='end'>
        <Button data-testid="continue-button" label={t('workflowCompletions:continue')} disabled={loading} sx={{ mt: 3, textAlign: 'center' }} type='submit' />
      </Box>
    </Form>
  );
};
