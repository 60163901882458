import { useTranslation } from 'react-i18next';
import { TableTotalCell } from '../../../../2-component';
import { formatMoneyValue, formatPercentValue, getBookCostCents } from '../../../../../util';
import { useThemeTokens } from '../../../../../providers/themeTokenProvider';
import { Account } from '../../../../../interfaces';
import { BookValueType } from '../../../../../providers/statsHooks';

export const AccountTotalCell = ({
  accounts, type, totalMarketValue, useExternalStatisticsEnabled, isFirst, dataTestId = 'account-total-cell',
}: { accounts: Account[], type: string, totalMarketValue: number, useExternalStatisticsEnabled?: boolean, isFirst?: boolean, dataTestId?: string }) => {
  const { t } = useTranslation('client');
  const { sys } = useThemeTokens();

  const { totalInvestedMarketValueCents, totalBookCostCents } = accounts.reduce((acc, account) => {
    const statistics = useExternalStatisticsEnabled ? account.custodianStatistics : account.statistics;
    const investedMarketValueCents = acc.totalInvestedMarketValueCents + (
      statistics?.holdings?.reduce((p, h) => (
        p + (!h.financialProduct?.isCash && !!h.adjustedCostBaseCents ? (h.totalCents ?? 0) : 0)
      ), 0) ?? 0
    );
    const bookCostCents = acc.totalBookCostCents + (
      statistics?.holdings?.reduce((p, h) => (
        p + getBookCostCents(h, BookValueType.ACCOUNT)
      ), 0) ?? 0
    );
    return { totalInvestedMarketValueCents: investedMarketValueCents, totalBookCostCents: bookCostCents };
  }, { totalInvestedMarketValueCents: 0, totalBookCostCents: 0 });
  const unrealizedGainsAndLossCents = totalInvestedMarketValueCents - totalBookCostCents;
  const unrealizedGainsAndLossPercent = totalBookCostCents !== 0 ? (unrealizedGainsAndLossCents / Math.abs(totalBookCostCents)) : 0;

  const getUnrealizedGainLoss = () => (
    <>
      {`${formatMoneyValue(unrealizedGainsAndLossCents)}
      (${formatPercentValue(unrealizedGainsAndLossPercent)})`}
    </>
  );

  switch (type) {
    case 'holder':
      return (
        <TableTotalCell testId={dataTestId} isFirst={isFirst}>{t('accountTable.totalSum')}</TableTotalCell>
      );
    case 'nickname':
      return (
        <TableTotalCell testId={dataTestId} isFirst={isFirst}></TableTotalCell>
      );
    case 'name':
      return (
        <TableTotalCell testId={dataTestId} isFirst={isFirst}></TableTotalCell>
      );
    case 'accountNumber':
      return (
        <TableTotalCell testId={dataTestId} isFirst={isFirst}></TableTotalCell>
      );
    case 'program':
      return (
        <TableTotalCell testId={dataTestId} isFirst={isFirst}></TableTotalCell>
      );
    case 'unrealizedGainLoss':
      return (
        <TableTotalCell testId={dataTestId} number isFirst={isFirst} sx={{
          textAlign: 'right',
          color: unrealizedGainsAndLossCents === 0 ? sys.color.onSurface : unrealizedGainsAndLossCents > 0 ? sys.color.positive : sys.color.negative,
          borderBottom: 'none',
        }}>
          {getUnrealizedGainLoss()}
        </TableTotalCell>
      );
    case 'bookCost':
      return (
        <TableTotalCell testId={dataTestId} number isFirst={isFirst} sx={{
          textAlign: 'right',
          borderBottom: 'none',
        }}>
          {formatMoneyValue(totalBookCostCents)}
        </TableTotalCell>
      );
    case 'percentOfTotal':
      return (
        <TableTotalCell testId={dataTestId} isFirst={isFirst} right>100%</TableTotalCell>
      );
    case 'currentValue':
      return (
        <TableTotalCell testId={dataTestId} isFirst={isFirst} right bold number>{formatMoneyValue(totalMarketValue)}</TableTotalCell>
      );
    default:
      return (
        <TableTotalCell testId={dataTestId} isFirst={isFirst}>-</TableTotalCell>
      );
  }
};
