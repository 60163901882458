import { useContext, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  Typography, Link as MuiLink,
  Skeleton,
} from '../../1-primative';
import {
  MenuItem, Table, TableBody, TableCell, TableHeadCell,
  TableRow, Pagination, Badge, Card,
  CardContent, SelectField,
} from '../../2-component';
import { UserContext } from '../../../providers/userContextProvider';
import { usePageState } from '../../../util/usePageState';
import { BankAccountDocumentViewer } from './components/BankAccountDocumentViewer';
import { Date } from '../../../components/misc/date/date';
import { useLocalization } from '../../../util/useLocalization';
import { generateClientNameString } from '../../../util';

export const FETCH_BANK_ACCOUNTS = gql`
  query fetchBankAccounts($input: FetchBankAccountsInput!) {
    fetchBankAccounts(input: $input) {
      bankAccounts {
        id
        name
        state
        bankAccountNumber
        transitNumber
        institutionNumber
        user{
          id
          firstName
          entityName
          lastName
          organization{
            id
            name
          }
        }
        createdAt
        updatedAt
        stateChanges
      }
      totalCount
    }
  }
`;
export const stateBankAccountStateColor = (state: any) => (state === 'APPROVED' ? 'positive' : ['REJECTED'].includes(state) ? 'negative' : 'warning');
const calculateDaysWaiting = (bankAccount: any): number => {
  const createdDate = dayjs(bankAccount.createdAt).utc();
  const toDay = dayjs().utc();
  return toDay.diff(createdDate, 'days') || 0;
};

export const BankAccountsToReview = () => {
  const { activeOrganization } = useContext(UserContext);
  const { t } = useTranslation(['bankAccountsReview', 'client']);
  const { localizedDate } = useLocalization();
  const [page, setPage] = usePageState(1, 'page');
  const [currentBankAccount, setCurrentBankAccount] = useState<any>({});
  const [pageSize, setPageSize] = usePageState(15, 'pageSize');

  const [filterState, setFilterState] = usePageState('AWAITING_REVIEW', 'state');

  const [openViewer, setOpenViewer] = useState(false);

  const {
    loading, error, data, previousData,
  } = useQuery(FETCH_BANK_ACCOUNTS, {
    variables: {
      input: {
        filter: {
          state: filterState === 'ANY' ? undefined : filterState,
          organizationId: activeOrganization.id,
        },
        pagination: {
          sortField: 'createdAt', sortDesc: filterState !== 'AWAITING_REVIEW', perPage: pageSize, offSet: (page - 1) * pageSize,
        },
      },
    },
  });

  if (error) (<Typography>Error</Typography>);
  return (
    <Card loading={loading}>
      <CardContent>
        <SelectField
          label=''
          value={filterState || ''}
          onChange={(e: any) => {
            setPage(1);
            setFilterState(e.target.value);
          }}>
          <MenuItem value='ANY'>{t('bankAccountsReview:filters.any')}</MenuItem>
          <MenuItem value='AWAITING_REVIEW'>{t('bankAccountsReview:filters.awaitingReview')}</MenuItem>
          <MenuItem value='APPROVED'>{t('bankAccountsReview:filters.approved')}</MenuItem>
          <MenuItem value='REJECTED'>{t('bankAccountsReview:filters.rejected')}</MenuItem>
        </SelectField>
      </CardContent>
      <Table aria-label="table">
        <TableBody>
          <TableRow>
            <TableHeadCell>{t('bankAccountsReview:filters.clientName')}</TableHeadCell>
            <TableHeadCell>{t('bankAccountsReview:filters.organization')}</TableHeadCell>
            <TableHeadCell>{t('bankAccountsReview:filters.state')}</TableHeadCell>
            <TableHeadCell>{t('bankAccountsReview:filters.requestedDate')}</TableHeadCell>
            <TableHeadCell>{t('bankAccountsReview:filters.approvedDate')}</TableHeadCell>
            <TableHeadCell>{t('bankAccountsReview:filters.daysWaiting')}</TableHeadCell>
            <TableHeadCell>{t('bankAccountsReview:filters.bankAccountNumber')}</TableHeadCell>
          </TableRow>
          { loading && !previousData && [...Array(15)].map((x: any, i: number) => (
            <TableRow key={i}>
              <TableCell><Skeleton width='100%' /></TableCell>
              <TableCell><Skeleton width='100%' /></TableCell>
              <TableCell><Skeleton width='100%' /></TableCell>
              <TableCell><Skeleton width='100%' /></TableCell>
              <TableCell><Skeleton width='100%' /></TableCell>
              <TableCell><Skeleton width='100%' /></TableCell>
              <TableCell><Skeleton width='100%' /></TableCell>
            </TableRow>
          ))}
          {(data || previousData)?.fetchBankAccounts?.bankAccounts?.map((bankAccount: any) => (
            <TableRow
              hover
              pointer
              key={bankAccount.id}
              onClick={() => {
                setCurrentBankAccount(bankAccount);
                setOpenViewer(true);
              }}
            >
              <TableCell component="th" scope="row">
                <MuiLink component={Link} to={`/clients/${bankAccount.user.id}`}>{generateClientNameString(bankAccount.user, false, true)}</MuiLink>
              </TableCell>
              <TableCell component="th" scope="row">
                {bankAccount.user.organization.name}
              </TableCell>
              <TableCell component="th" scope="row">
                <Badge label={t(`bankAccountsReview:filters.stateOptions.${bankAccount.state}`)} color={stateBankAccountStateColor(bankAccount.state)} />
              </TableCell>
              <TableCell component="th" scope="row">
                <Date date={bankAccount.createdAt} fontSize='small' />
              </TableCell>
              <TableCell component="th" scope="row">
                {bankAccount.state !== 'APPROVED' ? '-' : (<Typography fontSize='small'>{localizedDate(bankAccount.updatedAt)}</Typography>)}
              </TableCell>
              <TableCell component="th" scope="row" sx={{ color: calculateDaysWaiting(bankAccount) > 3 ? 'red' : 'inherit' }}>
                {['APPROVED', 'REJECTED'].includes(bankAccount.state) && '-'}
                {bankAccount.state === 'AWAITING_REVIEW'
                  && (<Badge label={calculateDaysWaiting(bankAccount).toString()} color={calculateDaysWaiting(bankAccount) > 3 ? 'negative' : 'positive'} />)}
              </TableCell>
              <TableCell component="th" scope="row">
                {bankAccount.bankAccountNumber}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Pagination
        onChangePerPage={setPageSize}
        count={Math.ceil(((data || previousData)?.fetchBankAccounts?.totalCount ?? 0) / pageSize)}
        page={page}
        onChange={(_e, newPage) => setPage(newPage)}
        sx={{
          p: 1,
          textAlign: 'right',
          '.MuiPagination-ul': {
            justifyContent: 'end',
          },
        }}
      />
      <BankAccountDocumentViewer
        open={openViewer}
        handleClose={() => setOpenViewer(false)}
        bankAccount={currentBankAccount}
      />
    </Card>
  );
};

export default BankAccountsToReview;
