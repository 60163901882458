import { CurrencyCodes } from '@onevesthq/ov-enums';
import { useThemeTokens } from '../../../../providers/themeTokenProvider';

export interface HoldingAttributes {
  key: string;
  hexColor: string;
  secondaryAssetClassName: string;
  secondaryAssetClassDescription: string;
  percentage: number;
  totalCents?: number;
}
export const getAssetBarIndeterminateColor = (index: number): string => {
  const arrayOfAshColors = ['#EEF1F4', '#E5E8EB', '#DBDEE0', '#D1D4D6', '#C7CACC'];
  return arrayOfAshColors[index];
};

export interface FinancialProduct {
  id: number;
  name: number;
  ticker: string;
  translatedName: {
    en: string;
    fr: string;
  };
  translatedPortfolioDescription: {
    en: string;
    fr: string;
  };
}
export interface SecurityHoldings {
  percentage: number;
  totalCents: number;
  quantity: number;
  financialProduct: FinancialProduct;
}
export interface SecondaryAssetClassHoldings {
  assetClass: AssetClass;
  percentage: number;
  totalCents: number;
  securityHoldings: SecurityHoldings[];
}
export interface PrimaryAssetClassHoldings {
  assetClass: AssetClass;
  percentage: number;
  totalCents: number;
  secondaryAssetClassHoldings: SecondaryAssetClassHoldings[];
}
export interface AssetClass {
  key: string;
  translatedName: {
    en: string;
    fr: string;
  };
  translatedDescription: {
    en: string;
    fr: string;
  };
}

export interface HoldingResponse {
  financialProduct: {
    id: number;
    name: number;
    ticker: string;
    translatedName: { en: string; fr: string };
    translatedPortfolioDescription: { en: string; fr: string };
    primaryAssetClass: AssetClass;
    secondaryAssetClass: AssetClass;
  };
  percentage?: number;
  totalCents?: number;
  quantity?: number;
  originalCurrency?: {
    currency: CurrencyCodes;
    totalCents: number;
  };
  userBookValue?: number;
  goalBookValue?: number;
  accountBookValue?: number;
  subAccountBookValue?: number;
  householdClientGroupBookValue?: number;
}

export const useSupportColors = () => {
  const { ref } = useThemeTokens();

  return [
    { active: ref.palette.supportOne50, inactive: ref.palette.supportOne40 },
    { active: ref.palette.supportTwo50, inactive: ref.palette.supportTwo40 },
    { active: ref.palette.supportThree50, inactive: ref.palette.supportThree40 },
    { active: ref.palette.supportFour50, inactive: ref.palette.supportFour40 },
    { active: ref.palette.supportFive50, inactive: ref.palette.supportFive40 },
    { active: ref.palette.supportSix50, inactive: ref.palette.supportSix40 },
  ];
};

export const calculatePrimaryAssetClassHoldings = (holdings: any[], totalCents: number): any[] => {
  const primaryAssetClassHoldings = holdings.reduce((result: any[], current: HoldingResponse) => {
    if (!current.financialProduct.primaryAssetClass || !current.financialProduct.secondaryAssetClass) {
      // Skipping if primaryAssetClass or secondaryAssetClass is null
      return result;
    }
    const index = result.findIndex((a) => a.assetClass.key === current.financialProduct.primaryAssetClass.key);
    if (index === -1) {
      result.push({
        assetClass: { ...current.financialProduct.primaryAssetClass },
        totalCents: current?.originalCurrency?.totalCents ?? current.totalCents ?? 0,
        secondaryAssetClassHoldings: [
          {
            assetClass: { ...current.financialProduct.secondaryAssetClass },
            totalCents: current?.originalCurrency?.totalCents ?? current.totalCents ?? 0,
            securityHoldings: [
              {
                financialProduct: { ...current.financialProduct },
                totalCents: current?.originalCurrency?.totalCents ?? current.totalCents ?? 0,
                quantity: current.quantity ?? 0,
              },
            ],
          },
        ],
      });
    } else {
      const { secondaryAssetClassHoldings } = result[index];
      const assetClassIndex = secondaryAssetClassHoldings.findIndex((a: SecondaryAssetClassHoldings) => a.assetClass.key === current.financialProduct.secondaryAssetClass.key);
      if (assetClassIndex === -1) {
        secondaryAssetClassHoldings.push({
          assetClass: { ...current.financialProduct.secondaryAssetClass },
          totalCents: current?.originalCurrency?.totalCents ?? current.totalCents ?? 0,
          securityHoldings: [
            {
              financialProduct: { ...current.financialProduct },
              totalCents: current?.originalCurrency?.totalCents ?? current.totalCents ?? 0,
              quantity: current.quantity ?? 0,
            },
          ],
        });
      } else {
        const { securityHoldings } = secondaryAssetClassHoldings[assetClassIndex];
        const securityHoldingsIndex = securityHoldings.findIndex((a: SecurityHoldings) => a.financialProduct.id === current.financialProduct.id);
        if (securityHoldingsIndex === -1) {
          securityHoldings.push({
            financialProduct: { ...current.financialProduct },
            totalCents: current?.originalCurrency?.totalCents ?? current.totalCents ?? 0,
            quantity: current.quantity ?? 0,
          });
        } else {
          securityHoldings[securityHoldingsIndex] = {
            ...securityHoldings[securityHoldingsIndex],
            totalCents: (current?.originalCurrency?.totalCents ?? current.totalCents ?? 0) + securityHoldings[securityHoldingsIndex].totalCents,
            quantity: (current.quantity ?? 0) + securityHoldings[securityHoldingsIndex].quantity,
          };
        }

        secondaryAssetClassHoldings[assetClassIndex] = {
          ...secondaryAssetClassHoldings[assetClassIndex],
          totalCents: (current?.originalCurrency?.totalCents ?? current.totalCents ?? 0) + secondaryAssetClassHoldings[assetClassIndex].totalCents,
          securityHoldings,
        };
      }
      /* eslint no-param-reassign: "error" */
      result[index] = {
        ...result[index],
        totalCents: (current?.originalCurrency?.totalCents ?? current.totalCents ?? 0) + result[index].totalCents,
        secondaryAssetClassHoldings,
      };
    }

    return result.map((r: any) => ({
      ...r,
      percentage: (r.totalCents / totalCents) * 100,
      secondaryAssetClassHoldings: r.secondaryAssetClassHoldings.map((s: any) => ({
        ...s,
        percentage: (s.totalCents / totalCents) * 100,
        securityHoldings: s.securityHoldings.map((h: any) => ({
          ...h,
          percentage: (h.totalCents / totalCents) * 100,
        })),
      })),
    }));
  }, []);

  return primaryAssetClassHoldings;
};
