import { useState } from 'react';
import Search from '@mui/icons-material/Search';
import { gql, useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Box, Skeleton, Typography } from '../../1-primative';
import {
  MenuItem, Table, TableBody, TableCell, TableHeadCell,
  TableRow, TextField, Pagination, InputAdornment,
  Card,
  CardContent,
  SelectField,
  Badge,
} from '../../2-component';
import { FilterModal } from '../../3-pattern';
import { usePermissions } from '../../../providers/userContextProvider';
import { usePageState } from '../../../util/usePageState';
import { generateAddressString } from '../../../util';
import InstitutionViewer from './components/institutionViewer';
import NewInstitution from './components/newInstitution';
import { useLocalization } from '../../../util/useLocalization';

export const FETCH_INSTITUTIONS = gql`
  query fetchInstitutions($input: FetchInstitutionsInput!) {
    fetchInstitutions(input: $input) {
      institutions {
        id
        name
        physicalAddress{
          streetName
          city
          houseNumber
          neighborhood
          postal
          province
          unitNumber
        }
        isDraft
        state
        stateChanges
        createdAt
        updatedAt
      }
      totalCount
    }
  }
`;
export const stateInstitutionStateColor = (state: any) => (state === 'APPROVED' ? 'positive' : ['REJECTED'].includes(state) ? 'negative' : 'warning');
const calculateDaysWaiting = (bankAccount: any): number => {
  const createdDate = dayjs(bankAccount.createdAt).utc();
  const toDay = dayjs().utc();
  return toDay.diff(createdDate, 'days') || 0;
};

export const InstitutionsToReview = () => {
  const { permissions } = usePermissions();
  const { t } = useTranslation(['institutionsReview', 'client', 'shared']);
  const { localizedDate } = useLocalization();
  const [page, setPage] = usePageState(1, 'page');
  const [currentInstitution, setCurrentInstitution] = useState<any>({});
  const [pageSize, setPageSize] = usePageState(15, 'pageSize');
  const [searchText, setSearchText] = usePageState('', 'q');
  const [filterState, setFilterState] = usePageState('AWAITING_REVIEW', 'state');
  const [openViewer, setOpenViewer] = useState(false);
  const {
    loading, error, data, previousData,
  } = useQuery(FETCH_INSTITUTIONS, {
    variables: {
      input: {
        filter: {
          state: filterState === 'ANY' ? undefined : filterState,
          searchText: searchText === '' ? undefined : searchText,
        },
        pagination: {
          sortField: 'createdAt', sortDesc: filterState === 'AWAITING_REVIEW', perPage: pageSize, offSet: (page - 1) * pageSize,
        },
      },
    },
  });

  if (error) (<Typography>Error</Typography>);
  return (
    <Card loading={loading}>
      <CardContent>
        <Box display="flex" justifyContent="space-between">
          <TextField
            label=''
            leadingIcon='search'
            value={searchText}
            onChange={(event: any) => {
              setSearchText(event.target.value);
              setPage(1);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
          <Box display="flex" gap={1}>
            <FilterModal>
              <SelectField
                label={t('institutionsReview:filters.state')}
                fullWidth
                value={filterState || ''}
                onChange={(e: any) => {
                  setPage(1);
                  setFilterState(e.target.value);
                }}>
                <MenuItem value='ANY'>{t('institutionsReview:filters.any')}</MenuItem>
                <MenuItem value='AWAITING_REVIEW'>{t('institutionsReview:filters.awaitingReview')}</MenuItem>
                <MenuItem value='APPROVED'>{t('institutionsReview:filters.approved')}</MenuItem>
                <MenuItem value='REJECTED'>{t('institutionsReview:filters.rejected')}</MenuItem>
              </SelectField>
            </FilterModal>
            {permissions.includes('write:institution_basic') && <NewInstitution />}
          </Box>
        </Box>
      </CardContent>
      <Table aria-label="table">
        <TableBody>
          <TableRow>
            <TableHeadCell>{t('institutionsReview:filters.name')}</TableHeadCell>
            <TableHeadCell>{t('institutionsReview:filters.state')}</TableHeadCell>
            <TableHeadCell>{t('institutionsReview:filters.requestedDate')}</TableHeadCell>
            <TableHeadCell>{t('institutionsReview:filters.approvedDate')}</TableHeadCell>
            <TableHeadCell>{t('institutionsReview:filters.daysWaiting')}</TableHeadCell>
            <TableHeadCell>{t('institutionsReview:filters.address')}</TableHeadCell>
          </TableRow>
          { loading && !previousData && [...Array(15)].map((x: any, i: number) => (
            <TableRow key={i}>
              <TableCell><Skeleton width='100%' /></TableCell>
              <TableCell><Skeleton width='100%' /></TableCell>
              <TableCell><Skeleton width='100%' /></TableCell>
              <TableCell><Skeleton width='100%' /></TableCell>
              <TableCell><Skeleton width='100%' /></TableCell>
              <TableCell><Skeleton width='100%' /></TableCell>
            </TableRow>
          ))}
          {(data || previousData)?.fetchInstitutions?.institutions?.map((institution: any) => (
            <TableRow
              hover
              pointer
              key={institution.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 }, textDecoration: 'none' }}
              onClick={() => {
                setCurrentInstitution(institution);
                setOpenViewer(true);
              }}
            >
              <TableCell component="th" scope="row">
                {institution.name}
              </TableCell>
              <TableCell component="th" scope="row">
                <Badge label={t(`bankAccountsReview:filters.stateOptions.${institution.state}`)} color={stateInstitutionStateColor(institution.state)} />
              </TableCell>
              <TableCell component="th" scope="row">
                <Typography fontSize='small'>{localizedDate(institution.createdAt)}</Typography>
              </TableCell>
              <TableCell component="th" scope="row">
                {institution.state !== 'APPROVED' ? '-' : (<Typography fontSize='small'>{localizedDate(institution.updatedAt)}</Typography>)}
              </TableCell>
              <TableCell component="th" scope="row" sx={{ color: calculateDaysWaiting(institution) > 3 ? 'red' : 'inherit' }}>
                {['APPROVED', 'REJECTED'].includes(institution.state) && '-'}
                {institution.state === 'AWAITING_REVIEW'
                  && (<Badge label={calculateDaysWaiting(institution).toString()} color={calculateDaysWaiting(institution) > 3 ? 'negative' : 'positive'} />)}
              </TableCell>
              <TableCell component="th" scope="row">
                {generateAddressString(institution.physicalAddress)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Pagination
        onChangePerPage={setPageSize}
        count={Math.ceil(((data || previousData)?.fetchInstitutions?.totalCount ?? 0) / pageSize)}
        page={page}
        onChange={(_e, newPage) => setPage(newPage)}
        sx={{
          p: 1,
          textAlign: 'right',
          '.MuiPagination-ul': {
            justifyContent: 'end',
          },
        }}
      />
      <InstitutionViewer
        open={openViewer}
        handleClose={() => setOpenViewer(false)}
        institution={currentInstitution}
      />
    </Card>
  );
};

export default InstitutionsToReview;
