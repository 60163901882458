import { useTranslation } from 'react-i18next';
import { Box, Typography } from '../../1-primative';
import { useThemeTokens } from '../../../providers/themeTokenProvider';
import RiskProfileIcon from '../../../assets/images/risk-profile/riskProfile.svg';

const styleBox = {
  display: 'flex',
  alignItems: 'left',
  borderRadius: '8px',
  padding: '12px 16px 12px 16px',
  backgroundColor: '#fffff',
  border: '1px solid #DCDDDF',
  boxShadow: '0 4px 6px #DCDDDF',
  gap: '8px',
};

const innerStyleBox = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

export const RiskLevelBox = ({
  testId,
  riskLevel,
  riskLevelDescription,
}: {
  testId?: string;
  riskLevel: string;
  riskLevelDescription?: string;
}) => {
  const { sys } = useThemeTokens();
  const { t } = useTranslation('riskProfile');

  return (
    <>
      <Box
        data-testid={testId ?? 'risk-level-box'}
        sx={styleBox}>
        <Box display={'flex'} alignItems={'center'}>
          <Box sx={innerStyleBox}>
            <img src={RiskProfileIcon} alt='risk-profile-icon'></img>
          </Box>
          <Box sx={innerStyleBox} ml={1}>
            <Typography display={'contents'} weight='bold' variant='bodyLarge' >{t('riskProfileIs')}
            <Box sx={{ color: sys.color.primary, marginLeft: '5px', display: 'inline-block' }}>{riskLevel}</Box>
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box margin={'20px 0'}>
        <Typography sx={{ fontSize: '14px', lineHeight: '20px', letterSpacing: '0.5px' }}>{riskLevelDescription}</Typography>
      </Box>
    </>
  );
};
