import { gql, useMutation } from '@apollo/client';
import { getOrganizationUserName, OrganizationUser } from 'interfaces';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../../../../providers/userContextProvider';
import { ConfirmationModal } from '../../../../3-pattern';

const INVITE_ORGANIZATION_USERS = gql`
mutation inviteOrganizationUsers($input: InviteOrganizationUsersInput!) {
  inviteOrganizationUsers(input: $input) {
    success
  }
}
`;

const SendOrganizationUserInvitations = ({
  open,
  onComplete,
  handleClose,
  organizationUser,
  pendingUsersCount,
}: {
  open: boolean,
  onComplete: () => void,
  handleClose: () => void,
  organizationUser?: OrganizationUser,
  pendingUsersCount?: number,
}) => {
  const { t } = useTranslation(['orgSettings']);
  const { activeOrganization } = useContext(UserContext);

  const [inviteOrganizationUsers, { loading }] = useMutation(INVITE_ORGANIZATION_USERS, {
    variables: {
      input: {
        organizationId: activeOrganization.id,
        ...(organizationUser ? { organizationUserId: organizationUser.id } : {}),
      },
    },
  });

  const inviteUsers = async () => {
    await inviteOrganizationUsers();
    onComplete();
  };

  const title = organizationUser ? t('inviteSingleUserConfirmationModal.title') : t('inviteUsersConfirmationModal.title');
  const body = organizationUser ? (
    t('inviteSingleUserConfirmationModal.body', { name: getOrganizationUserName(organizationUser), email: organizationUser.email })
  ) : t('inviteUsersConfirmationModal.body', { total: pendingUsersCount });

  return (
    <ConfirmationModal
      onCancel={handleClose}
      onConfirm={inviteUsers}
      open={open}
      loading={loading}
      title={title}
      bodyText={body}
      testIdPrefix='send-organization-user'
    >
    </ConfirmationModal>
  );
};

export default SendOrganizationUserInvitations;
