import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useContext, useState, useEffect } from 'react';
import {
  isUndefined, union, remove,
} from 'lodash/fp';
import NewProduct from './components/newProduct';
import EditProduct from './components/editProduct';
import { usePermissions, UserContext } from '../../../providers/userContextProvider';
import { formatMoneyValue } from '../../../util';
import { usePageState } from '../../../util/usePageState';
import { textColumnStyles, useControlColumnStyles } from '../../../util/styles';
import { Exchanges } from '../../../interfaces/financialProduct';
import {
  Typography, Box,
  Skeleton,
} from '../../1-primative';
import {
  Table, TableRow, TableCell, TableBody, Pagination, TextField,
  MenuItem, Card, CardContent, SelectField, TableHeadCell, Badge,
  Checkbox,
} from '../../2-component';
import {
  AssetClassSelect,
  FilterModal,
} from '../../3-pattern';
import TaxRank from '../../4-module/widgets/portfolio/components/taxRank';
import DownloadProductShelf from './downloadProductShelf';

export const FETCH_FINANCIAL_PRODUCTS = gql`
  query fetchFinancialProducts($input: FetchFinancialProductsInput!) {
    fetchFinancialProducts(input: $input) {
      financialProducts {
        id
        url
        translatedName {
          en
          fr
        }
        ticker
        exchange
        currency
        originalCurrency
        originalCurrentPriceCents
        currentPriceCents
        autoUpdatePrices
        primaryAssetClass {
          id
          translatedName {
            en
          }
        }
        secondaryAssetClass {
          id
          translatedName {
            en
          }
        },
        tertiaryAssetClass {
          id
          translatedName {
            en
          }
        },
        substituteFinancialProduct {
          id
          ticker
          translatedName {
            en
          }
        },
        schedule{
          id
          name
          nextDate
        }
        waivedFeePercentage
        taxRank
        volatile
        settlementDays,
        state
        isPartial
        cusip
        isin
        ric
      }
      totalCount
    }
  }
`;

export const parseToBoolean = (value: string): boolean => {
  if (value.toLowerCase() === 'true') return true;
  if (value.toLowerCase() === 'false') return false;
  return false;
};

const isFilterActive = ({
  searchString,
  filterByExchange,
  filterByPrimaryAssetClassId,
  filterBySecondaryAssetClassId,
  filterByIsAutoUpdatePrices,
  filterByHasSubstituteProduct,
  filterByIsScheduled,
}: {
  searchString: string,
  filterByExchange: string,
  filterByPrimaryAssetClassId: string,
  filterBySecondaryAssetClassId: string
  filterByIsAutoUpdatePrices: string
  filterByHasSubstituteProduct: string,
  filterByIsScheduled: string,
}) => {
  if (searchString !== '') return true;
  if (!isUndefined(filterByExchange) && filterByExchange !== 'ANY') return true;
  if (!isUndefined(filterByPrimaryAssetClassId) && filterByPrimaryAssetClassId !== 'ANY') return true;
  if (!isUndefined(filterBySecondaryAssetClassId) && filterBySecondaryAssetClassId !== 'ANY') return true;
  if (!isUndefined(filterByIsAutoUpdatePrices) && filterByIsAutoUpdatePrices !== 'ANY') return true;
  if (!isUndefined(filterByHasSubstituteProduct) && filterByHasSubstituteProduct !== 'ANY') return true;
  if (!isUndefined(filterByIsScheduled) && filterByIsScheduled !== 'ANY') return true;
  return false;
};

export const ProductShelf = () => {
  const { permissions } = usePermissions();
  const { activeOrganization } = useContext(UserContext);
  const { t } = useTranslation(['configureModels']);
  const [productOpen, setProductOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<any>(null);
  const [page, setPage] = usePageState(1, 'page');
  const pageSize = 15;
  const [filterByExchange, setFilterByExchange] = usePageState('ANY', 'exchange');
  const [filterByPrimaryAssetClassId, setFilterByPrimaryAssetClassId] = usePageState('ANY', 'primaryClass');
  const [filterBySecondaryAssetClassId, setFilterBySecondaryAssetClassId] = usePageState('ANY', 'secondaryClass');
  const [filterByHasSubstituteProduct, setFilterByHasSubstituteProduct] = usePageState('ANY', 'hasSubstitute');
  const [filterByIsScheduled, setFilterByIsScheduled] = usePageState('ANY', 'isScheduled');
  const [filterByIsAutoUpdatePrices, setFilterByIsAutoUpdatePrices] = usePageState('ANY', 'isAutoUpdatePrices');
  const [searchString, setSearchString] = usePageState('', 'q');
  const [selected, setSelected] = useState<string[]>([]);
  const { columnStyles, wrapperStyles, customStyle } = useControlColumnStyles();
  const [selectedPerPage, setSelectedPerPage] = useState<Record<number, boolean>>({});

  const {
    loading, error, data, refetch, previousData,
  } = useQuery(FETCH_FINANCIAL_PRODUCTS, {
    skip: !activeOrganization.id,
    variables: {
      input: {
        filter: {
          organizationId: activeOrganization.id,
          searchText: searchString !== '' ? searchString : undefined,
          exchange: (filterByExchange.toLowerCase() !== 'any') ? filterByExchange : undefined,
          primaryAssetClassId: (filterByPrimaryAssetClassId.toLowerCase() !== 'any') ? filterByPrimaryAssetClassId : undefined,
          secondaryAssetClassId: (filterBySecondaryAssetClassId.toLowerCase() !== 'any') ? filterBySecondaryAssetClassId : undefined,
          hasSubstituteProduct: (filterByHasSubstituteProduct.toLowerCase() !== 'any') ? parseToBoolean(filterByHasSubstituteProduct) : undefined,
          isScheduled: (filterByIsScheduled.toLowerCase() !== 'any') ? parseToBoolean(filterByIsScheduled) : undefined,
          autoUpdatePrices: (filterByIsAutoUpdatePrices.toLowerCase() !== 'any') ? parseToBoolean(filterByIsAutoUpdatePrices) : undefined,
        },
        pagination: {
          sortField: 'ticker', sortDesc: false, perPage: pageSize, offset: (page - 1) * pageSize,
        },
      },
    },
  });

  const exchanges = Object.keys(Exchanges).filter((key) => isNaN(Number(key))).sort();

  const handleSelectAll = (checked: boolean) => {
    if (!data?.fetchFinancialProducts?.financialProducts) return;

    const currentPageIds = data.fetchFinancialProducts.financialProducts.map((x: any) => x.id);

    if (checked) {
      setSelected(union(selected, currentPageIds));
      setSelectedPerPage({ ...selectedPerPage, [page]: true });
    } else {
      setSelected(selected.filter((id) => !currentPageIds.includes(id)));
      setSelectedPerPage({ ...selectedPerPage, [page]: false });
    }
  };

  const handleIndividualSelect = (checked: boolean, productId: string) => {
    if (checked) {
      setSelected(union(selected, [productId]));
    } else {
      setSelected(remove((x: string) => x === productId, selected));
    }

    const currentPageIds = data?.fetchFinancialProducts?.financialProducts.map((x: any) => x.id) || [];
    const allCurrentPageSelected = currentPageIds.every((id: string) => (checked
      ? union(selected, [productId]).includes(id)
      : remove((x: string) => x === productId, selected).includes(id)));

    setSelectedPerPage({ ...selectedPerPage, [page]: allCurrentPageSelected });
  };

  useEffect(() => {
    if (data?.fetchFinancialProducts?.financialProducts) {
      const currentPageIds = data.fetchFinancialProducts.financialProducts.map((x: any) => x.id);
      const allCurrentPageSelected = currentPageIds.every((id: string) => selected.includes(id));
      setSelectedPerPage((prev) => ({ ...prev, [page]: allCurrentPageSelected }));
    }
  }, [page, data?.fetchFinancialProducts?.financialProducts, selected]);

  useEffect(() => {
    setSelected([]);
    setSelectedPerPage({});
  }, [searchString, filterByExchange, filterByPrimaryAssetClassId, filterBySecondaryAssetClassId,
    filterByIsAutoUpdatePrices, filterByHasSubstituteProduct, filterByIsScheduled]);

  if (error) (<Typography>Error</Typography>);

  return (
    <Card loading={loading}>
      <CardContent>
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          <TextField
            label=''
            sx={{ width: '300px', m: 1 }}
            value={searchString}
            onChange={(event: any) => {
              setSearchString(event.target.value);
              setPage(1);
            }}
            leadingIcon='search'
          />
          <Box display='flex'>
            <FilterModal filterExists={isFilterActive({
              searchString,
              filterByExchange,
              filterByPrimaryAssetClassId,
              filterBySecondaryAssetClassId,
              filterByIsAutoUpdatePrices,
              filterByHasSubstituteProduct,
              filterByIsScheduled,
            })}>
              <Box display='flex' flexDirection='column' gap={2}>
                <SelectField label={t('filters.exchange')} fullWidth
                  value={filterByExchange || ''}
                  onChange={(e: any) => {
                    setFilterByExchange(e.target.value);
                    setPage(1);
                  }}
                >
                  <MenuItem key='ANY' value='ANY'>{t('components:any')}</MenuItem>
                  {exchanges.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </SelectField>
                <AssetClassSelect
                  value={filterByPrimaryAssetClassId}
                  omitAny={false}
                  setAssetClass={(e) => {
                    setFilterByPrimaryAssetClassId(e.target.value);
                    setPage(1);
                  }}
                  label={t('filters.primaryAssetClass')}
                />
                <AssetClassSelect
                  value={filterBySecondaryAssetClassId}
                  omitAny={false}
                  setAssetClass={(e) => {
                    setFilterBySecondaryAssetClassId(e.target.value);
                    setPage(1);
                  }}
                  label={t('filters.secondaryAssetClass')}
                />
                <SelectField label={t('filters.hasSubstitute')} fullWidth
                  value={filterByHasSubstituteProduct || 'ANY'}
                  onChange={(e: any) => {
                    setFilterByHasSubstituteProduct(e.target.value);
                    setPage(1);
                  }}
                >
                  <MenuItem key='ANY' value='ANY'>{t('components:any')}</MenuItem>
                  <MenuItem key='true' value='true'>{t('components:booleanOptions.yes')}</MenuItem>
                  <MenuItem key='false' value='false'>{t('components:booleanOptions.no')}</MenuItem>
                </SelectField>
                <SelectField label={t('filters.isScheduled')} fullWidth
                  value={filterByIsScheduled || 'ANY'}
                  onChange={(e: any) => {
                    setFilterByIsScheduled(e.target.value);
                    setPage(1);
                  }}
                >
                  <MenuItem key='ANY' value='ANY'>{t('components:any')}</MenuItem>
                  <MenuItem key='true' value='true'>{t('components:booleanOptions.yes')}</MenuItem>
                  <MenuItem key='false' value='false'>{t('components:booleanOptions.no')}</MenuItem>
                </SelectField>
                <SelectField label={t('filters.autoUpdatePrices')} fullWidth
                  value={filterByIsAutoUpdatePrices || 'ANY'}
                  onChange={(e: any) => {
                    setFilterByIsAutoUpdatePrices(e.target.value);
                    setPage(1);
                  }}
                >
                  <MenuItem key='ANY' value='ANY'>{t('components:any')}</MenuItem>
                  <MenuItem key='true' value='true'>{t('components:booleanOptions.yes')}</MenuItem>
                  <MenuItem key='false' value='false'>{t('components:booleanOptions.no')}</MenuItem>
                </SelectField>
              </Box>
            </FilterModal>
            {permissions.includes('read:financial_products_snapshots') && (
              <DownloadProductShelf
                filter={{
                  searchString,
                  filterByExchange,
                  filterByPrimaryAssetClassId,
                  filterBySecondaryAssetClassId,
                  filterByIsAutoUpdatePrices,
                  filterByHasSubstituteProduct,
                  filterByIsScheduled,
                }}
                selected={selected}
                onDownloadComplete={() => setSelected([])}
              />
            )}
            {permissions.includes('write:financial_products') && (
              <NewProduct afterCreate={refetch} />
            )}
          </Box>
        </Box>
      </CardContent>
      <Box sx={{ overflowX: 'auto' }}>
        <Table aria-label="table" sx={{ minWidth: '1400px' }}>
          <TableBody>
            <TableRow>
              <TableHeadCell>
                <Box display='flex' alignItems='center' justifyContent='center'>
                  <Checkbox
                    checked={selectedPerPage[page] || false}
                    onChange={(checked: boolean) => handleSelectAll(checked)}
                    customStyle={{
                      margin: 0,
                      padding: 0,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  />
                </Box>
              </TableHeadCell>
              <TableHeadCell>{t('table.ticker')}</TableHeadCell>
              <TableHeadCell>{t('table.name')}</TableHeadCell>
              <TableHeadCell>{t('table.exchange')}</TableHeadCell>
              <TableHeadCell>{t('table.primaryAssetClass')}</TableHeadCell>
              <TableHeadCell>{t('table.secondaryAssetClass')}</TableHeadCell>
              <TableHeadCell>{t('table.substituteProduct')}</TableHeadCell>
              <TableHeadCell>{t('table.taxRank')}</TableHeadCell>
              <TableHeadCell>{t('table.tradeSchedule')}</TableHeadCell>
              <TableHeadCell>{t('table.currency')}</TableHeadCell>
              <TableHeadCell right>{t('table.currentPrice')}</TableHeadCell>
              <TableHeadCell>{t('table.originalCurrency')}</TableHeadCell>
              <TableHeadCell right>{t('table.originalPrice')}</TableHeadCell>
              <TableHeadCell right>{t('table.autoUpdatePrices')}</TableHeadCell>
            </TableRow>
            {loading && !previousData && [...Array(15)].map((x: any, i: number) => (
              <TableRow key={i}>
                <TableCell><Skeleton width='100%' /></TableCell>
                <TableCell><Skeleton width='100%' /></TableCell>
                <TableCell><Skeleton width='100%' /></TableCell>
                <TableCell><Skeleton width='100%' /></TableCell>
                <TableCell><Skeleton width='100%' /></TableCell>
                <TableCell><Skeleton width='100%' /></TableCell>
                <TableCell><Skeleton width='100%' /></TableCell>
                <TableCell><Skeleton width='100%' /></TableCell>
                <TableCell><Skeleton width='100%' /></TableCell>
                <TableCell><Skeleton width='100%' /></TableCell>
                <TableCell><Skeleton width='100%' /></TableCell>
                <TableCell><Skeleton width='100%' /></TableCell>
                <TableCell><Skeleton width='100%' /></TableCell>
              </TableRow>
            ))}
            {(data || previousData)?.fetchFinancialProducts?.financialProducts?.map((product: any) => (
              <TableRow
                hover
                onClick={() => {
                  if (permissions.includes('write:financial_products')) {
                    setSelectedProduct(product);
                    setProductOpen(true);
                  }
                }}
                key={product.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 }, textDecoration: 'none', cursor: 'pointer' }}
              >
                <TableCell sx={columnStyles}>
                  <div style={wrapperStyles}>
                    <Checkbox
                      checked={selected.includes(product.id)}
                      onClick={(e: any) => e.stopPropagation()}
                      onChange={(checked: boolean) => handleIndividualSelect(checked, product.id)}
                      customStyle={customStyle}
                    />
                  </div>
                </TableCell>
                <TableCell component="th" scope="row">
                  {product.ticker}
                </TableCell>
                <TableCell>
                  <Typography sx={textColumnStyles}>{product.translatedName.en}</Typography>
                </TableCell>
                <TableCell><Badge label={product.exchange} /></TableCell>
                <TableCell><Typography sx={textColumnStyles}>{product.primaryAssetClass?.translatedName?.en}</Typography></TableCell>
                <TableCell><Typography sx={textColumnStyles}>{product.secondaryAssetClass?.translatedName?.en}</Typography></TableCell>
                <TableCell title={product.substituteFinancialProduct?.translatedName?.en}>{product.substituteFinancialProduct?.ticker}</TableCell>
                <TableCell component="th" scope="row">
                  {product.taxRank && (<TaxRank taxRank={product.taxRank} />)}
                </TableCell>
                <TableCell>{product?.schedule ? t('table.planned') : t('table.noSchedule')}</TableCell>
                <TableCell><Badge label={product.currency} /></TableCell>
                <TableCell number>{formatMoneyValue(product.currentPriceCents)}</TableCell>
                <TableCell><Badge label={product.originalCurrency} /></TableCell>
                <TableCell number>{formatMoneyValue(product.originalCurrentPriceCents)}</TableCell>
                <TableCell right>{product?.autoUpdatePrices ? t('table.yes') : t('table.no')}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
      <Pagination
        count={Math.ceil(((data || previousData)?.fetchFinancialProducts?.totalCount ?? 0) / pageSize)}
        page={page}
        onChange={(_e, newPage) => setPage(newPage)}
        sx={{
          p: 1,
          textAlign: 'right',
          '.MuiPagination-ul': {
            justifyContent: 'end',
          },
        }}
      />
      {permissions.includes('write:financial_products') && (
        <EditProduct afterUpdate={() => {
          setProductOpen(false);
          refetch();
        }} productToUpdate={selectedProduct} open={productOpen} handleClose={() => setProductOpen(false)} />
      )}
    </Card>
  );
};

export default ProductShelf;
