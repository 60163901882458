import { Square } from '@mui/icons-material';
import { useState } from 'react';
import { ChromePicker } from 'react-color';
import { Box } from '../../1-primative';
import { InputAdornment, TextField } from '../textField/textField';

interface ColorPickerFieldProps {
  label: string,
  value: string,
  onChange: (event: any) => void,
  fullWidth?: boolean,
  testId?: string,
}
export const ColorPickerField = (props: ColorPickerFieldProps) => {
  const [open, setOpen] = useState(false);
  const style = {
    popover: {
      position: 'absolute',
      zIndex: '2',
      left: '10px',
      top: '60px',
    },
    cover: {
      position: 'fixed',
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px',
    },
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ position: 'relative' }}>
      <TextField
        label={props.label}
        fullWidth={props.fullWidth}
        value={props.value}
        onClick={handleClickOpen}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Square style={{ color: props.value }} />
            </InputAdornment>
          ),
        }}
        onChange={(e: any) => {
          props.onChange(e.target.value);
        }}
        testId={props.testId}
      />
      {open && (
        <Box sx={style.popover}>
          <Box sx={style.cover} onClick={handleClose} />
          <ChromePicker
            color={props.value}
            onChangeComplete={(e) => {
              props.onChange(e.hex);
            }}
          />
        </Box>
      )}
    </Box>
  );
};
