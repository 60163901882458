import { gql, useMutation } from '@apollo/client';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CountryCodes } from '@onevesthq/ov-enums';
import { Add } from '@mui/icons-material';
import { Box } from '../../../1-primative';
import {
  AddressField, Button, Dialog, DialogContent, DialogFooter, DialogTitle, Form, TextField,
} from '../../../2-component';
import { FETCH_AWAITING_INSTIUTIONS_COUNT } from '../../../../providers/globalStatsHooks';
import { PhysicalAddress } from '../../../../interfaces';

export const CREATE_INSTITUTION = gql`
  mutation createInstitution($input: CreateInstitutionInput!) {
    createInstitution(input: $input) {
      institution {
        id
        name
      }
    }
  }
`;

export const FETCH_INSTITUTIONS = gql`
  query fetchInstitutions($input: FetchInstitutionsInput!) {
    fetchInstitutions(input: $input) {
      institutions {
        id
        name
        physicalAddress {
          city
          province
          streetName
          postal
          unitNumber
          houseNumber
          neighborhood
        }
      }
    }
  }
`;

const NewInstitution = () => {
  const { t } = useTranslation(['institutionsReview', 'shared']);
  const [open, setOpen] = useState(false);
  const [institutionName, setInstitutionName] = useState('');
  const [physicalAddress, setPhysicalAddress] = useState<PhysicalAddress>({
    city: '',
    country: CountryCodes.CA,
    houseNumber: '',
    neighborhood: '',
    postal: '',
    province: '',
    streetName: '',
    unitNumber: '',
  });
  const [createInstitution] = useMutation(CREATE_INSTITUTION, {
    variables: {
      input: {
        isDraft: true,
        name: institutionName,
        physicalAddress: {
          ...physicalAddress,
          province: physicalAddress.jurisdiction || '',
        },
      },
    },
    refetchQueries: [FETCH_INSTITUTIONS, FETCH_AWAITING_INSTIUTIONS_COUNT],
  });

  const onCreate = async (event: any) => {
    await createInstitution();
    setInstitutionName('');
    setPhysicalAddress({
      city: '',
      country: CountryCodes.CA,
      houseNumber: '',
      neighborhood: '',
      postal: '',
      province: '',
      streetName: '',
      unitNumber: '',
    });
    setOpen(false);
  };

  return (
    <>
      <Button onClick={() => setOpen(true)} label={t('shared:add')} leadingIcon={Add}/>
      <Dialog open={open} onClose={() => setOpen(false)} maxWidth='sm' fullWidth>
        <DialogTitle onClose={() => setOpen(false)}>{t('institutionsReview:addModal.title')}</DialogTitle>
        <Form onSubmit={onCreate}>
          <DialogContent>
            <Box display='flex' flexDirection='column' gap={2}>
              <TextField
                value={institutionName}
                label={t('institutionsReview:filters.name')}
                fullWidth
                onChange={(e: any) => setInstitutionName(e.target.value)}
                data-testid="institution-name"
              />
              <Box>
                <AddressField address={physicalAddress} label={t('institutionsReview:addModal.address')} onChange={(e) => setPhysicalAddress(e)} manualAddressEntry />
              </Box>
            </Box>
          </DialogContent>
          <DialogFooter>
            <Box display='flex' justifyContent='flex-end'>
              <Button label={t('shared:create')} type='submit' />
            </Box>
          </DialogFooter>
        </Form>
      </Dialog>
    </>
  );
};

export default NewInstitution;
