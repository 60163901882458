import { Link, TableCell } from '@mui/material';
import dayjs from 'dayjs';
import isNumber from 'lodash/isNumber';
import { useTranslation } from 'react-i18next';
import { DateTime } from '../../../../components/misc/dateTime/dateTime';

const isTimeValue = (val: string): boolean => {
  if (['am', 'pm'].includes(val.slice(-2))) {
    return isNumber(Date.parse(val.replace('am', '').replace('pm', '')));
  }
  if (val.match(/^\d\d\d\d-\d\d-\d\dT\d\d:\d\d:\d\d/)) {
    return true;
  }
  return false;
};

const generateLocalFormat = (time: string) => {
  const dayjsLocal = dayjs(time.replace('am', ' am').replace('pm', ' pm'));
  return dayjsLocal;
};

const ReportTableCell = ({ content, columnName }: { content: any, columnName: string }) => {
  const { t } = useTranslation(['customReports', 'shared']);

  if (columnName === 'userLink' || columnName === 'primaryUserLink') {
    return <TableCell>
      <Link href={content} target='blank'>{t('link')}</Link>
    </TableCell>;
  }

  if (isTimeValue(content) && /\d/.test(content)) {
    return <TableCell>
      <DateTime variant='body2' date={generateLocalFormat(content)} timezone='MST' />
    </TableCell>;
  }

  return <TableCell>{content}</TableCell>;
};

export default ReportTableCell;
